import {
  alpha,
  AppBar,
  Box,
  ButtonBase,
  ButtonGroup,
  Divider,
  Fab,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  styled,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { ReactComponent as AddSvg } from 'common/images/icons/Utility/add.svg';
import { ReactComponent as FadeSvg } from 'common/images/icons/Camera/Day and Night.svg';
import { ReactComponent as RecordCircleSvg } from 'common/images/icons/Camera/Record.svg';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate three.svg';
import { ReactComponent as DotsSvg } from 'common/images/icons/Utility/dots.svg';
import { ReactComponent as DupSvg } from 'common/images/icons/Camera/Duplicate.svg';
import { ReactComponent as TrashSvg } from 'common/images/icons/Utility/trash.svg';
import { ReactComponent as UpSvg } from 'common/images/icons/Utility/up pointer.svg';
import { ReactComponent as DownSvg } from 'common/images/icons/Utility/down pointer.svg';
import { ReactComponent as PlaySvg } from 'common/images/icons/Utility/play.svg';
import { first, indexOf, intersection, isEmpty, isError, isNil, isUndefined, max, min, sum } from 'lodash';
import { toTimestampString } from 'common/utils/string';
import { t } from '@lingui/macro';
import Tooltip from 'common/components/tooltip/tooltip.component';
import IconTooltip from 'common/components/tooltip/iconTooltip.component';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import React, { forwardRef, FunctionComponent, ReactNode, useRef, useState } from 'react';
import {
  IPersonalizedClipSlot,
  IPersonalizedVideoClip,
  ISingleClipSlot,
  ITarget,
  ITargetInput,
  ITimelineObject,
  Maybe,
} from 'data/_generated';
import { DraggableTypes, isInitializedSlot, isPersonalizedClipSlot, isSingleClipSlot, isTransitionSlot, SlotDragItem } from 'edit/const';
import { useFeatureFlag } from 'config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from 'config/models/featureFlags';
import LogRocket from 'logrocket';
import AlertError from 'common/components/alert/alertError.component';
import { useDrag, useDrop } from 'react-dnd';
import { convertToUpdateTarget } from 'edit/helper';
import { trackEvent } from 'common/utils/errors';
import { getTrimmedDuration } from './utils';
import usePersonalizedIntroMax from 'common/hooks/usePersonalizedIntroMax';
import { TooltipContent as MaxRecipientTooltip } from './targetCreation/personalizedIntroLimit.component';
import { PALETTE_COLOR } from '../../config/models/themeOptions';

const TIMELINE_BOX_HEIGHT = '64px';
const Container = styled(AppBar)(({ theme }) => ({
  padding: theme.spacing(3),
  overflowX: 'auto',
  overflowY: 'hidden',
  //https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-stop snapping 
  scrollSnapType: 'x mandatory',
  'button': {
    scrollSnapAlign: 'center',
    scrollSnapStop: 'always',
  },
}));

type TrapezoidBoxProps = {
  isOpen: boolean;
};
const TrapezoidBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isOpen' })<TrapezoidBoxProps>(({ isOpen }) => ({
  display: 'inline-block',
  color: 'black',
  transformStyle: 'preserve-3d',
  padding: '0.5rem 1rem',
  border: `1px solid ${PALETTE_COLOR.neutral[200]}`,
  transform: `perspective(80px) rotateX(35deg) translateY(${isOpen ? '2px' : '1px'})`,
  backfaceVisibility: 'hidden',
  width: '160px',
  alignSelf: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '5px 5px 0 0',
  background: PALETTE_COLOR.neutral[0],
  borderBottomColor: isOpen ? PALETTE_COLOR.neutral[0] : PALETTE_COLOR.neutral[200],
}));
const Heading = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const TimelineButton = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodySemibold,
  fontFamily: 'inherit',
  scrollSnapStop: 'normal',
  display: 'flex',
  flexDirection: 'column',
  width: 94,
  height: TIMELINE_BOX_HEIGHT,
  backgroundColor: 'white',
  borderRadius: 8,
  border: '1px solid',
  padding: '18px 8px',
  alignItems: 'stretch',
  justifyItems: 'center',
  marginLeft: 0,
  transition: 'margin-left .5s',
  color: theme.palette.neutral[200],
  '&.is-filled': {
    color: theme.palette.primary[500],
    borderColor: theme.palette.primary[200],
  },
  '&.is-active': {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[500],
    borderColor: theme.palette.primary[300],
  },
  ':focus': {
    borderColor: theme.palette.primary[400],
  },
  ':hover': {
    borderColor: theme.palette.primary[500],
  },
}));

type DropIndicatorProps = {
  /** whether to show the indicator or not */
  show: boolean,
};

/**
 * Component for indicating where a slot can be dropped
 */
function DropIndicator({ show }: DropIndicatorProps) {
  return (
    <Divider
      orientation='vertical'
      flexItem
      sx={{
        mr: 2,
        display: show ? 'block' : 'none',
        backgroundColor: (theme) => theme.palette.primary[500],
        width: 2,
      }}
    />
  );
}

type TimelineButtonGroupProps = {
  isFilled: boolean;
  isActive: boolean;
  disabled?: boolean;
  thumbnail?: string;
  timestamp: string;
  title: string;
  onClick: () => void;
  onDelete?: () => void | Promise<void>;
  onDuplicate?: () => void | Promise<void>;
  /** callback on updating slot order */
  onUpdateOrder?: (slotIndex: number, newIndex: number) => void | Promise<void>;
  /** slot index  */
  slotIndex: number;
};

/** Component to show Single Clip with Context menu to delete and duplicate */
function TimelineButtonGroup({
  isFilled,
  disabled,
  isActive,
  thumbnail,
  timestamp,
  title,
  onClick,
  onDelete,
  onDuplicate,
  onUpdateOrder,
  slotIndex,
}: TimelineButtonGroupProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const titleId = title.replace(/\s+/g, '_');
  const buttonId = `dropdown-button-${titleId}`;
  const menuId = `dropdown-menu-${titleId}`;

  const canDragAndDrop = !isUndefined(onUpdateOrder);
  const [dragProps, drag] = useDrag(() => ({
    type: DraggableTypes.SLOT,
    item: { originalIndex: slotIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: canDragAndDrop,
  }), [slotIndex, canDragAndDrop]);

  const [dropProps, drop] = useDrop(() => ({
    accept: DraggableTypes.SLOT,
    drop: async (item: SlotDragItem) => {
      if (item.originalIndex !== slotIndex && onUpdateOrder) {
        await onUpdateOrder(item.originalIndex, slotIndex);
      }
    },
    collect: (monitor) => ({
      isOver: canDragAndDrop ? monitor.isOver() : false,
    }),
    canDrop: (_item, _monitor) => canDragAndDrop,
  }), [onUpdateOrder, slotIndex, canDragAndDrop]);
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(true);
  };

  const handleDuplicate = async () => {
    if (!onDuplicate) return;
    setIsOpen(false);
    setIsLoading(true);
    await onDuplicate();
    setIsLoading(false);
  };

  const handleDelete = async () => {
    if (!onDelete) return;
    setIsOpen(false);
    setIsLoading(true);
    await onDelete();
    setIsLoading(false);
  };

  return <ButtonGroup
    sx={{
      position: 'relative',
      [`&:hover #${buttonId}`]: { visibility: 'visible' },
      opacity: dragProps.isDragging ? 0.2 : 1,
    }}
    onContextMenu={handleContextMenu}>
    <DropIndicator show={dropProps.isOver} />
    <TimelineButton
      disabled={disabled}
      className={`
        ${isActive ? 'is-active' : ''} 
        ${isFilled ? 'is-filled' : ''}
      `}
      onClick={onClick}
      ref={(node) => drag(drop(node))}
    >
      <Typography component="span"
        width="100%" display="flex" flexDirection="row" justifyContent="space-between" variant="body1">
        <Typography
          lineHeight={1}
          component="span"
          noWrap
          textOverflow="ellipsis">{title}</Typography>
        <SvgIcon
          sx={{
            animation: isLoading ? 'rotating 2s linear infinite' : 'none',
          }}
          fontSize="inherit"
          component={isLoading ? RotateSvg : (isActive ? RecordCircleSvg : ConfirmSvg)}
          inheritViewBox />
      </Typography>
      <Box sx={{
        mt: 2,
        px: 1,
        borderRadius: '5px',
        color: isFilled ? PALETTE_COLOR.neutral[0] : PALETTE_COLOR.neutral[600],
        backgroundColor: isFilled ? (thumbnail ? PALETTE_COLOR.neutral[600] : 'none') : PALETTE_COLOR.neutral[100],
        flexGrow: 1,
        minHeight: '100%',
        textAlign: 'right',
        position: 'relative',
        overflow: 'hidden',
      }}>{thumbnail ?
          <>
            <ImageThumbnail
                alt={title}
                src={thumbnail}
                crossOrigin=""
            />
            <Box
            sx={{
              position: 'absolute',
              top: 0, left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: alpha(PALETTE_COLOR.neutral[500], 0.4),
            }}/>
          </>
        : (isFilled ? <Skeleton variant={'rounded'} animation={'wave'} sx={{
          position: 'absolute',
          display: 'block',
          top: 0, left: 0,
          width: '100%', height: '100%', objectFit: 'cover',
          transform: 'none',
        }} ></Skeleton> : undefined)}
        <Typography variant="bodySemibold" position="relative">{timestamp}</Typography>
      </Box>
    </TimelineButton>
    {(!isUndefined(onDelete) || !isUndefined(onDuplicate)) &&
      <>
        <Fab
          id={buttonId}
          sx={(theme) => ({
            position: 'absolute',
            top: 2, right: 2, height: 26, minHeight: 0, width: 26,
            padding: 0,
            fontSize: '18px',
            visibility: 'hidden',
            //TODO: need to work on keyboard a11y for the menu
            color: theme.palette.neutral[0],
            background: theme.palette.neutral[600],
            '&:focus,&:hover': {
              visibility: 'visible',
              background: theme.palette.primary.main,
            },
            '&[aria-expanded]': {
              visibility: 'visible',
              background: theme.palette.neutral[900],
            },
          })}
          ref={anchorEl}
          aria-controls={isOpen ? menuId : undefined}
          aria-expanded={isOpen ? 'true' : undefined}
          aria-haspopup="menu"
          disabled={disabled || isLoading}
          onClick={() => setIsOpen(true)}
        ><SvgIcon fontSize="inherit" inheritViewBox component={DotsSvg} /></Fab>
        <Menu
          id={menuId}
          anchorEl={anchorEl.current}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          MenuListProps={{ 'aria-labelledby': buttonId }}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {!isUndefined(onDuplicate) &&
            <MenuItem onClick={() => void handleDuplicate()}>
              <ListItemIcon><SvgIcon inheritViewBox component={DupSvg} /></ListItemIcon>
              {t`Duplicate`}</MenuItem>
          }
          {!isUndefined(onDelete) &&
            <MenuItem onClick={() => void handleDelete()}>
              <ListItemIcon><SvgIcon inheritViewBox component={TrashSvg} /></ListItemIcon>
              {t`Delete`}</MenuItem>
          }
        </Menu>
      </>
    }
  </ButtonGroup>;
}

type AddButtonType = {
  /** if this button should be disabled */
  disabled?: boolean;
  /** callback to handle adding */
  onAdd: () => void | Promise<void>;
  /** tooltip content */
  tooltipContent: ReactNode;
};

/**
 * Component to show the add button and
 * contain loading and error handing
 */
const AddButton = forwardRef<HTMLButtonElement, AddButtonType>(function AddButton({ disabled, onAdd, tooltipContent }: AddButtonType, ref) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const onClick = async () => {
    setIsLoading(true);
    try {
      await onAdd();
    } catch (e) {
      LogRocket.error(e);
      console.error(e);
      if (isError(e)) setError(e);
    }
    setIsLoading(false);
  };
  return <AlertError
    error={error}
    onClose={() => setError(undefined)}>
    <Tooltip
      color="dark" placement="top" title={tooltipContent}>
        <span>
          <TimelineButton
            ref={ref}
            disabled={disabled || isLoading}
            onClick={() => void onClick()}><Box textAlign="center">
              <SvgIcon
                sx={(theme) => ({
                  color: theme.palette.neutral[500],
                  animation: isLoading ? 'rotating 2s linear infinite' : 'none',
                })}
                fontSize="inherit"
                component={isLoading ? RotateSvg : AddSvg}
                inheritViewBox />
            </Box></TimelineButton>
        </span>
    </Tooltip>
  </AlertError>;
});

type PersonalizedSlotProps = {
  /** Personalized slot to display. May be undefined if no personalized clips have been added yet */
  slot: IPersonalizedClipSlot | undefined
  /** Currently focused target in slot */
  focusTargetId: string | undefined
  /** List of possible targets to add personalization for */
  targets: readonly ITarget[]
  disabled?: boolean
  setFocus: (newFocus: PersonalizedSlotFocus) => void;
  /** if transitions are enabled  **/
  useTransitions: boolean;
  /** Callback to toggle transition */
  toggleTransition: () => void | Promise<void>;
  /** if there is a transition */
  hasTransition: boolean;
  /** callback to add recipient, optional param whether to add a new target when opening */
  openAddRecipients: (addNewTarget?: boolean) => void;
  /** callback to delete a specific target */
  onDelete: (targetId: string) => void | Promise<void>;
};

function PersonalizedSlot({
  slot,
  focusTargetId,
  setFocus,
  targets,
  disabled,
  openAddRecipients,
  useTransitions,
  hasTransition,
  toggleTransition,
  onDelete,
}: PersonalizedSlotProps) {
  const theme = useTheme();
  const personalizedClips = slot?.personalizedClips;
  const numCompletedClips = isNil(personalizedClips) ? 0 : intersection(targets.map(target => target.id), personalizedClips.map(p => p.targetId)).length;
  const clipDurations = personalizedClips?.map(clip => getClipDuration(clip));
  const durationRange = getDurationRangeString(min(clipDurations) ?? 0, max(clipDurations) ?? 0);
  const { maxTargets, introMaxEnabled } = usePersonalizedIntroMax();
  const isMaxTarget = introMaxEnabled && targets.length >= maxTargets;

  return <Box>
    <Heading>
      <Box>
        <Typography variant="overline" sx={{ lineHeight: 'inherit' }}>
          {t`Personalized Intros`}
        </Typography>
        <IconTooltip
          color="dark"
          placement="top"
          title={t`Personalized Intros`}
          description={t`Create unique clips for different recipients to stitch to the beginning of your video.`}
        >
          <IconButton sx={{ p: 0, ml: 1 }}>
            <SvgIcon component={InfoSvg} inheritViewBox sx={{ fontSize: '1rem' }} />
          </IconButton>
        </IconTooltip>
      </Box>
    </Heading>
    <Stack spacing={3} direction={'row'}>
      {!isEmpty(targets) &&
        <>
          <TimelineButton sx={{ background: theme.palette.primary.main }}
        disabled={disabled}
        onClick={() => openAddRecipients()}>
        <Box display="flex">
          <Typography
            overflow="hidden" textOverflow="ellipsis"
            lineHeight={1}
            component="span"
            variant="caption"
            noWrap>
            {t`${numCompletedClips}/${targets.length} recorded`}
          </Typography>
        </Box>
        <Box sx={{
          mt: 2,
          px: 1,
          flexGrow: 1,
          minHeight: '100%',
          textAlign: 'right',
          position: 'relative',
          overflow: 'hidden',
        }}>
          <Typography variant="caption" sx={{ fontSize: 10 }}>{durationRange}</Typography>
        </Box>
      </TimelineButton>
          {[...targets].map((target, i) => {
            const personalizedSlot = slot?.personalizedClips?.find(clip => clip.targetId === target.id);
            const isFilled = !isEmpty(personalizedSlot?.videoClip);
            return <TimelineButtonGroup
              key={i}
              onClick={() => setFocus({ slot: slot, targetFocus: target.id })}
              disabled={disabled}
              isActive={target.id === focusTargetId}
              isFilled={isFilled}
              thumbnail={personalizedSlot?.videoClip.thumbnail ?? undefined}
              timestamp={getClipDurationString(personalizedSlot)}
              onDelete={() => onDelete(target.id)}
              title={target.name}
              slotIndex={i}
            />;
          })}
          {useTransitions &&
            <EffectButton
              title={hasTransition ? t`Remove Fade` : t`Add Fade`}
              disabled={disabled}
              onClick={toggleTransition}
              icon={hasTransition ? FadeSvg : AddSvg} />
          }
        </>}
      <AddButton
        disabled={disabled || isMaxTarget}
        onAdd={() => openAddRecipients(true)}
        tooltipContent={isMaxTarget ? <MaxRecipientTooltip max={maxTargets} /> : t`Add recipients`} />
    </Stack>
  </Box>;
}

const EffectButtonBase = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  border: 'none',
  padding: 6,
  borderRadius: '50%',
  color: theme.palette.neutral[0],
  background: theme.palette.primary[500],
  '&:hover': {
    background: theme.palette.neutral[900],
  },
}));

type EffectButtonProps = {
  /** the callback */
  onClick: () => void | Promise<void>;
  /** disabled state of the effect button */
  disabled?: boolean;
  /** the title of the effect */
  title: string;
  /** the icon to display  */
  icon: FunctionComponent<unknown>;
};

/**
 * Component to show an effect
 */
function EffectButton({ title, onClick, disabled, icon }: EffectButtonProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const click = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };
  return <Tooltip color="dark" placement="top" title={title}>
    <EffectButtonBase disabled={isLoading || disabled} onClick={() => void click()}>
      <SvgIcon fontSize="inherit"
        sx={{
          animation: isLoading ? 'rotating 2s linear infinite' : 'none',
        }}
        component={isLoading ? RotateSvg : icon}
        inheritViewBox />
    </EffectButtonBase>
  </Tooltip>;
}

const ImageThumbnail = styled('img')({
  position: 'absolute',
  display: 'block',
  top: 0, left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transform: 'none',
});

type VideoTimelineProps = {
  /** if this should be disabled */
  disabled?: boolean;
  /** list of the slots to display */
  slots: Readonly<ITimelineObject[]>;
  /** list of targets */
  targets: Readonly<ITarget[]>;
  /** current slot that is focus */
  focus?: TimelineSlotFocus;
  /** callback to update the current element focussed */
  setFocus: (newFocus: TimelineSlotFocus) => void;
  /** callback to append a single clip slot to timeline */
  appendSingleSlot: () => void | Promise<void>;
  /** callback to remove a transition at a specific index, 
   * no number indicates transition after personalizations  
   */
  removeTransition: (i?: number) => void | Promise<void>;
  /** callback to add a transition at a specific index, 
   * no number indicates transition after personalizations  
   */
  addTransition: (i?: number) => void | Promise<void>;
  /** callback to delete the specified slot */
  removeSlot: (slot: ISingleClipSlot) => void | Promise<void>;
  /** callback to duplicate the specified slot */
  duplicateSlot: (slot: ISingleClipSlot) => void | Promise<void>;
  /** callback to update targets */
  updateTargets: (data: ITargetInput[]) => void | Promise<void>;
  updateOrder: (slotIndex: number, newIndex: number) => void | Promise<void>;
  openTargetModal: (addNew?: boolean) => void;
  /** if timeline is open  */
  isOpen: boolean;
  /** callback to update isOpen state of timeline */
  setIsOpen: (open: boolean) => void;
  /** callback on preview */
  onPreview: () => void;
  /** if there should be the ability to show hide the timeline */
  canShowHide: boolean;
};

const DEFAULT_TIMESTAMP = '--:--';

function getClipDurationString(slot: Maybe<IPersonalizedVideoClip | ISingleClipSlot>): string {
  if (isNil(slot?.videoClip?.durationInSeconds)) return DEFAULT_TIMESTAMP;
  return toTimestampString(getClipDuration(slot));
}

function getClipDuration(slot: Maybe<IPersonalizedVideoClip | ISingleClipSlot>): number {
  return getTrimmedDuration(slot?.start, slot?.end, slot?.videoClip?.durationInSeconds) ?? 0;
}

function getDurationRangeString(minSeconds: number, maxSeconds: number): string {
  if (minSeconds === maxSeconds && minSeconds === 0) return DEFAULT_TIMESTAMP;
  if (minSeconds === maxSeconds) return `${toTimestampString(minSeconds)}`;
  return `${toTimestampString(minSeconds)}-${toTimestampString(maxSeconds)}`;
}

function getTotalDurationRangeString(slots: Readonly<ITimelineObject[]>): string {
  const personalizedSlot: IPersonalizedClipSlot | undefined = first(slots.filter(isPersonalizedClipSlot));
  const personalizedClipDurations = personalizedSlot?.personalizedClips?.map(slot => getClipDuration(slot)) ?? [];
  const initializedSlots = slots.filter(isInitializedSlot).filter(isSingleClipSlot);
  const singleClipTotalDuration = sum(initializedSlots.map(slot => getClipDuration(slot)));
  const [minPersonalization, maxPersonalization] = [min(personalizedClipDurations) ?? 0, max(personalizedClipDurations) ?? 0];
  return getDurationRangeString(minPersonalization + singleClipTotalDuration, maxPersonalization + singleClipTotalDuration);
}

export type TimelineSlotFocus = SingleSlotFocus | PersonalizedSlotFocus;
export type SingleSlotFocus = { slot: ISingleClipSlot };
export type PersonalizedSlotFocus = { slot?: IPersonalizedClipSlot | undefined, targetFocus: string };

export function isPersonalizedSlotFocus(focus: TimelineSlotFocus | undefined): focus is PersonalizedSlotFocus {
  const focusProp: keyof PersonalizedSlotFocus = 'targetFocus';
  return !isUndefined(focus) && focusProp in focus;
}

export default function VideoTimeline({
  disabled,
  slots,
  focus,
  setFocus,
  appendSingleSlot,
  removeSlot,
  duplicateSlot,
  addTransition,
  removeTransition,
  targets,
  updateTargets,
  updateOrder,
  openTargetModal,
  isOpen,
  setIsOpen,
  onPreview,
  canShowHide,
}: VideoTimelineProps) {
  const useTransitions = useFeatureFlag(BooleanFeatureFlagNames.videoTransitionEffects).isEnabled;
  const personalizedSlot: IPersonalizedClipSlot | undefined = first(slots.filter(isPersonalizedClipSlot));
  const hasPersonalizedTransition =
    !isUndefined(personalizedSlot) &&
    isTransitionSlot(slots[indexOf(slots, personalizedSlot) + 1]);
  const totalDuration = getTotalDurationRangeString(slots);

  const [lastDropProps, lastDrop] = useDrop(() => ({
    accept: DraggableTypes.SLOT,
    drop: async (item: SlotDragItem) => {
      if (item.originalIndex !== slots.length - 1) {
        await updateOrder(item.originalIndex, slots.length);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [updateOrder, slots.length]);

  return (
    <>
      {canShowHide && (
        <TrapezoidBox isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <Box
            style={{
              transform: 'perspective(80px) rotateX(-18deg) translateZ(4px) translateY(4px)',
              fontSize: '0.75rem',
            }}
          >
            {isOpen ? t`Hide timeline` : t`Show timeline`}
            <SvgIcon
              inheritViewBox
              component={isOpen ? DownSvg : UpSvg}
              sx={{ '&.MuiSvgIcon-root': { fontSize: '0.75rem' }, ml: 3 }}
            />
          </Box>
        </TrapezoidBox>
      )}
      <Container
        elevation={0}
        position="static"
        sx={{
          overflowX: 'auto',
          borderTop: `1px solid ${PALETTE_COLOR.neutral[200]}`,
          display: isOpen ? 'flex' : 'none',
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Box sx={{ minWidth: 'max-content', width: '100%' }}>
          <Stack spacing={3} direction="row">
            {canShowHide &&
              <IconButton
                onClick={onPreview}
                size={'large'}
                color={'primary'}
                disabled={disabled}
                sx={{
                  alignSelf: 'end',
                  height: TIMELINE_BOX_HEIGHT,
                  border: `1px solid ${PALETTE_COLOR.primary[400]}`,
                  '&.Mui-disabled': {
                    color: PALETTE_COLOR.primary[400],
                  },
                }}
              >
                <SvgIcon component={PlaySvg} inheritViewBox sx={{ width: '40px', height: '40px' }} />
              </IconButton>
            }
            <PersonalizedSlot
              slot={personalizedSlot}
              useTransitions={useTransitions}
              hasTransition={hasPersonalizedTransition}
              toggleTransition={async () => (hasPersonalizedTransition ? removeTransition() : addTransition())}
              focusTargetId={isPersonalizedSlotFocus(focus) ? focus.targetFocus : undefined}
              setFocus={setFocus}
              disabled={disabled}
              targets={targets}
              onDelete={(targetId) =>
                updateTargets(targets.filter((clip) => clip.id !== targetId).map(convertToUpdateTarget))
              }
              openAddRecipients={(addNew) => {
                trackEvent('edit-add-recipient');
                openTargetModal(addNew ?? false);
              }}
            />
            <Divider orientation="vertical" sx={{ borderColor: '#BCBACC' }} flexItem />
            <Box flexGrow={1}>
              <Heading>
                <Box marginRight="12px">
                  <Typography variant="overline" sx={{ lineHeight: 'inherit' }}>
                    {t`Video Clips`}
                  </Typography>
                  <IconTooltip
                    color="dark"
                    placement="top"
                    title={t`Video Clips`}
                    description={t`See the main body of your video and stitch clips together for variety of content.`}
                  >
                    <IconButton sx={{ p: 0, ml: 1 }}>
                      <SvgIcon component={InfoSvg} inheritViewBox sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </IconTooltip>
                </Box>
                <Typography variant="body2" color="#696681" whiteSpace="pre">
                  {t`Total Duration: ${totalDuration}`}
                </Typography>
              </Heading>
              <Stack spacing={3} direction="row">
                {slots.map((slot, i) => {
                  switch (slot.__typename) {
                    case 'SingleClipSlot': {
                      const isFocussed = focus?.slot === slot;
                      const isFilled = !isEmpty(slot.videoClip);
                      const nextSlot = slots[i + 1];
                      const nextIsTransition = !isUndefined(nextSlot) && nextSlot.__typename === 'TransitionObject';
                      return (
                        <React.Fragment key={i}>
                          <TimelineButtonGroup
                            key={i}
                            slotIndex={i}
                            onClick={() => setFocus({ slot })}
                            disabled={disabled}
                            isActive={isFocussed}
                            isFilled={isFilled}
                            thumbnail={slot.videoClip?.thumbnail ?? undefined}
                            timestamp={getClipDurationString(slot)}
                            onDelete={() => removeSlot(slot)}
                            onDuplicate={() => duplicateSlot(slot)}
                            title={slot.videoClip?.title ?? slot.title}
                            onUpdateOrder={updateOrder}
                          />
                          {useTransitions && isFilled && !nextIsTransition && (
                            <EffectButton
                              title={t`Add Fade`}
                              disabled={disabled}
                              onClick={() => addTransition(i + 1)}
                              icon={AddSvg}
                            />
                          )}
                        </React.Fragment>
                      );
                    }
                    case 'TransitionObject': {
                      if (!useTransitions) return undefined;
                      if (isPersonalizedClipSlot(slots[i - 1])) return undefined;
                      return (
                        <EffectButton
                          key={i}
                          icon={FadeSvg}
                          title={t`Remove Fade`}
                          disabled={disabled}
                          onClick={() => removeTransition(i)}
                        />
                      );
                    }
                    case 'PersonalizedClipSlot': {
                      return undefined;
                    }
                    default:
                      console.error('Unsupported Slot Type', slot.__typename);
                      // Unsupported case
                      return undefined;
                  }
                })}
                <DropIndicator show={lastDropProps.isOver} />
                <AddButton
                  ref={lastDrop}
                  disabled={disabled}
                  onAdd={appendSingleSlot}
                  tooltipContent={t`Add a new clip`}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Container>
    </>
  );
}

