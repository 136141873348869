import { createContext, ReactNode, useContext, useState } from 'react';
import { Snackbar } from '@mui/material';
import { isUndefined } from 'lodash';
import Alert, { AlertProps } from '../alert/alert.component';

/**
 * Props for setting snack bar
 */
export type SnackBarContentProps = AlertProps;

const SnackBarContext = 
  createContext<{ setSnackbarContent: (content: SnackBarContentProps) => void }>({ setSnackbarContent: () => {} });

/**
 * Component for providing a snack bar context
 */
export function SnackBarContextProvider({ children }: { children: ReactNode }) {
  const [content, setContent] = useState<SnackBarContentProps | undefined>(undefined);
  
  return (
    <SnackBarContext.Provider value={{ setSnackbarContent: setContent }}>
      {children}
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
                open={!isUndefined(content)} 
                onClose={content?.closeable ? () => setContent(undefined) : undefined}>
        {content && <Alert {...content}/>}
      </Snackbar>
    </SnackBarContext.Provider>
  );
}

/**
 * Hook for setting current snack bar content
 */
export function useSnackBarContext() {
  return useContext(SnackBarContext);
}