import ShareActionThumbnail from './shareActionThumbnail.component';
import { t } from '@lingui/macro';
import { trackEvent } from '../../common/utils/errors';
import ShareActionLink from './shareActionLink.component';
import { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';


type ShareActionsProps = {
  thumbnail?: string;
  id: string;
};
export default function ShareActions({ thumbnail, id } : ShareActionsProps) {
  const [isCopied, setIsCopied] = useState<undefined | 'link' | 'link+thumbnail'>();
  useEffect(() => {
    if (!isUndefined(isCopied)) {
      const copiedTimer = setTimeout(() => setIsCopied(undefined), 2000);
      return () => clearTimeout(copiedTimer);
    }
    return;
  }, [isCopied]);

  const setClipboard = async (text: string, type: 'link' | 'link+thumbnail') => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(type);
    } catch (e) {
      setIsCopied(undefined);
    }
  };

  function sendToParent(message: string) {
    window.parent.postMessage(message, '*');
  }

  return <>
        {thumbnail &&
            <ShareActionThumbnail
                text={t`Copy and inject thumbnail and link`}
                targetId={id}
                success={isCopied === 'link+thumbnail'}
                thumbnail={thumbnail}
                onClick={(message)=> {
                  trackEvent('ext-share-copy-link+thumbnail');
                  sendToParent(message);
                  void setClipboard(message, 'link+thumbnail');
                } } />}
        <ShareActionLink
            targetId={id} 
            text={t`Copy and inject link`}
            success={isCopied === 'link'}
            iconOnly={true}
            onClick={()=> trackEvent('ext-share-copy-link')} onClicked={(message)=> {
              sendToParent(message);
              void setClipboard(message, 'link');
            }} />
    </>;
}