import { t } from '@lingui/macro';
import { ReactNode } from 'react';

export const DEFAULT_TRIAL_DAYS = 7;

/**
 * Name of Plan Name from Tier
 * @see https://bitbucket.org/cassia/copilotai.video/src/master/videoManagement/pricingPlans.json
 */
export const PRO_PLAN_NAME = 'plan:pro@2';
export const PRO_ANNUAL_PLAN_NAME = 'plan:proAnnual@2';
export const BASIC_PLAN_NAME = 'plan:basic@1';
export const BASIC_ANNUAL_PLAN_NAME = 'plan:basicAnnual@1';

export type PlanType = {
  /** display name of the plan */
  name: string,
  /** price */
  price: string,
  /** plan description */
  description: string,
  /** list of features for the plan*/
  features: string[],
  /** feature description */
  featureDescription: ReactNode,
  /** price details caption text */
  priceCaption: string;
  /** discount tag */
  discountTag?: string;
};

const BASIC_PLAN: PlanType = {
  name: 'Basic',
  price: '$16',
  description: t`Video that Scales`,
  priceCaption: '/mo',
  features: [
    t`Screen recording`,
    t`Personalized intros`,
    t`Add recipient`,
    t`Video upload`,
    t`Video downloads`,
    t`Video analytics`,
    t`Video CTAs`,
    t`Chrome extension`,
    t`5 intros per project`,
    t`2 minutes per clip`,
  ],
  featureDescription: 'This plan includes:',
};

const PRO_PLAN: PlanType = {
  name: 'Pro',
  price: '$39',
  features: [
    t`AI eye contact`,
    t`AI background blur`,
    t`AI script generator`,
    t`50 intros per project`,
    t`5 minutes per clip`,
  ],
  description: 'AI Video Messaging',
  priceCaption: '/mo',
  featureDescription: 'Everything in Basic plus',
};

const BASIC_ANNUAL_PLAN = {
  ...BASIC_PLAN,
  name: 'Basic - Annual',
  price: '$12',
  priceCaption: t`/mo ($144 billed annually)`,
  discountTag: t`25% off`,
};

const PRO_ANNUAL_PLAN = {
  ...PRO_PLAN,
  name: 'Pro - Annual',
  price: '$32',
  priceCaption: t`/mo ($384 billed annually)`,
  discountTag: t`18% off`,
};

export const PRICING_PLANS = {
  [BASIC_PLAN_NAME]: BASIC_PLAN,
  [BASIC_ANNUAL_PLAN_NAME]: BASIC_ANNUAL_PLAN,
  [PRO_PLAN_NAME]: PRO_PLAN,
  [PRO_ANNUAL_PLAN_NAME]: PRO_ANNUAL_PLAN,
} as const;
