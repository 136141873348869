import { Container, Step, StepButton, Stepper, styled } from '@mui/material';
import {
  IUpdateUserOnboardingInfoInput,
  IUserInfoFragment,
  useUpdateUserOnboardingInfoMutation, useUserSubscriptionDetailsLazyQuery,
} from 'data/_generated';
import SpacerBar from 'common/components/layout/spacerBar.component';
import OnboardingDetailsView from 'onboard/components/onboardingDetails.component';
import { useNavigate } from 'react-router-dom';
import { isNil, isUndefined } from 'lodash';
import { ONBOARD_URL_FRAGMENT } from 'config/constants/appcues.constant';
import useCreateCheckout from 'common/hooks/useCreateCheckout';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { getInitialPlan, getUTM } from 'common/utils/util';
import { t } from '@lingui/macro';
import useUserAppAccess from '../../common/hooks/useUserAppAccess';
import { useAuth } from '../../config/hooks/useAuth';
import { DEFAULT_TRIAL_DAYS } from '../plan.const';
import { trackEvent } from '../../common/utils/errors';
import TrialView from '../components/trialView.component';
import useTrialLength from '../../common/hooks/useTrialLength';

const OnboardingSteps = [t`Sign up`, t`Details`, t`Trial Activation`];
const SIGNUP_STEP_INDEX = 0 as const;
const DETAILS_STEP_INDEX = 1 as const;
const TRIAL_STEP_INDEX = 2 as const;

const StyledStepper = styled(Stepper)(({ theme }) => ({
  maxWidth: '500px',
  display: 'flex',
  padding: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '50px',
  '& .MuiStepConnector-root': {
    width: '50px',
    [theme.breakpoints.only('xs')]: {
      width: '15px',
    },
  },
  '& .MuiStepConnector-line': {
    borderTopWidth: '2px',
  },
  '& .MuiStepLabel-label': {
    fontSize: '16px',
  },
  '& .MuiStepLabel-iconContainer': {
    '& .MuiStepIcon-root': {
      width: '32px',
      height: '32px',
      border: 'none',
    },
  },
  '& .Mui-active': {
    fontWeight: 600,
  },
  '& .Mui-completed': {
    fontWeight: 600,
  },
}));

type OnboardingPageProps = {
  /** user details */
  userInfo?: IUserInfoFragment;
  /** if user has access to the app */
  hasAccess?: boolean;
  /** if user has cancelled plan in history */
  hasCancelledPlan?: boolean;
  /** callback if they successfully onboarded */
  onComplete: () => void;
};

/**
 * Onboarding Page to collect User Details
 * @returns JSX.Element
 */
export default function OnboardingPage({ userInfo, hasAccess, hasCancelledPlan, onComplete }: OnboardingPageProps) {
  const originURL = Cookies.get('originURL');
  const originUTM = getUTM(originURL);
  const initialPlan = getInitialPlan(originURL);
  const referrerURL = Cookies.get('referrerURL');

  const { instance } = useAuth();
  const account = instance.getActiveAccount();

  const { hasBillingAccess } = useUserAppAccess();
  const { trialDaysEnabled } = useTrialLength();

  const navigate = useNavigate();
  const [updateUserOnboardingInfoMutation] = useUpdateUserOnboardingInfoMutation();
  const [getUserSubscription, { loading: userSubscriptionLoading }] = useUserSubscriptionDetailsLazyQuery({ fetchPolicy: 'network-only' });
  const [step, setStep] = useState<number>(DETAILS_STEP_INDEX);

  const { handleCreateCheckout, error } = useCreateCheckout({
    planName: initialPlan,
    successUrl: `${window.location.origin}${ONBOARD_URL_FRAGMENT}`,
    cancelUrl: window.location.origin,
    trialDays: DEFAULT_TRIAL_DAYS,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const userDetailsInput = createUserDetailsInput(userInfo);

  const handleSubmitDetails = async (data: IUpdateUserOnboardingInfoInput) => {
    setSubmitting(true);
    await updateUserOnboardingInfoMutation({
      variables: {
        data: {
          ...data,
          originURL,
          referrerURL,
          ...originUTM,
          initialPlan,
        },
      },
      refetchQueries: ['UserInfo'],
    });
    trackEvent('onboarded', {
      industry: data.industry,
      jobTitle: data.jobTitle,
      goal: isNil(data.goal) ? 'NULL' : data.goal,
      hasVideoExperience: data.hasVideoExperience,
      originURL,
      referrerURL,
    });
    if (!hasAccess && !hasCancelledPlan) {
      if (trialDaysEnabled) {
        setStep(TRIAL_STEP_INDEX);
      } else {
        await handleCreateCheckout();
      }
      setSubmitting(false);
    } else {
      navigate(ONBOARD_URL_FRAGMENT);
    }
  };

  const isStepDisabled = (val: number) => {
    switch (val) {
      case SIGNUP_STEP_INDEX:
        return true;
      case DETAILS_STEP_INDEX:
        return false;
      case TRIAL_STEP_INDEX:
        return isUndefined(userDetailsInput);
      //should not reach here as this switch should cover all possible step
      default:
        return true;
    }
  };

  const isStepCompleted = (stepIndex: number) => {
    switch (stepIndex) {
      case SIGNUP_STEP_INDEX:
        return true;
      case DETAILS_STEP_INDEX:
        return !isUndefined(userDetailsInput);
      case TRIAL_STEP_INDEX:
        return false;
      //should not reach here as this switch should cover all possible step
      default:
        return false;
    }
  };

  const onConfirmTrial = async () => {
    await getUserSubscription();
    trackEvent('trial-started');
    navigate(ONBOARD_URL_FRAGMENT);
    onComplete();
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      {hasBillingAccess && (
        <StyledStepper activeStep={step}>
          {OnboardingSteps.map((label, index) => (
            <Step
              key={label}
              disabled={trialDaysEnabled ? isStepDisabled(index) : true}
              completed={isStepCompleted(index)}
            >
              <StepButton onClick={() => setStep(index)}>{label}</StepButton>
            </Step>
          ))}
        </StyledStepper>
      )}
      {step === DETAILS_STEP_INDEX && (
        <OnboardingDetailsView
          accountName={account?.name}
          onSubmit={({ industry, goal, hasVideoExperience, jobTitle }) =>
            void handleSubmitDetails({ industry, goal, hasVideoExperience, jobTitle })
          }
          initalValues={userDetailsInput}
          loading={submitting}
          error={error}
        />
      )}
      {step === TRIAL_STEP_INDEX && (
        <TrialView
            onConfirm={onConfirmTrial}
            loading={userSubscriptionLoading}
        />
      )}
      <SpacerBar />
    </Container>
  );
}

/**
 * helper to create user details input.
 * if there is existing details, create input from userDetailsInfo query response.
 * if there is no details, pass undefined
 * @param values
 * @returns
 */
function createUserDetailsInput(values: IUserInfoFragment | undefined): IUpdateUserOnboardingInfoInput | undefined {
  return isNil(values) || isNil(values.hasVideoExperience) || isNil(values.jobTitle)
    ? undefined
    : {
      industry: values.industry,
      goal: values.goal,
      hasVideoExperience: values.hasVideoExperience,
      teamSize: values.teamSize,
      jobTitle: values.jobTitle,
    };
}
