import { ChangeEvent, RefObject, useRef, useState } from 'react';
import { isUndefined } from 'lodash';

type FileInputProps = {
  /**
   * Reference to the html input element that is being used
   */
  inputRef: RefObject<HTMLInputElement>;
  /**
   * onChange event handler that should be hooked up to input element
   * @param event file input event
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => string | undefined;
  /**
   * url to file that was uploaded. Will be undefined if no file has been selected through input
   */
  fileUrl: string | undefined;
  /**
   * Release file url
   */
  clearFile: () => void;
};

/**
 * Hook for setting up state and handlers for file inputs. Currently only works for single selection file inputs.
 * Ensure that clearFile is called when file is no longer needed to prevent leaking memory.
 */
export function useFileInput(): FileInputProps {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;
    // Reset the input to empty state
    if (inputRef.current) inputRef.current.value = '';

    const blobUrl = URL.createObjectURL(file);
    setFileUrl(blobUrl);
    return blobUrl;
  };

  const clearFile = () => {
    if (!isUndefined(fileUrl)) URL.revokeObjectURL(fileUrl);
    setFileUrl(undefined);
  };

  return {
    inputRef,
    onChange,
    fileUrl,
    clearFile,
  };
}
