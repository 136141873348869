import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

export type TooltipProps = MuiTooltipProps & {
  /** the color of the tooltip */
  color?: 'light' | 'dark';
};

/**
 * Extension of MuiTooltip to support dark mode tooltips
 */
export default function Tooltip({ color, children,  ...props } : TooltipProps) {
  return <MuiTooltip 
    arrow
    PopperProps={{ className: color === 'dark' ? 'MuiTooltip--dark' : undefined }}
    {...props} >{children}</MuiTooltip>;
}