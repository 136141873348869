import { SvgIcon } from '@mui/material';
import { ReactComponent as ImageSvg } from 'common/images/icons/Utility/image file.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import ActionButton from './actionButton.component';
import { embedString } from '../util/share';
import Tooltip from 'common/components/tooltip/tooltip.component';

type ShareActionThumbnailProps = {
  text: string;
  targetId: string;
  duration?: number;
  thumbnail: string;
  success?: boolean;
  onClick: (text: string) => void;
};
export default function ShareActionThumbnail({
  text,
  targetId,
  success = false,
  duration,
  thumbnail,
  onClick,
}: ShareActionThumbnailProps) {
  return <Tooltip color="dark" title={text} placement="top" arrow>
        <ActionButton
            variant="outlined"
            color={success ? 'success' : 'inherit'}
            onClick={() => onClick(embedString(
              targetId,
              thumbnail,
              { duration, addLink: true }))}
            startIcon={<SvgIcon component={success ? ConfirmSvg : ImageSvg} inheritViewBox />}
            size="small"
        />
    </Tooltip>;
}