import { isUndefined } from 'lodash';
import { useGetAuthToken } from './useGetAuthToken';

/**
 * Hook that returns function for uploading a blob to specified url. Attaches authorization header to request.
 */
export function useUploadBlob() {
  const getAuthToken = useGetAuthToken();
  return async (blob: Blob, url: string) => {
    const authorization = await getAuthToken();
    const init: RequestInit = {
      method: 'put',
      body: blob,
      headers:
        {
          ...{
            'Content-Type': blob.type,
            'x-ms-blob-type': 'BlockBlob',
          },
          ...(!isUndefined(authorization) && { authorization }),
        },
    };
    const response = await fetch(url, init);
    if (!response.ok) throw new Error(`Upload request failed with status ${response.statusText}`);
  };
}