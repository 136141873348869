import { useEffect, useRef, useState } from 'react';

type PollingProps = {
  /** callback to start polling */
  startPolling(pollInterval: number): void;
  /** callback to stop polling */
  stopPolling(): void;
  /** should we be polling */
  shouldPoll:boolean
};

const POLL_INTERVAL = 10000; // 10 seconds (10000 ms)
const MAX_POLLING = 600000; // 10 minutes (600000 ms)

/**
 * Hook to poll when should be polling is true.
 * Shared by dashboard and share pages
 */
export const usePolling = ({
  startPolling, 
  stopPolling,
  shouldPoll,
}: PollingProps) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // if we should poll,
    // execute start polling periodically every 10 seconds until everything we should not poll
    // or past the max polling time
    if (shouldPoll) {
      if (!isPolling) {
        startPolling(POLL_INTERVAL);
        setIsPolling(true);

        // Set time out to stop polling if they don't finish after max time
        // To prevent fetching infinitely
        timeoutId.current = 
          setTimeout(stopPolling, MAX_POLLING);
      }
    } else if (isPolling) {
      stopPolling();
      setIsPolling(false);
      clearTimeout(timeoutId.current);
    }
    // cleanup
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [shouldPoll, startPolling, stopPolling, isPolling]);

  return [ isPolling ];
};