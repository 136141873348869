import { isNil } from 'lodash';

/**
 * helper function to get trimmed time value
 * @param trimPoint trimming point in percentage
 * @param videoDuration total video duration
 * @returns trimPoint converted from percentage to time
 */
export function getTrimmedTime(trimPoint: number, videoDuration: number) {
  return (trimPoint / 100) * videoDuration;
}

/**
 * Helper function to get trimmed time value even if the duration or trimPoint is undefined
 * @param trimPoint 
 * @param videoDuration 
 * @returns returns undefined if either trimPoint or videoDuration is undefined
 */
export function getTrimmedTimeOptional(trimPoint?: number | null, videoDuration?: number | null) {
  if (isNil(trimPoint) || isNil(videoDuration)) return;
  return getTrimmedTime(trimPoint, videoDuration);
}

/**
  * Helper function to get duration of the trimmed video
  * @param startPoint trim start point in %
  * @param endPoint trim end point in %
  * @param videoDuration total video duration
  * @returns trimmed duration or video duration if startPoint or endPoint is missing
  */
export function getTrimmedDuration(startPoint?: number | null, endPoint?: number | null, videoDuration?: number | null) {
  if (isNil(videoDuration)) return;
  if (isNil(startPoint) || isNil(endPoint)) return videoDuration;
  const startTime = getTrimmedTime(startPoint, videoDuration);
  const endTime = getTrimmedTime(endPoint, videoDuration);

  return endTime - startTime;
} 