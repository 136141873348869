import { Box, SvgIcon, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { SPACER } from 'config/models/themeOptions';
import { getStatusIcon } from 'common/utils/style';
import Tooltip, { TooltipProps } from './tooltip.component';

type IconTooltipProps = TooltipProps & {
  /** type of tooltip icon to display */
  type?: 'error' | 'warning' | 'info' | 'success';
  /** title */
  title: ReactNode;
  /** description */
  description?: ReactNode;
  /** if description should be bold */
  bold?: boolean;
  /** true if tooltip should be disabled */
  disabled?: boolean;
  /** reference element to hold tooltip  */
  children: ReactNode;
};

export default function IconTooltip({
  type = 'info',
  title,
  description,
  disabled = false,
  bold = false,
  children,
  ...otherProps
}: IconTooltipProps) {
  return (
    <Tooltip
      {...otherProps}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      title={
        <Box display="flex">
          <SvgIcon component={getStatusIcon(type)} inheritViewBox />
          <Box ml={(theme) => theme.spacing(SPACER.XS)}>
            {typeof title === 'string' ? <Typography variant='h6Semibold' sx={{ display: 'block', mb: 2 }}>{title}</Typography> : title}
            {typeof description === 'string' ? <Typography variant={bold ? 'bodySemibold' : 'bodyReg'} sx={{ display: 'block' }}>{description}</Typography> : description}
          </Box>
        </Box>
      }
    >
      {/* Need to add extra wrap element around LoadingButton to accommodate disabled state 
          https://mui.com/material-ui/react-tooltip/#disabled-elements */}
      <span>{children}</span>
    </Tooltip>
  );
}
