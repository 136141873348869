import { ICreateTimelineVideoProjectInput } from '../../data/_generated';
import { ReactComponent as DownPointerSVG } from 'common/images/icons/Utility/down pointer.svg';
import { ReactComponent as HourGlassSVG } from 'common/images/icons/Business & Finance/Start timer.svg';
import { ReactComponent as PlaySvg } from 'common/images/icons/Utility/play.svg';
import {
  Accordion, AccordionDetails,
  AccordionSummary, Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia, Chip, Fab, Stack, SvgIcon,
  Typography, useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS } from '../../edit/const';
import { t } from '@lingui/macro';
import { useRef, useState } from 'react';
import { SPACER } from '../../config/models/themeOptions';
import Modal from '../../common/components/modal/modal.component';
import useCreateProject from '../hook/useCreateProject';
import { isUndefined } from 'lodash';
import { trackEvent } from '../../common/utils/errors';

type VideoTemplateIcons = 'hourglass';
type VideoTemplate = Readonly<{
  title: string
  description: string
  tags: readonly { label: string, icon?: VideoTemplateIcons }[]
  posterUrl: string
  videoUrl: string
  projectTemplate?: ICreateTimelineVideoProjectInput
}>;

/* eslint-disable max-len */
export const VIDEO_TEMPLATES: VideoTemplate[] = [
  {
    title: 'Outreach',
    description: 'Engage prospects with personalized videos that convert.',
    tags: [{ label: 'Prospecting' }, { label: 'Networking' }, { label: 'Sales' }, { label: 'Lead generation' }, { label: '45 sec recording', icon: 'hourglass' }],
    posterUrl: '/template-outreach.jpeg',
    videoUrl: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/24ee4b2009e985c86b84e47874237a9b',
    projectTemplate: {
      title: 'Sales Outreach',
      scriptV2: `Personalized Intro:
Hi {name}, this is [Your Name] from [Your Company Name].
      
Value:
I wanted to reach out and personally introduce myself and our services. We’ve helped [List a few of your most successful clients or a quick overview of what your company does]. I’d love to connect and see if there’s a way we can help you as well. Let me know if you have a few minutes to chat.
We’ve helped [List a few of your most successful clients or a quick overview of what your company does].
      
Ask:
I’d love to connect and see if there’s a way we can help you as well. Let me know if you have a few minutes to chat.
Thank you`,
      timeline: [
        {
          singleClip: {
            title: 'Value',
            script: 'We’ve helped [List a few of your most successful clients or a quick overview of what your company does].',
          },
        },
        {
          singleClip: {
            title: 'Ask',
            script: `I’d love to connect and see if there’s a way we can help you as well. Let me know if you have a few minutes to chat.
Thank you`,
          },
        },
      ],
    },
  },
  {
    title: 'Meeting Reminder',
    description: 'Make meetings unforgettable with personalized video invites.',
    tags: [{ label: 'Productivity' }, { label: 'Scheduling' }, { label: 'Task management' }, { label: '30 sec recording', icon: 'hourglass' }],
    posterUrl: '/template-meeting.jpeg',
    videoUrl: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/1736d005d001a34cb102cd877b9eef3f',
    projectTemplate: {
      title: 'Meeting Reminder',
      scriptV2: 'Hi {name}, this is [Your Name] from [Your Company Name]. Just wanted to remind you of our meeting tomorrow at [Meeting Time] [Timezone]. We’ll be discussing [Brief overview of the agenda or what you’ll be covering]. Let me know if you have any questions before the meeting. Looking forward to talking with you tomorrow.',
      timeline: [
        {
          singleClip: {
            title: 'All-in',
            script: 'Hi [Prospect’s Name], this is [Your Name] from [Your Company Name]. Just wanted to remind you of our meeting tomorrow at [Meeting Time] [Timezone]. We’ll be discussing [Brief overview of the agenda or what you’ll be covering]. Let me know if you have any questions before the meeting. Looking forward to talking with you tomorrow.',
          },
        },
      ],
    },
  },
  {
    title: 'Follow-Up',
    description: 'Nurture leads and close more deals with video messages.',
    tags: [{ label: 'Relationship building' }, { label: 'Customer retention' }, { label: 'Upselling' }, { label: 'Feedback' }, { label: '45 sec recording', icon: 'hourglass' }],
    posterUrl: '/template-followup.jpeg',
    videoUrl: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/0c159b4177b60694edd20f51f6f46b14',
    projectTemplate: {
      title: 'Meeting Follow-Up',
      scriptV2: `Personalized Intro:
Hi {name}, this is [Your Name] from [Your Company Name].

Recap:
Just wanted to touch base and see how everything is going. We talked about [Briefly summarize the previous conversation or meeting].  I wanted to check in and see if you had any further questions or if there was anything else we could help you with.

Next Steps:
Let me know if you’d like to schedule another call to discuss further. Thank you!`,
      timeline: [
        {
          singleClip: {
            title: 'Recap',
            script: 'Just wanted to touch base and see how everything is going. We talked about [Briefly summarize the previous conversation or meeting].  I wanted to check in and see if you had any further questions or if there was anything else we could help you with.',
          },
        },
        {
          singleClip: {
            title: 'Next Steps',
            script: 'Let me know if you’d like to schedule another call to discuss further. Thank you!',
          },
        },
      ],
    },
  },
];

function VideoTemplateTagIcon({ icon }: { icon: VideoTemplateIcons }) {
  switch (icon) {
    case 'hourglass': return <SvgIcon component={HourGlassSVG} inheritViewBox/>;
  }
}

type VideoTemplateModalProps = {
  template: VideoTemplate
  onClose: () => void
};

function VideoTemplateModal({ template, onClose }: VideoTemplateModalProps) {
  const [create, { loading, error }] = useCreateProject();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlayStarted, setIsPlayStarted] = useState<boolean>(false);
  
  return (
    <Modal
      maxWidth='sm'
      title={template.title}
      onClose={() => {
        trackEvent('template-dismissed', { templateTitle: template.title, videoCurrentTime: videoRef.current?.currentTime ?? 0 });
        onClose();
      }}
      isOpen={true}
      disableClose={loading}
      confirmLabel={t`Use template`}
      confirmError={error}
      confirmLoading={loading}
      onConfirm={() => {
        trackEvent('template-used', { templateTitle: template.title, videoCurrentTime: videoRef.current?.currentTime ?? 0 });
        void create({ project : template.projectTemplate });
      }}
    >
      <Stack direction='column' spacing={SPACER.S}>
        <Box maxWidth='100%' position='relative'>
          <video src={template.videoUrl}
                 poster={template.posterUrl}
                 ref={videoRef}
                 crossOrigin=""
                 playsInline
                 onPlay={() => setIsPlayStarted(true)}
                 controls={isPlayStarted}
                 controlsList="nodownload"
                 preload="auto"
                 className='crisp-rendering'
                 style={{
                   maxWidth: '100%',
                 }}
          />
           <Fab
              sx={(theme) => ({
                bgcolor: theme.palette.common.white,
                display: isPlayStarted ? 'none' : 'flex',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              })}
              onClick={() => {
                void videoRef.current?.play();
              }}
            >
              <SvgIcon inheritViewBox component={PlaySvg}></SvgIcon>
            </Fab>
        </Box>
       
        <Stack direction='row' gap={SPACER.S} flexWrap='wrap'>
          {template.tags.map( (tag, index) => 
            <Chip variant='badge' color={!isUndefined(tag.icon) ? 'default' : 'secondary'} key={index} icon={!isUndefined(tag.icon) ? <VideoTemplateTagIcon icon={tag.icon}/> : undefined} label={tag.label}/>)}
        </Stack>
        <Typography gutterBottom component="div">
          {template.description}
        </Typography>
      </Stack>
    </Modal>
  );
}

type VideoTemplateCardProps = {
  template: VideoTemplate
  onClick: () => void
};

function VideoTemplateCard({ template, onClick }: VideoTemplateCardProps) {
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <Card sx={{ border: `1px solid ${theme.palette.primary[200]}`, flex: '1' }} elevation={0} >
      <CardActionArea
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', height: '100%' }}
        onClick={onClick}
        onMouseEnter={() => void videoRef.current?.play()} 
        onMouseLeave={() => videoRef.current?.pause()}>
        <CardMedia
          sx={{ flexGrow: 1, objectFit: 'cover' }}
          component="video"
          ref={videoRef}
          src={template.videoUrl}
          crossOrigin=""
          muted
        />
        <CardContent sx={{ padding: 4, marginBottom: 0 }}>
          <Typography variant="h6" component="div"  textOverflow={'ellipsis'} overflow={'hidden'} noWrap>
            {template.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

/**
 * Component for displaying a collapsable list of video templates
 */
export default function VideoTemplatesDashboardView({ templates = VIDEO_TEMPLATES }: { templates?: VideoTemplate[] }) {
  const [expandVideoTemplatesView, setExpandVideoTemplatesView] = useLocalStorage(LOCAL_STORAGE_KEYS.ExpandVideoTemplatesView, true);
  const [openedTemplateModal, setOpenedTemplateModal] = useState<VideoTemplate>();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  if (isXS) return null;
  return (
    <Accordion
      elevation={0}
      disableGutters={true}
      sx={{
        backgroundColor: theme.palette.primary[50], 
        border: `1px solid ${theme.palette.primary[100]}`,
        padding: '8px',
      }}
      expanded={expandVideoTemplatesView} 
      onChange={(_event, expanded ) => setExpandVideoTemplatesView(expanded)}>
      <AccordionSummary
        expandIcon={<SvgIcon component={DownPointerSVG} inheritViewBox sx={{ color: theme.palette.common.black, width: '16px', height: '16px' }} />}
      >
        <Typography variant='h3'>
          {t`Need some ideas?`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails >
        <Stack direction='row' spacing={SPACER.S}>
          {templates.map( (template, index) => (<VideoTemplateCard key={index} template={template} onClick={() => setOpenedTemplateModal(template)}/>))}
        </Stack>
        {!isUndefined(openedTemplateModal) && <VideoTemplateModal template={openedTemplateModal} onClose={() => setOpenedTemplateModal(undefined)}/>}
      </AccordionDetails>
    </Accordion>
  );
}