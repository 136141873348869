import { Box, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { useState } from 'react';
import Modal from './modal/modal.component';
import { ReactComponent as SparkleSvg } from 'common/images/icons/Extras/sparkle_gradient.svg';
import IconItemList from './iconItemList.component';
import { extractError, trackEvent } from '../utils/errors';
import { AIUpsellTrackingResource } from '../hooks/useAiUpsell';

const AIFeatureList = [t`AI Eye Contact`, t`AI Background Blur`, t`AI Script Generator`];

type AIFeatureModalProps = {
  onClose: () => void;
  imgSrc: string;
  trackSource: AIUpsellTrackingResource;
  description?: string;
  getUpgradeUrl: () => string | Promise<string>;
};

/**
 * AI Feature upsell modal
 */
export default function AIFeatureModal({
  onClose,
  description = t`Harness the seamless power of AI to captivate your audience and polish your content effortlessly.`,
  imgSrc,
  trackSource,
  getUpgradeUrl,
}: AIFeatureModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const handleClick = async () => {
    setLoading(true);
    try {
      trackEvent('ai-upsell-upgrade', { source: trackSource });
      const upgradeUrl = await getUpgradeUrl();
      window.parent.location = upgradeUrl;
    } catch (e) {
      setLoading(false);
      setError(extractError(e));
    }
  };

  return (
        <Modal
            title={t`Unlock the power of AI with Pro`}
            icon={SparkleSvg}
            isOpen
            onClose={onClose}
            onConfirm={() => void handleClick()}
            confirmLoading={loading}
            confirmLabel={t`Upgrade`}
            confirmError={error}
        >
          <Box ml={10}>
            <Typography mb={3}>{description}</Typography>
            <IconItemList items={AIFeatureList} />
          </Box>
          <img src={imgSrc} alt={'upgrade'} style={{ width: '100%', marginTop: '15px' }} />
        </Modal>
  );
}
