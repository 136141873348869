import { ReactNode, useState } from 'react';
import {
  Flag,
  register,
  RoxSetupOptions,
  setCustomStringProperty,
  setup,
  showOverrides,
  RoxString,
  RoxNumber,
  unfreeze,
  RoxFlagFreezeLevel,
} from 'rox-browser';
import { FeatureFlagsContext } from '../context/featureFlags.context';
import { useConfig } from '../hooks/useConfig';
import { useAuth } from '../hooks/useAuth';
import { FeatureFlagsListType } from '../models/featureFlags';
import { isNil } from 'lodash';
import { useEffectAsync } from '../../common/hooks/useEffectAsync';
import LogRocket from 'logrocket';
import { DEFAULT_MAX_RECORDING_TIME } from 'edit/const';

/**
 * Flag definitions
 * if boolean flag, default set to false to avoid showing the feature by accident
 */
const flags: FeatureFlagsListType = {
  /** Development flag, this indicates if the current user is a developer */
  dev: new Flag(false),
  videoTransitionEffects: new Flag(false),
  onboardingCalendar: new RoxString(''),
  videoRecordLength: new RoxNumber(DEFAULT_MAX_RECORDING_TIME),
  videoRecordQuality: new RoxString(''),
  monetization: new Flag(false),
  /** using -1 to determine whether to hide the feature or not */
  personalizedIntroMax: new RoxNumber(-1),
  deleteVideoProject: new Flag(false),
  duplicateVideoProject: new Flag(false),
  trialDays: new RoxNumber(-1),
  aiFeatures: new Flag(false),
  flexibleRecording: new Flag(true),
  onboardingChecklist: new Flag(true),
  screenShareAudio: new Flag(false),
  ngcConference: new Flag(false),
  appCuesKey: new RoxString(''),
  userPilotKey: new RoxString(''),
  mixPanelKey: new RoxString(''),
  juneSoKey: new RoxString(''),
};

/**
 * Set up feature flags local development mode
 */
function setupDevMode() {
  window.addEventListener('keydown', (e) => {
    // Show overrides menu
    // CTRL + ALT + F // Windows
    // ⌃ + ⌥ + F // Mac
    if (e.ctrlKey && e.altKey && !e.repeat && (e.key === 'f' || e.key === 'ƒ')) {
      showOverrides();
    }
  });
}

/**
 * Feature flags provider
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 * @returns
 */
function FeatureFlagsProvider({ children }: { children: ReactNode }) {
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const accountId = account?.localAccountId;
  const { featureFlagKey } = useConfig();
  const [isReady, setIsReady] = useState(false);
  const isDev = process.env.NODE_ENV === 'development';

  //https://docs.cloudbees.com/docs/cloudbees-feature-management-api/latest/api-reference/javascript-browser-api#_options
  const options: RoxSetupOptions = {
    freeze: 'untilLaunch' as RoxFlagFreezeLevel,
    configurationFetchedHandler: ({ fetcherStatus, hasChanges, errorDetails }) => {
      LogRocket.track(`FeatureFlagFetch_${fetcherStatus}`, { hasChanges, errorDetails: errorDetails ?? '' });
    },
    impressionHandler: (reporting) => {
      LogRocket.track(`FeatureFlagImpression_${reporting.name}`, { ...reporting });
    },
  };

  useEffectAsync(async () => {
    if (!isNil(accountId)) {
      // Custom property for user splitting rules
      // https://docs.cloudbees.com/docs/cloudbees-feature-management/latest/feature-releases/flag-context
      setCustomStringProperty('rox.distinct_id', () => accountId);
      setCustomStringProperty('user_id', () => accountId);

      // Register the flags
      register({ ...flags });
      // Setup the config
      await setup(featureFlagKey || '6261e78eecba0c2ba443e95b', options);
      // https://docs.cloudbees.com/docs/cloudbees-feature-management-api/latest/api-reference/javascript-browser-api#_unfreeze_4
      unfreeze(); //unfreeze the flags when config is ready to fetch the latest values

      setIsReady(true);
      if (isDev || flags.dev.isEnabled()) setupDevMode();
    }
  }, [accountId, isDev]);

  return <FeatureFlagsContext.Provider value={{ isReady, ...flags }}>{children}</FeatureFlagsContext.Provider>;
}

export default FeatureFlagsProvider;
