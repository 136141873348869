import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import Alert from 'common/components/alert/alert.component';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce, useEventListener } from 'usehooks-ts';
import { isNil } from 'lodash';
import Button from '@mui/material/Button';

declare global {
  interface WindowEventMap {
    'extension-event': CustomEvent<{ path: string }>
  }
}

/**
 * Page to handle communication with extension while it is recording
 */
export default function ExtensionRecordingPage() {
  const navigate = useNavigate();
  useEventListener<'extension-event'>('extension-event', (event) => {
    navigate(event.detail.path);
  });
  if (isNil(window.chrome)) {
    throw new Error('Extension page only works in Chrome');
  }
  
  const endRecording = () => {
    window.dispatchEvent(new CustomEvent('app-event', { detail: { type: 'end-recording' } }));
  };
  
  useEffectOnce(() => {
    function connectToExtension(): Promise<void> {
      //Tells extension that app is ready to accept messages
      window.dispatchEvent(new CustomEvent('app-event', { detail: { type: 'is-ready', isReady: true } }));
      return Promise.resolve();
    }
    void connectToExtension();
  });
  

  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection={'column'}
      alignItems='center'
      justifyContent='center'>
      <Alert
        severity='success'
        title={t`Recording in different tab`}
        description={t`Don't close this tab`}
      />
      <Button onClick={() => void endRecording()}>End Recording</Button>
    </Box>
  );
}

