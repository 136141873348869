import { ValueOf } from 'common/utils/types';
import { IVideoSummaryFragment } from 'data/_generated';

export const SortableProjectField = {
  title: 'title',
  dateCreated: 'dateCreated',
  lastUpdated: 'lastUpdated',
  videoPlayCount: 'videoPlayCount',
  watchPercent: 'watchPercent',
  ctaClickCount: 'ctaClickCount',
} as const;

export type SortableProjectFieldValues = ValueOf<typeof SortableProjectField>;

export type DisplayProject = Readonly<Omit<IVideoSummaryFragment, '__typename'> & {
  videoPlayCount?: number;
  watchPercent?: number;
  ctaClickCount?: number;
}>;