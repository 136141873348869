import { t } from '@lingui/macro';
import { Button, IconButton, InputAdornment, SvgIcon, useTheme, Box, useMediaQuery } from '@mui/material';
import TextField from 'common/components/textField/textField.component';
import { ITargetInput } from 'data/_generated';
import {
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { ReactComponent as GroupIcon } from 'common/images/icons/Utility/group.svg';
import { ReactComponent as EmailIcon } from 'common/images/icons/Utility/unread message.svg';
import { ReactComponent as TrashIcon } from 'common/images/icons/Utility/trash.svg';
import { ReactComponent as AddCircleSvg } from 'common/images/icons/Utility/add_1.svg';

import { notEmpty } from 'common/utils/array';
import { NEW_TARGET_FORM_DATA_INPUT, TargetFormDataType, TargetPropertyNames, Targets } from './const';
import { Theme } from '@mui/material/styles';
import { FieldPath } from 'react-hook-form/dist/types';
import PersonalizedIntroLimit from './personalizedIntroLimit.component';
import { useEffectOnce } from 'usehooks-ts';
import { SPACER } from '../../../config/models/themeOptions';

const MINIMUM_TARGETS = 1;

/**
 * Helper function to get labelId for form fields
 * Following the react hook form convention
 * @param identifier target identifier
 * @param type type of target field
 * @returns string
 */
function getLabelId(
  identifier: number,
  /** TODO: [VID-1145] Update type to ValueOf<typeof TargetPropertyNames> once old project codes are cleaned up */
  type: typeof TargetPropertyNames.name | typeof TargetPropertyNames.email | typeof TargetPropertyNames.id,
): FieldPath<TargetFormDataType> {
  switch (type) {
    case TargetPropertyNames.name:
      return `${Targets}.${identifier}.${TargetPropertyNames.name}`;
    case TargetPropertyNames.email:
      return `${Targets}.${identifier}.${TargetPropertyNames.email}`;
    case TargetPropertyNames.id:
      return `${Targets}.${identifier}.${TargetPropertyNames.id}`;
  }
}

type FormRowProps = Pick<UseFormReturn<TargetFormDataType>, 'control'> &
Pick<UseFieldArrayReturn, 'remove'> & {
  /** identifier to id each row field */
  identifier: number;
  /** loading status */
  loading: boolean;
  /** row field error */
  error: Merge<FieldError, FieldErrorsImpl<ITargetInput>> | undefined;
  /** if row can be removed */
  removable: boolean;
};

type TargetFormFieldsProps = { hideEmail: boolean } & 
Pick<FormRowProps, 'control' | 'loading' | 'error' | 'identifier'>;

/**
 * Target Form Fields
 */
const TargetFormFields = ({ hideEmail, control, loading, error, identifier }: TargetFormFieldsProps) => {
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  
  return (
    <>
      <Controller
        name={getLabelId(identifier, TargetPropertyNames.name)}
        control={control}
        render={({ field }) => (
          <TextField
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            size="small"
            placeholder={t`Name`}
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon inheritViewBox component={GroupIcon} />
                </InputAdornment>
              ),
            }}
            fullWidth
            error={notEmpty(error?.name)}
            helperText={error?.name?.message}
          />
        )}
      />
      {!hideEmail && <Controller
        name={getLabelId(identifier, TargetPropertyNames.email)}
        control={control}
        render={({ field }) => (
          <TextField
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            size="small"
            fullWidth
            placeholder={t`Email`}
            disabled={loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon inheritViewBox component={EmailIcon} />
                </InputAdornment>
              ),
            }}
            error={notEmpty(error?.email)}
            helperText={error?.email?.message}
            sx={isXS ? { my: 2 } : { ml: 2 }}
          />
        )}
      />}
    </>
  );
};
/**
 * Component for Row in the TargetForm
 */
function TargetFormRow({ hideEmail, identifier, loading, control, remove, error, removable }: 
{ hideEmail: boolean } & FormRowProps) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <Box
        display="flex"
        alignItems={'baseline'}
        justifyContent={isXS ? 'space-between' : undefined}
        sx={{ mb: SPACER.S }}
      >
        {/* TargetFormFields placement need to change depending on the screen size for layout purpose 
            which is why we have conditional renderings below and further down below refer to VID-1065 for details */}
        <TargetFormFields
          hideEmail={hideEmail}
          identifier={identifier}
          control={control}
          loading={loading}
          error={error}
        />
        {removable &&
          <Controller
            name={getLabelId(identifier, TargetPropertyNames.id)}
            control={control}
            render={() => (
              <IconButton
                onClick={() => remove(identifier)}
                disabled={loading}
                sx={{ alignSelf: error?.email || error?.name ? 'flex-start' : 'center' }}
              >
                <SvgIcon component={TrashIcon} inheritViewBox />
              </IconButton>
            )}
          />
        }
      </Box>
    </>
  );
}

type TargetFormProps = {
  /** loading state */
  isLoading?: boolean;
  /** max number of rows */
  maxRows: number;
  /** minimum number of rows */
  minRows: number;
  /** show an empty row on init */
  showEmptyRow?: boolean;
  /** hide email field */
  hideEmail: boolean;
};

export default function TargetForm({
  isLoading = false,
  maxRows,
  minRows = MINIMUM_TARGETS,
  showEmptyRow = false,
  hideEmail,
}: TargetFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TargetFormDataType>();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));

  const { fields, remove, append } = useFieldArray({
    control,
    name: Targets,
  });

  const addNewRow = () => append({ ...NEW_TARGET_FORM_DATA_INPUT });

  useEffectOnce(() => {
    if (showEmptyRow) addNewRow();
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <TargetFormRow
            hideEmail={hideEmail}
            key={item.id}
            identifier={index}
            loading={isLoading}
            control={control}
            remove={remove}
            error={errors[Targets]?.[index]}
            removable={fields.length > minRows}
          />
        );
      })}
      <Box display={isXS ? 'block' : 'flex'} justifyContent={'space-between'} alignItems="center">
        <Button
          onClick={addNewRow}
          sx={{ width: 'fit-content' }}
          startIcon={<SvgIcon inheritViewBox component={AddCircleSvg} />}
          variant="text"
          disabled={fields.length >= maxRows}
        >
          {t`Add one more recipient`}
        </Button>
        <PersonalizedIntroLimit max={maxRows} current={fields.length} />
      </Box>
    </>
  );
}
