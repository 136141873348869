import {
  Box,
  Button,
  Menu,
  styled,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { IVideoProcessingStateV2, useUpdateUserUsageInfoMutation } from 'data/_generated';
import { isNil } from 'lodash';
import { replaceProps, toDurationString } from 'common/utils/string';
import { EMAIL_BODY, EMAIL_SUBJECT } from 'share/const';
import { ReactComponent as UnreadSvg } from 'common/images/icons/Utility/unread message.svg';
import { ReactComponent as DownloadSvg } from 'common/images/icons/Utility/download.svg';
import { ReactComponent as OptionsSvg } from 'common/images/icons/Utility/options_vertical.svg';
import { ReactComponent as PencilSvg } from 'common/images/icons/Utility/pencil.svg';
import { ReactComponent as LinkSvg } from 'common/images/icons/Utility/link three.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { ReactComponent as ImageSvg } from 'common/images/icons/Utility/image file.svg';
import { ReactComponent as RemoveSvg } from 'common/images/icons/Utility/remove_1.svg';
import { ReactComponent as TrashSvg } from 'common/images/icons/Utility/trash.svg';
import RetryPublishButton from 'share/components/retryPublishButton.component';
import Modal from 'common/components/modal/modal.component';
import emailImage from 'common/images/illustration/email with arrow.png';
import { extractError, logError, trackEvent } from 'common/utils/errors';
import { EditSingleValueModal } from 'common/components/modal/editSingleValueModal.component';
import useEmail from 'common/hooks/useEmail';
import { SPACER } from 'config/models/themeOptions';
import ProjectMenuItem from '../../common/components/projectMenu.component';
import { renameDownload } from '../../common/utils/util';

type EmailActionProps = {
  /** callback on click */
  onClick: () => void;
  /** mailTo link */
  mailToLink: string;
  /** email of target */
  targetEmail: string;
  /** show text to send email */
  showText: boolean;
};

/**
 * Component for Email Action
 */
function EmailAction({ onClick, targetEmail, mailToLink, showText }: EmailActionProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const theme = useTheme();
  const handleClick = () => {
    onClick();
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title={t`Send email`} placement="top" arrow>
        <ActionButton
          startIcon={<SvgIcon component={UnreadSvg} inheritViewBox />}
          size="small"
          variant="contained"
          color="secondary"
          onMouseDown={handleClick}
          sx={{
            '.MuiButton-startIcon': {
              color: theme.palette.primary[300],
              mr: showText ? SPACER.XS : undefined,
            },
          }}
        >
          {showText ? t`Send email` : undefined}
        </ActionButton>
      </Tooltip>
      <Modal
        title={t`Your email is one step away`}
        confirmLabel={t`Email ${targetEmail}`}
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleClose}
        confirmHref={mailToLink}
      >
        <>
          <Typography>
            {t`We’ve automatically copied the link and thumbnail to paste in the body section of your email.`}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={emailImage} alt={t`Email with arrow`} />
          </Box>
        </>
      </Modal>
    </>
  );
}

export type ActionButtonsProps = {
  /** id of target */
  targetId: string;
  /** name of target */
  targetName: string;
  /** email of target */
  targetEmail?: string | null;
  /** publish status of target video */
  publishedStatus?: IVideoProcessingStateV2 | null;
  /** target video's thumbnail location */
  thumbnailLocation: string;
  /**
   * target video title
   */
  targetVideoTitle?: string;
  /** landing page title template */
  targetVideoTitleTemplate: string;
  /** callback on copy */
  onCopied: (hasCopied: boolean) => void;
  /** name of user account */
  accountName: string;
  /** duration of the video */
  duration: number;
  /** callback on edit target title */
  onEditTargetTitle: (title: string) => Promise<void>;
  /** callback to delete target */
  onDeleteTarget: (targetId: string) => Promise<void>;
  /** if the actions are full width */
  fullWidth?: boolean;
};

/** Share Action Buttons */
function ActionButtons({
  targetId,
  targetName,
  targetEmail,
  publishedStatus,
  thumbnailLocation,
  targetVideoTitle,
  targetVideoTitleTemplate,
  onCopied,
  accountName,
  duration,
  onEditTargetTitle,
  onDeleteTarget,
  fullWidth,
}: ActionButtonsProps) {
  const mailTo = useEmail();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUsage] = useUpdateUserUsageInfoMutation({ refetchQueries: ['UserUsageInfo'] });
  const open = Boolean(anchorEl);
  const [isEditTargetTitle, setIsEditTargetTitle] = useState<boolean>(false);
  const [isDeletingTarget, setIsDeletingTarget] = useState(false);
  const [isDeleteTargetModalOpen, setIsDeleteTargetModalOpen] = useState(false);
  const [deleteTargetError, setDeleteTargetError] = useState<Error>();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmallerMD = useMediaQuery(theme.breakpoints.down('md'));
  const displayFullWidth = fullWidth || isSmallerMD;
  useEffect(() => {
    if (isCopied) {
      const copiedTimer = setTimeout(() => setIsCopied(false), 2000);
      return () => clearTimeout(copiedTimer);
    }
    return;
  }, [isCopied]);

  const trackShare = (type: string, trackUsage?: boolean) => {
    trackEvent(type);
    if (trackUsage ?? true) {
      void updateUsage({ variables: { data: { hasCopiedVideoLink: true } } });
    }
  };

  const setClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      onCopied(true);
      setIsCopied(true);
      handleClose();
    } catch (e) {
      onCopied(false);
      setIsCopied(false);
    }
  };

  const setClipboardHTML = async (html: string) => {
    const blob = new Blob([html], { type: 'text/html' });
    const clipboardData = new ClipboardItem({ 'text/html': blob });

    try {
      await navigator.clipboard.write([clipboardData]);
      onCopied(true);
      handleClose();
    } catch (e) {
      onCopied(false);
    }
  };

  // TODO: use config to get base url instead of window.location.origin
  const absoluteLink = (id: string) => `${window.location.origin}/s/${id}`;

  const embedString = (id: string, addLink = false) => {
    const alt = t`▶️ Watch video (${toDurationString(duration)})`;
    let embed = `<a href="${absoluteLink(id)}">
      <img
        style="width:320px"
        width="320" 
        alt="${alt}" 
        src="${thumbnailLocation}"/>
    </a>`;

    if (addLink) {
      embed += '<br>';
      embed += `<a href="${absoluteLink(id)}">${alt}</a>`;
    }
    return embed;
  };
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        LinkComponent="a"
        href={`/s/${targetId}`}
        onClick={(e) => {
          trackShare('share-copy-link', !isCopied);
          if (e.metaKey || e.ctrlKey) return;
          e.preventDefault();
          void setClipboard(absoluteLink(targetId));
        }}
        startIcon={<SvgIcon component={isCopied ? ConfirmSvg : LinkSvg} inheritViewBox />}
        size="small"
        variant="contained"
        color={isCopied ? 'success' : 'secondary'}
        sx={{ flex: displayFullWidth ? 1 : undefined, mt: { xs: 3, sm: 0 } }}
      >
        {isCopied ? t`Copied!` : isXS ? t`Copy video link` : t`Copy link`}
      </Button>
      {!isXS && (
        <>
          <EmailAction
            showText={!displayFullWidth}
            onClick={() => {
              trackShare('share-email');
              void setClipboardHTML(embedString(targetId));
            }}
            targetEmail={targetEmail ?? ''}
            mailToLink={mailTo(
              targetEmail ?? undefined,
              replaceProps(EMAIL_SUBJECT, { '[first_name]': targetName }),
              replaceProps(EMAIL_BODY, {
                '[first_name]': targetName,
                '[my_name]': accountName,
              }),
            )}
          />
          <Tooltip title={t`Copy with thumbnail`} placement="top" arrow>
            <ActionButton
              variant="outlined"
              onClick={() => {
                trackEvent('share-copy-link+thumbnail');
                void setClipboardHTML(embedString(targetId, true));
              }}
              startIcon={<SvgIcon component={ImageSvg} inheritViewBox />}
              size="small"
            />
          </Tooltip>
          <Tooltip title={t`More`} placement="top" arrow>
            <ActionButton
              size="small"
              variant="outlined"
              startIcon={<SvgIcon component={OptionsSvg} inheritViewBox />}
              onClick={handleClickMenu}
            />
          </Tooltip>

          <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
            <ProjectMenuItem
              onClick={() => {
                trackEvent('share-edit-video-title');
                setIsEditTargetTitle(true);
                handleClose();
              }}
              icon={PencilSvg}
              label={t`Edit page title`}
            />
            <ProjectMenuItem
              onClick={() => {
                void renameDownload(publishedStatus?.result ?? '', targetVideoTitle ?? targetVideoTitleTemplate);
                trackShare('share-download');
                handleClose();
              }}
              disabled={isNil(publishedStatus?.result)}
              icon={DownloadSvg}
              label={t`Download`}
            />
            <ProjectMenuItem onClick={() => {
              trackShare('share-delete-target-opened');
              setIsDeleteTargetModalOpen(true);
              handleClose();
            }} icon={TrashSvg} label={t`Delete recipient`}/>
          </Menu>
        </>
      )}
      <EditSingleValueModal
        title={t`Edit page title`}
        description={t`Editing the page title will update the text displayed above the video for this recipient.`}
        value={targetVideoTitle}
        key={targetVideoTitle ?? targetVideoTitleTemplate}
        icon={PencilSvg}
        onUpdate={onEditTargetTitle}
        isOpen={isEditTargetTitle}
        onClose={() => setIsEditTargetTitle(false)}
        placeholderText={targetVideoTitleTemplate}
        confirmLabel={t`Save Changes`}
        cancelLabel={t`Cancel`}
      />
      <Modal
        isOpen={isDeleteTargetModalOpen}
        confirmLoading={isDeletingTarget}
        confirmError={deleteTargetError}
        title={'Delete Recipient?'}
        onConfirm={async () => {
          trackShare('share-delete-target');
          try {
            setIsDeletingTarget(true);
            await onDeleteTarget(targetId);
            handleClose();
            setIsDeleteTargetModalOpen(false);
          } catch (e) {
            const error = extractError(e);
            logError(error);
            setDeleteTargetError(error);
          } finally {
            setIsDeletingTarget(false);
          }
        }}
        onClose={() => {
          setDeleteTargetError(undefined);
          setIsDeleteTargetModalOpen(false);
        }}
        icon={RemoveSvg}
        confirmLabel={'Delete permanently'}
        iconStyle={{ color: theme.palette.warning[500] }}
        maxWidth="xs"
      >
        <Typography variant="bodyMedium">
          {t`Deleting the recipient will remove access to any shared videos and associated analytics permanently.`}
        </Typography>
      </Modal>
    </>
  );
}

type ShareActionProps = Omit<ActionButtonsProps, 'onEditTargetTitle'> & {
  /** id of video project */
  projectId: string;
  /** callback on update target */
  onEditTargetTitle: (title: string) => Promise<void>;
  /** if the actions should be full width */
  fullWidth?: boolean;
};

/**
 * Share Action Component
 */
export default function ShareAction({
  targetId,
  targetName,
  targetEmail,
  publishedStatus,
  thumbnailLocation,
  accountName,
  projectId,
  onCopied,
  duration,
  onEditTargetTitle,
  onDeleteTarget,
  targetVideoTitle,
  targetVideoTitleTemplate,
  fullWidth,
}: ShareActionProps) {
  if (publishedStatus?.status === 'error') return <RetryPublishButton targetId={targetId} projectId={projectId} />;
  return (
    <ActionButtons
      duration={duration}
      targetId={targetId}
      targetName={targetName}
      targetEmail={targetEmail}
      publishedStatus={publishedStatus}
      onCopied={onCopied}
      thumbnailLocation={thumbnailLocation}
      accountName={accountName}
      onEditTargetTitle={onEditTargetTitle}
      onDeleteTarget={onDeleteTarget}
      targetVideoTitle={targetVideoTitle}
      targetVideoTitleTemplate={targetVideoTitleTemplate}
      fullWidth={fullWidth}
    />
  );
}

const ActionButton = styled(Button)(({ theme }) => ({
  '& .MuiButton-startIcon': {
    color: theme.palette.primary[300],
    marginRight: 0,
    marginLeft: 0,
  },
  marginLeft: 8,
  borderColor: theme.palette.primary[50],
  backgroundColor: theme.palette.neutral[0],
  padding: 8,
  minWidth: 0,
  '&:hover': {
    borderColor: theme.palette.primary[50],
    backgroundColor: theme.palette.primary[50],
  },
}));
