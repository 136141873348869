/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { getAccountData } from 'common/utils/util';
import { useAuth } from 'config/hooks/useAuth';
import LogRocket from 'logrocket';
import { ReactNode, useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useConfig } from '../hooks/useConfig';
import setupLogRocketReact from 'logrocket-react';

interface LogRocketProps {
  children: ReactNode;
}

/**
 * This is the LogRocket AppId
 * Shared between all the deployments
 * @type string
 */
const LOG_ROCKET_APP_ID = 'ehkll0/video';

/**
 * LogRocket provider
 * @returns JSX.Element
 */
function LogRocketProvider({ children }: LogRocketProps) {
  const { instance } = useAuth();
  const { versionNumber } = useConfig();
  const account = instance.getActiveAccount();

  const canEnable = process.env.NODE_ENV !== 'development';
  useEffectOnce(() => {
    if (canEnable) {
      LogRocket.init(LOG_ROCKET_APP_ID, {
        release: versionNumber,
        network: {
          requestSanitizer: (request) => {
            // remove authorization token in network log
            if (request.headers.Authorization) request.headers.Authorization = '';
            return request;
          },
        },
        ingestServer: `${window.origin}/LogRocket`,
        rootHostname: 'copilotai.com',
      });
      setupLogRocketReact(LogRocket);
    }
  });

  useEffect(() => {
    if (canEnable && account ) {
      const data = getAccountData(account);
      LogRocket.identify(account.localAccountId, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return <>{children}</>;
}

export default LogRocketProvider;
