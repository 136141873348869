
import {
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useOutletContext } from 'react-router-dom';
import { DashboardLayoutContext } from './dashboardLayout.layout';
import { ReactNode } from 'react';

type DashboardBarProps = {
  /** the top title to show */
  title: string,
  /** the title variant to use, default is h1  */
  variant?: 'h1' | 'h3',
  /** if the dashboard bar should show more option */
  children?: ReactNode,
};

/**
 * The top bar of the dashboard
 * @returns {JSX.Element}
 */
export default function DashboardBar({ title, children, variant = 'h3' }: DashboardBarProps) {
  const { handleDrawerToggle } = useOutletContext<DashboardLayoutContext>();

  return <Toolbar disableGutters sx={{ mb: 2 }}>
    <IconButton
      color="inherit"
      aria-label="open drawer"
      edge="start"
      onClick={handleDrawerToggle}
      component="span"
      sx={{ mr: 2, display: { sm: 'none' } }}
    >
      <MenuIcon />
    </IconButton>
    <Typography variant={variant}>
      {title}
    </Typography>
    <Box sx={{ ml: 'auto' }}>
      {children}
    </Box>
  </Toolbar>;
}