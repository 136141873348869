import { useUpdateVideoProjectMutation } from 'data/_generated';
import { MAX_TITLE_LENGTH } from 'edit/const';
import { isEmpty } from 'lodash';

/**
 * A hook for shared logic to update the title of a video project
 */
export default function useUpdateTitle(id: string) {
  const [updateVideoProject] = useUpdateVideoProjectMutation();
  const updateTitle = async (title: string) => {
    if (isEmpty(title.trim())) throw Error('Your project name cannot be empty');
    if (title.length > MAX_TITLE_LENGTH) throw Error('Your project name is too long');
    await updateVideoProject({
      variables: {
        updateVideoProjectId: id,
        data: { title },
      },
    });
  };
  return { updateTitle };
}
