import { AuthenticationResult, AuthError } from '@azure/msal-browser';
import { useAuth } from 'config/hooks/useAuth';
import { useRef } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type AuthStatus = {
  /** The status results of successful authentication */
  IsLoggedIn?: AuthenticationResult;
  /** The status results of auth error */
  IsLoggedOut?: AuthError;
};

/**
 * Use hook to not block the user when authentication has not gone through yets
 * 
 * @param onAuth callback to know when the user has been authenticated or not
 * @returns {[ Ref<AuthStatus> ]} a reference to the status
 */
export default function useAuthSilent(onAuth?: (auth: boolean) => void) {
  const { instance, scopes } = useAuth();
  const authResult = useRef<AuthStatus | undefined>();

  const silentAuth = async () => {
    try {
      authResult.current = { IsLoggedIn: await instance.ssoSilent({ scopes }) };
      if (onAuth) onAuth(true);
    } catch (e) {
      if (e instanceof AuthError) {
        authResult.current = { IsLoggedOut : e };
      }
      if (onAuth) onAuth(false);
    }
  };

  useEffectOnce(() => {
    void silentAuth();
  });

  return [authResult];
}
