import { styled, TextField } from '@mui/material';
import { useEventListener } from 'usehooks-ts';
import { useRef } from 'react';

const SCROLL_BY = 100;

const TeleprompterField = styled(TextField)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '50%',
  zIndex: 1,
  overflowY: 'auto',
  border: 'none',
  display: 'unset',
  '&::placeholder': {
    opacity: 1,
    color: theme.palette.neutral[0],
  },
  background:
    'linear-gradient(180deg, rgba(2, 0, 15, 0.8) 36.68%, rgba(2, 0, 15, 0.662198) 57.13%, rgba(2, 0, 15, 0.120833) 91.43%, rgba(2, 0, 15, 0) 100%)',
  '& .MuiOutlinedInput-root': {
    color: theme.palette.neutral[0],
    background: 'unset',
    border: 'unset',
    fontSize: 24,
    height: '100%',
    textAlign: 'start',
    cursor: 'default',
    width: '100%',
    '& > textarea': {
      height: '100% !important',
      overflowY: 'auto !important',
      cursor: 'default',
    },
    '&:hover:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled)': {
      color: theme.palette.neutral[0],
    },
  },
}));

type BasicTeleprompterComponentProps = {
  value?: string | null;
  isOpen?: boolean;
};

/**
 * Basic teleprompter with display functionality only
 */
export default function BasicTeleprompterComponent({ value, isOpen }: BasicTeleprompterComponentProps) {
  const fieldRef = useRef<HTMLDivElement>(null);

  useEventListener('keydown', (event) => {
    if (event.key === 'ArrowUp') {
      fieldRef.current?.scrollBy(0, -SCROLL_BY);
    } else if (event.key === 'ArrowDown') {
      fieldRef.current?.scrollBy(0, SCROLL_BY);
    }
  });
  return (
    <>
      {isOpen && (
        <TeleprompterField ref={fieldRef} value={value} multiline InputProps={{
          readOnly: true,
        }}/>
      )}
    </>
  );
}
