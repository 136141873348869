import { Route, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import DashboardLayout from 'dashboard/components/dashboardLayout.layout';
import Authenticated from './authenticated.component';
import LandingPage from 'share/pages/landing.page';
import LandingPageLayout from 'share/components/landing.layout';
import AppBarLayout from 'common/components/layout/appBar.layout';
import FetchUser from 'common/components/fetchUser.component';
import MobileAdvisory from 'common/components/mobileAdvisory';
import NotFound from 'pages/notFound.page';
import TimelineLayout from 'edit/layout/timeline.layout';
import LivePage from 'edit/pages/live.page';
import LocalPage from 'edit/pages/local.page';
import B2CSignUpPage from 'pages/B2CSignUp.page';
import AnalyticsPage from 'dashboard/pages/analytics.page';
import ProjectsPage from 'dashboard/pages/projects.page';
import TrackingProvider from './tracking.component';
import PlanReactivatedModal from 'common/components/planReactivatedModal.component';
import SharePage from 'dashboard/pages/share.page';
import PricingPage from 'pages/pricing.page';
import QuickPage from 'edit/pages/quick.page';
import ExtensionPage from '../../pages/extension.page';
import ExtensionInstalledPage from '../../pages/extensionInstalled.page';
import ExtensionRecordingPage from '../../pages/extensionRecording.page';
import { SnackBarContextProvider } from '../../common/components/snackBar/snackBarContext.component';
import { t } from '@lingui/macro';
import Guides from './guides.component';

function Router() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/signup" element={<B2CSignUpPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/ext-installed" element={<ExtensionInstalledPage />} />
        {/* Add June Provider for Pages that may have the user using the Application */}
        <Route
          element={
            <TrackingProvider>
              <Outlet />
            </TrackingProvider>
          }
        >
          {/* Add Routes within this element if the page required the user to be Authenticated */}
          <Route
            element={
              <>
                <Authenticated />
                <MobileAdvisory />
              </>
            }
          >
            <Route
              element={
                <Guides>
                  <Outlet />
                </Guides>
              }
            >
              <Route
                element={
                  <FetchUser>
                    <Outlet />
                  </FetchUser>
                }
              >
                <Route path="/ext-recording" element={<ExtensionRecordingPage />} />
                <Route path="/ext" element={<ExtensionPage />} />
                <Route element={<AppBarLayout />}>
                  <Route element={<DashboardLayout />}>
                    <Route path="/" element={<ProjectsPage />} />
                    <Route
                      path="/welcome-back"
                      element={
                        <>
                          <ProjectsPage />
                          <PlanReactivatedModal />
                        </>
                      }
                    />
                    <Route path="/analytics" element={<AnalyticsPage />} />
                  </Route>
                </Route>
                <Route element={<TimelineLayout backHref="/analytics" canEditTitle={false} showUserButton={false} />}>
                  <Route path="/share/:id" element={<SharePage />} />
                </Route>
                <Route element={<TimelineLayout canEditTitle={true} showUserButton={false} />}>
                  <Route path="/quick/" element={<QuickPage />} />
                </Route>
                <Route element={<TimelineLayout canEditTitle={true} showUserButton={false} />}>
                  <Route path="/edit/:id" element={<LivePage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <Guides>
                <TimelineLayout canEditTitle={true} backHref={'https://videoby.copilotai.com'} backText={t`Back`} />
              </Guides>
            }
          >
            <Route path="/play" element={<LocalPage />} />
            <Route path="/edit" element={<LocalPage />} />
          </Route>
        </Route>
        <Route element={<LandingPageLayout />}>
          {/* This route should be never accessed on production as it is taken care by the API Gateway */}
          <Route path="/s/:id" element={<LandingPage />} />
          <Route path="/v/:id" element={<LandingPage />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </>,
    ),
  );

  return (
    <SnackBarContextProvider>
      <RouterProvider router={router} />
    </SnackBarContextProvider>
  );
}

export default Router;
