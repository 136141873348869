import { Box, alpha } from '@mui/material';
import VideoPlayTag from './videoPlayTag.component';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import AlertError from 'common/components/alert/alertError.component';
import VideoPlayScrubber from './videoPlayScrubber.component';
import { VideoDimensions } from 'common/constants/video';
import { trackError } from 'common/utils/errors';
import { isError, isNil, isUndefined } from 'lodash';
import { forwardRef, RefObject, useImperativeHandle, useRef, useState } from 'react';
import { useElapsedTime } from 'use-elapsed-time';
import { useEffectOnce } from 'usehooks-ts';
import { MAX_NUMBER_OF_IMAGES } from 'edit/const';
import useThumbnail from 'common/hooks/useThumbnails';

type VideoPlayProps = {
  /** the location of the video */
  src: string;
  /** a video element that should always match */
  matchVideoRef?: RefObject<HTMLVideoElement>;
  /** if there are more controls over the scrubber */
  children?: React.ReactNode;
  /** if there is loading */
  loading?: boolean;
  /** the duration of the video in seconds */
  durationInSeconds?: number;
  /** the start time to limit */
  startTime?: number;
  /** the end time to limit */
  endTime?: number;
  /** if the controls are open by default */
  expanded?: boolean;
  /** callback when dimensions are updated */
  onDimensionsChange: (dimensions: VideoDimensions & { offsetHeight: number }) => void;
  /** callback when valid duration is updated */
  onDurationChange?: (duration: number) => void;
  /** callback when there is an error playing the video */
  onError?: (error: Error) => void;
  /** if the video should auto play */
  autoPlay?: boolean;
};

/** 
 * Component to Play video
 * Wraps the functionality of the video element
 * adds a scrubber with thumbnails and enables trimming
 * by specifying the controls
 * TODO: be explicit what is available to with the forwardRef (not just the element)
 */
export default forwardRef<HTMLVideoElement, VideoPlayProps>(function VideoPlay({
  expanded = false,
  src, 
  matchVideoRef,
  children: trimming,
  durationInSeconds,
  startTime,
  endTime,
  loading = false,
  onDimensionsChange,
  onDurationChange,
  onError,
  autoPlay = false,
}, ref) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentInSeconds, setCurrentInSeconds] = useState<number | undefined>();
  // if the video was playing before the user clicked on the scrubber
  const [wasPlaying, setWasPlaying] = useState<boolean>(false);
  // if there was an error trying to play the video
  const [error, setError] = useState<Error | undefined>();
  // if we should show the error
  const [showError, setShowError] = useState<boolean>(false);
  const isValidDuration = durationInSeconds && isFinite(durationInSeconds) && durationInSeconds > 0;
  const videoRef = useRef<HTMLVideoElement>(null);
  const trimmedDuration = durationInSeconds ? (isUndefined(endTime) ? (durationInSeconds - (startTime ?? 0)) : (endTime - (startTime ?? 0))) :
    undefined;
  const { multipleThumbnail: thumbnails } = useThumbnail({
    options: { createMultiple: true },
    videoSrc: src,
    startTimeInSec: expanded ? undefined : startTime,
    durationInSec: expanded ? durationInSeconds : trimmedDuration,
  });

  // to expose the video element to the parent component
  // https://dmitripavlutin.com/react-forwardref/
  useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement, [videoRef]);

  const updateInterval = isUndefined(durationInSeconds) ? 0.1 : durationInSeconds / 2000;
  // while playing maintain a level of accuracy of 0.1 seconds as onUpdateTime is called
  // at random intervals
  useElapsedTime({
    updateInterval,
    isPlaying,
    onUpdate: () => {
      if (isNil(videoRef.current)) return;
      if (videoRef.current.paused) setIsPlaying(false);
      timeCheck(videoRef.current);
    },
  });

  useEffectOnce(() => {
    if (isNil(videoRef.current)) return;
    //set the current time to the start time if specified
    videoRef.current.currentTime = startTime ?? 0;
  });

  const updateDuration = ({ duration }: { duration: number }) => {
    if (isUndefined(onDurationChange)) return;
    const isPassedDurationValid = isFinite(duration) && duration > 0;
    if (!isPassedDurationValid) return;
    if (isValidDuration && durationInSeconds === duration) return;
    // Only update duration if passed duration valid or the saved duration is not valid
    onDurationChange(duration);
  };

  /** callback when there is an issue trying to look at selected video */
  const handleError = (mediaError: MediaError) => {
    let errorMessage = 'Failed to load video';
    switch (mediaError.code) {
      case MediaError.MEDIA_ERR_ABORTED:
        errorMessage = 'Loading this video has been aborted';
        break;
      case MediaError.MEDIA_ERR_NETWORK:
        errorMessage = 'There was a network issue loading this video';
        break;
      case MediaError.MEDIA_ERR_DECODE:
        errorMessage = 'This video file could not be parsed.';
        break;
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        errorMessage = "Your device doesn't support this video format";
        break;
      case 5: // MediaError.MEDIA_ERR_ENCRYPTED:
        errorMessage = 'The video failed to load due to security reasons';
        break;
      default:
        if (mediaError.message) {
          errorMessage = `Failed to load video (${mediaError.message})`;
        }
    }
    trackError('video-play-error', {
      src,
      errorMessage,
      errorCode: `${mediaError.code}`,
      errorDetails: mediaError.message,
    });
    const newError = new Error(errorMessage);
    setError(newError);
    if (onError) onError(newError);
  };

  const togglePlay = () => {
    if (isNil(videoRef.current)) return;
    try {
      if (videoRef.current.paused) {
        void videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    } catch (e) {
      if (isError(e)) setError(e);
    }
    // ensure this is update to whatever the state is
    setIsPlaying(!videoRef.current.paused);
  };

  const timeCheck = (currentTarget: HTMLVideoElement)=> {
    if (matchVideoRef?.current) matchVideoRef.current.currentTime = currentTarget.currentTime; 
    setCurrentInSeconds(currentTarget.currentTime);
    if (isUndefined(endTime) || isUndefined(startTime)) return;
    // currentTime only supports up to 4 decimal points of accuracy
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/currentTime#reduced_time_precision
    const startTimeFixed = parseFloat(startTime.toFixed(4));
    if (currentTarget.currentTime < startTimeFixed || currentTarget.currentTime > endTime) {
      currentTarget.currentTime = startTimeFixed;
      setCurrentInSeconds(startTimeFixed);
    }
  };

  const currentInSecondsWithStart = trimming ?
    currentInSeconds :
    currentInSeconds ? Math.max(0, currentInSeconds - (startTime ?? 0)) : undefined;
  // start playing the video if it is not valid duration
  const shouldAutoPlay = (onDurationChange && !isValidDuration) || autoPlay;
  return <><video
    ref={videoRef}
    crossOrigin=""
    playsInline
    width="100%"
    height="100%"
    onSeeked={({ currentTarget }) => updateDuration(currentTarget)}
    onCanPlayThrough={({ currentTarget }) => updateDuration(currentTarget)}
    onLoadedMetadata={({ currentTarget: { videoWidth, videoHeight, offsetHeight, duration } }) => {
      onDimensionsChange({ videoWidth, videoHeight, offsetHeight });
      updateDuration({ duration });
    }}
    onError={({ currentTarget: { error: currentError } }) => {
      if (!currentError) return;
      handleError(currentError);
    }}
    autoPlay={shouldAutoPlay}
    src={src}
    onPlaying={() => setIsPlaying(true)}
    onPause={() => setIsPlaying(false)}
    onTimeUpdate={({ currentTarget }) => timeCheck(currentTarget)}
    poster={thumbnails[0]}
    controls={false}
    muted={loading}
    controlsList="nodownload nofullscreen noremoteplayback nopictureinpicture"
    disablePictureInPicture
    disableRemotePlayback
    loop
  /><Box sx={{
    position: 'absolute',
    bottom: 10,
    left: 10,
    borderRadius: '10px',
    width: expanded ? 'calc(100% - 20px)' : undefined,
    backgroundColor: alpha(PALETTE_COLOR.neutral[900], 0.8),
    textAlign: 'left',
    '> div': {
      paddingTop: '11px',
      display: !expanded ? 'none' : undefined,
    },
    '&:hover': error ? undefined : {
      backgroundColor: alpha(PALETTE_COLOR.neutral[900], 0.9),
      width: 'calc(100% - 20px)',
      '> div': {
        display: 'block',
      },
    },
  }}>
      <Box>
        <VideoPlayScrubber
          thumbnails={thumbnails}
          thumbnailCount={MAX_NUMBER_OF_IMAGES}
          currentInSeconds={currentInSecondsWithStart ?? 0}
          loading={loading}
          durationInSeconds={durationInSeconds}
          onUpdate={(time) => {
            if (isNil(videoRef.current)) return;
            if (!videoRef.current.paused) {
              setWasPlaying(true);
              videoRef.current.pause();
            }
            videoRef.current.currentTime = time + (trimming ? 0 : (startTime ?? 0));
          }}
          onDone={() => {
            if (!wasPlaying) return;
            if (isNil(videoRef.current)) return;
            setWasPlaying(false);
            void videoRef.current.play();
          }}
        >{trimming}
        </VideoPlayScrubber>
      </Box>
      <AlertError open={showError} error={error}>
        <VideoPlayTag
          state={error ? 'error' : (isPlaying ? 'paused' : 'playing')}
          onClick={error ? () => setShowError(true)
            : (loading ? undefined : togglePlay)}
          currentInSeconds={currentInSecondsWithStart}
          durationInSeconds={durationInSeconds} />
      </AlertError>
    </Box></>;
});