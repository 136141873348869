import { ReactNode, useState } from 'react';
import VideoProcess from './videoProcess.component';
import VideoUploader from './videoUploader.component';
import { Box } from '@mui/material';
import { t } from '@lingui/macro';
import { UpdateClipInput } from 'edit/const';
import { useAuth } from 'config/hooks/useAuth';
import { VideoDetails, VideoExisting } from './const';
import { isUndefined } from 'lodash';
import { trackEvent } from '../../common/utils/errors';
import VideoDemoLibrary from './videoDemoLibrary.component';
import VideoFetchLibrary from './videoFetchLibrary.component';

export type VideoConfirmProps = {
  /** script to display */
  script?: string;
  /** callback of the video clip id when it's complete, boolean if should clean up the blob */
  onUpload: (videoClip: UpdateClipInput) => boolean | Promise<boolean>;
  /** whether recording is in progress */
  isRecording: boolean;
  /** sets isRecording state */
  setIsRecording: (recording: boolean) => void;
  /** whether to show trimming */
  showTrimming?: boolean;
  /** whether to show teleprompter */
  showTeleprompter?: boolean;
  /** actions to display */
  children?: ReactNode;
  /** clip uploading percent */
  uploadingPercent?: number;
};

/***
 * Component to confirm and upload the video blob url
 * @note will clean up blob url when component is unmounted
 */
export default function VideoConfirm({
  onUpload,
  script,
  showTrimming = false,
  showTeleprompter = false,
  isRecording,
  setIsRecording,
  uploadingPercent,
  children }: VideoConfirmProps) {
  const [video, setVideo] = useState<VideoDetails | VideoExisting | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const onSelectVideo = (newVideo: VideoExisting) => {
    setVideo(newVideo);
  };
  const onUploadVideo = (newVideo: VideoDetails) => {
    setVideo(newVideo);
  };

  const onVideoCancel = (e: Error | undefined) => {
    setError(e);
    if (isUndefined(video)) return;
    if (video.location) URL.revokeObjectURL(video.location);
    setVideo(undefined);
  };

  const onVideoConfirm = async (clip: UpdateClipInput) => {
    const cleanup = await onUpload(clip);
    if (cleanup && clip.location) URL.revokeObjectURL(clip.location);
    trackEvent('record-complete', {
      videoWidth: clip.videoWidth ?? undefined,
      videoHeight: clip.videoHeight ?? undefined,
      durationInSeconds: clip.durationInSeconds ?? undefined,
      source: clip.source ?? undefined,
    });
  };

  //console.log('[render] VideoConfirm');
  return (
    <>
      {video?.location && (
        <VideoProcess
          video={video}
          onCancel={onVideoCancel}
          onUpload={onVideoConfirm}
          confirmLabel={t`Confirm`}
          cancelLabel={t`Re-record`}
          showTrimming={showTrimming}
          uploadingPercent={uploadingPercent}
        />
      )}
      <Box
        sx={{
          height: '100%',
          width:'100%',
          display: video ? 'none' : 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: video ? '0' : '1',
          visibility: video ? 'hidden' : 'visible',
          position: video ? 'absolute' : 'static',
        }}
      >
        <VideoUploader
          error={error}
          onError={setError}
          script={script}
          showTeleprompter={showTeleprompter}
          onDone={onUploadVideo}
          isRecording={isRecording}
          setIsRecording={setIsRecording}>
          {account ? <VideoFetchLibrary onSelected={onSelectVideo} /> : <VideoDemoLibrary onSelected={onSelectVideo} />}
          {children}
        </VideoUploader>
      </Box>

    </>
  );
}
