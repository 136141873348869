import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Box, Button, CardContent, Chip, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, SvgIcon, Menu, useMediaQuery, useTheme } from '@mui/material';
import TableHeadSort from 'common/components/table/tableHeadSort.component';
import { MEDIUM_DATE_FORMAT } from 'common/constants/date';
import useCreateSortHandler from 'common/hooks/useCreateSortHandler';
import { orderIterateeHelper } from 'common/utils/table';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import { DisplayProject, SortableProjectField, SortableProjectFieldValues } from 'dashboard/type';
import { ILandingPageMetricsByProjectIdDetailsFragment, IVideoSummaryFragment } from 'data/_generated';
import { isUndefined, orderBy } from 'lodash';
import { MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDeleteProject from '../../common/hooks/useDeleteProject';
import DeleteProjectModal from './deleteProjectModal.component';
import ProjectMenuItem from '../../common/components/projectMenu.component';
import { ReactComponent as OptionsSvg } from 'common/images/icons/Utility/options_vertical.svg';
import { ReactComponent as TrashSvg } from 'common/images/icons/Utility/trash.svg';
import { ReactComponent as PencilSvg } from 'common/images/icons/Utility/pencil.svg';
import { DEFAULT_TITLE } from '../../edit/const';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';
import EditProjectModal from './editProjectModal.component';

type EmptyProjectsListProps = {
  /** the text to show in empty projects list */
  text: string;
  /** any children to show */
  children?: ReactNode;
};

/** Component to show empty content in a Card component */
export function EmptyProjectsList({ children, text }: EmptyProjectsListProps) {
  return <CardContent sx={{ textAlign: 'center', paddingY: SPACER.XXL }}>
    <Typography variant='h4' color={PALETTE_COLOR.neutral[300]}>{text}</Typography>
    {children}
  </CardContent>;
}

type ProjectMenuProps = {
  type: 'published' | 'draft';
  /** id of the project */
  projectId: string;
  /** name of the project */
  projectName: string;
};

export function DashboardProjectMenu({ projectId, projectName, type }: ProjectMenuProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDeleteProject, setIsDeleteProject] = useState<boolean>(false);
  const [isEditingProject, setIsEditingProject] = useState<boolean>(false);

  const { deleteVideoProject, loading: deleting } = useDeleteProject({
    projectId,
    refetchQueries: ['VideoProjects'],
  });
  const handleClickMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    if (type === 'draft') {
      void deleteVideoProject();
    } else {
      setIsDeleteProject(true);
      handleClose();
    }
  };

  const handleEdit = ()=> {
    if (type === 'draft') {
      navigate(`/edit/${projectId}`);
    } else {
      setIsEditingProject(true);
      handleClose();
    }
  };
  return (
      <>
        <Button
            size="small"
            startIcon={<SvgIcon component={OptionsSvg} inheritViewBox />}
            onClick={handleClickMenu}
            sx={{ minWidth: 0, p: 2 }}
        />
        <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <ProjectMenuItem
              onClick={handleEdit}
              icon={PencilSvg}
              label={t`Edit video`}
          />
          <ProjectMenuItem
              onClick={handleDelete}
              icon={TrashSvg}
              label={t`Delete`}
              loading={deleting}
          />
        </Menu>
        {isDeleteProject && (
            <DeleteProjectModal projectId={projectId} projectName={projectName} onClose={() => setIsDeleteProject(false)} />
        )}
        {isEditingProject && <EditProjectModal projectId={projectId} onClose={() => setIsEditingProject(false)} />}
      </>
  );
}


type PublishedProjectTableCellsProps = {
  data: IVideoSummaryFragment;
  analytics?: ILandingPageMetricsByProjectIdDetailsFragment;
  showMenu: boolean;
  isMobile: boolean;
};

/**
 * A list of table cells for published projects
 */
const PublishedProjectTableCells = ({ data, analytics, showMenu, isMobile }: PublishedProjectTableCellsProps) => {
  return (
      <>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`Video plays:`}
          </Typography>
          {analytics?.videoPlayCount ?? <Skeleton />}
        </TableCell>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`Average % watched:`}
          </Typography>
          {analytics?.watchPercent ?? <Skeleton />}
        </TableCell>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`CTA clicks:`}
          </Typography>
          {analytics?.ctaClickCount ?? <Skeleton />}
        </TableCell>
        {/* For desktop table view. Last column of the row */}
        {!isMobile && showMenu && (
            <TableCell
                onClick={(e) => {
                  e.stopPropagation();
                }}
                sx={{ textAlign: 'center' }}
            >
              <DashboardProjectMenu
                  type={isUndefined(data.publishStatus?.status) ? 'draft' : 'published'}
                  projectId={data.id}
                  projectName={data.title ?? DEFAULT_TITLE}
              />
            </TableCell>
        )}
      </>
  );
};

type DraftProjectTableCellsProps = {
  data: IVideoSummaryFragment;
  showMenu: boolean;
  isMobile: boolean;
};

/**
 * A list of table cells for draft projects
 */
function DraftProjectTableCells({ data, showMenu, isMobile }: DraftProjectTableCellsProps) {
  return (
      <>
        {!isMobile && showMenu && <>
          {/*Placeholder empty table cells for unpublished project with no analytics*/}
          <TableCell/>
          <TableCell />
          <TableCell />
          <TableCell
              onClick={(e) => {
                e.stopPropagation();
              }}
              sx={{ textAlign: 'center' }}
          >
            <DashboardProjectMenu
                type={isUndefined(data.publishStatus?.status) ? 'draft' : 'published'}
                projectId={data.id}
                projectName={data.title ?? DEFAULT_TITLE}
            />
          </TableCell>
        </>
        }
        {!showMenu && <TableCell colSpan={4} align="right" >
          <Button variant="contained" color="warning" href={`/edit/${data.id}`}>
            {t`Edit draft`}
          </Button>
        </TableCell>}
      </>
  );
}

type ProjectsListProps = {
  /** List of projects to show */
  data: DisplayProject[];
  /** name of the default field to sort by */
  defaultSort: SortableProjectFieldValues;
  /** whether to show actionable menu */
  showMenu?: boolean;
};

type ProjectRowProps = {
  /** The specific project to show */
  data: IVideoSummaryFragment;
  /** the detailed analytics for this project, undefined if not fetched yet */
  analytics?: ILandingPageMetricsByProjectIdDetailsFragment,
  /** whether to show actionable menu */
  showMenu?: boolean;
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  '& .MuiTableCell-root .cell-label': {
    color: PALETTE_COLOR.neutral[500],
    display: 'none',
  },
  '& img': {
    display: 'block',
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    borderRadius: 5,
    objectFit: 'contain',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    border: '1px solid',
    borderColor: PALETTE_COLOR.primary[50],
    borderRadius: 10,
    marginBottom: 8,
    padding: theme.spacing(SPACER.S),
    textAlign: 'left',
    '& .MuiTableCell-root': {
      border: 0,
      padding: 0,
      display: 'inline-block',
      width: '50%',
      '&:first-of-type': {
        width: '100%',
        paddingBottom: theme.spacing(SPACER.XS),
        display: 'flex',
      },
      '& .cell-label': {
        display: 'inline',
      },
    },
  },
}));

/** Display of a specific project */
function ProjectRow({ data, analytics, showMenu = false }: ProjectRowProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const { isEnabled: deleteProjectEnabled } = useFeatureFlag(BooleanFeatureFlagNames.deleteVideoProject);

  return (
    <StyledTableRow
      hover
      onClick={() => navigate(!isUndefined(data.publishStatus?.status) ? `/share/${data.id}` : `/edit/${data.id}`)}
    >
      <TableCell sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display={'flex'}>
          <Box mr={SPACER.S}>
            {data.thumbnail?.location ? (
              <img
                width={90}
                alt={`${data.title} thumbnail`}
                crossOrigin=""
                src={data.thumbnail.location}
                height={57}
              />
            ) : (
              <Skeleton height={57} width={90} variant="rounded" />
            )}
          </Box>
          <Box>
            {isUndefined(data.publishStatus?.status) ? (
              <Chip label={t`Draft`} variant="status" />
            ) : (
              <Chip label={t`Published`} variant="status" color="success" />
            )}
            {/* TODO: Update this to not use a fixed width. Difficult to make this responsive as it's in a table */}
            <Typography
              maxWidth={'200px'}
              component={'div'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              noWrap
              variant="bodySemibold"
            >
              {data.title}
            </Typography>
          </Box>
        </Box>
        {/*For mobile view. To be placed on the top title/thumbnail row*/}
        {isSmall && showMenu && deleteProjectEnabled &&
            <Box onClick={(e) => e.stopPropagation()}
            sx={{ position: 'absolute', right: 0 }}>
            <DashboardProjectMenu
                type={isUndefined(data.publishStatus?.status) ? 'draft' : 'published'}
              projectId={data.id}
              projectName={data.title ?? DEFAULT_TITLE}
            />
          </Box>
        }
      </TableCell>
      <TableCell>
        <Typography className="cell-label" variant="caption">
          {t`Last updated:`}{' '}
        </Typography>
        {i18n.date(data.lastUpdated, MEDIUM_DATE_FORMAT)}
      </TableCell>
      {!isUndefined(data.publishStatus?.status) ? (
       <PublishedProjectTableCells data={data} showMenu={showMenu && deleteProjectEnabled} isMobile={isSmall} analytics={analytics}/>
      ) : (
        <DraftProjectTableCells isMobile={isSmall} showMenu={deleteProjectEnabled} data={data}/>
      )}
    </StyledTableRow>
  );
}

/** Display of project */
export default function ProjectsList({ data, defaultSort, showMenu }: ProjectsListProps) {
  const { order, orderByField, createSortHandler } = useCreateSortHandler<SortableProjectFieldValues>(
    defaultSort,
  );
  const { isEnabled: deleteProjectEnabled } = useFeatureFlag(BooleanFeatureFlagNames.deleteVideoProject);

  return (
    <TableContainer sx={{ p: { xs: SPACER.S, md: 0 } }}>
      <Table sx={{ borderCollapse: 'separate' }}>
        <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
          <TableRow>
            <TableHeadSort
              id={SortableProjectField.title}
              label={t`Video details`}
              isOrdered={orderByField === SortableProjectField.title}
              orderDirection={order}
              onSort={createSortHandler(SortableProjectField.title)}
            />
            <TableHeadSort
              id={SortableProjectField.lastUpdated}
              label={t`Last updated`}
              isOrdered={orderByField === SortableProjectField.lastUpdated}
              orderDirection={order}
              onSort={createSortHandler(SortableProjectField.lastUpdated)}
            />
            <TableHeadSort
              id={SortableProjectField.videoPlayCount}
              label={t`Video plays`}
              isOrdered={orderByField === SortableProjectField.videoPlayCount}
              orderDirection={order}
              onSort={createSortHandler(SortableProjectField.videoPlayCount)}
            />
            <TableHeadSort
              id={SortableProjectField.watchPercent}
              label={t`Average % watched`}
              isOrdered={orderByField === SortableProjectField.watchPercent}
              orderDirection={order}
              onSort={createSortHandler(SortableProjectField.watchPercent)}
            />
            <TableHeadSort
              id={SortableProjectField.ctaClickCount}
              label={t`Call-to-action clicks`}
              isOrdered={orderByField === SortableProjectField.ctaClickCount}
              orderDirection={order}
              onSort={createSortHandler(SortableProjectField.ctaClickCount)}
            />
            {/*Placeholder header for menu for styling*/}
            {deleteProjectEnabled && showMenu && <TableCell id={'menu'} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderBy(
            data,
            [
              (project) =>
                orderIterateeHelper(
                  orderByField,
                  [SortableProjectField.title, SortableProjectField.lastUpdated],
                  [
                    SortableProjectField.ctaClickCount,
                    SortableProjectField.videoPlayCount,
                    SortableProjectField.watchPercent,
                  ],
                  project[orderByField],
                ),
            ],
            [order],
          ).map((project) => {
            const { videoPlayCount, watchPercent, ctaClickCount, ...projectData } = project;
            return (
              <ProjectRow
                data={{ ...projectData }}
                key={project.id}
                showMenu={showMenu}
                analytics={
                  isUndefined(videoPlayCount) || isUndefined(watchPercent) || isUndefined(ctaClickCount)
                    ? undefined
                    : { videoPlayCount, watchPercent, ctaClickCount, projectId: project.id }
                }
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
