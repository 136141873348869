export type StreamOps = {
  stream: MediaStream;
  destroy: () => void;
};

type StreamProps = {
  stream: MediaStream;
  screen?: MediaStream;
};


/**
* Videos can't have more that one video or audio track and if the screen stream has an
* audio track we need to use WebAudioAPI to join the 2 streams to the destination stream
* https://stackoverflow.com/questions/55165335/how-do-you-combine-many-audio-tracks-into-one-for-mediarecorder-api
* @returns {StreamOps}
*/
export default function createMediaStream({ stream, screen } : StreamProps): StreamOps {
  const audioContext = new AudioContext();
  const dest = audioContext.createMediaStreamDestination();

  // Video
  if (screen) {
    screen.getVideoTracks().forEach((track) => {
      dest.stream.addTrack(track);
    });
  } else {
    stream.getVideoTracks().forEach((track) => {
      dest.stream.addTrack(track);
    });
  }

  // Join the 2 audio streams
  if (stream.getAudioTracks().length > 0) {
    const audioIn1 = audioContext.createMediaStreamSource(stream);
    audioIn1.connect(dest);
  }
  if (screen && screen.getAudioTracks().length > 0) {
    const audioIn2 = audioContext.createMediaStreamSource(screen);
    audioIn2.connect(dest);
  }

  return {
    stream: dest.stream,
    destroy: () => { 
      // Close Audio Context
      void audioContext.close();
    },
  };
}