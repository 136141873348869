import { useTheme, Container, Toolbar, Button, AppBar as MuiAppBar, Box } from '@mui/material';
import { SPACER, TOOLBAR_HEIGHT } from 'config/models/themeOptions';
import Logo from './logo.component';
import SpacerBar from './spacerBar.component';
import { AppBarProps } from './const';

/** Base App Bar without version number at the top */
export default function AppBarBase({
  backHref = '/',
  backDisabled,
  backIcon,
  backText = <Logo />,
  backStyling,
  justifyContent,
  children,
}: AppBarProps) {
  const theme = useTheme();
  return <><MuiAppBar
    color="transparent"
    variant="outlined"
    sx={{
      backgroundColor: theme.palette.common.white,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    }}
    elevation={0}
  >
    <Container maxWidth={false} sx={{ paddingX: { xs: theme.spacing(SPACER.S) } }}>
      <Toolbar disableGutters sx={{ justifyContent: justifyContent ?? 'space-between',
        [theme.breakpoints.up('sm')]: {
          minHeight: TOOLBAR_HEIGHT,
        } }}>
        <Box sx={{ ...backStyling }}>
          <Button
            disabled={backDisabled}
            startIcon={backIcon}
            href={backHref}
            size="small"
            sx={{ ml: -1 }}
          >
            {backText}
          </Button>
        </Box>
        {children}
      </Toolbar>
    </Container>
  </MuiAppBar>
    <SpacerBar />
  </>;
}