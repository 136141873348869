import { useEffect, useState } from 'react';
import { extractError, logError } from '../utils/errors';

type PollingOptions = {
  url: string;
  intervalInMs: number;
  startPolling: boolean
};
/**
 * Hook for polling an endpoint until it is successful. Returns undefined until data is fetched
 */
export function usePollEndpoint<T>({ url, intervalInMs, startPolling }: PollingOptions): T | undefined {
  const [data, setData] = useState<T>();

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined = undefined;
    setData(undefined);
    if (startPolling) {
      const fetchData = async () => {
        try {
          const response = await fetch(url, { cache: 'no-cache' });
          if (response.ok) {
            const responseData = await response.json() as T;
            setData(responseData);
            if (intervalId) {
              clearInterval(intervalId);
            }
          }
        } catch (error) {
          logError(extractError(error));
        }
      };
      
      void fetchData();
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      intervalId = setInterval(fetchData, intervalInMs);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [url, intervalInMs, startPolling, setData]);

  return data;
}