import { t } from '@lingui/macro';
import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';
import { ReactComponent as HiSvg } from 'common/images/icons/Party/hi message.svg';
import { ReactComponent as RightSvg } from 'common/images/icons/Utility/right.svg';
import onboardImage from 'common/images/illustration/woman profile.png';
import * as Yup from 'yup';
import { SPACER } from 'config/models/themeOptions';
import SelectWithCustomOption from 'onboard/components/selectWithCustomOptions.component';
import { IUpdateUserOnboardingInfoInput } from 'data/_generated';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useScript } from 'usehooks-ts';
import AlertError from 'common/components/alert/alertError.component';
import { ToggleCards } from '../../common/components/toggleCard/toggleCards.component';

const GOALS = [
  t`Content Creation/YouTube`,
  t`Personal Videos (e.g. virtual greetings or thank you messages)`,
  t`Presentations, Webinars, or Training videos`,
  t`Video Prospecting`,
  t`Awareness or Political Campaigns`,
  t`Customer Support Videos`,
];

export const UserOnboardingInfoFieldNames = {
  goal: 'goal',
  industry: 'industry',
  hasVideoExperience: 'hasVideoExperience',
  goalDetails: 'goalDetails',
  jobTitle: 'jobTitle',
} as const;

const VALIDATION_SCHEMA = Yup.object().shape({
  goal: Yup.string().required().trim(),
  hasVideoExperience: Yup.bool().required(),
});

const INITIAL_VALUES = {
  [UserOnboardingInfoFieldNames.jobTitle]: '',
  [UserOnboardingInfoFieldNames.industry]: '',
  [UserOnboardingInfoFieldNames.goal]: '',
};

type OnboardingDetailsViewProps = {
  /** account name */
  accountName?: string;
  /** the call back to submit the form, expected to be valid */
  onSubmit: (values: IUpdateUserOnboardingInfoInput) => void;
  /** initial form values */
  initalValues?: IUpdateUserOnboardingInfoInput;
  /** loading state */
  loading?: boolean;
  /** error state */
  error?: Error;
};

/**
 * Type used for onboarding form input
 * goalDetails is custom data entered by the user giving detail on their selected goal.
 * If goalDetails is given, will replace goal (option provided by us)
 */
type OnboardingFormInputType = IUpdateUserOnboardingInfoInput & { goalDetails?: string };

/** First View in showing the Onboarding Details */
export default function OnboardingDetailsView({ accountName, onSubmit, initalValues, loading, error }: OnboardingDetailsViewProps) {
  // Unbounce script to track conversions
  useScript('/unbounce.js', { removeOnUnmount: true });
  const form = useForm<OnboardingFormInputType>({
    defaultValues: initalValues ?? INITIAL_VALUES,
    resolver: yupResolver(VALIDATION_SCHEMA),
    shouldFocusError: true,
  });

  const handleSubmit = () => {
    const values = form.getValues();
    onSubmit({
      ...values,
      goal: isEmpty(values.goalDetails) ? values.goal : values.goalDetails,
    });
  };
  return (
    <>
      <Grid container flexGrow={1} alignContent={'center'}>
        <Grid item xs={12} md={7} order={{ xs: 2, md: 0 }}>
          <Box p={SPACER.M}>
            <Typography variant="h1" sx={{ mb: 2 }}>
              <SvgIcon component={HiSvg} inheritViewBox fontSize="inherit" sx={{ mr: 2 }} />
              {t`Tell us more about you, ${accountName}`}
            </Typography>
            <Typography
              sx={{ fontSize: 18, display: 'block', mb: 9 }}
              variant="h6"
            >{t`We would like to get to know you more to help personalize your experience`}</Typography>
            <FormProvider {...form}>
              <SelectWithCustomOption
                label={t`What is your goal?`}
                textPlaceholder={t`Describe the activity you are using video for`}
                otherLabel={t`Tell us more`}
                optionsPlaceholder={t`Select a goal`}
                otherValue={form.getValues('goalDetails')}
                value={form.getValues('goal')}
                name="goal"
                options={GOALS}
                customFieldName={UserOnboardingInfoFieldNames.goalDetails}
                labelVariant={'bodyMedium'}
              />
              <Controller
                name={UserOnboardingInfoFieldNames.hasVideoExperience}
                control={form.control}
                render={({ field }) => (
                  <Box mt={SPACER.M}>
                    <Typography variant="bodyMedium">
                      {t`Have you used video communication for outreach before?`}
                    </Typography>
                    <ToggleCards
                      description={t`Have you used video communication for outreach before?`}
                      options={[t`Yes`, t`No`]}
                      selected={getVideoExperienceValue(form.getValues('hasVideoExperience'))}
                      onSelect={(value) => {
                        /** event.target.value is a string. Reverting back to boolean */
                        form.setValue(field.name, value === t`Yes`, { shouldValidate: true });
                      }}
                    ></ToggleCards>
                  </Box>
                )}
              />
              <AlertError error={error}>
                <LoadingButton
                  fullWidth
                  color={error ? 'error' : 'primary'}
                  disabled={!form.formState.isValid}
                  variant="contained"
                  onClick={() => handleSubmit()}
                  sx={{ marginTop: SPACER.L }}
                  loading={loading}
                >
                  <span>{t`Next`}</span>
                  <SvgIcon component={RightSvg} inheritViewBox />
                </LoadingButton>
              </AlertError>
            </FormProvider>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} textAlign={'center'} alignSelf={'center'}>
          <img
            style={{
              maxHeight: '40vh',
              maxWidth: '100%',
              margin: '0 auto',
            }}
            src={onboardImage}
            alt={t`Person in a profile page`}
          />
        </Grid>
      </Grid>
    </>
  );
}

/**
 * helper to get label for options from video experience form value
 * @param hasVideoExperience
 * @returns label string
 */
function getVideoExperienceValue(hasVideoExperience?: boolean) {
  if (isUndefined(hasVideoExperience)) return null;
  if (hasVideoExperience) {
    return t`Yes`;
  } else return t`No`;
}
