import { useUserInfoQuery } from '../../data/_generated';
import { ReactNode, useState } from 'react';
import AppBar from './layout/appBarConfig.component';
import PlanCancelledModal from './planCancelledModal.component';
import UserDisabled from './userButton/userDisabled.component';
import LoadingSkeleton from './loadingSkeleton.component';
import useUserAppAccess from 'common/hooks/useUserAppAccess';
import OnboardingPage from 'onboard/pages/onboarding.page';
import { isNil, isUndefined } from 'lodash';
import LoadingIndicator from './loadingIndicator.component';
import { t } from '@lingui/macro';
import PlanSelectionModal from './planSelectionModal.component';
import { useAuth } from '../../config/hooks/useAuth';
import useTrialLength from '../hooks/useTrialLength';

interface FetchUserProps {
  children: ReactNode;
}

/**
 * Fetch User and redirect before rendering the page
 * @returns {ReactNode}
 */
export default function FetchUser({ children }: FetchUserProps) {
  const { loading: loadingUserInfo, data: userInfoData } = useUserInfoQuery();
  const [isOnboarding, setIsOnboarding] = useState<boolean | undefined>();
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const {
    hasAppAccess,
    hasCancelledPaidPlan,
    paymentMethod,
    loading: loadingSubscriptionDetails,
  } = useUserAppAccess();
  const { trialDaysEnabled } = useTrialLength();

  if (loadingUserInfo || loadingSubscriptionDetails) {
    return (
      <>
        <AppBar>
          <UserDisabled />
        </AppBar>
          <LoadingIndicator title={t`Launching...`} variant={'determinate'} />
      </>
    );
  }

  if (isUndefined(isOnboarding)) {
    setIsOnboarding(isNil(userInfoData?.userInfo));
  }
  if (isNil(userInfoData?.userInfo) || isOnboarding) {
    return (
              <>
                  <AppBar>
                      <UserDisabled/>
                  </AppBar>
                  <OnboardingPage
                      hasAccess={hasAppAccess}
                      hasCancelledPlan={hasCancelledPaidPlan}
                      userInfo={userInfoData?.userInfo ?? undefined}
                      onComplete={()=> setIsOnboarding(false)}
                  />
              </>
    );
  }
  if (!hasAppAccess) {
    if (trialDaysEnabled) {
      if (hasCancelledPaidPlan) {
        return (
              <>
                  <AppBar>
                      <UserDisabled/>
                  </AppBar>
                  <PlanSelectionModal
                      title={t`Your plan has been cancelled`}
                      description={t`Your plan has been cancelled, but it's not the end of the world! 
                        Simply select your plan to keep creating and sharing your content.`}
                      paymentMethod={paymentMethod}
                      accountName={account?.name}/>
                  <LoadingSkeleton animated={false}/>
              </>
        );
      } else {
        return (
                <>
                    <AppBar>
                        <UserDisabled/>
                    </AppBar>
                    <PlanSelectionModal
                        title={t`Your free trial has ended`}
                        description={t`Your free trial has ended, but it's not the end of the world! 
                        Simply select your plan to keep creating and sharing your content.`}
                        paymentMethod={paymentMethod}
                        accountName={account?.name}/>
                    <LoadingSkeleton animated={false}/>
                </>
        );
      }
    } else {
      return (
              <>
                  <AppBar>
                      <UserDisabled/>
                  </AppBar>
                  <PlanCancelledModal
                      userInfo={userInfoData?.userInfo ?? undefined}/>
                  <LoadingSkeleton animated={false}/>
              </>
      );
    }
  }

  return <>{children}</>;
}