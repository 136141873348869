import { unstable_useBlocker as useBlocker, useBeforeUnload } from 'react-router-dom';
import { useEffect } from 'react';
import { t } from '@lingui/macro';

type UseNavigateWarningParams = {
  /** whether warning should be disabled. Eg: when a form is empty before navigating away, no need to show the warning prompt */
  disable?: boolean;
  /** warning message to show in React Router navigation warning prompt */
  message?: string;
};
/**
 * hook to show warning prompt before navigating away from current page
 */
export default function useNavigateWarning({
  disable = false,
  message = t`You have unsaved changes. Are you sure you want to leave?`,
}: UseNavigateWarningParams) {
  /**
   * useBeforeUnload is for window's beforeunload event
   * Triggered before the page refreshes or closes
   */
  useBeforeUnload((e: WindowEventHandlersEventMap['beforeunload']) => {
    if (!disable) {
      e.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      e.returnValue = true;
    }
  });
  const onLocationChange = () => {
    if (!disable) {
      /**
       * if answer is OK, return false to unblock and proceed navigation.
       * if answer is cancel, return true to block and prevent navigation.
       */
      return !window.confirm(message);
    }
    return false;
  };

  const blocker = useBlocker(onLocationChange);

  /**
   * React router blocker is for path navigation event.
   * Triggered before navigating away in the app
   */
  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  }, [blocker]);
}
