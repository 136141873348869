import { t } from '@lingui/macro';
import { Box, Typography, useTheme } from '@mui/material';
import Modal from '../../common/components/modal/modal.component';
import { ReactComponent as CancelSvg } from 'common/images/icons/Utility/remove_1.svg';
import useDeleteProject from '../../common/hooks/useDeleteProject';
import { SAVE_CLIPS_ARTICLE_URL } from '../../edit/const';

type DeleteProjectModalProps = {
  /** id of the project */
  projectId: string;
  /** name of the project to delete */
  projectName: string;
  /** callbaack on close */
  onClose: () => void;
  /** url to navigate after delete */
  navigateUrl?: string;
};

/**
 * Modal for delete project
 */
export default function DeleteProjectModal({ projectId, projectName, onClose, navigateUrl }: DeleteProjectModalProps) {
  const theme = useTheme();

  const { deleteVideoProject, loading, error } = useDeleteProject({
    projectId,
    navigateUrl,
    refetchQueries: ['VideoProjects'],
  });

  return (
      <Modal
          title={`Delete "${projectName}"?`}
          confirmLabel={t`Delete project`}
          onConfirm={deleteVideoProject}
          cancelLabel={t`Cancel`}
          onClose={onClose}
          isOpen
          icon={CancelSvg}
          isDanger
          iconStyle={{ color: theme.palette.error[300] }}
          maxWidth={'xs'}
          confirmLoading={loading}
          confirmError={error}
          disableClose
      >
        <Box pl={10}>
          <Typography variant='bodyReg' display={'block'}>{t`All previously shared videos for this project will no longer be accessible.`}</Typography>
          <Typography variant='bodyReg' display={'block'} pt={4}>{t`All clips created in this project will be deleted unless previously saved. Learn how to save your clips here.`}</Typography>
          {/*TODO: [VID-2029] Update help article for how to save clip */}
          <Typography variant='bodyReg' sx={{ textDecoration: 'underline' }} component={'a'} target='_blank' href={SAVE_CLIPS_ARTICLE_URL}>{t`Learn how to save your clips here.`}</Typography>
        </Box>
      </Modal>
  );
}
