import { trackEvent } from '../utils/errors';
import { useState } from 'react';
import useUserAppAccess from './useUserAppAccess';
import useGetUpgradePlanLocation from './useGetUpgradePlanLocation';

/** where ai upsell can be triggered from */
export type AIUpsellTrackingResource = 'video-effect' | 'ai-script-builder' | 'ai-script-prompt' | 'video-effect-local' | 'ai-script-builder-local' | 'ai-script-prompt-local';

/**
 * hook to manage ai feature upselling
 * @param source where ai upsell can be triggered from
 */
export default function useAiUpsell({ source  }: { source: AIUpsellTrackingResource }) {
  const { hasAIAccess } = useUserAppAccess();
  const getUpgradeUrl = useGetUpgradePlanLocation();
  const [isAiUpsellOpen, setIsAiUpsellOpen] = useState<boolean>(false);

  /**
   * function to determine the appropriate behavior for AI feature
   * @param succssCallback callback when ai can be applied
   * @param failedCallback callback when ai cannot be applied
   */
  const canApplyAI = (succssCallback: () => void, failedCallback?: () => void) => {
    if (hasAIAccess) {
      succssCallback();
    } else {
      setIsAiUpsellOpen(true);
      failedCallback?.();
      trackEvent('ai-upsell', { source: source });
    }
  };

  return {
    isAiUpsellOpen,
    canApplyAI,
    handleAIUpsellClose: () => setIsAiUpsellOpen(false),
    getUpgradeUrl,
  };
}