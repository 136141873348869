import { trackError } from 'common/utils/errors';
import { VideoDetails } from 'edit/components/const';
import { IVideoClipSourcesType } from 'edit/const';
import { StreamOps } from './createMediaStream';
import { MS_PER_SECOND } from '../../common/constants/date';
import { isUndefined } from 'lodash';

export type RecorderOps = {
  start: () => void;
  stop: () => boolean;
  destroy: () => void;
  source: IVideoClipSourcesType;
  startTime?: number;
};

type RecorderProps = {
  stream: StreamOps;
  startTime?: number;
  // number of ms to adjust the startTime. Will be used to subtract from startTime
  startAdjust?: number;
  source: IVideoClipSourcesType;
  onComplete: (video: VideoDetails) => void;
  onStart: () => void;
  onStop: () => void;
  maxDurationInSeconds: number;
};

/**
 * Helper function to create a MediaRecorder and provide a simple interface
 * to start, stop, and destroy the recorder.
 * @returns {RecorderOps}
 */
export default function createMediaRecorder({
  stream,
  startTime,
  startAdjust = 0,
  source,
  onStop,
  onStart,
  onComplete,
  maxDurationInSeconds,
}: RecorderProps): RecorderOps {
  const r = new MediaRecorder(stream.stream);
  const calcStartTime = isUndefined(startTime) ? undefined : startTime - startAdjust;
  let recordedDuration: number | undefined = undefined;
  const generateVideo = (ch: BlobPart[], props: BlobPropertyBag | undefined) => {
    const blob = new Blob(ch, props);
    const videoUrl = URL.createObjectURL(blob);
    const video = document.createElement('video');

    const checkVideoMetadata = () => {
      // duration is not available until metadata has played
      // through fully do to https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (!isFinite(video.duration)) return;
      onComplete({
        __typename: 'VideoDetails',
        location: videoUrl,
        durationInSeconds: video.duration, //use the more accurate video duration
        source,
      });
      // finally clean up the recorder
      video.onerror =
        video.onloadedmetadata = 
        video.ontimeupdate = null;
      video.remove();
    };
    video.onerror = console.error;
    video.onloadedmetadata = video.ontimeupdate = checkVideoMetadata;
    video.muted = true;
    video.playbackRate = 10;
    video.autoplay = true;
    video.loop = true;
    video.src = videoUrl;

    // Due to the inaccuracy of elapsed time we subtract 0.5 seconds
    // to ensure we don't go over the duration
    const shorterDuration = (recordedDuration ?? maxDurationInSeconds) - 0.5;
    // use the elapsed time to skip to the end of the video
    // to get the most accuracy on the duration
    video.currentTime = shorterDuration;
    try { void video.play(); } catch (e) {
      console.error('[createMediaRecorder] video.play()', e);
    }
  };
  r.onerror = (e) => {
    const errorEvent = e as MediaRecorderErrorEvent;
    const error = errorEvent.error ?? {};
    trackError('media-recorder-error', { ...error });
  };

  r.ondataavailable = (e) => {
    if (e.data.size <= 0) return;
    generateVideo([e.data], { type: e.data.type });
  };
  return {
    source,
    startTime: calcStartTime,
    start: () => {
      r.start();
      onStart();
    },
    stop: () => {
      if (r.state === 'inactive') return false;
      // when the tab is not in focus the elapsed time is not accurate
      recordedDuration = isUndefined(calcStartTime) ? undefined : (Date.now() - calcStartTime) / MS_PER_SECOND;
      r.stop();
      onStop();
      return true;
    },
    destroy: () => {
      r.onerror = r.ondataavailable = null;
      if (r.state !== 'inactive') r.stop();
      stream.destroy();
    },
  };
}
