import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useEffectOnce, useEventListener, useLocalStorage } from 'usehooks-ts';
import { isError, isUndefined } from 'lodash';
import VideoWebCamGrid from 'edit/components/videoWebCamGrid.component';
import { RecorderControlsState, VideoDetails } from './const';
import { LOCAL_STORAGE_KEYS } from 'edit/const';
import { trackEvent } from 'common/utils/errors';
import { IVideoClipSources } from 'data/_generated';
import useSound from 'use-sound';
import createMediaRecorder, { RecorderOps } from 'edit/util/createMediaRecorder';
import createMediaStream from 'edit/util/createMediaStream';
import { COUNT_DOWN_MS, VideoRecordButton, VideoStopButton } from './videoRecordingButtons.component';
import VideoRecordingProgress from './videoRecordingProgress.component';
import useUserAppAccess from '../../common/hooks/useUserAppAccess';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';

/**
 *  MediaRecorderErrorEvent interface is not included in the standard TypeScript library, 
 * but it is part of the DOM API. Therefore, you can access it by defining a global 
 * type declaration for MediaRecorderErrorEvent
 * https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/error_event#event_properties
 */
declare global {
  interface MediaRecorderErrorEvent extends Event {
    readonly error: DOMException | null;
  }
}


export type VideoRecorderControlsProps = {
  /** Video Stream */
  stream: MediaStream;
  /** Callback when recording has started */
  onStart: () => void;
  /** Callback when recording was cancelled */
  onCanceled: () => void;
  /** Callback when blob is ready */
  onStop: (video: VideoDetails) => void;
  /** callback when the track has ended */
  onTrackEnd: () => void;
  /** whether the screen is loading */
  screenLoading: boolean | Error;
  /** callback to set if the screen is loading */
  setScreenLoading: (b: boolean | Error) => void;
  /** Callback to flip the video */
  flipVideo: () => void;
  /** children to show in loading state */
  children: ReactNode;
};

/**
 * Inner Component to contain controls, toolbars and overlays
 * @returns {JSX.Element}
 */
export default function VideoRecorderControls({
  children,
  onTrackEnd,
  onStart,
  onStop,
  stream,
  screenLoading,
  setScreenLoading,
  flipVideo,
}: VideoRecorderControlsProps) {

  const { isEnabled: screenShareAudioEnabled } = useFeatureFlag(BooleanFeatureFlagNames.screenShareAudio);
  //console.log('[render] VideoRecorderControls');
  const [state, setState] = useState<RecorderControlsState>('setup'); //re-render on state changes
  const [showGrid, setShowGrid] = useLocalStorage<boolean>(LOCAL_STORAGE_KEYS.Grid, true);
  const [playBoop] = useSound('/boop.mp3', { volume: 0.25, interrupt: true });
  // Keyboard shortcut
  useEventListener('keydown', ({ key, code, target }) => {
    // EventTarget does not inherit as HTMLElement by default. Type asserting to access element properties
    const eventTarget = target as HTMLElement;
    /** if target is textarea, disable keyboard shortcuts */
    if (eventTarget.tagName === 'TEXTAREA' || eventTarget.tagName === 'INPUT') return;
    if (key === 'd' || code === 'KeyD') {
      // D
      if (state === 'setup') {
        delayedStart(IVideoClipSources.Recorded);
      } else if (state === 'recording') {
        recorder?.stop();
      }
    } else if (key.toLowerCase() === 'g' || code === 'KeyG') {
      // G
      setShowGrid(!showGrid);
    } else if (key.toLowerCase() === 'f' || code === 'KeyF') {
      // F
      flipVideo();
    }
  });

  /** Screen Recording Option */
  const screen = useRef<MediaStream | undefined>();

  const videoTracks = stream.getVideoTracks();
  if (videoTracks.length > 0 &&
    videoTracks[0].readyState === 'live') {
    videoTracks[0].onended = () => {
      onTrackEnd();
    };
  }

  const [recorder, setRecorder] = useState<RecorderOps | undefined>();

  const { maxClipDurationSeconds } = useUserAppAccess();

  // Start the recorder, only called from the countdown
  const start = () => {
    if (!stream.getTracks().length) return;
    if (!recorder) return;
    trackEvent('record', { source: recorder.source });
    recorder.start();
  };

  // Destroy
  useEffectOnce(() => {
    return () => {
      destroyRecorder();
      destroyScreen();
    };
  });

  // Start the recorder, only called from completion of getting screenshare
  const nowStart = (source: IVideoClipSources) => {
    if (!stream.getTracks().length) return;
    const newRecorder = createMediaRecorder({
      startTime: Date.now(),
      startAdjust: COUNT_DOWN_MS,
      stream: createMediaStream({ stream, screen: screen.current }),
      onComplete: (v) => {
        onStop(v);
        destroyRecorder();
        setState('setup');
      },
      onStart: () => setState('recording'),
      onStop: () => setState('inactive'),
      source,
      maxDurationInSeconds: maxClipDurationSeconds,
    });
    onStart();
    setRecorder(newRecorder);
    trackEvent('record', { source });
    newRecorder.start();
    if (!screen.current) return;
    // If the screen is closed, stop the recorder
    screen.current.getVideoTracks().forEach((track) => {
      track.onended = () => newRecorder.stop();
    });
  };

  // Starts the countdown and then starts the recorder
  const delayedStart = (source: IVideoClipSources) => {
    setRecorder((currentRecorder) => {
      if (!isUndefined(currentRecorder)) return currentRecorder;
      onStart();
      const newRecorder = createMediaRecorder({
        startTime: Date.now(),
        stream: createMediaStream({ stream, screen: screen.current }),
        onComplete: (v) => {
          onStop(v);
          destroyRecorder();
          setState('setup');
        },
        onStart: () => setState('recording'),
        onStop: () => setState('inactive'),
        source,
        maxDurationInSeconds: maxClipDurationSeconds,
      });
      return newRecorder;
    });
  };

  const destroyRecorder = () => {
    // Clear the current recorder
    setRecorder((val)=> {
      if (!val) return undefined;
      val.destroy();
      return undefined;
    });
  };

  const destroyScreen = () => {
    if (!screen.current) return;
    // Stop Screen Share
    screen.current.getTracks().forEach((track) => {
      track.onended = null;
      track.stop();
    }); //stop any screen recording
    screen.current = undefined;
  };

  const setupScreen = async () => {
    if (screenLoading !== true) return;
    console.log('[screenLoading] VideoRecordingControls');
    screen.current = undefined;
    try {
      const newScreen = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: screenShareAudioEnabled });
      console.log('[getUserMedia][success] ');
      screen.current = newScreen;
      nowStart(IVideoClipSources.ScreenShared);
      setScreenLoading(false);
    } catch (err) {
      console.error('[getUserMedia][error]', err);
      if (isError(err)) {
        setScreenLoading(err);
      }
    }
  };

  useEffect(() => { void setupScreen(); }, [screenLoading]);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <>
      {showGrid && <VideoWebCamGrid />}
      {state === 'setup' && children}
      <VideoRecordingProgress
        isMobile={isXS}
        maxRecordingTime={maxClipDurationSeconds}
        onCompleted={() => recorder?.stop()}
        state={state}
        startTime={recorder?.startTime}>
        {state === 'setup' ?
          <VideoRecordButton
            isMobile={isXS}
            onStart={() => {
              delayedStart(IVideoClipSources.Recorded);
            }}
            onUpdate={() => playBoop()}
            onComplete={start}
            startTime={recorder?.startTime}
          /> :
          <VideoStopButton
            isMobile={isXS}
            disabled={state === 'inactive'}
            onClick={() => recorder?.stop()} />
        }
      </VideoRecordingProgress>

    </>
  );
}