import { Skeleton, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import { useConfig } from '../../config/hooks/useConfig';
import { throttle, isUndefined } from 'lodash';
import { ReactNode, useRef } from 'react';
import useUsageTracking from '../tracking/useUsageTracking';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Preview, { LandingPageData } from 'share/components/preview.component';
import Alert from 'common/components/alert/alert.component';
import { useFetchTargetLandingPageDetails } from '../hooks/useFetchTargetLandingPageDetails';
import { logError, trackEvent } from 'common/utils/errors';
import { PALETTE_COLOR } from 'config/models/themeOptions';

const SKELETON_VIDEO_WIDTH = 640;

type VideoSkeletonProps = {
  /** is this in error state */
  isError?: boolean;
  /** what's the inner error message to show? */
  children?: ReactNode;
};

/** 
 * Presentational component 
 * To show Video Skeleton with ability to show error message 
 **/
function VideoSkeleton({
  isError = false,
  children }: VideoSkeletonProps) {
  return <>
    <Typography sx={{ py: 3 }} variant="h1">
      <Skeleton
        sx={isError ? { background: PALETTE_COLOR.error[50] } : undefined}
        animation={!isError ? 'wave' : false}
        variant="text"></Skeleton>
    </Typography>
    <Skeleton
      variant="rectangular"
      animation={!isError ? 'wave' : false}
      sx={{
        maxWidth: '100vw',
        aspectRatio: '4/3',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '>*': {
          visibility: 'visible',
        },
        ...(isError && {
          background: PALETTE_COLOR.error[50],
        }),
      }}
      width={SKELETON_VIDEO_WIDTH}
    >
      {children}
    </Skeleton>
  </>;
}

type VideoTrackingProps = {
  targetId: string;
  data: LandingPageData;
  baseUrl: string;
};

/**
 * Display and Tracking for the video
 *
 * @param targetId
 * @param data
 * @param baseUrl
 * @constructor
 */
function VideoTracking({ targetId, data, baseUrl }: VideoTrackingProps) {
  const { landingPageDetails } = data;
  // set up usage tracking
  const videoCurrentTime = useRef(0);
  const videoTotalTime = useRef(0);
  const { logEvent } = useUsageTracking(targetId, {
    projectId: landingPageDetails.projectId,
    ownerId: landingPageDetails.ownerId,
    baseUrl,
  });

  // events
  const onTick = throttle((ts: number, duration: number) =>
    logEvent('videoTick', { currentTime: ts, totalTime: duration }), 1000);
  const onCtaClick = (ctaText: string) =>
    logEvent('ctaClick', {
      videoTimestamp: videoCurrentTime.current,
      videoTotalTime: videoTotalTime.current,
      ctaText,
    });

  const videoTitle = landingPageDetails.title ?? t`👋 Hi there ${landingPageDetails.targetName}`;

  return (
        <HelmetProvider>
            <Helmet>
                <title>{videoTitle}</title>
                <meta property="og:title" content={videoTitle} />
                <meta property="og:image" content={landingPageDetails.thumbnailLocation} />
                <meta property="og:type" content="video.other" />
                <meta property="og:video:type" content="text/html" />
            </Helmet>
            <Typography sx={{ pt: 3, pb: 5, width: SKELETON_VIDEO_WIDTH }} variant="h1">{videoTitle}</Typography>
            <Preview
                width={SKELETON_VIDEO_WIDTH}
                data={data}
                onPlay={() => logEvent('videoPlay')}
                onPause={() => logEvent('videoPause')}
                onTimeUpdate={({ currentTarget: { currentTime, duration } }) => {
                  onTick(currentTime, duration);
                  videoCurrentTime.current = currentTime;
                  videoTotalTime.current = duration;
                }}
                onCtaClick={onCtaClick}
            />
        </HelmetProvider>
  );
}

/**
 * Page for viewing the resulting videos
 * @returns
 */
export default function LandingPage() {
  const { id } = useParams();
  if (isUndefined(id)) throw new Error('Could not find the target ID in the URL, maybe this was a bad link?');

  // fetch page data
  const { endpoint } = useConfig();
  const { landingPageDetails: { data, loading, error }, videoDetails } = useFetchTargetLandingPageDetails(id);

  if (loading || isUndefined(data))
    return <VideoSkeleton />;

  if (error) {
    logError(error);
    trackEvent('video-view-error', { targetId: id });
    return <VideoSkeleton isError={true}>
      <Alert severity="error"
        title={t`Whoops! Missing Link.`} 
        description={t`We could not find your video`} 
        actionLabel={t`Try Again`}
        onAction={()=> window.location.reload()}
        />
    </VideoSkeleton>;
  }

  return <VideoTracking
      targetId={id}
      data={{ landingPageDetails: data, videoDetails }}
      baseUrl={endpoint ?? ''} />;
}
