import * as React from 'react';
import {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import { ReactComponent as ClipsSvg } from 'common/images/icons/Utility/images.svg';
import {
  Box,
  InputBase,
  SvgIcon,
  ClickAwayListener,
  Popper,
  styled,
  Autocomplete,
  Badge,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { toTimestampString } from 'common/utils/string';
import { orderBy, compact, isError } from 'lodash';
import { LoadingButton } from '@mui/lab';
import AlertError from 'common/components/alert/alertError.component';
import { t } from '@lingui/macro';
import LogRocket from 'logrocket';
import { DEFAULT_ASPECT_RATIO } from 'common/constants/video';
import { VideoExisting } from './const';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: '1px solid',
      borderColor: theme.palette.neutral[200],
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
      {
        backgroundColor: theme.palette.neutral[100],
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const StyledThumbnail = styled('img')(({ theme }) => ({
  height: '100%',
  marginLeft: 5,
  maxWidth: '100px',
  maxHeight: '3.3em',
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.neutral[0],
  background: theme.palette.neutral[300],
  '&.img-error': {
    aspectRatio: DEFAULT_ASPECT_RATIO,
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.neutral[200],
  borderRadius: 10,
  zIndex: theme.zIndex.modal,
  width: 300,
  backgroundColor: theme.palette.neutral[0],
}));


type VideoLibraryProps = {
  /** callback when a clip is selected */
  onSelected: (c: VideoExisting) => void | Promise<void>;
  /** disabled video library while recording */
  disabled?: boolean;
  options?: VideoExisting[],
  loading: boolean;
  error?: Error;
};

/**
 * Component to show list from the clip library
 */
export default function VideoLibrary({ onSelected, disabled = false, options, error, loading }: VideoLibraryProps) {
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const [selectError, setSelectError] = useState<Error | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleSelect = async (newValue: VideoExisting) => {
    setSelectLoading(true);
    handleClose();
    try {
      await onSelected(newValue);
    } catch (e) {
      if (isError(e)) setSelectError(e);
      console.error(e);
      LogRocket.error(e);
    }
    setSelectLoading(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'library-label' : undefined;
  const ordered = orderBy(options, 'dateCreated', 'desc');
  const count = compact(options).length;

  const clipList = (<div>
    <Autocomplete
      open
      onClose={(
        _event,
        reason: AutocompleteCloseReason,
      ) => {
        if (reason === 'escape') {
          handleClose();
        }
      }}
      onChange={(event, newValue, reason) => {
        if (
          event.type === 'keydown' &&
          (event as React.KeyboardEvent).key === 'Backspace' &&
          reason === 'removeOption'
        ) {
          return;
        }
        if (newValue) void handleSelect(newValue);
      }}
      PopperComponent={StyledAutocompletePopper}
      renderTags={() => null}
      noOptionsText="No Saved Clips"
      renderOption={(props, option) => (
        (option.title && option.thumbnail && option.durationInSeconds) ?
          <li {...props}>
            <Box flex={'1 1 0'}>
              <Typography
                component='h5'
                variant='bodyMedium'
                textOverflow='ellipsis'>
                {option.title}
              </Typography>
              <Typography
                variant='caption'
                color={(theme) => theme.palette.neutral[300]}>
                {toTimestampString(option.durationInSeconds)}
              </Typography>
            </Box>
            <StyledThumbnail crossOrigin='' onError={(e) => {
              LogRocket.error('thumbnail load fail', e);
              e.currentTarget.className += ' img-error';
            }} src={option.thumbnail} title={option.title} />
          </li> : undefined
      )
      }
      options={ordered}
      getOptionLabel={(option) => `${option.title} ${option.id}`}
      renderInput={(params) => (
        <InputBase
          sx={{ width: '100%' }}
          fullWidth
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus
          placeholder={t`Filter by Title`}
        />
      )}
    />
  </div >);

  return <>
    <Badge color='primary' badgeContent={loading ? undefined : count}>
      <AlertError fullWidth error={selectError ?? error} onClose={() => setSelectError(undefined)}>
        <LoadingButton
          loading={loading || selectLoading}
          disabled={loading || disabled || count === 0}
          aria-describedby={id}
          onClick={handleClick}
          color={error ? 'error' : 'primary'}
          variant='outlined'
          sx={{ width: { xs: '100%', sm: 'auto' } }}
          startIcon={<SvgIcon inheritViewBox component={ClipsSvg} />}
        >
          <span>{t`Clip Library`}</span>
        </LoadingButton>
      </AlertError>
    </Badge >
    <StyledPopper
      id={id} open={open} anchorEl={anchorEl} placement="bottom-start"
      onReset={undefined} onResetCapture={undefined} >
      <ClickAwayListener onClickAway={handleClose}>
        {clipList}
      </ClickAwayListener>
    </StyledPopper>
  </>;
}