import AppConfig from './appConfig.component';
import Auth from './auth.component';
import Apollo from './apollo.component';
import Theme from './theme.component';
import Localization from './localization.component';
import Router from './router.component';
import 'common/style/animation.css';
import 'common/style/font.css';
import 'common/style/utility.css';
import LogRocketProvider from './logRocket.component';
import FeatureFlagsProvider from './featureFlags.component';
import Azure from './azure.component';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <AppConfig>
      <Auth>
        <Azure>
          <LogRocketProvider>
            <FeatureFlagsProvider>
              <Apollo>
                <Theme>
                  <Localization>
                    <DndProvider backend={HTML5Backend}>
                      <Router />
                    </DndProvider>
                  </Localization>
                </Theme>
              </Apollo>
            </FeatureFlagsProvider>
          </LogRocketProvider>
        </Azure>
      </Auth>
    </AppConfig>
  );
}

export default App;
