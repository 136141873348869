import { Flag, RoxNumber, RoxString } from 'rox-browser';

/**
 * Boolean flag names
 */
export const BooleanFeatureFlagNames = {
  /** Enables shortcut for feature flag overrides */
  dev: 'dev',
  /** Enables toggling a transition effect on a video project */
  videoTransitionEffects: 'videoTransitionEffects',
  /** Enables monetization */
  monetization: 'monetization',
  /** Enables deletion of video project */
  deleteVideoProject: 'deleteVideoProject',
  /** Enables video project duplication */
  duplicateVideoProject: 'duplicateVideoProject',
  /** Enables feature related to AI */
  aiFeatures: 'aiFeatures',
  /** Enables flexible recording */
  flexibleRecording: 'flexibleRecording',
  /** Enables the onboarding checklist */
  onboardingChecklist: 'onboardingChecklist',
  /** Enables recording tab audio when doing screen share */
  screenShareAudio: 'screenShareAudio',
  /** Toggles behaviour wanted for ngc */
  ngcConference: 'ngcConference',
  // Add boolean feature flags here
} as const;

/**
 * String flag names
 */
export const StringFeatureFlagNames = {
  /** The URL to the onboarding calendar */
  onboardingCalendar: 'onboardingCalendar',
  /** the quality allowed for a video recording */
  videoRecordQuality: 'videoRecordQuality',
  /** the app cues key */
  appCuesKey: 'appCuesKey',
  /** the user pilot key */
  userPilotKey: 'userPilotKey',
  /** the  key */
  mixPanelKey: 'mixPanelKey',
  /** the june so key */
  juneSoKey: 'juneSoKey',
  // Add string feature flags here
} as const;

/**
 * Number flag names
 */
export const NumberFeatureFlagNames = {
  /** the number of second allowed for a video recording */
  videoRecordLength: 'videoRecordLength',
  /** the number of personalized intro allowed for a video project */
  personalizedIntroMax: 'personalizedIntroMax',
  /** the number trial days allowed for a user */
  trialDays: 'trialDays',
  // Add number feature flags here
} as const;

/**
 * Type of all names of boolean flags
 */
export type BooleanFeatureFlagNamesType = (typeof BooleanFeatureFlagNames)[keyof typeof BooleanFeatureFlagNames];

/**
 * Type of all names of string flags
 */
export type StringFeatureFlagNamesType = (typeof StringFeatureFlagNames)[keyof typeof StringFeatureFlagNames];

/**
 * Type of all names of number flags
 */
export type NumberFeatureFlagNamesType = (typeof NumberFeatureFlagNames)[keyof typeof NumberFeatureFlagNames];

/**
 * Type for all feature flags
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 */
export type FeatureFlagsListType = {
  [k in BooleanFeatureFlagNamesType]: Flag;
} & {
  [k in StringFeatureFlagNamesType]: RoxString;
} & {
  [k in NumberFeatureFlagNamesType]: RoxNumber;
};

/**
 * Type for feature flags with isReady
 */
export interface FeatureFlagsType extends FeatureFlagsListType {
  /**
   * Whether feature flags were initialized from server
   */
  isReady: boolean;
}
