import { Popper, ClickAwayListener, Paper, PopperProps } from '@mui/material';
import { isError } from 'lodash';
import Alert from './alert.component';

export type AlertPopperProps = {
  /** if the alert should be shown */
  open?: boolean;
  /** the error, if none specified no error popper is show */
  error?: Error;
  /** action button label */
  actionLabel?: string;
  /** href to specify the url link on onAction*/
  actionHref?: string;
  /** callback to clear error state
   * @default noop no operation
   */
  onClose?: () => void;
};


export default function AlertPopper({
  open = true,
  error,
  actionHref,
  actionLabel, 
  onClose = () => { }, 
  anchorEl }: AlertPopperProps & Pick<PopperProps, 'anchorEl'>) {
  if (!isError(error)) return null;
  return <Popper
    sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
    open={open}
    disablePortal={false}
    anchorEl={anchorEl}
    placement="top"
    onReset={undefined}
    onResetCapture={undefined}>
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <Paper elevation={3} sx={{ borderRadius: 10 }}>
        <Alert
          severity='error'
          description={error.message}
          actionHref={actionHref}
          actionLabel={actionLabel}
        />
      </Paper>
    </ClickAwayListener>
  </Popper>;
}