import { isUndefined } from 'lodash';
import { useEffect, useRef, useCallback } from 'react';

// Utility helper for random number generation
const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

/**
 * Hook for calling a function at random intervals
 * @link https://www.joshwcomeau.com/snippets/react-hooks/use-random-interval/
 * @param callback 
 * @param minDelay 
 * @param maxDelay 
 * @returns 
 */
export default function useRandomInterval(callback: () => void, minDelay?: number, maxDelay?: number) {
  const timeoutId = useRef<NodeJS.Timeout>();
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const isEnabled = typeof minDelay === 'number' && typeof maxDelay === 'number';
    if (isEnabled) {
      const handleTick = () => {
        const nextTickAt = random(minDelay, maxDelay);
        timeoutId.current = setTimeout(() => {
          savedCallback.current();
          handleTick();
        }, nextTickAt);
      };
      handleTick();
    }
    return () => {
      if (isUndefined(timeoutId.current)) return;
      clearTimeout(timeoutId.current);
    };
  }, [minDelay, maxDelay]);
  const cancel = useCallback(function () {
    if (isUndefined(timeoutId.current)) return;
    clearTimeout(timeoutId.current);
  }, []);
  return cancel;
}
