import { MouseEvent, useState } from 'react';

export type DataOrder = 'asc' | 'desc';

/**
 * hook for creating sort handler
 * @param defaultField default field name to sort an array by
 * @param defaultOrder default order to sort an array by
 * @returns {order: order to sort an array by, orderBy: property to order an array by, createSortHandler: function to handle sorting }
 */
export default function useCreateSortHandler<T>(defaultField: T, defaultOrder: DataOrder = 'desc') {
  const [order, setOrder] = useState<DataOrder>(defaultOrder);
  const [orderByField, setOrderByField] = useState<T>(defaultField);

  const createSortHandler = (fieldName: T) => (_event: MouseEvent<unknown>) => {
    const isDesc = orderByField === fieldName && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderByField(fieldName);
  };

  return { order, orderByField, createSortHandler };
}
