import { t } from '@lingui/macro';
import { DisplayProject, SortableProjectFieldValues } from 'dashboard/type';
import {
  IVideoSummaryFragment,
  useLandingPageMetricsByProjectIdQuery,
  ILandingPageMetricsByProjectIdDetailsFragment,
} from 'data/_generated';
import { keyBy, isNil, isEmpty, Dictionary } from 'lodash';
import ProjectsList, { EmptyProjectsList } from './projectsList.component';
import { SPACER } from 'config/models/themeOptions';
import CreateVideoButton from './createVideoButton.component';

export type ProjectFilterOptions = 'All' | 'Published' | 'Draft';

type ProjectFilterListProps = {
  /** video project data */
  data: IVideoSummaryFragment[];
  /** filter to apply to projects */
  filter: ProjectFilterOptions;
  /** message to show when there's empty list */
  emptyMessage?: string;
  /** name of the default field to sort by */
  defaultSort: SortableProjectFieldValues;
  /** whether to show actionable menu */
  showMenu?: boolean;
};

/**
 * Component to filter projects list
 */
export default function ProjectsFilterList({ data, filter, emptyMessage = t`You have no ${filter} videos yet.`, defaultSort, showMenu }: ProjectFilterListProps) {
  const { data: analytics } = useLandingPageMetricsByProjectIdQuery({
    variables: { projectIds: data.map((c) => c.id) },
  });
  const analyticsData = keyBy<ILandingPageMetricsByProjectIdDetailsFragment | undefined>(
    analytics?.landingPageMetricsByProjectId ?? [],
    (metrics) => metrics?.projectId ?? '',
  );
  const filtered = data.filter((d) => {
    if (filter === 'All') return true;
    const wasPublished = !isNil(d.publishStatus?.status);
    return (filter === 'Published' && wasPublished) || (filter === 'Draft' && !wasPublished);
  });
  if (isEmpty(filtered)) return <EmptyProjectsList text={emptyMessage}>
    <CreateVideoButton
      buttonSx={{ marginTop: SPACER.S }}
      buttonColor="secondary"
      trackEventName='emptylist-create'
    />
  </EmptyProjectsList>;

  const displayProjects = getDisplayProjects(filtered, analyticsData);
  return <ProjectsList data={displayProjects} defaultSort={defaultSort} showMenu={showMenu}/>;
}

/**
 * Helper to process projects for table
 * @param projects list of projects
 * @param analytics project metrics by projectId
 */
function getDisplayProjects(
  projects: IVideoSummaryFragment[],
  analytics?: Dictionary<ILandingPageMetricsByProjectIdDetailsFragment | undefined>,
): DisplayProject[] {
  return projects.map((project) => {
    const projectAnalytics = analytics?.[project.id];
    // if project hasn't been published, set values to -1 or empty string for sorting when table is set to display all projects
    if (isNil(project.publishStatus?.status)) {
      return {
        ...project,
        dateCreated: '',
        videoPlayCount: -1,
        watchPercent: -1,
        ctaClickCount: -1,
      };
    }
    return {
      ...project,
      videoPlayCount: projectAnalytics?.videoPlayCount,
      watchPercent: projectAnalytics?.watchPercent,
      ctaClickCount: projectAnalytics?.ctaClickCount,
    };
  });
}
