import { Box, Skeleton } from '@mui/material';
import { DRAWER_WIDTH } from 'common/const';
import { SPACER } from 'config/models/themeOptions';


/**
 * Loading skeleton of the application
 * @returns {JSX.Element}
 */
export default function LoadingSkeleton({ animated }: { animated: boolean }) {
  const animation = animated ? 'wave' : false;
  return <Box
    flexGrow={1}
    flexDirection='row'
    display='flex'>
    <Box
      p={SPACER.M}
      sx={{
        mx: 1,
        display: {
          xs: 'none',
          sm: 'flex',
        },
        flexDirection: 'column',
        minHeight: '100%',
        background: 'white',
        width: DRAWER_WIDTH,
      }}
    >

      <Skeleton variant='rounded' width={'100%'} height={40} animation={animation}
        sx={{ mb: SPACER.M }} />
      <Box display={'flex'} p={SPACER.XS}>
        <Skeleton variant='circular' width={24} height={24} animation={animation} sx={{ mr: SPACER.XS }} />
        <Skeleton variant='text' width={150} animation={animation} />
      </Box>
      <Box display={'flex'} p={SPACER.XS}>
        <Skeleton variant='circular' width={24} height={24} animation={animation} sx={{ mr: SPACER.XS }} />
        <Skeleton variant='text' width={100} animation={animation} />
      </Box>
      <Box flexGrow={1} display={'flex'} alignItems={'flex-end'}>
        <Skeleton variant='rounded' width={'100%'} height={140} animation={animation} />
      </Box>

    </Box>
    <Box p={SPACER.S} flexGrow={1}>
      <Skeleton variant='rounded' width={'100%'} height={70} animation={animation} />
      <Skeleton variant='text' width={150} height={30} animation={animation} sx={{ my: SPACER.XS }} />
      <Skeleton variant='rounded' width={'100%'} height={200} animation={animation} />
    </Box>
  </Box>;
}