import { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';

const NoContentStatusCode = 204;

function getPath(route: string, baseUrl?: string): string {
  if (isUndefined(baseUrl)) return route;
  const base = new URL(baseUrl);
  base.pathname = `${base.pathname}${route}`;
  return base.toString();
}

/**
 * This hook is to call REST API endpoints
 * @param {string} route routes to hit
 * @param {string | undefined} baseUrl base url for cross origin requests 
 * @example
 * ```
 * const { data, loading, error } = useRest<{ clientId: string }>('/config');
 * ```
 */
function useRest<DataType>(route: string, baseUrl?: string | undefined) {
  const [data, setData] = useState<DataType | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const url = getPath(route, baseUrl);
    fetch(url, {
      cache: 'no-cache',
    })
      .then(
        async response => {
          if (response.status !== NoContentStatusCode) setData((await response.json()) as DataType);
        },
      )
      .catch(setError)
      .finally(
        () => setLoading(false),
      );
  }, [route, baseUrl]);

  return { data, loading, error };
}

export default useRest;
