import { Box, Button, CircularProgress, Fab, SvgIcon, Tooltip } from '@mui/material';
import { SyntheticEvent, useRef, useState } from 'react';
import { ReactComponent as PlaySvg } from 'common/images/icons/Utility/play.svg';
import { notEmpty } from 'common/utils/array';
import LogRocket from 'logrocket';
import { PublishedLandingPageDetails, PublishedVideoDetails } from '../hooks/useFetchTargetLandingPageDetails';
import { isUndefined } from 'lodash';
import { t } from '@lingui/macro';

/**
 * Data shown in the landing page
 */
export type LandingPageData = {
  landingPageDetails: PublishedLandingPageDetails
  videoDetails?: PublishedVideoDetails
};

type PreviewProps = {
  /** target video data to preview */
  data: LandingPageData;
  /** width of video to display.
   * If undefined, video width is set by HTMLVideoElement's videoWidth property */
  width?: React.CSSProperties['width'];
  /** callback on video play */
  onPlay?: () => void;
  /** callback on video pause */
  onPause?: () => void;
  /** callback on video time update */
  onTimeUpdate?: React.ReactEventHandler<HTMLVideoElement>;
  /** callback on cta link click */
  onCtaClick?: (text: string) => void;
};

/**
 * Component for Processed Video Preview
 */
export default function Preview(props: PreviewProps) {
  const { data: { landingPageDetails, videoDetails }, width, onPlay, onPause, onTimeUpdate, onCtaClick } = props;
  const videoElement = useRef<HTMLVideoElement | null>(null);
  const [isPlayStarted, setIsPlayStarted] = useState<boolean>(false);

  const onError = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
    if (videoElement.current) {
      if (videoElement.current.error?.code) {
        switch (videoElement.current.error.code) {
          case MediaError.MEDIA_ERR_ABORTED:
            console.log('MEDIA_ERR_ABORTED');
            break;
          case MediaError.MEDIA_ERR_NETWORK:
            console.log('MEDIA_ERR_NETWORK');
            break;
          case MediaError.MEDIA_ERR_DECODE:
            console.log('MEDIA_ERR_DECODE');
            break;
          case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            console.log('MEDIA_ERR_SRC_NOT_SUPPORTED');
            break;
        }
      }
      const videoTypes = ['video/mp4', 'video/ogg', 'video/webm'];
      videoTypes.forEach(type => console.log(`Can Play ${type}`, videoElement.current?.canPlayType(type)));
    }
    console.error(e);
    LogRocket.error(e);
  };

  const handleOnPlay = () => {
    setIsPlayStarted(true);
    if (onPlay) onPlay();
  };
  
  const videoUrl = landingPageDetails.videoUrl ?? videoDetails?.videoUrl;

  return (
    <>
      <Box position="relative">
        <video
          crossOrigin=""
          playsInline
          ref={videoElement}
          onPlay={handleOnPlay}
          onPause={onPause}
          onTimeUpdate={onTimeUpdate}
          poster={landingPageDetails.thumbnailLocation}
          src={videoUrl}
          controls={isPlayStarted}
          controlsList="nodownload"
          preload="auto"
          onError={onError}
          onLoadedMetadata={(e) => {
            console.log('video loaded', e.currentTarget.videoWidth, e.currentTarget.videoHeight);
          }}
          className='crisp-rendering'
          style={{
            borderRadius: 5,
            width: width,
            maxWidth: '100vw',
            maxHeight: '60vh',
          }}
        ></video>
        { isUndefined(videoUrl) ?
          <Tooltip placement="top" title={t`Video is being prepared`}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            ><CircularProgress />
            </Box>
          </Tooltip> :
          <Fab
            sx={(theme) => ({
              bgcolor: theme.palette.common.white,
              display: isPlayStarted ? 'none' : 'flex',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            })}
            onClick={() => {
              void videoElement.current?.play();
            }}
          >
            <SvgIcon inheritViewBox component={PlaySvg}></SvgIcon>
          </Fab>
        }
      </Box>
      <Box sx={{ py: 3 }}>
        {landingPageDetails.links.filter(notEmpty).map((link) => (
          <Button
            onClick={() => {
              if (onCtaClick) onCtaClick(link.text);
            }}
            href={link.link.includes('http') ? link.link : `http://${link.link}`}
            target="_blank"
            key={link.text}
            variant="contained"
            component="a"
          >
            {link.text}
          </Button>
        ))}
      </Box>
    </>
  );
}
