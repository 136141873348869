import { t } from '@lingui/macro';
import Modal, { ModalProps } from 'common/components/modal/modal.component';
import TextField from 'common/components/textField/textField.component';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { isError, isNil, isUndefined } from 'lodash';

type EditSingleValueProps = Pick<ModalProps, 'isOpen'> & {
  /** main title */
  title: string;
  /** description */
  description: string;
  /** placeholder text for text field */
  placeholderText?: string;
  /** value to edit */
  value?: string | null;
  /** callback on update */
  onUpdate: (newName: string) => Promise<void>;
  /** callback on close */
  onClose: () => void;
  /** icon display in modal */
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  /** label for confirm button */
  confirmLabel: string;
  /** label for cancel button */
  cancelLabel: string;
};

/**
 * Modal for editing single text value
 */
export function EditSingleValueModal({
  title,
  description,
  placeholderText,
  value,
  isOpen,
  onUpdate,
  onClose,
  icon,
  confirmLabel,
  cancelLabel,
}: EditSingleValueProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const [text, setText] = useState<string | undefined | null>(value);

  const onClickClose = () => {
    onClose();
    setError(undefined);
    setText(value);
  };

  const handleUpdate = async () => {
    if (isNil(text)) {
      setError(Error(t`Required field`));
      return;
    }
    setIsSaving(true);
    try {
      await onUpdate(text);
      onClose();
    } catch (e) {
      if (isError(e)) setError(Error(t`Update Failed`));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title={title}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      onConfirm={() => void handleUpdate()}
      onClose={onClickClose}
      isOpen={isOpen}
      icon={icon}
      confirmLoading={isSaving}
      maxWidth="xs"
    >
      <>
        <Typography variant="bodyMedium">{description}</Typography>
        <TextField
          fullWidth
          autoFocus
          error={!isUndefined(error)}
          helperText={error?.message}
          placeholder={placeholderText}
          onChange={(e) => setText(e.currentTarget.value)}
          value={text}
        />
      </>
    </Modal>
  );
}
