import { t } from '@lingui/macro';
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { extractError } from '../../common/utils/errors';
import { LoadingButton } from '@mui/lab';
import AlertError from 'common/components/alert/alertError.component';
import { VideoEffectOptions } from './const';

type VideoEffectSelection = Pick<VideoEffectOptions, 'backgroundBlur' | 'gazeRedirection'>;

type VideoEffectModalProps = {
  /** Initial settings for video effects */
  initSettings?: VideoEffectSelection;
  /** if the video effects are loading */
  isLoading: boolean;
  /** if should allow retry applying the same */
  enableRetry: boolean;
  /** Callback for confirming and applying video effect settings */
  onConfirm: (settings: VideoEffectSelection) => Promise<void>;
  /** callback for clicking video effect settings */
  onClick: (fn: () => void) => void;
};

/**
 * Apply video effect modal
 */
export default function VideoEffectModal({ 
  initSettings = { backgroundBlur: false, gazeRedirection: false }, 
  onConfirm, 
  isLoading,
  enableRetry,
  onClick }: VideoEffectModalProps) {
  const [settings, setSettings] = useState<VideoEffectSelection>(initSettings);
  const [isProcessing, setIsProcessing] = useState(isLoading);
  const [error, setError] = useState<Error>();
  const handleToggleBlur = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      backgroundBlur: !prevSettings.backgroundBlur,
    }));
  };

  const handleToggleGazeRedirection = () => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      gazeRedirection: !prevSettings.gazeRedirection,
    }));
  };

  const handleOnConfirm = async () => {
    setIsProcessing(true);
    try {
      await onConfirm({ 
        backgroundBlur: settings.backgroundBlur,
        gazeRedirection: settings.gazeRedirection,
      });
    } catch (e) {
      const confirmError = extractError(e);
      setError(confirmError);
    }
  };

  return (<>
    <Typography variant="h5Semibold" >{t`Video Effects`}</Typography>
    <FormGroup>
      <FormControlLabel
        control={<Switch disabled={isProcessing}
          checked={settings.gazeRedirection} onChange={() => onClick(handleToggleGazeRedirection)} sx={{ mr: 1 }}/>
        }
        label={t`AI Eye Contact`}
        sx={{ ml: 0 }}
      />
      <FormControlLabel
        control={<Switch
          disabled={isProcessing}
          checked={settings.backgroundBlur}
          onChange={() => onClick(handleToggleBlur)} sx={{ mr: 1 }}/>}
        label={t`Background Blur`}
        sx={{ ml: 0 }}
      />
    </FormGroup>
    <Box textAlign='right'>
      <AlertError error={error} maxWidth='100%'>
        <LoadingButton
          loading={isProcessing}
          disabled={(!enableRetry && initSettings === settings) || isProcessing}
          onClick={() => void handleOnConfirm()}
          variant="contained" color="primary">
          <span>{enableRetry && initSettings === settings ? t`Retry` : t`Apply`}</span>
        </LoadingButton>
      </AlertError>
    </Box>
  </>);
}