import { MIN_CURRENT_TIME } from 'common/constants/video';
import Gif from 'gif.js';
import { useEffect, useState } from 'react';
import { Maybe } from '../../data/_generated';
import { MAX_NUMBER_OF_IMAGES } from '../../edit/const';
import { isNil, isUndefined } from 'lodash';
import { extractError } from '../utils/errors';

declare global {
  interface HTMLVideoElement {
    // not supported in Safari
    // https://caniuse.com/mdn-api_htmlmediaelement_capturestream
    captureStream?: () => MediaStream;
    // not supported in Firefox
    mozCaptureStream?: () => MediaStream;
    // supported in safari only
    // https://caniuse.com/audiotracks
    audioTracks?: MediaStreamTrack[];
    // supported in safari only
    // https://caniuse.com/videotracks
    videoTracks?: MediaStreamTrack[];
  }
}

// 800x600 blank image if thumbnail location can't be found
export const BLANK_THUMBNAIL =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAJYAQMAAACguBAzAAAAA1BMVEUAAACnej3aAAAAUElEQVR42u3BAQEAAACCoP6vbojAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACDg7LgAAfCRwpsAAAAASUVORK5CYII=';


function getCanvas(videoElement: HTMLVideoElement, width?: number) {
  const canvasElement = document.createElement('canvas');
  canvasElement.width = width ?? videoElement.videoWidth;
  canvasElement.height = width
    ? width * (videoElement.videoHeight / videoElement.videoWidth)
    : videoElement.videoHeight;
  const context = canvasElement.getContext('2d');
  if (!context) throw Error('No context');
  return { canvasElement, context };
}

/**
 * Get ImageData from video element
 */
function getImageData(
  time: number,
  videoElement: HTMLVideoElement,
  canvasElement: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
) {
  return new Promise<ImageData>((resolve) => {
    videoElement.onseeked = () => {
      context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      resolve(context.getImageData(0, 0, canvasElement.width, canvasElement.height));
    };
    videoElement.currentTime = time;
  });
}

function getDataURL(
  time: number,
  videoElement: HTMLVideoElement,
  canvasElement: HTMLCanvasElement,
  context: CanvasRenderingContext2D,
) {
  return new Promise<string>((resolve) => {
    videoElement.onseeked = () => {
      context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      resolve(canvasElement.toDataURL('image/jpeg', 0.8));
    };
    videoElement.currentTime = time;
  });
}

/**
 * Helper function to get audio and video tracks from a video element
 * Takes into account browser compatibility
 */
function getTracks(element: HTMLVideoElement): { audioTracks: MediaStreamTrack[]; videoTracks: MediaStreamTrack[] } {
  const stream: MediaStream | undefined =
    (element.captureStream && element.captureStream()) ?? (element.mozCaptureStream && element.mozCaptureStream());
  const audioTracks: MediaStreamTrack[] = element.audioTracks ?? stream?.getAudioTracks() ?? [];
  const videoTracks: MediaStreamTrack[] = element.videoTracks ?? stream?.getVideoTracks() ?? [];
  return { audioTracks, videoTracks };
}

async function renderElement(src: string): Promise<HTMLVideoElement> {
  const element = document.createElement('video');
  element.style.position = 'fixed';
  element.style.top = '0';
  element.style.left = '0';
  element.style.width = '10%';
  element.style.zIndex = '99999';
  element.style.display = 'none';

  element.src = src;
  // Required if the video is not in the same domain
  // DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
  element.crossOrigin = '';
  element.muted = true;
  element.autoplay = true;
  document.body.appendChild(element);
  try { void element.play(); } catch (e) {
    console.error('[useThumbnail] video.play()', e, src);
  }

  await new Promise<void>((resolve, reject) => {
    element.onerror = (e) => {
      reject(new Error(`Video can't be played (${e.toString()}`));
      element.remove();
    };
    element.onloadeddata = () => {
      const { audioTracks, videoTracks } = getTracks(element);
      if (audioTracks.length === 0) {
        reject(new Error('Audio missing. Upload a video with an audio track.'));
        element.remove();
      } else if (videoTracks.length === 0) {
        reject(new Error('Video missing. Upload a video with a video track.'));
        element.remove();
      } else {
        resolve();
      }
    };
  });
  return element;
}

function renderSingle(videoElement: HTMLVideoElement, startTime: number = MIN_CURRENT_TIME): Promise<string> {
  // Create a canvas element and draw the thumbnail frame onto it
  const { canvasElement, context } = getCanvas(videoElement);
  return getDataURL(startTime, videoElement, canvasElement, context);
}

async function renderAnimated(videoElement: HTMLVideoElement, startTime = MIN_CURRENT_TIME, clipDuration?: number): Promise<string> {
  const { canvasElement, context } = getCanvas(videoElement, 600);
  // only want to animate the first 2 seconds
  const duration = Math.min(2, clipDuration ?? videoElement.duration);
  const count = MAX_NUMBER_OF_IMAGES;
  const frames: ImageData[] = [];
  let time = startTime;
  const step = duration / (count - 1);
  for (let i = 0; i < count; i++) {
    const frame = await getImageData(time, videoElement, canvasElement, context);
    frames.push(frame);
    time += step;
  }

  // Create an animated GIF from the frames using gif.js
  const gif = new Gif({
    workers: 2,
    workerScript: '/gif.worker.js',
    quality: 10,
    width: canvasElement.width,
    height: canvasElement.height,
  });
  for (const frame of frames) {
    gif.addFrame(frame, { delay: 150 });
  }
  const dataURI = await new Promise<string>((resolve) => {
    gif.on('finished', (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string);
    });
    gif.render();
  });
  return dataURI;
}

async function renderMultiple(
  videoElement: HTMLVideoElement,
  duration: number,
  count: number,
  onSingleRender: (thumbnail: string, i: number) => void,
  start: number = MIN_CURRENT_TIME,
) {
  // Create a canvas element and draw the thumbnail frame onto it
  const { canvasElement, context } = getCanvas(videoElement);

  let time = start;
  const step = duration / (count - 1);
  for (let i = 0; i < count; i++) {
    const single = await getDataURL(time, videoElement, canvasElement, context);
    onSingleRender(single, i);
    time += step;
  }
}

type UseThumbnailParams = {
  /**
   * Specifies types of thumbnails to generate. Supports creating multiple types at once
   */
  options: {
    /**
     * Create a single thumbnail
     */
    createStatic?: boolean;
    /**
     * Create an animated gif thumbnail
     */
    createAnimated?: boolean;
    /**
     * Create multiple static thumbnails spaced out over the specified duration
     */
    createMultiple?: boolean;
  },
  /**
   * Video to generate thumbnails for, if undefined no thumbnails will be generated
   */
  videoSrc: Maybe<string>,
  /**
   * Optional start time, thumbnails will be generated starting from this time
   */
  startTimeInSec?: number,
  /**
   * Duration of video, required when generating multiple thumbnails
   */
  durationInSec?: number,
};

type UseThumbnailResult = {
  /**
   * Generated static thumbnail data url
   */
  staticThumbnail: string | undefined;
  staticLoading: boolean;
  /**
   * Generated animated thumbnail data url
   */
  animatedThumbnail: string | undefined
  animatedLoading: boolean;
  /**
   * Result of multiple thumbnail generation, array of data url.
   * As each new thumbnail is generated this will be a new array with the additional thumbnail.
   */
  multipleThumbnail: readonly string[];
  /**
   * If multiple thumbnails are still being generated this will be true
   */
  multipleLoading: boolean;
  /**
   * Populated if an error occurred during thumbnail generation
   */
  error: Error | undefined;
  /**
   * Clear error state
   */
  clearError: () => void;
  /** video dimensions */
  videoDimension: { width: number, height: number } | undefined;
};

/**
 * Hook for generating different types of thumbnails for a video
 */
export default function useThumbnail({
  options: { createStatic, createAnimated, createMultiple },
  videoSrc,
  startTimeInSec = MIN_CURRENT_TIME,
  durationInSec,
}: UseThumbnailParams): UseThumbnailResult {
  const [staticThumbnail, setStaticThumbnail] = useState<string>();
  const [staticLoading, setStaticLoading] = useState(false);
  const [animatedThumbnail, setAnimatedThumbnail] = useState<string>();
  const [animatedLoading, setAnimatedLoading] = useState(false);
  const [multipleThumbnail, setMultipleThumbnail] = useState<readonly string[]>([]);
  const [multipleLoading, setMultipleLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [videoDimension, setVideoDimension] = useState<{ width: number, height: number }>();
  const clearError = () => {
    setError(undefined);
  };

  useEffect(() => {
    //If hook is re-rendered we want to cancel any in progress generation
    let canceled = false;
    let element: HTMLVideoElement | undefined;
    
    async function generateThumbnails(src: string) {
      console.time('generateThumbnails');
      setStaticLoading(createStatic ?? false);
      setAnimatedLoading(createAnimated ?? false);
      setMultipleLoading(createMultiple ?? false);
      try {
        element = await renderElement(src);
        setVideoDimension({ width: element.videoWidth, height: element.videoHeight });
        //Static
        if (!canceled && createStatic) {
          const rendered = await renderSingle(element, startTimeInSec);
          /* eslint-disable @typescript-eslint/no-unnecessary-condition*/
          //Linter doesn't know that cancelled may have changed
          if (!canceled) {
            setStaticThumbnail(rendered);
            setStaticLoading(false);
          }
        }
        //Animated
        if (!canceled && createAnimated) {
          const rendered = await renderAnimated(element, startTimeInSec, durationInSec);
          if (!canceled) {
            setAnimatedThumbnail(rendered);
            setAnimatedLoading(false);
          }
        }
        //Multiple
        if (!canceled && createMultiple && !isUndefined(durationInSec)) {
          setMultipleLoading(true);
          const onRenderHandler = (dataUri: string, index: number) => {
            if (!canceled) {
              setMultipleThumbnail((prev) => {
                const newVal = [...prev];
                newVal[index] = dataUri;
                return newVal;
              });
            }
          };
          await renderMultiple(element, durationInSec, MAX_NUMBER_OF_IMAGES, onRenderHandler, startTimeInSec);
          setMultipleLoading(false);
        }
      } catch (e) {
        console.warn(videoSrc, e);
        const err = extractError(e);
        setError(err);
      } finally {
        console.timeEnd('generateThumbnails');
        element?.remove();
      }
    }
    if (!isNil(videoSrc)) {
      void generateThumbnails(videoSrc);
    }
    return () => {
      canceled = true;
      element?.remove();
      clearError();
      setStaticLoading(false);
      setStaticThumbnail(undefined);
      setAnimatedLoading(false);
      setAnimatedThumbnail(undefined);
      setMultipleThumbnail([]);
      setMultipleLoading(false);
    };
  }, [videoSrc, startTimeInSec, durationInSec, createStatic, createAnimated, createMultiple]);
  
  return {
    error,
    clearError,
    staticThumbnail,
    staticLoading,
    animatedThumbnail,
    animatedLoading,
    multipleThumbnail,
    multipleLoading,
    videoDimension,
  };
}
