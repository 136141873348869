/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

/**
 * History aware link behavior for all link
 * @link [https://mui.com/guides/routing/#global-theme-link]
 */
const LinkBehavior = forwardRef<
any,
Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = 'ReactRouterLink';
export default LinkBehavior;