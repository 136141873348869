import { useUserSubscriptionDetailsQuery } from 'data/_generated';
import { isNil } from 'lodash';
import useUserAppAccess from './useUserAppAccess';

/** number of milliseconds per day */
const MS_PER_DAY = 86_400_000;

/**
 * hook for getting trial days details
 * @returns [totalTrialDays, remainingTrialDays]
 */
export default function useTrialDays(): [number | undefined, number | undefined] {
  const { data } = useUserSubscriptionDetailsQuery();
  const { hasBillingAccess } = useUserAppAccess();
  const currentPlan = data?.userSubscriptionDetails.currentPlan;

  if (!hasBillingAccess || isNil(currentPlan) || isNil(currentPlan.trialStartDate) || isNil(currentPlan.trialEndDate))
    return [undefined, undefined];

  const total = (new Date(currentPlan.trialEndDate).valueOf() - new Date(currentPlan.trialStartDate).valueOf()) / MS_PER_DAY;
  const remaining = (new Date(currentPlan.trialEndDate).valueOf() - new Date().valueOf()) / MS_PER_DAY;

  return [
    Math.ceil(total),
    Math.ceil(remaining),
  ];
}
