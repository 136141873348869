import { Box, Chip, Divider, Paper, SxProps, Typography } from '@mui/material';
import { PlanType } from '../../onboard/plan.const';
import { PALETTE_COLOR, SPACER } from '../../config/models/themeOptions';
import { isUndefined } from 'lodash';
import { ReactNode } from 'react';
import IconItemList from './iconItemList.component';

const MARGIN_BOTTOM =  SPACER.S;

type PlanCardProps = {
  /** plan to display */
  plan: PlanType;
  /** custom styling */
  sx?: SxProps;
  /** any extra elements to show */
  children?: ReactNode;
};

/**
 * Component for displaying plan details
 */
export default function PlanCard({
  plan,
  sx,
  children = <Divider /> }: PlanCardProps) {
  return (
    <Paper
      elevation={5}
      sx={{
        p: 8,
        border: `2px solid ${PALETTE_COLOR.primary[400]}`,
        borderRadius: 5, ...sx,
      }}
    >
      <Typography variant={'h3'} color={PALETTE_COLOR.primary[500]} mb={MARGIN_BOTTOM}>
        {plan.name}
        {!isUndefined(plan.discountTag) && <Chip variant={'tag'} color='secondary' sx={{ ml: 2, mt: '-7px' }} label={plan.discountTag} />}
      </Typography>
      <Typography mb={MARGIN_BOTTOM}>{plan.description}</Typography>
      <Box display={'flex'} mb={MARGIN_BOTTOM} alignItems={'end'}>
        <Typography variant={'h1'}>{plan.price}</Typography>
        <Typography variant={'caption'}>{plan.priceCaption}</Typography>
      </Box>
      {children}
      <Box my={MARGIN_BOTTOM}>
        <Typography variant={'bodySemibold'} >{plan.featureDescription}</Typography>
      </Box>
      <IconItemList items={plan.features}/>
    </Paper>
  );
}