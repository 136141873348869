import useActiveAccount from '../../config/hooks/useActiveAccount';
import { ICustomerPortalFlowType, useCreateCustomerPortalMutation } from '../../data/_generated';
import { isUndefined } from 'lodash';


/**
 * Hook to get link to upgrade plan
 */
export default function useGetUpgradePlanLocation() : ()=> Promise<string> | string {
  const { accountId } = useActiveAccount();
  const [createCustomerPortal] = useCreateCustomerPortalMutation({
    variables: {
      returnUrl: window.location.href,
      flowType: ICustomerPortalFlowType.SubscriptionUpdate,
    },
  });
  if (isUndefined(accountId)) return ()=> '/pricing';

  return async () => {
    const result = await createCustomerPortal();
    if (result.data?.createCustomerPortal) {
      return result.data.createCustomerPortal;
    } else {
      throw new Error('Upgrade url is unavailable');
    }
  };
}