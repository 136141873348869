import { IconButton, Slider, SliderThumb, styled, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import { isArray } from 'lodash';
import { ReactComponent as LeftPointerSvg } from 'common/images/icons/Utility/left pointer.svg';
import { ReactComponent as RightPointerSvg } from 'common/images/icons/Utility/right pointer.svg';
import { toTimestampString } from 'common/utils/string';
import { t } from '@lingui/macro';
import { useState } from 'react';
import Tooltip from 'common/components/tooltip/tooltip.component';
import { ReactComponent as CloseSvg } from 'common/images/icons/Utility/close.svg';
const RANGE_MAX = 100;

/** width of trim handle */
const TRIM_HANDLE_WIDTH = '17px';


const TrimSlider = styled(Slider)(({ theme }) => ({
  position: 'absolute',
  opacity: 1,
  transform: 'translateY(-50%)',
  top: '50%',
  height: 0,
  padding: '0 !important',
  '& .MuiSlider-thumb': {
    boxShadow: 'none',
    height: 42,
    backgroundColor: theme.palette.primary[500],
    position: 'absolute',
    zIndex: -1,
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    right: 0,
    cursor: 'ew-resize',
    flexFlow: 'row-reverse',
    '&:before': {
      boxShadow: 'none',
    },
    '&.first-thumb': {
      borderRadius: '5px 0 0 5px',
      width: TRIM_HANDLE_WIDTH,
    },
    '&.last-thumb': {
      borderRadius: '0 5px 5px 0',
      width: TRIM_HANDLE_WIDTH,
    },
    '&.Mui-active': {
      boxShadow: 'none',
      cursor: 'col-resize',
    },
  },
  '& .MuiSlider-track': {
    opacity: 0,
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
  '& .MuiSlider-valueLabel': {
    ...theme.typography.tag,
    backgroundColor: theme.palette.primary[600],
    color: theme.palette.neutral[0],
    borderRadius: 5,
    padding: '5px',
    top: -3,
    //TODO: Update value labels to not overflow the window
  },
  '& .Mui-disabled': {
    backgroundColor: theme.palette.neutral[500],
  },
}));

function RangeThumb(props: React.HTMLAttributes<unknown>) {
  const { children, className, ...other } = props;

  const isFirstThumb = other['data-index'] === 0;
  const thumbClassName = isFirstThumb ? 'first-thumb' : 'last-thumb';

  return (
    <SliderThumb {...other} className={`${className} ${thumbClassName}`}>
      {children}
      <SvgIcon component={isFirstThumb ? LeftPointerSvg : RightPointerSvg} inheritViewBox sx={{ fontSize: '0.8rem' }} />
    </SliderThumb>
  );
}

type VideoPlayTrimmerProps = {
  /** where trimming starts in % */
  startValue: number;
  /** where trimming ends in % */
  endValue: number;
  /** callback on startValue change */
  handleUpdateStart: (value: number) => void;
  /** callback on endValue change */
  handleUpdateEnd: (value: number) => void;
  /** whether it's loading */
  loading: boolean;
  /** total duration of video */
  durationInSeconds: number;
};

export default function VideoPlayTrimmer({
  startValue,
  endValue,
  handleUpdateStart,
  handleUpdateEnd,
  loading,
  durationInSeconds,
}: VideoPlayTrimmerProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleMouseUp = () => {
    setShowTooltip(false);
  };

  const handleChange = (_event: Event, range: number | number[]) => {
    if (!isArray(range)) return;
    const [updatedStart, updatedEnd] = range;
    handleUpdateStart(updatedStart);
    handleUpdateEnd(updatedEnd);
  };

  const disabled = durationInSeconds === 0;

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  return <>
    <div
      className="clip_shadow"
      style={{ width: `${startValue}%` }}
    ></div>
    <div
      className="clip_shadow"
      style={{
        width: `${100 - endValue}%`,
        left: `${endValue}%`,
      }}
    ></div>
    <div
      className={'clip_box'}
      style={{
        width: `${endValue - startValue}%`,
        left: `${startValue}%`,
      }}
    />
    <Tooltip
      color="dark"
      arrow
      open={showTooltip && !disabled}
      placement={isXS ? 'top' : 'right'}
      title={
        <Typography display={'flex'} alignItems={'center'} variant="tag">
          {t`Drag to trim video`}
          <IconButton onClick={() => setShowTooltip(false)} sx={{ color: 'white' }}>
            <SvgIcon component={CloseSvg} inheritViewBox />
          </IconButton>
        </Typography>
      }
    >
      <TrimSlider
        getAriaLabel={() => 'Trimming Range'}
        value={[startValue, endValue]}
        disableSwap
        disabled={loading || disabled}
        onChange={handleChange}
        onChangeCommitted={handleMouseUp}
        components={{ Thumb: RangeThumb }}
        track={false}
        valueLabelDisplay="auto"
        min={0}
        max={RANGE_MAX}
        valueLabelFormat={(value) => toTimestampString((value / RANGE_MAX) * durationInSeconds)}
      ></TrimSlider>
    </Tooltip></>;
}
