import { t } from '@lingui/macro';
import { Card, CardContent, CardHeader, Grid, IconButton, Skeleton, SvgIcon, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as PlaySvg } from 'common/images/icons/Camera/Play.svg';
import { ReactComponent as HeartSvg } from 'common/images/icons/Utility/full heart.svg';
import { ReactComponent as ClickSvg } from 'common/images/icons/Utility/cursor.svg';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import { ReactComponent as PlaneSvg } from 'common/images/icons/Utility/paper airplane.svg';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import IconTooltip from 'common/components/tooltip/iconTooltip.component';
import { isNull } from 'lodash';
import { getEqualColumnWidth } from 'dashboard/helper';
import { FunctionComponent, SVGProps } from 'react';
import { Theme } from '@mui/material/styles';

type SummaryCardProps = {
  /** Data to show in card, if undefined show loading */
  data?: string;
  /** The label for this card */
  label: string;
  /** The description for this card */
  description: string;
  /** color for the icon */
  color: string;
  /** background color around the icon */
  backgroundColor: string;
  /** the icon that represents this stat */
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

/** Presentational component showing the specific aspect */
function SummaryCard({ data, label, description, color, backgroundColor, icon }: SummaryCardProps) {
  return (
    <Card variant="outlined" sx={{ flexGrow: 1 }}>
      <CardHeader
        action={
          <SvgIcon
            sx={{
              backgroundColor,
              color,
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              padding: '8px',
            }}
            component={icon}
            inheritViewBox
          />
        }
        sx={{
          alignItems: 'flex-start',
          '& .MuiCardHeader-content': {
            display: 'flex',
          },
        }}
        title={
          <Typography>
              {label}
              <IconTooltip color="dark" arrow title={description}>
                  <IconButton sx={{ p: 0, ml: 1, color: PALETTE_COLOR.neutral[300], top: '-1px' }}>
                      <SvgIcon component={InfoSvg} inheritViewBox sx={{ fontSize: '1rem' }} />
                  </IconButton>
              </IconTooltip>
          </Typography>
          }
      />
      <CardContent>
        {data ? (
          <Typography variant="h1">{data}</Typography>
        ) : (
          <Skeleton variant="text" height={'37px'} width={'40px'} />
        )}
      </CardContent>
    </Card>
  );
}

type SummaryData = {
  /** number of video plays */
  videoPlays?: string;
  /** avg watch in % out of 100 */
  avgWatch?: string;
  /** number of cta clicks */
  ctaClick?: string;
  /** cta click over video plays - data is hidden when null */
  conversionRate?: string | null;
};

type SummaryProps = {
  /** Summary Data, if undefined, then data is still loading */
  data?: SummaryData;
};

/** Presentational component to show Summary of stats for a user  */
export default function Summary({ data }: SummaryProps) {
  const gridMdBreakPoint = getEqualColumnWidth(data);
  const showShortenedLabel = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <Grid container spacing={SPACER.S} mb={SPACER.S}>
      <Grid item xs={12} md={gridMdBreakPoint} display={'flex'}>
        <SummaryCard
          data={data?.videoPlays}
          description={t`Total number of times your videos have been played from the beginning`}
          label={t`Video Plays`}
          icon={PlaySvg}
          color={PALETTE_COLOR.secondary[500]}
          backgroundColor={PALETTE_COLOR.secondary[50]}
        />
      </Grid>
      <Grid item xs={12} md={gridMdBreakPoint} display={'flex'}>
        <SummaryCard
          data={data?.avgWatch}
          description={t`Percentage of the total video length that viewers are watching on average`}
          label={t`Average ${showShortenedLabel ? '%' : 'percentage'} watched`}
          icon={HeartSvg}
          color={PALETTE_COLOR.error[400]}
          backgroundColor={PALETTE_COLOR.error[50]}
        />
      </Grid>
      <Grid item xs={12} md={gridMdBreakPoint} display={'flex'}>
        <SummaryCard
          data={data?.ctaClick}
          description={t`Number of call-to-action (CTA) clicks on your video pages`}
          label={t`Call-to-action clicks`}
          icon={ClickSvg}
          color={PALETTE_COLOR.warning[400]}
          backgroundColor={PALETTE_COLOR.warning[50]}
        />
      </Grid>
      {!isNull(data?.conversionRate) && (
        <Grid item xs={12} md={gridMdBreakPoint} display={'flex'}>
          <SummaryCard
            data={data?.conversionRate}
            description={t`Rate at which your call-to-action (CTA) is clicked for per video play`}
            label={t`Conversion rate`}
            icon={PlaneSvg}
            color={PALETTE_COLOR.success[400]}
            backgroundColor={PALETTE_COLOR.success[50]}
          />
        </Grid>
      )}
    </Grid>
  );
}
