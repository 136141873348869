import { ReactNode } from 'react';
import {
  Box, Drawer,
} from '@mui/material';

export type ResponsiveDrawerProps = {
  drawerWidth: number;
  mobileTopOffset?: number;
  topOffset?: number;
  children: ReactNode;
  mobileOpen: boolean;
  /**
   * Better open performance on mobile.
   * BUT means the children will be re-rendered twice on the page
   * if you use references to any children in the drawer you'll need 
   * deal with that
   * @type {boolean}
   */
  keepMounted?: boolean,
  onDrawerToggle: () => void;
};

/**
 * [Presentational] Responsive Drawer
 * @param props
 */
function ResponsiveDrawer({
  mobileTopOffset,
  drawerWidth, children, mobileOpen, onDrawerToggle, topOffset,
  keepMounted = true,
}: ResponsiveDrawerProps) {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{ keepMounted }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            display: 'flex',
            boxSizing: 'border-box',
            paddingTop: mobileTopOffset ? `${mobileTopOffset}px` : undefined,
            width: drawerWidth,
          },
        }}
      >
        {children}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            display: 'flex',
            top: topOffset,
            height: topOffset ? `calc(100vh - ${topOffset}px)` : '100%',
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
        open
      >
        {children}
      </Drawer>
    </Box>
  );
}

export { ResponsiveDrawer };