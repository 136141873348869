import {
  Alert as MuiAlert,
  AlertTitle,
  AlertProps as MuiAlertProps,
  SvgIcon,
  Typography,
  Button,
  IconButton,
  Collapse,
  Box,
} from '@mui/material';
import { getStatusIcon } from 'common/utils/style';
import { ReactNode, useState, forwardRef, Ref } from 'react';
import { ReactComponent as CloseSvg } from 'common/images/icons/Utility/close.svg';

export type AlertProps = Pick<MuiAlertProps, 'severity' | 'icon' | 'onClose' | 'sx'> & {
  /** title in the alert */
  title?: string;
  /** description in the alert */
  description: ReactNode;
  /** callback on clicking action button. */
  onAction?: () => void;
  /** action button label */
  actionLabel?: string;
  /** href to specify the url link on onAction*/
  actionHref?: string;
  /** whether alert is closeable */
  closeable?: boolean;
  /** the elevation of the alert
   * @default 0
   */
  elevation?: number;
};

const UrlSchemaRegex = new RegExp(/^((https?):\/\/)/);
/**
 * check if url is full url including schema (http or https)
 */
function isFullUrl(url?: string) {
  return url ? UrlSchemaRegex.test(url) : false;
}

/** Alert component from Design System */
const Alert = forwardRef(function Alert(props: AlertProps, ref: Ref<HTMLDivElement>) {
  const {
    title,
    description,
    severity = 'success',
    onAction,
    onClose,
    actionLabel,
    actionHref,
    closeable = false,
    icon,
    sx,
    elevation = 0,
  } = props;

  const [close, setClose] = useState<boolean>(false);

  if ((onAction || actionHref) && !actionLabel) throw new Error('missing label for action button');

  const AlertMessage = <MuiAlert
    sx={{ ...sx, '& .MuiAlert-message': { display: 'flex', alignItems: 'flex-start' } }}
    /** icon is displayed as a part of message component to dynamically change the layout of messaging and action */
    icon={false}
    severity={severity}
    elevation={elevation}
    ref={ref}
    action={
      <>
        {(onAction || actionHref) && (
          <Button
            variant="link"
            onClick={onAction}
            href={actionHref}
            target={isFullUrl(actionHref) ? '_blank' : '_self'}
            component={actionHref ? 'a' : 'button'}
          >
            {actionLabel}
          </Button>
        )}
        {closeable && (
          <IconButton
            size="small"
            onClick={(event) => {
              setClose(true);
              if (onClose) onClose(event);
            }}
          >
            <SvgIcon component={CloseSvg} inheritViewBox />
          </IconButton>
        )}
      </>
    }
  >
    {icon ?? <SvgIcon component={getStatusIcon(severity)} inheritViewBox />}
    <Box sx={{ ml: 1 }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {typeof description === 'string' ? <Typography variant="bodyReg">{description}</Typography> : description}
    </Box>
  </MuiAlert>;
  return (closeable) ? <Collapse in={!close}>{AlertMessage}</Collapse> : AlertMessage;
});

export default Alert;
