import { useLandingPageMetricsByTargetIdQuery, ILandingPageTargetsMetricsFragment } from 'data/_generated';
import { keyBy } from 'lodash';

export default function useTargetMetrics(targetIds: string[], enabled: boolean ) {
  const landingPageMetricsByTargetId = useLandingPageMetricsByTargetIdQuery({
    variables: { targetIds },
  });
  const targetsAnalyticsData = keyBy<ILandingPageTargetsMetricsFragment | undefined>(
    landingPageMetricsByTargetId.data?.landingPageMetricsByTargetId ?? [],
    (metrics) => metrics?.targetId ?? '',
  );
  if (!enabled) return undefined;
  return targetsAnalyticsData;
}