
type UseScriptOptions = Partial<{
  async: boolean;
  defer: boolean;
  onload: () => void;
  id: string;
  type: string;
}>;

/**
 * Load an external script
 * @param url Script url we want to load
 * @param options Options for loading the script
 */
export const loadScript = (url: string, options: UseScriptOptions): [HTMLScriptElement, () => void] => {
  const {
    async, defer, onload, id,
    type = 'text/javascript',
  } = options;
  const script = document.createElement('script');
  script.type = type;
  if (async !== undefined) script.async = async;
  if (defer !== undefined) script.defer = defer;
  if (id !== undefined) script.id = id;
  if (onload !== undefined) script.onload = onload;
  script.src = url;
  script.crossOrigin = 'anonymous';
  const headElement = document.getElementsByTagName('head')[0];
  headElement.appendChild(script);
  const removeFunction = () => headElement.removeChild(script);
  return [script, removeFunction];
};