import { useAuth } from '../../config/hooks/useAuth';

const INTERACTION_REQUIRED_ERROR = 'interaction_required';

/**
 * Returns async function for getting authorization token. If token not found will redirect to login page.
 */
export function useGetAuthToken(): () => Promise<string> {
  const { instance, scopes } = useAuth();
  return async () => {
    try {
      const { accessToken } = await instance.acquireTokenSilent({ scopes });
      if (!accessToken) throw new Error('Token not found');
      return `Bearer ${accessToken}`;
    } catch (error) {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/349
      // fallback => if acquireTokenSilent fails, we try acquireTokenRedirect method
      if (error instanceof Error && error.message.includes(INTERACTION_REQUIRED_ERROR)) {
        await instance.acquireTokenRedirect({ scopes: scopes });
      }
      throw error;
    }
  };
}