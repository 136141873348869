import { ApolloError } from '@apollo/client';
import { ICreateTimelineVideoProjectInput, ITargetInput, useCreateTimelineVideoProjectMutation, useUpdateVideoProjectTargetsMutation } from 'data/_generated';
import { DEFAULT_TIMELINE_PROJECT } from 'edit/const';
import { useNavigate } from 'react-router-dom';
import { isUndefined } from 'lodash';

type CreateParams = {
  project?: ICreateTimelineVideoProjectInput,
  targets?: ITargetInput[],
  appendUrl?: string,
};

/** Helper hook to create projects */
export default function useCreateProject(): [(params?: CreateParams) => Promise<void>, { loading: boolean, error: ApolloError | undefined, reset: () => void }] {
  const navigate = useNavigate();

  const [createVideoProject, { loading, error, reset }] = useCreateTimelineVideoProjectMutation();
  const [updateTargets] = useUpdateVideoProjectTargetsMutation();

  const create = async ({
    project = DEFAULT_TIMELINE_PROJECT,
    appendUrl,
    targets,
  } : CreateParams = {}) => {
    const { data } = await createVideoProject({ variables: { data: project } });

    if (data?.createTimelineVideoProject.id) {
      // Create targets if available
      if (!isUndefined(targets)) {
        await updateTargets({
          variables: {
            updateVideoProjectTargetsId: data.createTimelineVideoProject.id,
            data: {
              targets,
            },
          },
        });
      }
      navigate(`/edit/${data.createTimelineVideoProject.id}${appendUrl ?? ''}`);
    } else {
      throw new Error('Failed to create project');
    }
  };
  return [create, { loading, error, reset }];
}
