import { isNil } from 'lodash';

/**
 * Helper method to filter notEmpty items
 *
 * @param value the value
 * @returns true if the item is not empty
 */
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return !isNil(value);
}
