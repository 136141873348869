import { Snackbar } from '@mui/material';
import { t } from '@lingui/macro';
import { useLocalStorage } from 'usehooks-ts';
import Alert from './alert/alert.component';
import { LOCAL_STORAGE_KEYS } from 'edit/const';

/**
 * Using local storage shows a mobile advisory to the user
 * @returns {JSX.Element}
 */
export default function MobileAdvisory() {

  const [hideMobileAdvisory, setHideMobileAdvisory] =
      useLocalStorage(LOCAL_STORAGE_KEYS.MobileAdvisory, false);
  if (window.parent !== window) return <></>;
  return (
    <Snackbar sx={{ display: { sm: 'none' } }} open={!hideMobileAdvisory}>
      <Alert
        closeable
        onClose={() => setHideMobileAdvisory(true)}
        severity="info"
        title={t`Want the best experience?`}
        description={t`Try our optimized desktop version on your favourite browser.`}
      />
    </Snackbar>
  );
}
