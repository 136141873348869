import { useVideoClipsQuery } from '../../data/_generated';
import { VideoExisting } from './const';
import { isEmpty, isUndefined } from 'lodash';
import VideoLibrary from './videoLibrary.component';

type VideoFetchLibraryProps = {
  /** callback when a clip is selected */
  onSelected: (c: VideoExisting) => void | Promise<void>;
};

export  default  function VideoFetchLibrary({ onSelected } : VideoFetchLibraryProps) {
  const { loading, data, error } = useVideoClipsQuery({ fetchPolicy: 'no-cache' });
  const options: VideoExisting[] =
        data?.videoClips.filter(clip => !isEmpty(clip.title))
          .map(clip => (clip.location ? { ...clip } : undefined))
          .filter((clip): clip is VideoExisting => !isUndefined(clip)) ?? [];
  return <VideoLibrary onSelected={onSelected} options={options} loading={loading} error={error}  />;
}