import { SvgIcon, TextField as MUITextField, TextFieldProps, Typography, useTheme } from '@mui/material';
import { getStatusIcon } from 'common/utils/style';
import { forwardRef } from 'react';

type ButtonColorType = 'success' | 'error';

function getHelperContent(text: string, type?: ButtonColorType) {
  return (
    <Typography display="flex" alignItems="center" variant="caption">
      {type && <SvgIcon component={getStatusIcon(type)} inheritViewBox sx={{ mr: 1 }} />}
      {text}
    </Typography>
  );
}

type StyledTextFieldProps = Omit<TextFieldProps, 'helperText'> & {
  helperText?: string;
};

/**
 * Styled TextField
 * - Styling from Design System
 */
export default forwardRef<HTMLInputElement, StyledTextFieldProps>(function TextField(props: StyledTextFieldProps, ref) {
  const { helperText, color, error, sx, ...otherProps } = props;
  const theme = useTheme();

  let status: ButtonColorType | undefined;
  if (color === 'success') status = 'success';
  if (error) status = 'error';

  let helperContentColor;
  if (status === 'success') {
    helperContentColor = theme.palette.success[500];
  } else if (status === 'error') helperContentColor = theme.palette.error[500];

  return (
    <MUITextField
      {...otherProps}
        ref={ref}
      error={error}
      color={color}
      helperText={helperText ? getHelperContent(helperText, status) : undefined}
      sx={{
        '& .MuiFormHelperText-root': {
          color: helperContentColor,
        },
        ...sx,
      }}
    />
  );
});
