import Modal from 'common/components/modal/modal.component';
import { IPersonalizedVideoClip, ISingleClipSlot, ITarget, Maybe } from 'data/_generated';
import { compact, isEmpty, isNil, isUndefined, keyBy, maxBy, sum } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlaySvg } from 'common/images/icons/Camera/Play.svg';
import { t } from '@lingui/macro';
import { DEFAULT_ASPECT_RATIO, getAspectRatio } from 'common/constants/video';
import { AlertColor, alpha, Box, ButtonBase, styled, Typography, useTheme } from '@mui/material';
import {  VIDEO_WRAPPER } from 'edit/const';
import Alert from 'common/components/alert/alert.component';
import { getTrimmedTime, getTrimmedDuration } from './utils';
import { useElapsedTime } from 'use-elapsed-time';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { toTime } from 'common/utils/string';
import { ValueOf } from '../../common/utils/types';

export const VideoButton = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.bodyMedium,
  fontFamily: 'inherit',
  scrollSnapStop: 'normal',
  display: 'flex',
  flexDirection: 'column',
  width: 94,
  height: 64,
  borderRadius: 10,
  marginRight: 10,
  border: '1px solid',
  alignItems: 'stretch',
  justifyItems: 'center',
  marginLeft: 0,
  transition: 'margin-left .5s',
  borderColor: theme.palette.neutral[200],
  backgroundBlendMode: 'overlay',
  backgroundSize: 'cover',
  backgroundColor: `color-mix(in srgb, ${theme.palette.neutral[700]} 50%, transparent)`,
  '&.is-active': {
    borderColor: theme.palette.primary[500],
    backgroundColor: `color-mix(in srgb, ${theme.palette.primary[500]} 50%, transparent)`,
  },
  ':hover': {
    borderColor: theme.palette.primary[500],
  },
}));

const Video = styled('video')({
  display: 'none',
  objectFit: 'contain',
  height: '100%',
  margin: '0 auto',
});

const MS_PER_SECOND = 1000;

export type PreviewAlertType = ValueOf<typeof PREVIEW_ALERT>;

const PREVIEW_ALERT = {
  missingTarget: 'missingTarget',
  missingIntro:  'missingIntro',
  missingClip: 'missingClip',
} as const;

const PreviewAlertInfo: { [alertType:  string]: { severity: AlertColor, description: string, actionLabel?: string } } = {
  [PREVIEW_ALERT.missingClip]: { severity: 'error', description: t`You have no video clips recorded. Add a few clips to your timeline to preview!` },
  [PREVIEW_ALERT.missingTarget]: { severity: 'info', description: t`Tip: Add personalized intros to create unique videos for different recipients`, actionLabel: t`Try it out` },
  [PREVIEW_ALERT.missingIntro]: { severity: 'warning', description: t`Looks like some of your recipients are missing recordings. Empty recipients will not have any personalized intro.`, actionLabel:  t`Go back to record` },
};

type VideoPreviewProps = {
  /** list of personalized video clips */
  personalized?: ReadonlyArray<IPersonalizedVideoClip> | null;
  /** list of targets */
  targets?: ReadonlyArray<ITarget>;
  /** list of non-personalized clips */
  slots: ReadonlyArray<ISingleClipSlot>;
  /** callback on close */
  onClose: () => void;
  /** thumbnail to display on slot */
  thumbnail?: string;
  /** callback on alert action */
  onAlertAction?: () => void;
  /** type of alert */
  alertType?: PreviewAlertType;
};

type SelectedTarget = { targetId: string, personalizedSlot?: IPersonalizedVideoClip };
/**
 * Preview video without pre-processing
 */
export default function VideoPreview({ personalized, slots, onClose, targets, thumbnail, onAlertAction, alertType }: VideoPreviewProps) {
  const theme = useTheme();
  const [videoElements, setVideoElements] = useState<HTMLCollectionOf<HTMLVideoElement>>();
  const timeoutId = useRef<NodeJS.Timeout>();
  const [videoIndex, setVideoIndex] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const personsalizedByTargetId = keyBy(personalized, (clip) => clip.targetId);
  const [selectedTarget, setSelectedTarget] = useState<SelectedTarget | undefined>((!isUndefined(targets) && !isUndefined(targets[0]?.id)) ? { targetId: targets[0].id, personalizedSlot: personsalizedByTargetId[targets[0].id] } : undefined);
  const allSlots = isUndefined(selectedTarget) || isUndefined(selectedTarget.personalizedSlot) ? slots : [selectedTarget.personalizedSlot, ...slots];
  const videoDuration = sum(compact(allSlots.map((c) => getTrimmedDuration(c.start, c.end, c.videoClip?.durationInSeconds) ?? 0)));
  useEffect(() => {
    // Using isPlaying to prevent playVideos() from running on unmounted modal
    if (videoElements?.length === allSlots.length && !isPlaying) {
      void playVideos();
    }
  }, [videoElements, allSlots, isPlaying]);

  const handleClose = () => {
    handleReset();
    onClose();
  };

  /**
   * Setup callback to be called on end of the clip duration
   * - handles playing the next clip or replay from the beginning
   */
  const setOnClipEnd = (duration: Maybe<number>, video: HTMLVideoElement, index: number) => {
    let currentIndex = index;
    if (isNil(duration)) return;
    const timeout = setTimeout(() => {
      video.pause();
      video.style.display = 'none';
      currentIndex++;

      if (currentIndex < allSlots.length) {
        void playVideos(currentIndex);
      } else {
        //auto-replay the video
        void playVideos(0);
      }
    }, duration * MS_PER_SECOND);
    timeoutId.current = timeout;
  };

  const playVideos = async (index = 0 ) => {
    if (isUndefined(videoElements)) return;
    setVideoIndex(index);
    const currentVideoElement = videoElements[index];
    
    currentVideoElement.style.display = 'block';
    const currentClip = allSlots[index];

    if (!isNil(currentClip.start) && currentClip.videoClip?.durationInSeconds)
      currentVideoElement.currentTime = getTrimmedTime(currentClip.start, currentClip.videoClip.durationInSeconds);

    setIsPlaying(true);
    await currentVideoElement.play();

    const duration =
      getTrimmedDuration(currentClip.start, currentClip.end, currentClip.videoClip?.durationInSeconds) ??
      currentClip.videoClip?.durationInSeconds;
    setOnClipEnd(duration, currentVideoElement, index);
  };

  const handleReset = () => {
    setIsPlaying(false);
    const currentVideoElement = videoElements?.[videoIndex];
    if (!isUndefined(currentVideoElement)) {
      currentVideoElement.style.display = 'none';
      currentVideoElement.pause();
    }
    setVideoIndex(0);
    clearTimeout(timeoutId.current);
  };

  const handleSelectTarget = (targetId: string) => {
    handleReset();
    setSelectedTarget({ targetId, personalizedSlot: personsalizedByTargetId[targetId] } );
  };
  return (
    <Modal
      title={t`Preview your video`}
      icon={PlaySvg}
      onClose={handleClose}
      isOpen
      confirmLabel={alertType === 'missingClip' ? t`Cool!` : t`Looks neat!`}
      onConfirm={handleClose}
      maxWidth={'sm'}
      sx={{ width: '100%' }}
    >
      <VIDEO_WRAPPER
        sx={{
          display: 'block', aspectRatio: getMaxAspectRatio(allSlots), background: theme.palette.neutral[900] }}
        ref={(node: HTMLDivElement) => {
          if (!isNil(node)) {
            const videoChildren = node.getElementsByTagName('video');
            setVideoElements(videoChildren);
          }
        }}
      >
        {isEmpty(allSlots) ?
            <Box height={'200px'} sx={{ backgroundColor: PALETTE_COLOR.neutral[500] }}/> :
            <>
              {!isUndefined(selectedTarget?.personalizedSlot) &&
                  <Video
                      key={selectedTarget?.personalizedSlot.videoClip.id}
                      playsInline
                      src={selectedTarget?.personalizedSlot.videoClip.location ?? ''}
                  />}
              {slots.map((slot) => {
                return (
                    <Video
                        key={slot.videoClip?.id}
                        playsInline
                        src={slot.videoClip?.location ?? ''}
                    />
                );
              })}
            </>
        }

      <TimeStamp duration={videoDuration} isPlaying={isPlaying} target={selectedTarget}/>
      </VIDEO_WRAPPER>
     {!isEmpty(targets) && 
        <Box display="flex" mt={4}>
          {targets?.map((target) => {
            const isSelected = target.id === selectedTarget?.targetId;
            return (
              <VideoButton 
              key={target.id} 
              onClick={() => {handleSelectTarget(target.id);}} 
              sx={{ backgroundImage: `url(${thumbnail})`, backgroundSize: 'cover' }} 
              className={`
              ${isSelected ? 'is-active' : ''} 
            `}>
                <Typography zIndex={1} sx={{  
                  color: theme.palette.neutral[0],
                }}>{target.name}</Typography>
              </VideoButton>
            );
          })}
        </Box>}
      {!isUndefined(alertType) &&
          <Alert
          sx={{ mt: 4 }}
          severity={PreviewAlertInfo[alertType].severity}
          description={PreviewAlertInfo[alertType].description}
          onAction={onAlertAction ? () => {
            handleClose();
            onAlertAction();
          } : undefined}
          actionLabel={PreviewAlertInfo[alertType].actionLabel}/>}
    </Modal>
  );
}

/** helper to get max aspect ratio out of available clips */
function getMaxAspectRatio(clipSlots: ReadonlyArray<IPersonalizedVideoClip | ISingleClipSlot>) {
  const clips = clipSlots.map((slot) => slot.videoClip);
  const scalingReferenceVideo = maxBy(clips, (clip) =>
    getAspectRatio(
      isNil(clip) || isNil(clip.videoHeight) || isNil(clip.videoWidth)
        ? undefined
        : { videoHeight: clip.videoHeight, videoWidth: clip.videoWidth },
    ),
  );

  if (
    isNil(scalingReferenceVideo) ||
    isNil(scalingReferenceVideo.videoWidth) ||
    isNil(scalingReferenceVideo.videoHeight)
  ) {
    return Number(DEFAULT_ASPECT_RATIO);
  }

  return scalingReferenceVideo.videoWidth / scalingReferenceVideo.videoHeight;
}

type TimeStampProps = {
  /** duration of video */
  duration: number;
  /** whether video is playing  */
  isPlaying: boolean;
  /** target of video */
  target?: SelectedTarget;
};

/**
 * Component to show video timestamp
 */
function TimeStamp({ duration, isPlaying, target }: TimeStampProps) {

  const { elapsedTime, reset:resetTime } = useElapsedTime({ isPlaying, duration, onComplete: (_total) => ({ shouldRepeat:true }) });
  useEffect(() => {
    if (!isUndefined(target)) {
      resetTime();
    }
  }, [target]);

  return (
    <Box sx={{
      position: 'absolute',
      minWidth: '64px', 
      bottom: 10,
      left: 10,
      borderRadius: '10px',
      backgroundColor: alpha(PALETTE_COLOR.neutral[900], 0.8),
      textAlign: 'left',
      padding: '5px',
    }}>
       <Typography sx={{ fontFamily: 'monospace', fontSize: 14, fontWeight: 'bold', color: PALETTE_COLOR.neutral[0],
       }} >
      {`${toTime(elapsedTime)}/${toTime(duration)}`}
    </Typography>
   </Box>
  );
}