import { Skeleton, Slider, styled } from '@mui/material';
import { isNumber, isUndefined, take } from 'lodash';
import { toTimestampString } from 'common/utils/string';
/** width of trim handle */
const TRIM_HANDLE_WIDTH = '17px';

const TrimContainer = styled('div')(({ theme }) => ({
  userSelect: 'none', // prevent text selection as dragging
  zIndex: 2,
  position: 'relative',
  padding: '4px',
  marginLeft: 4,
  marginRight: 4,
  display: 'flex',
  justifyContent: 'center',
  '.image_box': {
    position: 'relative',
    width: '100%',
  },
  '.images': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '38px',
    overflow: 'hidden',
    borderRadius: '5px',
    width: '100%',
  },
  '.images > .image_item': {
    height: '100%',
    width: '50px',
    transform: 'none',
    borderRadius: 0,
    margin: 0,
    objectFit: 'cover',
  },
  '.clip_box': {
    border: `2px solid ${theme.palette.primary[500]}`,
    position: 'absolute',
    height: '110%',
    transform: 'translateY(-5%)',
    top: 0,
    pointerEvents: 'none',
  },
  '&[aria-disabled=true] .clip_box': {
    borderColor: theme.palette.neutral[500],
  },
  '.clip_box_des': {
    width: '1.3rem',
    height: '100%',
    backgroundColor: theme.palette.primary[500],
    position: 'absolute',
    zIndex: -1,
    display: 'flex',
    gap: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  '.clip_box_des:hover': {
    backgroundColor: theme.palette.neutral[700],
  },
  '.clip_box_des--first': {
    left: 0,
    width: TRIM_HANDLE_WIDTH,
    borderRadius: '5px 0 0 5px',
  },
  '.clip_box_des--last': {
    right: 0,
    flexFlow: 'row-reverse',
    width: TRIM_HANDLE_WIDTH,
    borderRadius: '0 5px 5px 0',
  },
  '.clip_box_des::before': {
    height: '50%',
  },
  '.clip_box_des::after': {
    height: '50%',
  },
  '.clip_shadow': {
    backgroundColor: theme.palette.neutral[600],
    opacity: 0.8,
    position: 'absolute',
    height: '100%',
    top: 0,
    borderRadius: '5px',
  },
}));

const TimeSlider = styled(Slider)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: 5,
  padding: '0 !important',
  '&:hover' : {
    backgroundColor: 'rgba(255,255,255,0.25)',
  },
  '& .MuiSlider-rail': {
    height: '14px',
    top: 'auto',
    bottom: 0,
    transform: 'none',
    borderRadius: 0,
    opacity: 0,
  },
  '& .MuiSlider-thumb': {
    transform: 'none',
    translate: '-50% ',
    top: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: 'none',
    '&.Mui-active': {
      cursor: 'grabbing',
    },
    '&.Mui-active,&:hover': {
      boxShadow: 'none',
    },
    '&:after': {
      boxShadow: 'none',
      borderStyle: 'solid',
      borderWidth: '8px 8px 0 8px',
      borderRadius: 0,
      borderColor: `${theme.palette.neutral[0]} transparent transparent transparent`,
      width: 0,
      height: 0,
      top: -4,
    },
    '&:before': {
      boxShadow: 'none',
      height: 38,
      width: 3,
      backgroundColor: theme.palette.neutral[0],
      top: 0,
    },
  },
  '& .MuiSlider-valueLabel': {
    ...theme.typography.tag,
    backgroundColor: theme.palette.neutral[0],
    color: theme.palette.primary[600],
    borderRadius: 5,
    padding: 5,
    top: -8,
  },
}));


type VideoPlayScrubberProps = {
  /** list of thumbnails to display in trim selector */
  thumbnails: readonly string[];
  /** number of images to load */
  thumbnailCount: number;
  /** whether it's loading */
  loading: boolean;
  /** total duration of video */
  durationInSeconds?: number;
  /** current time in seconds */
  currentInSeconds: number;
  /** updated current time is seconds */
  onUpdate?: (value: number) => void;
  /** callback when complete updating */
  onDone?: (value: number) => void;
  /** extra overlay */
  children?: React.ReactNode;
};

export default function VideoPlayScrubber({
  thumbnailCount,
  thumbnails,
  loading,
  durationInSeconds,
  currentInSeconds,
  onUpdate,
  onDone,
  children,
}: VideoPlayScrubberProps) {
  const disabled = isUndefined(durationInSeconds) || durationInSeconds === 0;
  const empty = thumbnails.length < thumbnailCount ? new Array(thumbnailCount - thumbnails.length).fill('') : [];

  return <TrimContainer aria-disabled={loading || disabled}>
    <div className="image_box">
      <div className="images">
        {take(thumbnails, thumbnailCount).map((imgURL, id) =>
          <img
            crossOrigin=""
            src={imgURL}
            className="image_item"
            alt={`sample_video_thumbnail_${id}`}
            key={id} />)}
        {empty.map((_str, index) =>
          <Skeleton
            key={index}
            className="image_item" />)}
      </div>
      <TimeSlider
        track={false}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => toTimestampString(value)}
        min={0}
        step={0.01}
        max={durationInSeconds}
        value={currentInSeconds}
        onChange={(_event, value) => {
          if (!isNumber(value)) return;
          onUpdate?.(value);
        }}
        onChangeCommitted={(_event, value)=> {
          if (!isNumber(value)) return;
          onDone?.(value);
        }}
      />
      {children}
    </div>
  </TrimContainer>;
}
