import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import Alert from 'common/components/alert/alert.component';
import img from 'common/images/illustration/woman on guitar.png';

/** 
 * Page To show the successful install of the extension
 */
export default function ExtensionInstalledPage() {
  return (
    <Box
      flexGrow={1}
      display='flex'
      flexDirection={'column'}
      alignItems='center'
      justifyContent='center'>
        <img src={img} alt={t`Success`}/>
      <Alert
        severity='success'
        title={t`Installed!`}
        description={t`You've successfully installed the Video by CoPilot AI Chrome Extension`}
      />
    </Box>
  );
}
