import { t } from '@lingui/macro';
import { Button, SvgIcon, Typography, TextField } from '@mui/material';
import { useState } from 'react';
import { isEmpty, isError } from 'lodash';
import AlertError from 'common/components/alert/alertError.component';
import { ReactComponent as PencilSvg } from 'common/images/icons/Utility/pencil.svg';
import Tooltip from 'common/components/tooltip/tooltip.component';


type TitleEditProps = {
  /** title */
  title?: string;
  /** placeholder to show */
  placeholder?: string;
  /** callback on update title */
  onUpdate: (title: string) => Promise<void>;
  /** if we use start icon or end icon */
  startIcon?: boolean;
  /** if we should show the pencil icon on hover */
  showIconOnHover?: boolean;
  /** the type to use */
  variant?: 'h1' | 'h5Semibold' | 'body1';
  /** the max width */
  maxWidth?: string;
};

export default function TitleEdit({
  title,
  placeholder,
  variant = 'h5Semibold',
  startIcon = true,
  showIconOnHover = false,
  maxWidth = '100%',
  onUpdate }: TitleEditProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>(title ?? '');
  const [error, setError] = useState<Error>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const handleUpdate = async () => {
    try {
      setIsSaving(true);
      await onUpdate(newTitle);
    } catch (err) {
      //revert back to old title if there is an error
      setNewTitle(title ?? '');
      if (isError(err)) {
        setError(err);
      }
    } finally {
      setIsEditing(false);
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setNewTitle(title ?? '');
    setIsEditing(false);
    setIsSaving(false);
  };

  const handleChange = (value: string) => {
    setNewTitle(value);
    setError(undefined);
  };

  const handleOpenTooltip = () => {
    setIsTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setIsTooltipOpen(false);
  };

  const baseIcon = <SvgIcon component={PencilSvg} inheritViewBox />;
  const icon = showIconOnHover ? baseIcon : <Tooltip
    enterDelay={100}
    leaveDelay={0}
    open={isTooltipOpen}
    color="dark"
    title={t`Edit title`}
    placement="bottom-start"
  >{baseIcon}</Tooltip>;

  return (
    <AlertError
      fullWidth
      error={error}
      onClose={() => {
        setError(undefined);
      }}
      flex={1}
    >
      {isEditing ? (
        <TextField
          sx={(theme) => ({
            '& .MuiInputBase-root': {
              ...theme.typography[variant],
              padding: '2px 7px',
              border: 0,
              backgroundColor: 'transparent',
              input: {
                textAlign: 'inherit',
              },
            },
          })}
          fullWidth
          autoFocus
          onFocus={({ currentTarget }) => {
            currentTarget.select();
          }}
          placeholder={placeholder}
          disabled={isSaving}
          value={newTitle}
          onChange={({ currentTarget }) => {
            handleChange(currentTarget.value);
          }}
          onBlur={() => void handleUpdate()}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              void handleUpdate();
            } else if (event.key === 'Escape') {
              handleCancel();
            }
          }}
        />
      ) : (
        <Button
          variant="text"
          color="inherit"
          onClick={() => {
            setIsEditing(true);
            handleCloseTooltip();
          }}
          onMouseEnter={handleOpenTooltip}
          onMouseLeave={handleCloseTooltip}
          startIcon={startIcon ? icon : undefined}
          endIcon={!startIcon ? icon : undefined}
          sx={{
            fontFamily: 'Basier Square',
            maxWidth,
            justifyContent: 'flex-start',
            flex: '1 1 0',
            ...(showIconOnHover ? {
              '& .MuiButton-iconSizeMedium': { display: 'none' },
              '&:hover .MuiButton-iconSizeMedium': { display: 'block' },
            } : {}),
          }}
        >
          <Typography noWrap textOverflow="ellipsis" variant={variant}>
            {isEmpty(newTitle) ? placeholder : newTitle}
          </Typography>
        </Button>
      )}
    </AlertError>
  );
}