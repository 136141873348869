import { styled, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FocusEvent } from 'react';

const SpacedToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .MuiToggleButtonGroup-grouped': {
    '&:not(:first-of-type)': {
      borderColor: `${theme.palette.primary[200]}`,
      '&.Mui-selected': {
        background: `${theme.palette.primary[100]}`,
        '&:hover': {
          background: `${theme.palette.primary[200]}`,
        },
      },
    },
    '&:first-of-type': {
      borderColor: `${theme.palette.primary[200]}`,
      '&.Mui-selected': {
        background: `${theme.palette.primary[100]}`,
        '&:hover': {
          background: `${theme.palette.primary[200]}`,
        },
      },
    },
  },
}));

export interface ToggleCardsProps {
  /** accessibility description about the card group */
  description: string;
  /** values for each of the card options */
  options: string[];
  /** state to store current selection, null when no selection */
  selected: string | null;
  /** callback for when selection changes, empty string for no selection */
  onSelect: (state: string) => void;
  /** callback for validating whether form field has been touched */
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

/**
 * Cards with exclusive selection
 * @returns JSX.Element
 */
function ToggleCards(props: ToggleCardsProps) {
  const { description, options, selected, onSelect, onBlur } = props;

  const handleSelect = (_event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    if (!isNil(newValue)) {
      onSelect(newValue);
    }
  };
  return (
    <SpacedToggleButtonGroup
      exclusive
      value={selected}
      onChange={handleSelect}
      aria-label={description}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
      onBlur={onBlur}
    >
      {options.map((value) => {
        return (
          <ToggleButton key={value} value={value}>
            <Typography>{value}</Typography>
          </ToggleButton>
        );
      })}
    </SpacedToggleButtonGroup>
  );
}

export { ToggleCards };
