import { t } from '@lingui/macro';
import { isUndefined } from 'lodash';

/**
 * Formats a duration in a human readable format
 * 0 or lower: Less than a sec
 * 1 - 59: XX sec
 * 60 +: XX min
 * 
 * @param duration the number of second
 * @returns {string}
 */
export function toDurationString(duration: number) {
  if (duration <= 1) return t`Less than a sec`;
  if (duration >= 60) return t`${Math.floor(duration / 60)} min`;
  return t`${Math.floor(duration)} sec`;
}


/**
 * Formats a duration in seconds like 01:00
 * To be used in display of video duration
 * @param timestamp the number of seconds
 * @returns {string}
 */
export function toTimestampString(timestamp: number) {
  return `${Math.floor(timestamp / 60)
    .toString()
    .padStart(2, '0')}:${Math.floor(timestamp % 60)
    .toString()
    .padStart(2, '0')}`;
}

/**
 * Formats a duration and if undefiled, returns '--:--'
 * @param inSeconds the number of seconds
 * @returns 
 */
export function toTime(inSeconds?: number) {
  return isUndefined(inSeconds) ? '--:--' :
    toTimestampString(inSeconds);
}


/**
 * Helper function to replace template strings with values
 * 
 * @param str the string ro replace
 * @param props the list of props to replace with values
 * @returns a modified string
 */
export function replaceProps(str: string, props: { [key: string]: string }) {
  let result = str;
  Object.keys(props).forEach(key => {
    result = result.replace(key, props[key]);
  });
  return result;
}