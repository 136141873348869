import { isEmpty, isUndefined } from 'lodash';
import { IVideoClipEffectProcessingStatus, IVideoClipEffectSettings, useVideoClipQuery } from 'data/_generated';
import { usePolling } from 'config/hooks/usePolling';
import useUserAppAccess from './useUserAppAccess';
import { useEffect, useState } from 'react';

type VideoEffectOnClipProps = {
  clipId: string;
  clipEffectSettings?: IVideoClipEffectSettings;
  /** Callback when successfully processed */
  onUpdate: (clipSetting: IVideoClipEffectSettings)=> void;
};

/**
 * Hook to abstract the logic of checking if a clip has a video effect applied to it.
 */
export default function useVideoEffectOnClip({
  clipId,
  clipEffectSettings,
  onUpdate,
}: VideoEffectOnClipProps) {
  const { hasAIAccess } = useUserAppAccess();
  const isVideoEffectEnabled = !isEmpty(clipId) && hasAIAccess;
  const hasVideoEffect = isVideoEffectEnabled && !isUndefined(clipEffectSettings);
  const [shouldPoll, setShouldPoll] = useState<boolean>(hasVideoEffect && clipEffectSettings.status === IVideoClipEffectProcessingStatus.Processing) ;
  const { data, stopPolling, startPolling } = useVideoClipQuery({
    variables: { videoClipId: clipId },
    fetchPolicy: 'no-cache',
    skip: !shouldPoll,
  });
  const effectSettings = data?.videoClip.effectSettings ?? clipEffectSettings;
  useEffect(() => {
    if (shouldPoll && !isUndefined(effectSettings) && effectSettings.status !== IVideoClipEffectProcessingStatus.Processing) {
      setShouldPoll(false);
      onUpdate(effectSettings);
    }
  }, [shouldPoll, effectSettings?.status]);

  usePolling({
    startPolling,
    stopPolling,
    shouldPoll,
  });
  return {
    isVideoEffectEnabled,
    hasVideoEffect,
    effectSettings,
  };
}
