import { Box, Button, CircularProgress, Grow, LinearProgress, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import { VideoDimensions, DEFAULT_ASPECT_RATIO } from 'common/constants/video';
import { isUndefined, isNil } from 'lodash';
import { UpdateClipInput, VIDEO_WRAPPER } from 'edit/const';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as CancelSvg } from 'common/images/icons/Utility/cancel.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { SPACER } from 'config/models/themeOptions';
import { LoadingButton } from '@mui/lab';
import AlertError from 'common/components/alert/alertError.component';
import { extractError, logError, trackError } from '../../common/utils/errors';
import { getTrimmedTime } from './utils';
import { VideoDetails } from './const';
import { IVideoClip } from 'data/_generated';
import useThumbnail from 'common/hooks/useThumbnails';
import VideoPlay from './videoPlay.component';
import VideoPlayTrimmer from './videoPlayTrimmer.component';

type VideoProcessProps = {
  video: VideoDetails | IVideoClip
  /** where trimming starts in %
   * @default 0
   */
  start?: number;
  /** where trimming ends in %
   * @default 100
   */
  end?: number;
  /** callback on confirm trim */
  onUpload: (videoClip: UpdateClipInput) => void | Promise<void>;
  /** callback on cancel trim */
  onCancel: (error?: Error) => void;
  /** label for confirm button */
  confirmLabel: string;
  /** label for cancel button */
  cancelLabel: string;
  /** whether to show trimming or not. When set to false, video gets uploaded as soon as it's done processing */
  showTrimming: boolean;
  /** clip uploading percent */
  uploadingPercent?: number;
};

const DEFAULT_TRIM_START = 0;
const DEFAULT_TRIM_END = 100;

/**
 * Component for trimming and processing video to upload
 */
export default function VideoProcess({
  video,
  onCancel,
  onUpload,
  start = DEFAULT_TRIM_START,
  end = DEFAULT_TRIM_END,
  confirmLabel,
  cancelLabel,
  showTrimming,
  uploadingPercent,
}: VideoProcessProps) {
  // prioritize metadata duration over duration passed in
  const [durationInSeconds, setDurationInSeconds] = useState<number>(video.durationInSeconds ?? 0);
  
  const { staticThumbnail, error: thumbError } = useThumbnail({
    options: { createStatic: true },
    videoSrc: video.location,
    startTimeInSec: getTrimmedTime(start, durationInSeconds),
  });
  const hasThumbnail = !isUndefined(staticThumbnail);

  const [loadingState, setLoadingState] = 
    useState<undefined | 'Processing' | 'Uploading'>(!showTrimming ? 'Processing' : undefined);
  const isLoading = !isUndefined(loadingState);
  const [error, setError] = useState<Error | undefined>();
  const [startTrim, setStartTrim] = useState(start);
  const [endTrim, setEndTrim] = useState(end);
  const [previewDimensions, setPreviewDimensions] = useState<VideoDimensions | undefined>();
  const videoRef = useRef<HTMLVideoElement>(null);

  const isValidDuration = isFinite(durationInSeconds) && durationInSeconds > 0;

  /**
   * Function that handles uploading video clip
   */
  const confirmUpload = async () => {
    if (!video.location) {
      trackError('video-process-no-location');
      return;
    }
    if (!hasThumbnail || !previewDimensions || !isValidDuration) return;
    console.time('confirmUpload');
    if (video.__typename === 'VideoDetails') { //new clip
      await uploadClip({
        __typename: 'NewVideoClip',
        videoHeight: previewDimensions.videoHeight,
        videoWidth: previewDimensions.videoWidth,
        source: video.source,
        location: video.location,
        durationInSeconds,
        thumbnail: staticThumbnail,
        start: startTrim,
        end: endTrim,
      });
    } else { // existing clip, not new lastStart and lastEnd
      await uploadClip({
        ...video,
        lastStart: startTrim,
        lastEnd: endTrim,
      });
    }
    console.timeEnd('confirmUpload');
  };

  const uploadClip = async (clipToUpload: UpdateClipInput) => {
    setLoadingState('Uploading');
    try {
      await onUpload(clipToUpload);
    } catch (e) {
      const uploadError = extractError(e);
      logError(uploadError);
      onCancel(uploadError);
    }
  };

  const aspectRatio = previewDimensions
    ? `${previewDimensions.videoWidth / previewDimensions.videoHeight}`
    : DEFAULT_ASPECT_RATIO;

  const handleStartTrimTime = (value: number) => {
    if (startTrim === value) return;
    setStartTrim(value);
    if (isNil(videoRef.current)) return;
    const startTime = getTrimmedTime(value, durationInSeconds);
    if (videoRef.current.currentTime < startTime) {
      videoRef.current.pause();
      videoRef.current.currentTime = startTime;
    }
  };

  const handleEndTrimTime = (value: number) => {
    if (endTrim === value) return;
    setEndTrim(value);
    if (isNil(videoRef.current)) return;
    const endTime = getTrimmedTime(value, durationInSeconds);
    if (videoRef.current.currentTime > endTime) {
      videoRef.current.pause();
      videoRef.current.currentTime = endTime;
    }
  };

  const theme = useTheme();

  // Preview Dimensions are ready and the first thumbnail
  const isReady = !isUndefined(previewDimensions) && hasThumbnail;

  /**
   * if no trimming is needed, upload the video clip as soon as it is ready.
   */
  useEffect(() => {
    if (!isUndefined(thumbError)) {
      onCancel(thumbError);
      return;
    }
    if (showTrimming || !isReady || loadingState === 'Uploading') return;
    void confirmUpload();
  }, [isReady, thumbError, showTrimming, loadingState, confirmUpload]);
  return (
    <>
      <VIDEO_WRAPPER
        sx={{
          aspectRatio,
          '& video': {
            filter: isLoading ? 'blur(5px)' : 'none',
          },
        }}
      >
        <VideoPlay
          ref={videoRef}
          expanded={showTrimming}
          src={video.location ?? ''}
          loading={isLoading}
          durationInSeconds={durationInSeconds === 0 ? undefined : durationInSeconds}
          onDimensionsChange={setPreviewDimensions}
          onDurationChange={setDurationInSeconds}
          onError={onCancel}
          startTime={getTrimmedTime(startTrim, durationInSeconds)}
          endTime={getTrimmedTime(endTrim, durationInSeconds)}
        >
          {showTrimming && (
            <VideoPlayTrimmer
              startValue={startTrim}
              endValue={endTrim}
              handleUpdateStart={handleStartTrimTime}
              handleUpdateEnd={handleEndTrimTime}
              loading={isLoading}
              durationInSeconds={durationInSeconds}
            />
          )}
        </VideoPlay>
        {isLoading && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              padding: `${theme.spacing(SPACER.S)} 0`,
            }}
          >
            {/*Empty div used for layout*/}
            <Box />
            <Box display="flex" flexDirection="column" sx={{ gap: theme.spacing(SPACER.S) }}>
              <CircularProgress sx={{ margin: '0 auto' }} />
              <Typography variant="h6" component="span" color={theme.palette.primary.main}>
                {`${loadingState}... `}
                {!isUndefined(uploadingPercent) && `${uploadingPercent}%`}
              </Typography>
              {!isUndefined(uploadingPercent) &&
                <LinearProgress
                  value={uploadingPercent}
                  color="primary"
                  sx={{ width: '36%', alignSelf: 'center' }}
                  variant={uploadingPercent === 100 ? 'indeterminate' : 'determinate'}
                />
              }
            </Box>
            <Box>
              {loadingState === 'Processing' && (
                <Button variant="link" sx={{ color: theme.palette.common.white }} onClick={() => onCancel()}>
                  Cancel
                </Button>
              )}
            </Box>
          </Box>
        )}
      </VIDEO_WRAPPER>
      <Grow in={showTrimming}>
        <Stack direction="row" spacing={4} justifyContent="center" mt={theme.spacing(SPACER.S)}>
          <Button
            startIcon={<SvgIcon inheritViewBox component={CancelSvg} />}
            variant="contained"
            color="secondary"
            onClick={() => onCancel()}
            disabled={isLoading}
          >
            {cancelLabel}
          </Button>
          <AlertError error={error} onClose={() => setError(undefined)}>
            <LoadingButton
              sx={{ animation: isReady && !isLoading ? 'wiggle 4s infinite' : undefined }}
              loading={isLoading}
              disabled={!isReady}
              variant="contained"
              color={error ? 'error' : 'primary'}
              startIcon={<SvgIcon inheritViewBox component={ConfirmSvg} />}
              onClick={() => void confirmUpload()}
            >
              <span>{confirmLabel}</span>
            </LoadingButton>
          </AlertError>
        </Stack>
      </Grow>
    </>
  );
}
