import { extractError } from 'common/utils/errors';
import { useCreateCheckoutSessionMutation } from 'data/_generated';
import { first, isUndefined } from 'lodash';
import { useState } from 'react';

/** legacy default plan before pricing v2 */
const LEGACY_DEFAULT_PLAN = 'plan:pro@1';

type CreateCheckoutProps = {
  /** the plan name that the user will be subscribed to, if undefined will use default plan */
  planName?: string;
  /** url to redirect to upon successful payment  */
  successUrl: string;
  /** url to redirect to upon cancelling payment */
  cancelUrl: string;
  /** the number of days for the trial */
  trialDays?: number;
};
/**
 * hook for creating stripe checkout session
 * @returns
 */
export default function useCreateCheckout({
  planName,
  successUrl,
  cancelUrl,
  trialDays,
}: CreateCheckoutProps) {
  const [error, setError] = useState<Error | undefined>();
  const [createCheckout, { loading: isCreatingCheckout }] = useCreateCheckoutSessionMutation({
    variables: {
      planName: planName ?? LEGACY_DEFAULT_PLAN,
      successUrl,
      cancelUrl,
      trialDays,
    },
  });
  const handleCreateCheckout = async () => {
    setError(undefined); //reset error state
    try {
      const result = await createCheckout();
      const checkoutURL = result.data?.createCheckoutSession;
      if (!isUndefined(checkoutURL)) {
        window.location.assign(checkoutURL);
      } else {
        setError(first(result.errors) ?? new Error("We couldn't create a checkout session"));
      }
    } catch (e) {
      setError(extractError(e));
    }
  };

  return {
    handleCreateCheckout,
    isCreatingCheckout,
    error,
  };
}
