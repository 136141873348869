import { useFeatureFlag, useNumberFeatureFlag } from 'config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames, NumberFeatureFlagNames } from 'config/models/featureFlags';
import { IPaymentMethod, IUserPlanInfo, Maybe, useUserSubscriptionDetailsQuery } from 'data/_generated';
import { isNil, isUndefined } from 'lodash';
import useActiveAccount from '../../config/hooks/useActiveAccount';

type UserAppAccessType = {
  /** if user has app access */
  hasAppAccess?: boolean;
  /** if user can access billing */
  hasBillingAccess?: boolean;
  /** if user has cancelled paid plan */
  hasCancelledPaidPlan?: boolean;
  /** last saved payment method */
  paymentMethod?: IPaymentMethod | null;
  /** if the user has AI access */
  hasAIAccess: boolean;
  /** max personalized intros allowed for user, -1 indicates unlimited intros */
  maxPersonalizedIntros: number;
  /** max clip duration allowed for user */
  maxClipDurationSeconds: number;
  /** if subscription details is fetching */
  loading: boolean;
};

export default function useUserAppAccess(): UserAppAccessType {
  const { accountId } = useActiveAccount();
  const skip = isUndefined(accountId);
  const { isEnabled: hasBillingAccess } = useFeatureFlag(BooleanFeatureFlagNames.monetization);
  const { data: subscriptionDetails, loading } = useUserSubscriptionDetailsQuery({ skip });
  const userSubscriptionDetails = subscriptionDetails?.userSubscriptionDetails;

  const {
    hasAIAccess,
    maxPersonalizedIntros,
    maxClipDurationSeconds,
  } = useGetFeatureAccess(userSubscriptionDetails?.currentPlan);

  return {
    hasAppAccess: userSubscriptionDetails?.hasAppAccess || !hasBillingAccess,
    hasAIAccess,
    hasBillingAccess,
    hasCancelledPaidPlan: userSubscriptionDetails?.hasCancelledPaidPlan,
    paymentMethod: userSubscriptionDetails?.paymentMethod,
    maxPersonalizedIntros,
    maxClipDurationSeconds,
    loading,
  };
}

function hasAiFeatureAccess(
  aiFeatureFlagEnabled: boolean,
  monetizationFlagEnabled: boolean,
  optInTrialEnabled: boolean,
  planInfo: Maybe<IUserPlanInfo>): boolean {
  if (aiFeatureFlagEnabled) return true;
  if (!optInTrialEnabled) return false;
  //This covers legacy users that were created before pricing was implemented
  if (!monetizationFlagEnabled) return false;
  //No subscribed plan or trial
  if (isNil(planInfo)) return false;
  //Trial users get ai features
  if (!isNil(planInfo.trialStartDate)) return true;
  // plan:pro@1, plan:pro@2, plan:proAnnual@2
  if (isProPlan(planInfo.planName) || isGetStartedPlan(planInfo.planName)) return true;
  
  return false;
}

type PlanFeatures = Readonly<{
  maxClipDurationSeconds: number;
  maxPersonalizedIntros: number;
}>;

// plan:basic@1, plan:basicAnnual@1
const BASIC_PLAN_FEATURES: PlanFeatures = {
  maxClipDurationSeconds: 120,
  maxPersonalizedIntros: 5,
};
//plan:pro@1
const GET_STARTED_FEATURES: PlanFeatures = {
  maxClipDurationSeconds: 120,
  maxPersonalizedIntros: 5,
};
// plan:pro@2, plan:proAnnual@2
const PRO_PLAN_FEATURES: PlanFeatures = {
  maxClipDurationSeconds: 300,
  maxPersonalizedIntros: 50,
};

function useGetFeatureAccess(currentPlan: Maybe<IUserPlanInfo>): Pick<UserAppAccessType, 'hasAIAccess' | 'maxPersonalizedIntros' | 'maxClipDurationSeconds'> {
  const optInPricingEnabled = useNumberFeatureFlag(NumberFeatureFlagNames.trialDays).number !== -1;
  const monetizationEnabled = useFeatureFlag(BooleanFeatureFlagNames.monetization).isEnabled;
  const aiFeatureFlagEnabled = useFeatureFlag(BooleanFeatureFlagNames.aiFeatures).isEnabled;
  const maxTargetsFeatureFlag = useNumberFeatureFlag(NumberFeatureFlagNames.personalizedIntroMax).number;
  const maxClipDurationFeatureFlag = useNumberFeatureFlag(NumberFeatureFlagNames.videoRecordLength).number;

  const hasBasicPlan = isBasicPlan(currentPlan?.planName);
  const hasGetStartedPlan = isGetStartedPlan(currentPlan?.planName);
  const hasProPlan = isProPlan(currentPlan?.planName);
  
  //Opt in pricing currently used to toggle new pricing flow as well as AI features
  const hasAIAccess = hasAiFeatureAccess(aiFeatureFlagEnabled, monetizationEnabled, optInPricingEnabled, currentPlan);
  
  //Values defined for plan takes precedent otherwise fallback to using feature flag values
  const { maxClipDurationSeconds, maxPersonalizedIntros } = hasBasicPlan ? BASIC_PLAN_FEATURES : 
    hasProPlan ? PRO_PLAN_FEATURES : 
      hasGetStartedPlan ? GET_STARTED_FEATURES :
        { maxClipDurationSeconds: maxClipDurationFeatureFlag, maxPersonalizedIntros: maxTargetsFeatureFlag };
  
  return {
    hasAIAccess,
    maxPersonalizedIntros,
    maxClipDurationSeconds,
  };
}

function isProPlan(planName: Maybe<string>): boolean {
  return !isNil(planName) && (planName.includes('pro@2') || planName.includes('proAnnual@2'));
}

function isGetStartedPlan(planName: Maybe<string>): boolean {
  return !isNil(planName) && planName.includes('pro@1');
}

function isBasicPlan(planName: Maybe<string>): boolean {
  return !isNil(planName) && planName.includes('basic');
}

