import { t } from '@lingui/macro';
import { Card, CardHeader, Typography } from '@mui/material';
import { FetchedVideoProjectProps } from 'common/components/fetchProject.component';
import {
  ITargetInput,
  useUpdateVideoProjectTargetsMutation,
  usePublishTargetVideoByTargetIdMutation, useDisableTargetsMutation,
} from 'data/_generated';
import { getTargetTotalDuration } from 'dashboard/helper';
import RecipientList from 'dashboard/components/recipientList.component';
import AddTarget from 'common/components/addTarget.component';
import { throwError } from 'common/utils/errors';
import PersonalizedIntroLimit from 'edit/components/targetCreation/personalizedIntroLimit.component';
import usePersonalizedIntroMax from 'common/hooks/usePersonalizedIntroMax';
import ProjectSummaryCard from './projectSummaryCard.component';
import useTargetMetrics from 'dashboard/hook/useTargetMetrics';

type ShareViewProps = {
  /** the title for this section */
  title?: string;
  /** can we operate on this view? */
  showActions?: boolean;
  /** show the analytics */
  showAnalytics?: boolean;
  /** show the thumbnail for preview */
  enablePreview?: boolean;
} & FetchedVideoProjectProps;

/**
 * Root dashboard page displaying all projects and analytics
 */
export default function ShareView({ 
  data, 
  showActions = true,
  showAnalytics = true,
  enablePreview = false,
  title = t`Your recipient list` }: ShareViewProps) {
  const enabledTargets = data.targets.filter((target) =>!target.isDisabled);
  const { maxTargets, introMaxEnabled } = usePersonalizedIntroMax();
 
  const [updateTargets] = useUpdateVideoProjectTargetsMutation();
  const [disableTargets] = useDisableTargetsMutation();
  const [publishTargetVideo] = usePublishTargetVideoByTargetIdMutation();

  const targetClipDurations = getTargetTotalDuration(enabledTargets, data.timeline);

  const targetMetrics = useTargetMetrics(enabledTargets.map((target) => target.id), showAnalytics);
  const onDeleteTarget = async (targetId: string) => {
    await disableTargets({
      variables: {
        projectId: data.id,
        data: {
          targetIds: [targetId],
        },
      },
    });
    await publishTargetVideo({
      variables: {
        input: {
          projectId: data.id,
          targetId: targetId,
        },
      },
      //TODO for every VideoProject in cache this will trigger a request,
      // we really only care about refetching the single project that was published
      refetchQueries: ['VideoProject'],
      awaitRefetchQueries: true,
    });
  };

  const onUpdateTarget = async (updatedTarget: ITargetInput) => {
    const updatedTargets: ITargetInput[] = data.targets.map((target) => {
      if (target.id === updatedTarget.id) {
        return updatedTarget;
      } else {
        return {
          email: target.email,
          id: target.id,
          name: target.name,
          title: target.title,
        };
      }
    });
    await updateTargets({
      variables: {
        updateVideoProjectTargetsId: data.id,
        data: {
          targets: updatedTargets,
        },
      },
    });
    await publishTargetVideo({
      variables: {
        input: {
          projectId: data.id,
          targetId: updatedTarget.id ?? throwError('Target has no id'),
        },
      },
      refetchQueries: ['VideoProject'],
      awaitRefetchQueries: true,
    });
  };
  return (<>
    {showAnalytics && <ProjectSummaryCard id={data.id} />}
    <Card variant="outlined">
      <CardHeader title={
        <>
          <Typography>{title}</Typography>
          {introMaxEnabled && <PersonalizedIntroLimit current={enabledTargets.length} max={maxTargets} />}
        </>
      }
        sx={{ '& .MuiCardHeader-title': { display: 'flex', justifyContent: 'space-between' } }}>
      </CardHeader>
      <RecipientList
        targets={enabledTargets}
        targetMetrics={targetMetrics}
        targetDurations={targetClipDurations}
        landingPageTitleTemplate={data.landingPageTitleTemplate}
        thumbnailLocation={data.thumbnail?.location}
        showShareActions={showActions}
        enablePreview={enablePreview}
        onUpdateTarget={onUpdateTarget}
        onDeleteTarget={onDeleteTarget}
      />
      {showActions &&
        <AddTarget targets={data.targets} projectId={data.id} maxTargets={introMaxEnabled ? maxTargets : undefined} />
      }
    </Card>
  </>
  );
}