import { IUsageInfoFragment, useUpdateUserUsageInfoMutation, useUserUsageInfoQuery } from 'data/_generated';
import { isUndefined } from 'lodash';
import { useState } from 'react';

type ChecklistData = {
  hasClips?: boolean;
  hasPublished?: boolean;
  hasCopied?: boolean;
  hasPersonal?: boolean;
  hasScreenshared?: boolean;
  hasCTA?: boolean;
  firstProgress?: number;
  secondProgress?: number;
};

type ChecklistDataProps = {
  /**  Callback to show the checklist */
  showChecklist: () => void;
};

/**
 * Use hook to manage data based off the current usage
 * @param param.showChecklist Callback to show the checklist if there are changes or it's never been shown
 */
export function useChecklistData({ showChecklist }: ChecklistDataProps): ChecklistData {
  // Helper to get the data from a fragment
  const get = (data?: IUsageInfoFragment | null): ChecklistData => {
    const hasClips = (data?.allClipCount ?? 0) > 0;
    const hasPublished = (data?.publishedProjectCount ?? 0) > 0;
    const hasCopied = (data?.hasCopiedVideoLink ?? false);
    const hasPersonal = (data?.personalizedClipCount ?? 0) > 0;
    const hasScreenshared = (data?.screenClipCount ?? 0) > 0;
    const hasCTA = (data?.ctaCount ?? 0) > 0;
    const firstSteps = [true, hasClips, hasCopied];
    const secondSteps = [hasPersonal, hasScreenshared, hasCTA];
    const firstProgress = Math.round((firstSteps.filter(c => c).length / firstSteps.length) * 100);
    const secondProgress = Math.round((secondSteps.filter(c => c).length / secondSteps.length) * 100);

    return {
      hasClips,
      hasPublished,
      hasCopied,
      hasPersonal,
      hasScreenshared,
      hasCTA,
      firstProgress,
      secondProgress,
    };
  };
  const [current, setCurrent] = useState<ChecklistData>();
  const [updateToSeen] = useUpdateUserUsageInfoMutation({
    variables: { data: { hasSeenOnboardingVideo: true } },
    refetchQueries: ['UserUsageInfo'],
  });
  useUserUsageInfoQuery({
    /**
     * This is magical in that any times that anytime we do a
     * refetch this will check to see if there are any changes
     * and show the checklist when there are changes
     */
    onCompleted: (data) => {
      const newData = get(data.userUsageInfo);
      // Check if user has seen this onboarding checklist yet
      // (note we're using an old prop hasSeenOnboardingVideo)
      if (data.userUsageInfo?.hasSeenOnboardingVideo === false) {
        void updateToSeen();
        const isFirstComplete = (newData.firstProgress ?? 0) === 100;
        if (!isFirstComplete) {
          // show the checklist if first part is not complete
          showChecklist();
        }
      }
      let hasChanged = false;
      if (!isUndefined(current)) {
        hasChanged = current.firstProgress !== newData.firstProgress ||
          current.secondProgress !== newData.secondProgress;
        if (hasChanged) {
          showChecklist();
        }
      }
      // If this has changes update it 100ms after to make that state change clear
      setTimeout(() => {
        setCurrent(newData);
      }, hasChanged ? 100 : 0);
    },
  });
  return current ?? {};
}