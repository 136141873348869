import { Button, styled } from '@mui/material';
export default styled(Button)({
  '& .MuiButton-startIcon': {
    marginRight: 0,
    marginLeft: 0,
  },
  marginLeft: 8,
  padding: 8,
  minWidth: 0,
});
