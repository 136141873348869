import { ButtonBase, Box, Typography, CircularProgress, Skeleton, styled } from '@mui/material';
import { ReactNode } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ToggleButtonBase = styled(ButtonBase)(({ theme }) => ({
  borderRadius: 8,
  background: theme.palette.background.paper,
  flexDirection: 'column',
  alignItems: 'stretch',
  textAlign: 'left',
  padding: 8,
  border: '1px solid',
  width: '100%',
}));

type ToggleButtonProps<T> = {
  /** Callback */
  onClick: T;
  /** Label to show */
  label: string;
  /** Whether this is the current active choice */
  isActive: boolean;
  /** If there is a Preview to Show */
  previewUrl?: string | null;
  /** If this should be disabled */
  isDisabled: boolean;
  /** If this is a loading state */
  isLoading?: boolean;
  /** The aspect ratio of the image */
  aspectRatio?: string;
  /** placeholder when nothing is selected */
  children?: ReactNode;
};

/**
 * Button to toggle between different thumbnails
 * @returns
 */
export default function ToggleButton({
  isLoading,
  isDisabled,
  onClick,
  isActive,
  label,
  previewUrl,
  aspectRatio = '16/9',
  children,
}: ToggleButtonProps<() => void>) {
  return (
    <ToggleButtonBase
      sx={{
        height: '100%',
        color: (theme) => (isActive ? theme.palette.primary.main : theme.palette.grey[500]),
      }}
      onClick={onClick}
      disabled={isLoading || isDisabled}
    >
      <Box display="flex">
        <Typography sx={{ flexGrow: 1 }}>{label}</Typography>
        {isLoading ? (
          <CircularProgress size="21px" color="primary" />
        ) : (
          <CheckCircleIcon color={isActive ? 'primary' : 'disabled'} />
        )}
      </Box>
      {previewUrl ? (
        <img
          style={{
            aspectRatio,
            borderRadius: 5,
            objectFit: 'cover',
            overflow: 'hidden',
            flexGrow: 1,
            marginTop: 4,
          }}
          className='crisp-rendering'
          crossOrigin=""
          src={previewUrl}
          alt={label}
        />
      ) : children ? (
        <Box
          sx={(theme) => ({
            borderRadius: '5px',
            background: theme.palette.neutral[100],
            color: theme.palette.neutral[400],
            aspectRatio,
            flexGrow: 1,
            marginTop: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          })}
        >
          {children}
        </Box>
      ) : (
        <Skeleton animation={isLoading ? 'wave' : false} sx={{ aspectRatio, flexGrow: 1, transform: 'none' }} />
      )}
    </ToggleButtonBase>
  );
}