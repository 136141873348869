import { t } from '@lingui/macro';
import { Collapse } from '@mui/material';
import { trackError } from 'common/utils/errors';
import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

type ErrorDetailsProps = {
  /** The error to show */
  error: Error;
};

/**
 * Component to show/hide the error details
 * @returns {JSX.Element}
 */
export default function ErrorDetails({ error }: ErrorDetailsProps) {
  useEffectOnce(() => {
    trackError('exception-unexpected', {
      'name': error.name,
      'message': error.message,
      'stack': error.stack,
    });
  });
  const [showDetails, setShowDetails] = useState<boolean>(false);
  return <> <a color='inherit' href='#' onClick={(event) => {
    setShowDetails(!showDetails);
    event.preventDefault();
    return false;
  }}>{showDetails ? t`Hide Details` : t`Show Details`}</a>
    <Collapse in={showDetails}>
      {error.stack ? <pre>{error.stack}</pre> : error.message}
    </Collapse>
  </>;
}