import { t } from '@lingui/macro';
import { Button, SvgIcon } from '@mui/material';
import {
  useUpdateVideoProjectTargetsMutation,
  usePublishTargetVideoByTargetIdMutation,
  IVideoProjectShareTargetFragment,
  ITargetInput,
  useUpdateTimelineVideoProjectMutation,
  useVideoTimelineLazyQuery,
  IPersonalizedClipSlot,
  IPersonalizedVideoClip,
  IVideoClipDetailFragment,
} from 'data/_generated';
import { useState } from 'react';
import AddPublishTargetModal from 'common/components/addTargetModal.component';
import { ReactComponent as AddSvg } from 'common/images/icons/Utility/add.svg';
import { first, isUndefined } from 'lodash';
import { isPersonalizedClipSlot, DEFAULT_PERSONALIZED_CLIP_SLOT, toInput } from 'edit/const';
import { trackEvent } from 'common/utils/errors';

type AddTargetProps = {
  /** the project id */
  projectId: string;
  /** the list of targets */
  targets: IVideoProjectShareTargetFragment[];
  /** max number of targets */
  maxTargets?: number;
};

/** Component  to show the button to Add Targets to a Project */
export default function AddTarget({ projectId, targets, maxTargets }: AddTargetProps) {
  const [updateTargets] = useUpdateVideoProjectTargetsMutation();
  const [publishTargetVideo] = usePublishTargetVideoByTargetIdMutation();

  const [getTimeline] = useVideoTimelineLazyQuery({ variables: { id: projectId } });
  const [updateTimeline] = useUpdateTimelineVideoProjectMutation({ refetchQueries: ['UserUsageInfo'] });
  const [isAddTargetModalOpen, setIsAddTargetModalOpen] = useState(false);
  const getUpdatedTimeline = async (videoClip: IVideoClipDetailFragment, targetId: string) => {
    const { data: project } = await getTimeline();
    const personalSlot = project?.videoProject.timeline.filter(isPersonalizedClipSlot)[0];
    const nonPersonalSlots = project?.videoProject.timeline.filter((slot) => !isPersonalizedClipSlot(slot)) ?? [];
    const withRemovedPersonalizedClip: IPersonalizedVideoClip[] =
      personalSlot?.personalizedClips
        ?.filter((clip) => clip.targetId !== targetId) ?? [];
    const updatedPersonalizedClips: IPersonalizedVideoClip[] = [{
      videoClip: {
        id: videoClip.id,
        dateCreated: '',
        lastUpdated: '',
        ownerId: '',
      }, 
      targetId: targetId,
      start: videoClip.lastStart,
      end: videoClip.lastEnd,
    }, ...withRemovedPersonalizedClip];

    //If Personalized slot hasn't been added to timeline yet, insert new default slot
    const personalizedSlot = isUndefined(personalSlot) ? DEFAULT_PERSONALIZED_CLIP_SLOT : personalSlot;
    const personalizedSlotToUpdate: IPersonalizedClipSlot = {
      ...personalizedSlot,
      personalizedClips: updatedPersonalizedClips,
    };
    return ([
      personalizedSlotToUpdate,
      ...nonPersonalSlots,
    ]).map(toInput);
  };
  const onAddPublishTarget = async (targetInput: ITargetInput, personalizedClip?: IVideoClipDetailFragment) => {
    const convertedTargets: ITargetInput[] = targets.map(({ name, email, id, title }) => ({ name, email, id, title }));
    const updatedTargets = [...convertedTargets, targetInput];
    const updateTargetsResult = await updateTargets({
      variables: {
        updateVideoProjectTargetsId: projectId,
        data: {
          targets: updatedTargets,
        },
      },
    });
    const existingTargetIds = new Set(targets.map(({ id }) => id));
    const newTargets = updateTargetsResult.data?.updateVideoProjectTargets.targets.filter((target) => !existingTargetIds.has(target.id)) ?? [];
    const targetToPublish = first(newTargets);
    if (!targetToPublish?.id) throw new Error('Expected one additional target');

    trackEvent('new-recipient', {
      intro: !isUndefined(personalizedClip?.id),
      added: 1,
      count: updatedTargets.length,
    });
    if (personalizedClip?.id) {
      const updatedTimeline = await getUpdatedTimeline(personalizedClip, targetToPublish.id);
      await updateTimeline({
        variables: {
          id: projectId,
          data: { timeline: updatedTimeline },
        },
      });
    }

    await publishTargetVideo({
      variables: {
        input: {
          projectId,
          targetId: targetToPublish.id,
        },
      },
      refetchQueries: ['VideoProject'],
      awaitRefetchQueries: true,
    });
  };
  const enabledTargets = targets.filter((target) => !target.isDisabled);

  return <>
    <Button
      fullWidth
      variant="contained"
      color={'secondary'}
      startIcon={<SvgIcon inheritViewBox component={AddSvg} />}
      onClick={() => {
        trackEvent('share-add-recipient');
        setIsAddTargetModalOpen(true);
      }}
      sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      disabled={!isUndefined(maxTargets) && enabledTargets.length >= maxTargets}>
      {t`Add one more recipient`}
    </Button>
    {isAddTargetModalOpen &&
      <AddPublishTargetModal
        onClose={() => setIsAddTargetModalOpen(false)}
        onPublishConfirm={onAddPublishTarget} />
    }
  </>;
}