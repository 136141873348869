import { Box, SxProps, Theme, Toolbar } from '@mui/material';

type SpacerBarProps = {
  /** custom styling */
  customStyling?: SxProps<Theme>;
};
/**
 * The Spacer Bar is used to add space to the layout for a fixed position toolbar
 * This is important for pages that used floating elements
 * @returns the spacer bar
 */
export default function SpacerBar({ customStyling }: SpacerBarProps) {
  return (
    <Box sx={{ border: '1px solid transparent' }}>
      <Toolbar sx={customStyling} />
    </Box>
  );
}
