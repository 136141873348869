import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import FetchProjects from '../dashboard/components/fetchProjects.component';
import { Chip, Card, CardActions, CardContent, CardMedia, TextField, Typography, Skeleton, Button } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { i18n } from '@lingui/core';
import { MEDIUM_DATE_FORMAT } from '../common/constants/date';
import { IVideoSummaryFragment } from '../data/_generated';
import Alert from '../common/components/alert/alert.component';
import { useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { HELP_CENTER_URL } from '../common/components/userButton/userButton.component';
import { PALETTE_COLOR } from '../config/models/themeOptions';
import ShareActions from '../share/components/shareActionsExt.component';

function ProjectListing(listing: IVideoSummaryFragment[], search: string,
  clearSearch: ()=> void ) {
  const loweredSearch = search.toLowerCase();
  const data =
      listing
        .filter(c=> !isNil(c.publishStatus?.status))
        .filter(c=> {
          if (isEmpty(search)) return true;
          const allTargets = c.targets.map(m=>
            `-${m.title?.toLowerCase() ?? ''}-${m.name.toLowerCase()}`).join(',');
          const inTitle = c.title?.toLowerCase().includes(loweredSearch);
          const inTargets = allTargets.includes(loweredSearch);
          return inTitle || inTargets;
        });

  return <>
        {data.length > 0 ?
          data.map(d=>
              <Card key={d.id} variant='outlined' sx={{ width:'100%', mb: 1 }}>
                  <Box display='flex' >
                <CardContent sx={{ flex: 1, pb: 0, pl: 2 }}>
                    <Typography component='a'
                        href={`/share/${d.id}`} target='_blank'
                        variant="h5">
                        {d.title}
                    </Typography>&nbsp;
                    <Typography variant='caption'>{i18n.date(d.dateCreated, MEDIUM_DATE_FORMAT)}</Typography>
                </CardContent>
                      <CardMedia
                          component="img"
                          sx={{ width: 80, height: 60 }}
                          image={d.thumbnail?.location ?? ''}
                          alt={t`${d.title} thumbnail`}
                      >
                      </CardMedia>
                  </Box>
                  <CardActions sx={{ flexWrap: 'wrap' }}>
                      {d.targets.map(m=>
                          <Box key={m.id} sx={{
                            borderRadius: '5px',
                            background: PALETTE_COLOR.neutral[100] }}>
                          <Chip label={isEmpty(m.name) ? (m.title ?? m.name) : m.name} variant={'badge'} />
                              <ShareActions
                                  id={m.id}
                                  thumbnail={d.thumbnail?.location ?? undefined} ></ShareActions>
                      </Box>)}
                  </CardActions>
            </Card>) : (listing.length === 0 ?
            <Alert
                severity={'info'}
                title={t`No videos yet`}
                description={t`You have no videos yet. 
                Record a new video to get started!`}></Alert> :
            (isEmpty(search) ?  <Alert
                    severity={'info'}
                    title={t`No published videos yet`}
                    description={t`Publish some of your videos`}></Alert> :
                    <Alert
                        severity={'info'}
                        onAction={clearSearch}
                    actionLabel={t`Clear search`}
                    title={t`No videos with your search`}
                    description={<>{t`We couldn't find a project or recipient with `}<Chip label={search} variant={'tag'} />
                        {t`. Try clearing your search filter`}</>}></Alert>))
            }
    </>;
}

function LoadingCard() {
  return <Card variant="outlined" sx={{ display: 'flex', width:'100%', mb: 1 }}>
        <CardContent sx={{ width: '100%', pb: 0, pl: 2 }}>
            <Skeleton variant='text' width={200} />
            <Skeleton variant='text' width={100} />
        </CardContent>
        <Skeleton variant='rectangular' width={100} height={60} />
    </Card>;
}

/** 
 * Page To show listing of all Projects and links
 */
export default function ExtensionPage() {
  const [search, setSearch] = useState<string>('');
  return <Box
          flexGrow={1}
          display='flex'
          flexDirection={'column'}
          alignItems='center'
          p={1}
          >
          <Box display='flex' width='100%' mb={1}>
          <TextField
              placeholder={t`Search by Project or Recipient`}
              fullWidth
              value={search}
             onChange={(e)=> setSearch(e.target.value)} />
              <Button
                  variant="outlined"
                  sx={{
                    '& .MuiButton-startIcon': { margin: 0 },
                    marginLeft: 1,
                    padding: 2,
                    minWidth: 0,
                  }}
                  title={t`Help`}
                  color="inherit"
                  target="_blank"
                  href={HELP_CENTER_URL} >
                  <HelpIcon/>
              </Button>
          </Box>
          <FetchProjects  render={(data)=>
            ProjectListing(data, search, ()=> setSearch(''))} ><>
              <LoadingCard/><LoadingCard/><LoadingCard/></>
          </FetchProjects>
    </Box>;
}
