import useUserAppAccess from './useUserAppAccess';

export default function usePersonalizedIntroMax() {
  const { maxPersonalizedIntros } = useUserAppAccess();

  return {
    maxTargets: maxPersonalizedIntros,
    introMaxEnabled: hasMaxIntros(maxPersonalizedIntros),
  };
}

/** Helper to determine if personalizedIntroMax feature flag is enabled.
 *  if maxTargets is -1, feature is considered disenabled.
 */
function hasMaxIntros(maxTargets: number): boolean {
  return maxTargets !== -1;
}
