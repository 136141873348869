import { getAccountData } from 'common/utils/util';
import { useAuth } from 'config/hooks/useAuth';
import { useUserInfoLazyQuery, useUserUsageInfoLazyQuery } from 'data/_generated';
import { isNil, omit } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';


type  UseUserDetailsProps = {
  callback: (userId: string, userDetails: Record<string, unknown>) => void;
  isEnabled?: boolean
};

/**
 * Helper use hook to get user details for mixpanel and june tracking about user details
 * removed __typename from the data
 * @returns { userId: string, userDetails: any }
 */
export default function useUserDetails({ isEnabled = true, callback } : UseUserDetailsProps) {
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const { name, email } =  isNil(account) ? { name: undefined, email: undefined } : getAccountData(account);

  const [infoQuery] = useUserInfoLazyQuery();
  const [usageQuery] = useUserUsageInfoLazyQuery();

  const getUserDetails = async (userId: string)=> {
    const [info, usage] = await Promise.all([infoQuery(), usageQuery()]);
    callback(userId, {
      name,
      email,
      ...omit(info.data?.userInfo, '__typename'),
      ...omit(usage.data?.userUsageInfo, '__typename'),
    });
  };

  useEffectOnce(()=> {
    if (isNil(account) || !isEnabled) return;
    callback(account.localAccountId, { name, email });
    void getUserDetails(account.localAccountId);
  });

  return {
    name,
    email,
    userId: account?.localAccountId,
  };
}