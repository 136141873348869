import { ReactComponent as MastercardSvg } from 'common/images/icons/SocialMedia/mastercard.svg';
import { ReactComponent as VisaSvg } from 'common/images/icons/SocialMedia/visa logo.svg';
import { ReactComponent as AmexSvg } from 'common/images/icons/SocialMedia/amex.svg';
import { PaymentMethod } from '@stripe/stripe-js';

/**
 * Name of common payment card brand
 * Naming convention taken from Stripe
 * https://stripe.com/docs/testing?testing-method=payment-methods#cards
 */
export const CardBrandDisplayNames = {
  visa: 'Visa',
  mastercard: 'Mastercard',
  amex: 'Amex',
  discover: 'Discover',
  diners: 'Diners Club',
  jcb: 'JCB',
  unionpay: 'UnionPay',
} as const;

/**
 * Get card brand icon
 * @param cardBrand name of card brand
 */
export function getCardBrandIcon(cardBrand: PaymentMethod.Card['brand']) {
  switch (cardBrand) {
    case 'visa':
      return VisaSvg;
    case 'mastercard':
      return MastercardSvg;
    case 'amex':
      return AmexSvg;
    default:
      return undefined;
  }
}