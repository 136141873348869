
import { useRef, useState } from 'react';
import VideoEffectModal from './videoEffectModal.component';
import { ReactComponent as SparkleGradientSvg } from 'common/images/icons/Extras/sparkle_gradient.svg';
import { ReactComponent as SparkleSvg } from 'common/images/icons/Extras/sparkle.svg';
import { UpdateClipInput } from 'edit/const';
import { ClickAwayListener, Paper, Popper, SvgIcon } from '@mui/material';
import { isNil, isUndefined } from 'lodash';
import { SPACER } from 'config/models/themeOptions';
import { IVideoClipDetailFragment, IVideoClipEffectProcessingStatus, IVideoClipEffectSettings, useApplyVideoEffectMutation } from 'data/_generated';
import { VideoEffectOptions } from './const';
import Tooltip from 'common/components/tooltip/tooltip.component';
import { t } from '@lingui/macro';
import { trackEvent } from '../../common/utils/errors';
import { LoadingButton } from '@mui/lab';
import AIFeatureModal from '../../common/components/AIFeatureModal.component';
import AIEyeBlurImg from '../../common/images/illustration/eye+blur.png';
import useAiUpsell from '../../common/hooks/useAiUpsell';
import useActiveAccount from '../../config/hooks/useActiveAccount';


type VideoEffectProps = {
  clipId: string;
  effectSettings?: IVideoClipEffectSettings;
  onUpdate: (videoClip: UpdateClipInput) => void | Promise<void>;
};

/**
 * Component to apply video effects to a clip.
 * Only show this if enabled
 */
export default function VideoEffect({ clipId, effectSettings, onUpdate }: VideoEffectProps) {
  const { accountId } = useActiveAccount();
  const { isAiUpsellOpen, canApplyAI, handleAIUpsellClose, getUpgradeUrl } = useAiUpsell({ source: isUndefined(accountId) ? 'video-effect-local' : 'video-effect' });
  const button = useRef<HTMLButtonElement>(null);
  const [applyEffectMutation] = useApplyVideoEffectMutation();

  const applyEffect = async ({
    baseClipId,
    backgroundBlur,
    gazeRedirection,
  }: VideoEffectOptions): Promise<IVideoClipDetailFragment> => {
    const result = await applyEffectMutation({
      variables: {
        clipId: baseClipId,
        gazeRedirection,
        backgroundBlur,
      },
      refetchQueries: ['VideoClips'],
    });
    if (isNil(result.data)) throw new Error('Failed to apply video effect');
    return result.data.applyVideoEffect;
  };


  const [state, setState] = useState<'processing' | 'open' | 'closed'>('closed');
  const isLoading = effectSettings?.status === IVideoClipEffectProcessingStatus.Processing;
  return <>
        <Tooltip title={t`Apply Video Effects`} color='dark' placement='top'>
            <LoadingButton
                onClick={() => {
                  setState((prev) => {
                    if (prev === 'closed') {
                      trackEvent('video-effects', { isOpen: true });
                      return 'open';
                    }
                    return prev;
                  });
                }}
                startIcon={<SvgIcon inheritViewBox component={isUndefined(effectSettings) ? SparkleGradientSvg : SparkleSvg}></SvgIcon>}
                ref={button}
                variant={isUndefined(effectSettings) ? 'outlined' : 'contained'}
                sx={{ minWidth: 'auto', ml: SPACER.XS, '& .MuiButton-startIcon' : { margin: 0 } }}
                color={effectSettings?.status === IVideoClipEffectProcessingStatus.Failed ? 'error' : 'primary'}
                loading={isLoading}>
            </LoadingButton>
        </Tooltip>
        <Popper
          sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
          open={state !== 'closed'}
          disablePortal={false}
          anchorEl={button.current}
          placement='bottom-end'
          onReset={undefined}
          onResetCapture={undefined}>
          <ClickAwayListener onClickAway={() => {
            setState((prev) => {
              if (prev === 'open') {
                trackEvent('video-effects', { isOpen: false });
                return 'closed';
              }
              return prev;
            });
          }}>
            <Paper
              elevation={1}
              sx={{
                p: SPACER.S,
                maxWidth: 369,
              }}>
              <VideoEffectModal
                enableRetry={effectSettings?.status === IVideoClipEffectProcessingStatus.Failed}
                isLoading={isLoading}
                initSettings={effectSettings}
                onConfirm={async (settings) => {
                  setState('processing');
                  const baseClipId = effectSettings?.baseClipId ?? clipId;
                  const effects = {
                    ...settings,
                    baseClipId,
                  };
                  trackEvent('video-effects-apply', effects);
                  const result = await applyEffect(effects);
                  await onUpdate(result);
                  setState('closed');
                  trackEvent('video-effects', { isOpen: false });
                }}
                onClick={(successFn: () => void) => {
                  canApplyAI(successFn, () => setState('closed'));
                }}
              />
            </Paper>
          </ClickAwayListener>
        </Popper>
      {isAiUpsellOpen && <AIFeatureModal
          onClose={handleAIUpsellClose}
          imgSrc={AIEyeBlurImg}
          trackSource={isUndefined(accountId) ? 'video-effect-local' : 'video-effect'}
          getUpgradeUrl={getUpgradeUrl}/>}
  </>;
}