import { IVideoProjectPublishStatus, useVideoProjectsQuery, IVideoSummaryFragment } from '../../data/_generated';
import { compact, orderBy } from 'lodash';
import { usePolling } from 'config/hooks/usePolling';
import { Grid, CircularProgress } from '@mui/material';
import { FetchProps } from 'common/const';

/**
 * Default loading state for Fetch product 
 * that takes up the entire window height
 */
function Loading() {
  return <Grid
    container
    sx={{ minHeight: (theme) => `calc(100vh - ${theme.spacing(17)})` }}
    justifyContent="center"
    alignItems="center"
  >
    <CircularProgress />
  </Grid>;
}

type FetchProjectsProps = FetchProps & {
  /** render callback when data is available */
  render: (data: IVideoSummaryFragment[]) => JSX.Element;
};

/**
 * Using the render props method to fetch all projects data and consistently handle errors
 * @returns {ReactNode}
 */
export default function FetchProjects({ render, children }: FetchProjectsProps) {
  const { loading, data, error, startPolling, stopPolling } = useVideoProjectsQuery({
    fetchPolicy: 'no-cache',
  });

  const statuses = data?.videoProjects.map((project) =>
    project.publishStatus?.status) ?? [];
  const shouldPoll = statuses.includes(IVideoProjectPublishStatus.Processing);

  usePolling({
    startPolling,
    stopPolling,
    shouldPoll,
  });

  if (error) throw error;
  if (loading) return children ? children : <Loading />;
  if (!data?.videoProjects) throw new Error('Could not find Projects');
  return <>{render(orderBy(compact(data.videoProjects), 'dateCreated', 'desc'))}</>;
}
