import { WatchQueryFetchPolicy } from '@apollo/client';

/** the width of the dashboard drawer */
export const DRAWER_WIDTH = 260;

/** video by copilotai email for contact us */
export const COPILOT_VIDEO_EMAIL = 'video@copilotai.com';

export type FetchProps = {
  /** @type {JSX.Element} explicit loading state */
  children?: JSX.Element
  /** @type {WatchQueryFetchPolicy} fetch policy to use */
  fetchPolicy?: WatchQueryFetchPolicy
};

export type FetchedViewProps<T> = {
  /** @type {T} the fetched data */
  data: T
};