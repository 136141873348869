import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import PlanCard from '../../common/components/planCard.component';
import { PlanType, PRICING_PLANS, PRO_PLAN_NAME } from '../plan.const';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import PaymentForm from './paymentForm.component';
import { t } from '@lingui/macro';
import { SPACER } from '../../config/models/themeOptions';
import useStripeElementConfig from '../../config/hooks/useStripeElementConfig';
import { IPaymentMethod } from '../../data/_generated';

type SubscribeViewProps = {
  /** account name */
  accountName?: string;
  /** last saved payment method */
  paymentMethod?: IPaymentMethod | null;
  planName: string;
  changePlan: () => void;
};
/**
 * View for plan subscription
 * @constructor
 */
export default function SubscribeView({ planName, accountName, paymentMethod, changePlan }: SubscribeViewProps) {
  const { stripePromise, clientSecret } = useStripeElementConfig();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedPlan : PlanType | undefined = PRICING_PLANS[planName];
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
    /** we have custom loader as there is a slight delay in showing stripe loader*/
    loader: 'never',
  };

  return (
    <Box sx={{ display: isXS ? 'block' : 'flex', justifyContent: 'space-between' }}>
      <Box flex={1}>
        <Typography mb={SPACER.S}>{t`Selected Plan`}</Typography>
        <PlanCard
          plan={selectedPlan ?? PRICING_PLANS[PRO_PLAN_NAME]}
          sx={{ height: 'fit-content', width: '100%', justifySelf: 'center' }}
        />
        <Box textAlign={'center'}>
          <Button variant='text' onClick={changePlan} >{t`Select another Plan`}</Button>
        </Box>
      </Box>

      <Box flex={1} p={isXS ? 4 : 8}>
        <Typography mb={SPACER.S}>{t`Payment Details`}</Typography>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm planName={planName} userName={accountName} paymentMethod={paymentMethod}/>
          </Elements>
        )}
      </Box>
    </Box>
  );
}
