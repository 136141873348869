import { AppBar, Box, Skeleton, Stack, Toolbar, Zoom } from '@mui/material';
import {
  IPersonalizedClipSlot,
  IPersonalizedVideoClip,
  ISingleClipSlot,
  ITargetInput,
  IThumbnailTypes,
  ITimelineObject,
  ITimelineObjectInput,
  IVideoClip,
  IVideoClipEffectProcessingStatus,
  IVideoProjectInput,
  Maybe,
  usePublishVideoProjectMutation,
  useUpdateTimelineVideoProjectMutation,
  useUpdateVideoClipMutation,
  useUpdateVideoProjectMutation,
  useUpdateVideoProjectTargetsMutation,
  useVideoTimelineQuery,
} from 'data/_generated';
import { first, indexOf, isEmpty, isNil, isNumber, isString, isUndefined, last, sum, without } from 'lodash';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DEFAULT_PERSONALIZED_CLIP_SLOT, DEFAULT_SINGLE_CLIP_SLOT, DEFAULT_TRANSITION_SLOT, isPersonalizedClipSlot, isSingleClipSlot, isTransitionSlot, toInput, UpdateClipInput, UpdateClipTitleInput } from 'edit/const';
import Edit from '../components/edit.component';
import { useState } from 'react';
import NotFound from 'pages/notFound.page';
import { TimelineLayoutContext } from 'edit/layout/timeline.layout';
import { t } from '@lingui/macro';
import { isPersonalizedSlotFocus, TimelineSlotFocus } from '../components/videoTimeline.component';
import { extractError, logError, trackEvent } from 'common/utils/errors';
import useUploadClip from 'common/hooks/useUploadClip';
import useUpdateTitle from 'common/hooks/useUpdateTitle';
import VideoPreview, { PreviewAlertType } from 'edit/components/videoPreviewModal.component';
import TargetsModal from 'edit/components/targetCreation/targetsModal.component';
import confettiImage from 'common/images/confetti_left.svg';
import CenteredImage from 'common/components/centeredImage.component';
import useThumbnail, { BLANK_THUMBNAIL } from '../../common/hooks/useThumbnails';
import { getTrimmedTimeOptional } from '../components/utils';
import { SPACER } from '../../config/models/themeOptions';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';

function LoadingView() {
  return (
    <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Skeleton
          sx={{
            margin: '0 auto',
            transform: 'none',
            aspectRatio: '4/3',
            height: '100px',
            minHeight: 0,
            flexGrow: 1,
            maxHeight: '50vh',
            maxWidth: '100%',
          }}
        />
        <Stack direction="row" spacing={2} p={SPACER.S}>
          <Skeleton width={80} height="2rem" variant="rounded" />
          <Skeleton width={80} height="2rem" variant="rounded" />
          <Skeleton width={80} height="2rem" variant="rounded" />
        </Stack>
      </Box>
      <AppBar position="static" elevation={6} color="default">
        <Box padding={3}>
          <Box paddingBottom={2} display="flex" flexDirection="row" justifyContent="space-between">
            <Skeleton width={100} variant="text" />
            <Skeleton width={100} variant="text" />
          </Box>
          <Skeleton width={94} height={64} variant="rounded" />
        </Box>
      </AppBar>
      <AppBar position="static" elevation={1} color="default">
        <Toolbar sx={{ justifyContent: { xs: 'center', sm: 'space-between' } }}>
          <Box></Box>
          <Stack direction="row" spacing={2}>
            <Skeleton width={80} height="2rem" variant="rounded" />
            <Skeleton width={80} height="2rem" variant="rounded" />
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

/**
 * Whether this timeline slot is initialized with a video clip
 * @param slot
 * @param check
 */
export function countClips(slot: ITimelineObject, check: (clip?: Maybe<IVideoClip>) => boolean): number {
  if (slot.__typename === 'PersonalizedClipSlot') return slot
    .personalizedClips?.map(({ videoClip })=> videoClip).filter(check).length ?? 0;
  return slot.__typename === 'SingleClipSlot' && check(slot.videoClip) ? 1 : 0;
}

/**
 * Whether this clip is initialized
 * @param clip
 */
export function isClipReady(clip?: Maybe<IVideoClip>): boolean {
  if (isEmpty(clip)) return false;
  return isString(clip.location);
}

/**
 * Whether this clip has an effect being applied or failed
 * @param clip
 */
export function isClipProcessing(clip?: Maybe<IVideoClip>): boolean {
  if (isEmpty(clip)) return false;
  return isString(clip.location) &&
      (clip.effectSettings?.status === IVideoClipEffectProcessingStatus.Processing ||
      clip.effectSettings?.status === IVideoClipEffectProcessingStatus.Failed);
}

/**
 * Page for editing a video project. Changes are persisted to the server.
 */
export default function LivePage() {
  const { id } = useParams();
  if (!id) throw new Error('Could not find the Project ID in the URL, maybe this was a bad link?');
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showTargetModal, setShowTargetModal] = useState<boolean>(false);
  const [showEmptyTargetRow, setShowEmptyTargetRow] = useState<boolean>(true);
  const [published, setPublished] = useState<boolean | 'loading'>(false);
  const [publishError, setPublishedError] = useState<Error | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { updateTitle } = useOutletContext<TimelineLayoutContext>();
  const { isEnabled: flexibleRecording } = useFeatureFlag(BooleanFeatureFlagNames.flexibleRecording);
  const [focus, setFocus] = useState<number | undefined>();
  /** Queries */
  const { loading, data, error } = useVideoTimelineQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
    onCompleted: ({ videoProject: { timeline, title, targets } }) => {
      setSlots(timeline);
      if (isUndefined(focus)) {
        const firstEmptySingle = first(timeline.filter((slot) => isSingleClipSlot(slot) && isNil(slot.videoClip)));
        const lastSingleSlot = last(timeline.filter((slot)=> isSingleClipSlot(slot)));
        if (!isUndefined(firstEmptySingle)) {
          setFocus(indexOf(timeline, firstEmptySingle));
        } else if (targets.length > 0) {
          setFocus(-1);
        } else if (!isUndefined(lastSingleSlot)) {
          setFocus(indexOf(timeline, lastSingleSlot));
        }
      } else if (focus === -1 && targets.length === 0) {
        //Last target removed, set to first slot
        const hasSingle = !isEmpty(timeline.filter((slot) => isSingleClipSlot(slot)));
        setFocus(hasSingle ? 1 : undefined);
      }
      // this is sync the layout with the current title
      if (isString(title)) updateTitle(title, onTitleUpdate);
    },
  });

  const [updateVideoProject] = useUpdateVideoProjectMutation({ refetchQueries: ['VideoTimeline'] });
  const [updateTimeline] = useUpdateTimelineVideoProjectMutation({
    refetchQueries: ['VideoTimeline', 'UserUsageInfo'],
  });
  const [publishProject] = usePublishVideoProjectMutation({
    variables: { publishVideoProjectId: id },
    refetchQueries: ['UserUsageInfo'],
  });
  const publish = async () => {
    setPublished('loading');
    if (published !== false) return;
    try {
      await publishThumbnail();
      await persistTimeline();
      await publishProject();
      trackEvent('publish', { type: 'multi' });
      setPublished(true);
    } catch (e) {
      setPublished(false);
      const pubError = extractError(e);
      setPublishedError(pubError);
      logError(pubError);
    }
  };

  const { uploadClip, progressPercent } = useUploadClip();
  const [updateClip] = useUpdateVideoClipMutation({
    refetchQueries: ['VideoClips'],
  });
  const [updateTargets] = useUpdateVideoProjectTargetsMutation();

  const [slots, setSlots] = useState<ITimelineObject[]>(data?.videoProject.timeline ?? []);
  const singleSlots = slots.filter(isSingleClipSlot);
  const firstSingleSlot = first(singleSlots);
  const { staticThumbnail: defaultThumbnail } = useThumbnail({
    options: {
      createStatic: true,
    },
    videoSrc: firstSingleSlot?.videoClip?.location,
    startTimeInSec: getTrimmedTimeOptional(firstSingleSlot?.start, firstSingleSlot?.videoClip?.durationInSeconds),
  });
  const { updateTitle: onTitleUpdate } = useUpdateTitle(id);
  if (loading) return <LoadingView />;
  if (!data?.videoProject) return <NotFound />;
  if (error) console.error(error);

  const publishThumbnail = async () => {
    if (!isNil(data.videoProject.thumbnail)) return;
    await onUpdateProject({
      thumbnail: {
        selection: IThumbnailTypes.Static,
        location: defaultThumbnail ?? BLANK_THUMBNAIL,
      },
    });
  };

  const persistClip = async (clip: UpdateClipInput): Promise<IVideoClip> => {
    if (clip.__typename !== 'NewVideoClip') {
      // Updated the clip
      const updatedClip = await updateClip({
        variables: {
          updateVideoClipId: clip.id,
          data: {
            lastStart: clip.lastStart,
            lastEnd: clip.lastEnd,
          },
        },
      });
      return { ...clip, ...updatedClip.data?.updateVideoClip };
    }
    return uploadClip(clip);
  };

  const persistTimeline = async (newTimeline = data.videoProject.timeline) => {
    setSlots(newTimeline);
    const timeline = newTimeline.map<ITimelineObjectInput>(toInput);
    await updateTimeline({ variables: { id, data: { timeline } } });
  };

  const updateSlot = async (videoClip: IVideoClip | undefined) => {
    if (isUndefined(slotFocus)) return;
    setIsSaving(true);
    if (isPersonalizedSlotFocus(slotFocus)) {
      let updatedPersonalizedClips =
        slotFocus.slot?.personalizedClips?.filter((clip) => clip.targetId !== slotFocus.targetFocus) ?? [];
      if (!isUndefined(videoClip)) {
        const newPersonalizedClip: IPersonalizedVideoClip = {
          videoClip,
          targetId: slotFocus.targetFocus,
          start: videoClip.lastStart,
          end: videoClip.lastEnd,
        };
        updatedPersonalizedClips = [newPersonalizedClip, ...updatedPersonalizedClips];
      }

      //If Personalized slot hasn't been added to timeline yet, insert new default slot
      const personalizedSlot = isUndefined(slotFocus.slot) ? DEFAULT_PERSONALIZED_CLIP_SLOT : slotFocus.slot;
      const personalizedSlotToUpdate: IPersonalizedClipSlot = {
        ...personalizedSlot,
        personalizedClips: updatedPersonalizedClips,
      };
      await persistTimeline([personalizedSlotToUpdate, ...slots.filter((slot) => !isPersonalizedClipSlot(slot))]);
    } else if (isSingleClipSlot(slotFocus.slot)) {
      const indexOfSlot = indexOf(slots, slotFocus.slot);
      if (indexOfSlot === -1) throw Error('Invalid slot index');
      let updatedSlot = slotFocus.slot;
      if (isUndefined(videoClip)) {
        updatedSlot = {
          ...slotFocus.slot,
          videoClip: undefined,
          start: undefined,
          end: undefined,
        };
      } else {
        updatedSlot = {
          ...slotFocus.slot,
          videoClip,
          start: videoClip.lastStart,
          end: videoClip.lastEnd,
        };
      }
      const newList = [...slots];
      const deleteCount = isUndefined(videoClip) && isTransitionSlot(slots[indexOfSlot + 1]) ? 2 : 1;
      newList.splice(indexOfSlot, deleteCount, updatedSlot);
      await persistTimeline(newList);
    } else {
      //satisfies never
      throw Error('Unhandled slot type');
    }
    setIsSaving(false);
  };
  
  const updateScriptV2 = async (script?: string)=> {
    if (script === data.videoProject.scriptV2) return;
    
    setIsSaving(true);
    await onUpdateProject({ scriptV2: script });
    setIsSaving(false);
  };

  const onUpdateScript = async (script?: string) => {
    return updateScriptV2(script);
  };

  const onUpdateClip = async (updatedClip: UpdateClipInput) => {
    await updateSlot(await persistClip(updatedClip));
    return true;
  };


  const onUpdateClipTitle = async ({
    id: updateVideoClipId,
    title }: UpdateClipTitleInput) => {
    await updateClip({
      variables: {
        updateVideoClipId,
        data: { title },
      },
      refetchQueries: ['VideoClips', 'VideoTimeline'],
    });
  };

  const onDeleteClip = async () => {
    await updateSlot(undefined);
  };

  const onDuplicateSlot = async (slot: ISingleClipSlot) => {
    setIsSaving(true);
    const indexOfItem = indexOf(slots, slot);
    const indexOfNewItem = isTransitionSlot(slots[indexOfItem + 1]) ? indexOfItem + 2 : indexOfItem + 1;
    const newList = [...slots];
    newList.splice(indexOfNewItem, 0, { ...slot });
    await persistTimeline(newList);

    setFocus(indexOfNewItem);
    setIsSaving(false);
  };

  const onNewTransition = async (i?: number) => {
    setIsSaving(true);
    const newList = [...slots];
    let addedSlots = 1;
    if (isUndefined(i)) {
      const personal = first(slots.filter(isPersonalizedClipSlot));
      if (isUndefined(personal)) {
        newList.splice(0, 0, { ...DEFAULT_PERSONALIZED_CLIP_SLOT });
        addedSlots += 1;
      }
      i = 1;
    }
    newList.splice(i, 0, { ...DEFAULT_TRANSITION_SLOT });
    await persistTimeline(newList);
    if (isNumber(focus) && i <= focus) {
      setFocus(focus + addedSlots);
    }
    setIsSaving(false);
  };

  const onDeleteTransition = async (i?: number) => {
    if (isUndefined(i)) {
      // Assumption that this the transition will be after the personalized slot
      // which should be the first slot
      i = 1;
      if (!isTransitionSlot(slots[i])) return;
    }
    setIsSaving(true);
    const newList = [...slots];
    newList.splice(i, 1);
    await persistTimeline(newList);
    if (isNumber(focus) && i <= focus) {
      setFocus(focus - 1);
    }
    setIsSaving(false);
  };

  //TODO support deleting personalized clips
  const onDeleteSlot = async (slot: ISingleClipSlot) => {
    setIsSaving(true);
    const indexOfItem = indexOf(slots, slot);

    // Clear the slot and transition
    const nextSlot = slots[indexOfItem + 1];
    let newList = without(slots, slot);
    if (isTransitionSlot(nextSlot)) {
      newList = without(newList, nextSlot);
    }
    // Determine the new focus
    const currentFocusSlot = focus ? slots[focus] : undefined;
    let newFocus = undefined;
    if (indexOfItem === focus) {
      // we're deleting the current element we're focused on
      const singles = slots.filter(isSingleClipSlot);
      const singleOnlyFocus = indexOf(singles, currentFocusSlot);
      const newSingleList = without(singles, slot);
      const newSingleFocus = newSingleList[singleOnlyFocus] ?? last(newSingleList);
      newFocus = indexOf(newList, newSingleFocus);
    } else if (isSingleClipSlot(currentFocusSlot)) {
      newFocus = indexOf(newList, currentFocusSlot);
    } else {
      newFocus = focus;
    }

    await persistTimeline(newList);
    setFocus(newFocus);
    setIsSaving(false);
  };

  /**
   * adds new single slot
   * @param addAfterFocus if true, new single slot is added right after current focus slot. if false, new single slot is added to end of the slots
   */
  const onNewSingleSlot = async (addAfterFocus?: boolean) => {
    setIsSaving(true);
    const newItem = { ...DEFAULT_SINGLE_CLIP_SLOT };
    const newList = addAfterFocus && !isUndefined(focus) ? [...data.videoProject.timeline.slice(0, focus + 1), newItem, ...data.videoProject.timeline.slice(focus + 1) ] : [...data.videoProject.timeline, newItem];

    // set focus to newly added slot
    setFocus((f) => (addAfterFocus && !isUndefined(f)) ? f + 1 : newList.length - 1);
    await persistTimeline(newList);
    setIsSaving(false);
  };

  const onUpdateProject = async (project: IVideoProjectInput) => {
    await updateVideoProject({
      variables: { updateVideoProjectId: id, data: project },
      refetchQueries: ['UserUsageInfo', 'VideoTimeline'],
    });
  };

  const onUpdateTargets = async (targets: ITargetInput[]) => {
    await updateTargets({
      variables: {
        updateVideoProjectTargetsId: id,
        data: {
          targets,
        },
      },
      refetchQueries: ['VideoTimeline'],
      awaitRefetchQueries: true,
    });
  };

  const isPreviewReady = sum(slots.map(s=>countClips(s, isClipReady))) > 0 &&
      sum(slots.map(s=>countClips(s, isClipProcessing))) === 0;
  const personalizedClip: IPersonalizedClipSlot | undefined = first(slots.filter(isPersonalizedClipSlot));
  const unfilledTargets = without(
    data.videoProject.targets.map((c) => c.id),
    ...(personalizedClip?.personalizedClips?.map((c) => c.targetId) ?? []),
  );

  const getFocus = (i: number | undefined): TimelineSlotFocus | undefined => {
    if (isUndefined(i)) return undefined;
    if (i >= 0) {
      // Single Focus
      const slot = slots[i];
      if (!isSingleClipSlot(slot)) return undefined;
      return { slot };
    } else {
      // Personal Focus
      const target = data.videoProject.targets[-(i + 1)];
      if (isUndefined(target)) return undefined;
      return {
        targetFocus: target.id,
        slot: personalizedClip,
      };
    }
  };
  const slotFocus = getFocus(focus);
  const hasUnfilledTargets = !isEmpty(unfilledTargets);

  const onUpdateOrder = async (slotIndex: number, newIndex: number) => {
    setIsSaving(true);

    const selectedSlot = slots[slotIndex];
    const selectedNextSlot = slots[slotIndex + 1];
    const newList = [...slots];

    let movingSlots = [];
    if (!isUndefined(selectedNextSlot) && selectedNextSlot.__typename === 'TransitionObject') {
      movingSlots = [selectedSlot, selectedNextSlot];
    } else {
      movingSlots = [selectedSlot];
    }

    newList.splice(slotIndex, movingSlots.length);
    newList.splice(slotIndex < newIndex ? newIndex - movingSlots.length : newIndex, 0, ...movingSlots);
    const newFocus = indexOf(newList, selectedSlot);
    setFocus(newFocus);
    await persistTimeline(newList);
    setIsSaving(false);
  };

  const previewAlertType: PreviewAlertType | undefined = sum(slots.map(s=>countClips(s, isClipReady))) === 0 ? 'missingClip' : isEmpty(data.videoProject.targets) ? 'missingTarget' : hasUnfilledTargets ? 'missingIntro' : undefined;

  const getPreviewAlertAction = () => {
    if (isUndefined(previewAlertType)) return undefined;
    switch (previewAlertType) {
      case 'missingClip':
        return undefined;
      case 'missingIntro': {
        return () => {
          const targetFocus = indexOf(
            data.videoProject.targets.map((c) => c.id),
            unfilledTargets[0],
          );
          if (targetFocus >= 0) {
            setFocus(-(targetFocus + 1));
          }
        };
      }
      case 'missingTarget': {
        return () => {
          setShowTargetModal(true);
        };
      }
    }
  };
  /**
   * opens target modal
   * @param addNew true to show new empty target row by default in the modal
   */
  const openTargetModal = (addNew?: boolean) => {
    setShowTargetModal(true);
    if (!isUndefined(addNew)) setShowEmptyTargetRow(addNew);
  };
  return (
    <>
      <Edit
        flexibleRecording={flexibleRecording}
        showTimelineDefault={true}
        isPreviewReady={isPreviewReady}
        isSaving={isSaving}
        uploadingPercent={progressPercent}
        isPublishing={published === 'loading'}
        onUpdateScript={onUpdateScript}
        landingPageTitleTemplate={data.videoProject.landingPageTitleTemplate}
        links={data.videoProject.links}
        thumbnail={data.videoProject.thumbnail}
        targets={data.videoProject.targets}
        focusKey={`${focus}-${slots.length}`}
        script={data.videoProject.scriptV2 ?? undefined}
        focus={slotFocus}
        setFocus={(newFocus) => {
          if (isPersonalizedSlotFocus(newFocus)) {
            const targetFocus = indexOf(
              data.videoProject.targets.map((c) => c.id),
              newFocus.targetFocus,
            );
            if (targetFocus >= 0) {
              setFocus(-(targetFocus + 1));
            }
          } else {
            setFocus(indexOf(slots, newFocus.slot));
          }
        }}
        slots={slots}
        onNewSingleSlot={onNewSingleSlot}
        onDeleteClip={onDeleteClip}
        onDeleteSlot={onDeleteSlot}
        onDuplicateSlot={onDuplicateSlot}
        onUpdateOrder={onUpdateOrder}
        onUpdateClip={onUpdateClip}
        onUpdateClipTitle={onUpdateClipTitle}
        onNewTransition={onNewTransition}
        onDeleteTransition={onDeleteTransition}
        onUpdateTargets={onUpdateTargets}
        shareError={publishError}
        onShare={() => void publish()}
        onPreview={() => setShowPreview(true)}
        openTargetModal={openTargetModal}
      />
      <Zoom
        mountOnEnter={true}
        in={published === true}
        onEntered={() => {
          setTimeout(() => {
            navigate(`/share/${id}`);
          }, 1000);
        }}
      >
        <CenteredImage src={confettiImage} alt={t`Congrats!`} />
      </Zoom>
      {showPreview && (
        <VideoPreview
          personalized={personalizedClip?.personalizedClips}
          targets={data.videoProject.targets}
          slots={singleSlots.filter((slot) => !isNil(slot.videoClip))}
          onClose={() => setShowPreview(false)}
          thumbnail={defaultThumbnail}
          onAlertAction={getPreviewAlertAction()}
          alertType={previewAlertType}
        />
      )}
      {showTargetModal && (
        <TargetsModal
          targets={data.videoProject.targets}
          onClose={() => setShowTargetModal(false)}
          showEmptyRow={showEmptyTargetRow}
          isOpen
          onSubmit={(targets: ITargetInput[]) => {
            void onUpdateTargets(targets);
            trackEvent('new-recipient', {
              intro: false,
              added: targets.length - data.videoProject.targets.length,
              count: targets.length,
            });
            // Set focus to the first personalized intro slot
            setFocus(-1);
          }}
        />
      )}
    </>
  );
}
