import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined | null;
export type InputMaybe<T> = T | undefined | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  _Any: any;
  _FieldSet: any;
};

export type IChatCompletionChoice = {
  __typename?: 'ChatCompletionChoice';
  /** The chat completed message generated by ChatGPT. */
  message: IChatCompletionMessage;
};

export type IChatCompletionInput = {
  /** The penalty for repeated tokens in the generated text, between -2.0 and 2.0. */
  frequencyPenalty?: InputMaybe<Scalars['Float']>;
  /** The maximum number of tokens to generate. */
  maxLength?: InputMaybe<Scalars['Int']>;
  /** The messages to complete. */
  messages: Array<IChatCompletionMessageInput>;
  /** The penalty for tokens that are present in the generated text, between -2.0 and 2.0. */
  presencePenalty?: InputMaybe<Scalars['Float']>;
  /** The randomness of the generated text, between 0.0 and 2.0. */
  temperature?: InputMaybe<Scalars['Float']>;
  /** The diversity of the generated text. */
  topP?: InputMaybe<Scalars['Float']>;
};

export type IChatCompletionMessage = {
  __typename?: 'ChatCompletionMessage';
  /** The text of the message. */
  content: Scalars['String'];
  /** The role of the message. */
  role: IChatCompletionMessageRole;
};

export type IChatCompletionMessageInput = {
  /** The text of the message. */
  content: Scalars['String'];
  /** The role of the message. */
  role: IChatCompletionMessageRole;
};

export enum IChatCompletionMessageRole {
  /** The author of the message is the assistant. */
  Assistant = 'ASSISTANT',
  /** The author of the message is the system. */
  System = 'SYSTEM',
  /** The author of the message is the user. */
  User = 'USER'
}

export type IChatCompletionOrModerationResult = IChatCompletionResult | IModerationResult;

export type IChatCompletionResult = {
  __typename?: 'ChatCompletionResult';
  /** A list of chat completed responses by ChatGPT. */
  choices: Array<IChatCompletionChoice>;
};

/** Input for the editMessage query */
export type IEditMessageInput = {
  /** Instructions on how to edit the message */
  instruction: IEditMessageInstruction;
  /** The message to be edited */
  message: Scalars['String'];
};

/** Instruction for how to edit a message */
export type IEditMessageInstruction = {
  /** List of instructions on how to edit the message */
  instructions: Array<Scalars['String']>;
};

/** Response generated from the editMessage query */
export type IEditMessageResponse = IMessageResult | IModerationResult;

/** A dictionary entry for keys to values */
export type IKeyValueInput = {
  /** The key of the entry */
  key: Scalars['String'];
  /** The value of the entry */
  value: Scalars['String'];
};

/** A response with a generated message */
export type IMessageResult = {
  __typename?: 'MessageResult';
  /** The generated message */
  message: Scalars['String'];
};

export type IModerationCategories = {
  __typename?: 'ModerationCategories';
  /** Whether the text contains hateful language */
  hate: Scalars['Boolean'];
  /** Whether the text contains threatening language */
  hateThreatening: Scalars['Boolean'];
  /** Whether the text contains self-hard language */
  selfHarm: Scalars['Boolean'];
  /** Whether the text contains sexual language */
  sexual: Scalars['Boolean'];
  /** Whether the text contains sexual language related to minors */
  sexualMinors: Scalars['Boolean'];
  /** Whether the text contains violent language */
  violence: Scalars['Boolean'];
  /** Whether the text contains violent language that is graphic */
  violenceGraphic: Scalars['Boolean'];
};

export type IModerationResult = {
  __typename?: 'ModerationResult';
  /** The categories that the text is flagged for */
  categories: IModerationCategories;
  /** Whether the text is flagged for moderation */
  flagged: Scalars['Boolean'];
};

export type IQuery = {
  __typename?: 'Query';
  _service: I_Service;
  /** Query for chat completion given messages and optional parameters. */
  chatCompletion: IChatCompletionOrModerationResult;
  /** Query for editing a message. */
  editMessage: IEditMessageResponse;
  /** Query for generating text based off a prompt label and values to replace in that prompt */
  secureMessage: ISecureMessageResponse;
  /** Query for text completion given a prompt and optional parameters. */
  textCompletion: ITextCompletionOrModerationResult;
};


export type IQueryChatCompletionArgs = {
  input: IChatCompletionInput;
};


export type IQueryEditMessageArgs = {
  input: IEditMessageInput;
};


export type IQuerySecureMessageArgs = {
  input: ISecureMessageInput;
};


export type IQueryTextCompletionArgs = {
  input: ITextCompletionInput;
};

/** Input for the secureMessage query */
export type ISecureMessageInput = {
  /** The prompt message label to lookup */
  promptLabel: Scalars['String'];
  /** The prompt message values to replace */
  promptValues?: InputMaybe<Array<IKeyValueInput>>;
};

/** Response generated from the secureMessage query */
export type ISecureMessageResponse = IMessageResult | IModerationResult;

export type ITextCompletionChoice = {
  __typename?: 'TextCompletionChoice';
  /** The text generated by ChatGPT. */
  text: Scalars['String'];
};

export type ITextCompletionInput = {
  /** The penalty for repeated tokens in the generated text, between -2.0 and 2.0. */
  frequencyPenalty?: InputMaybe<Scalars['Float']>;
  /** The maximum number of tokens to generate. */
  maxLength?: InputMaybe<Scalars['Int']>;
  /** The penalty for tokens that are present in the generated text, between -2.0 and 2.0. */
  presencePenalty?: InputMaybe<Scalars['Float']>;
  /** The prompt to complete. */
  prompt: Scalars['String'];
  /** The randomness of the generated text, between 0.0 and 2.0. */
  temperature?: InputMaybe<Scalars['Float']>;
  /** The diversity of the generated text. */
  topP?: InputMaybe<Scalars['Float']>;
};

export type ITextCompletionOrModerationResult = IModerationResult | ITextCompletionResult;

export type ITextCompletionResult = {
  __typename?: 'TextCompletionResult';
  /** A list of text completed responses by ChatGPT. */
  choices: Array<ITextCompletionChoice>;
};

export type I_Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type IChatCompletionQueryVariables = Exact<{
  input: ISecureMessageInput;
}>;


export type IChatCompletionQuery = { __typename?: 'Query', secureMessage: { __typename?: 'MessageResult', message: string } | { __typename?: 'ModerationResult', flagged: boolean, categories: { __typename?: 'ModerationCategories', sexual: boolean, hate: boolean, violence: boolean, selfHarm: boolean, sexualMinors: boolean, hateThreatening: boolean, violenceGraphic: boolean } } };


export const ChatCompletionDocument = gql`
    query ChatCompletion($input: SecureMessageInput!) {
  secureMessage(input: $input) {
    ... on MessageResult {
      message
    }
    ... on ModerationResult {
      flagged
      categories {
        sexual
        hate
        violence
        selfHarm
        sexualMinors
        hateThreatening
        violenceGraphic
      }
    }
  }
}
    `;

/**
 * __useChatCompletionQuery__
 *
 * To run a query within a React component, call `useChatCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatCompletionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCompletionQuery(baseOptions: Apollo.QueryHookOptions<IChatCompletionQuery, IChatCompletionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IChatCompletionQuery, IChatCompletionQueryVariables>(ChatCompletionDocument, options);
      }
export function useChatCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IChatCompletionQuery, IChatCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IChatCompletionQuery, IChatCompletionQueryVariables>(ChatCompletionDocument, options);
        }
export type ChatCompletionQueryHookResult = ReturnType<typeof useChatCompletionQuery>;
export type ChatCompletionLazyQueryHookResult = ReturnType<typeof useChatCompletionLazyQuery>;
export type ChatCompletionQueryResult = Apollo.QueryResult<IChatCompletionQuery, IChatCompletionQueryVariables>;
export function refetchChatCompletionQuery(variables: IChatCompletionQueryVariables) {
      return { query: ChatCompletionDocument, variables: variables }
    }