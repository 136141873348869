import { t } from '@lingui/macro';
import {
  MenuItem, SvgIcon, Button, Typography, Box, InputLabel,
  List, ListItem, ListItemIcon, ListItemText, SelectChangeEvent, Switch,
} from '@mui/material';
import { FunctionComponent, SVGProps, useState } from 'react';
import { ReactComponent as RecordCircleSvg } from 'common/images/icons/Camera/Record.svg';
import { ReactComponent as SettingsSvg } from 'common/images/icons/Utility/settings.svg';
import { ReactComponent as GridSvg } from 'common/images/icons/Camera/Grid.svg';
import { ReactComponent as FlipCameraSvg } from 'common/images/icons/Camera/Switch Video Camera.svg';
import Modal from 'common/components/modal/modal.component';
import Select from 'common/components/select/select.component';
import { isEmpty, isError } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';
import AlertError from 'common/components/alert/alertError.component';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import KeyboardShortcut from './keyboardShortcut.component';
import Tooltip from '../../common/components/tooltip/tooltip.component';

type DeviceMenuProps = {
  /** unique identifier for this component */
  id: string,
  /** label above the device list */
  label: string,
  /** list of media device  */
  devices: MediaDeviceInfo[],
  /** the currently selected device */
  currentDeviceId?: string,
  /** callback when a device is selected */
  onChange: (deviceId: string) => Promise<string | undefined> | void,
};

/**
 * Component to show a listing of devices
 * @returns 
 */
function DeviceMenu({
  id,
  devices,
  currentDeviceId,
  label,
  onChange,
}: DeviceMenuProps) {
  const labelId = `${id}_label`;
  const [isLoading, setLoading] = useState<boolean | Error>(false);
  const onUpdate = async ({ target: { value } }: SelectChangeEvent<string>) => {
    setLoading(true);
    //did the change result in the correct value?
    if (await onChange(value) !== value) {
      setLoading(new Error('We failed changing your device'));
    } else {
      setLoading(false);
    }

  };
  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ mb: 1 }}>
        <InputLabel id={labelId}>{label}</InputLabel>
      </Box>
      <AlertError
        fullWidth
        onClose={() => setLoading(false)}
        error={isError(isLoading) ? isLoading : undefined}>
        <Select
          variant={'standard'}
          labelId={labelId}
          value={currentDeviceId}
          size="small"
          fullWidth
          displayEmpty
          onChange={(e) => void onUpdate(e)}
          disabled={isLoading === true}
          loading={isLoading === true}
          renderValue={(value) =>
            isEmpty(value) ?
              <Typography
                sx={(theme) => ({ color: theme.palette.grey[500], lineHeight: 'inherit' })}
              >{t`No device selected`}</Typography> :
              <Typography
                textOverflow={'ellipsis'}
                overflow={'hidden'}
              >{devices.filter(d => d.deviceId === value)[0]?.label ?? value}
              </Typography>
          }
        >
          {devices.map((device, index) => (
            <MenuItem
              disabled={isEmpty(device.deviceId)}
              value={device.deviceId}
              key={device.deviceId}>
              <Typography
                textOverflow={'ellipsis'}
                overflow={'hidden'}
              >
                {device.label ?
                  device.label : (isEmpty(device.deviceId) ? t`Inaccessible Device (${index})` : device.deviceId )}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </AlertError>
    </Box>
  );
}

type VideoSettingsProps = {
  /** The currently selected video device id */
  videoDeviceId?: string;
  /** The currently selected audio device id */
  audioDeviceId?: string;
  /** Callback to set the video device id */
  setVideoDeviceId: (deviceId: string) => Promise<string | undefined> | void;
  /** Callback to set the audio device id */
  setAudioDeviceId: (deviceId: string) => Promise<string | undefined> | void;
  /** whether the video is mirrored*/
  videoMirror?: boolean;
  /** Callback to flip the video */
  flipVideo: () => void;
};

/**
 * Component to just show the available devices
 */
export function VideoSettingsPrompt({
  videoDeviceId,
  audioDeviceId,
  setVideoDeviceId,
  setAudioDeviceId,
  videoMirror,
  flipVideo,
}: VideoSettingsProps) {

  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>([]);
  const updateDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setMediaDevices(devices);
  };

  useEffectOnce(() => {
    // We can only enumerate on devices after the user has granted permission
    // otherwise all the labels are empty strings
    // refer to https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/label
    void updateDevices();
  });
  return <>
        <DeviceMenu
            id='audio-devices'
            label={t`Select your microphone`}
            currentDeviceId={audioDeviceId}
            devices={mediaDevices.filter(d => d.kind === 'audioinput')}
            onChange={setAudioDeviceId} />
        <DeviceMenu
            id='video-devices'
            label={t`Select your camera`}
            currentDeviceId={videoDeviceId}
            devices={mediaDevices.filter(d => d.kind === 'videoinput')}
            onChange={setVideoDeviceId} />
    <Box display={'flex'} justifyContent={'space-between'}>
      <InputLabel >{t`Mirror my video`}</InputLabel>
      <Switch checked={videoMirror} onChange={flipVideo}/>
    </Box>
    </>;

}

type VideoSettingItemProps = {
  /** icon */
  icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  /** label for the setting */
  label: string,
  /** label for the shortcut key */
  shortcutLabel: string,
  /** whether to include lined border bottom*/
  hasBorderBottom?: boolean,
};

function VideoSettingItem({ icon, label, shortcutLabel, hasBorderBottom = false }: VideoSettingItemProps) {
  return (
      <ListItem disableGutters sx={hasBorderBottom ? { borderBottom: `1px solid ${PALETTE_COLOR.neutral[300]}` } : undefined}>
        <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
          <SvgIcon inheritViewBox component={icon} />
        </ListItemIcon>
        <ListItemText primary={label} />
        <KeyboardShortcut label={shortcutLabel} />
      </ListItem>
  );
}

/**
 * Component to show the video and audio devices and keyboard shortcuts
 * @returns {JSX.Element}
 */
export default function VideoSettings(props: VideoSettingsProps) {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  return <><Tooltip title={t`Settings`} color={'dark'} placement={'top'} >
    <Button
    variant='outlined'
    sx={{
      position: 'absolute',
      bottom: 10,
      right: 10,
      minWidth: 'auto',
      zIndex: 1,
      '& .MuiButton-startIcon' : { margin: 0 },
    }}
    startIcon={<SvgIcon inheritViewBox component={SettingsSvg} />}
    onClick={() => setShowSettings(true)} ></Button></Tooltip>
    {showSettings &&
      <Modal
        sx={{ width: '100%' }}
        maxWidth={'xs'}
        onConfirm={() => setShowSettings(false)}
        cancelLabel={t`Cool!`}
        icon={SettingsSvg}
        isOpen={true} title={t`Settings`} onClose={() => setShowSettings(false)} >
        <VideoSettingsPrompt {...props} />
        <Typography variant='h3' sx={{ mt: 8 }}>
          {t`Keyboard shortcuts`}
        </Typography>
        <List sx={{ width: '100%' }}>
          <VideoSettingItem icon={RecordCircleSvg} label={t`Start/Stop Recording`} shortcutLabel={'D'} hasBorderBottom/>
          <VideoSettingItem icon={FlipCameraSvg} label={t`Flip Video`} shortcutLabel={'F'} hasBorderBottom/>
          <VideoSettingItem icon={GridSvg} label={t`Show/Hide Grid`} shortcutLabel={'G'} />
        </List>
      </Modal>}</>;
}
