import { ReactNode, useRef, useState } from 'react';
import {
  styled,
  Avatar,
  Typography,
  Box, SvgIcon, IconButton, ClickAwayListener, Paper,
  Popper,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  AccordionSummaryProps,
  AccordionProps,
  LinearProgress,
  Zoom,
} from '@mui/material';
import { t } from '@lingui/macro';
import { ReactComponent as ChecklistSvg } from 'common/images/icons/Utility/multiple list.svg';
import { ReactComponent as CompleteChecklistSvg } from 'common/images/icons/Utility/multiple checklist.svg';
import { ReactComponent as LockSvg } from 'common/images/icons/Utility/lock.svg';
import { ReactComponent as UnlockSvg } from 'common/images/icons/Utility/unlock.svg';
import { ReactComponent as DownSvg } from 'common/images/icons/Utility/down pointer.svg';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import { Check } from '@mui/icons-material';
import { isUndefined } from 'lodash';
import { useChecklistData } from 'common/hooks/useChecklistData';
import { trackEvent } from 'common/utils/errors';


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  background: 'none',
  borderRadius: 0,
  border: 0,
  '&:before': {
    display: 'none',
  },
});

const AccordionSummary = styled(({ hasIcon, ...props }: AccordionSummaryProps & { hasIcon: boolean }) => (
  <MuiAccordionSummary
    expandIcon={hasIcon ? <SvgIcon
      sx={{ fontSize: 10 }}
      component={DownSvg} inheritViewBox /> : undefined}
    {...props}
  />
))({
  background: 'none',
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
  '& p': {
    padding: 0,
    margin: 0,
    maxHeight: 0,
    color: PALETTE_COLOR.neutral[800],
    overflow: 'hidden',
    transition: 'all 0.5s',
  },
  '&.Mui-expanded p': {
    maxHeight: 100,
  },
});


type ChecklistIconProps = {
  /** text to show for this step */
  text: string;
  /** if this step is complete */
  isComplete: boolean;
};
/**
 * Component to show the current step
 */
function ChecklistIcon({ text, isComplete }: ChecklistIconProps) {
  const baseSX = {
    width: 32, height: 32,
    mr: SPACER.XS,
    bgcolor: PALETTE_COLOR.neutral[400],
    color: PALETTE_COLOR.neutral[0],
  };
  return <>
    <Zoom in={isComplete} unmountOnExit>
      <Avatar
        sx={{
          ...baseSX,
          bgcolor: PALETTE_COLOR.success[400],
        }} >
        <Check />
      </Avatar>

    </Zoom>
    {!isComplete &&
      <Avatar
        sx={baseSX} >
        {text}
      </Avatar>
    }
  </>;
}

type ChecklistItemProps = {
  /** step */
  step: string;
  /** title of this step */
  title: string;
  /** if this step is complete */
  isComplete?: boolean;
  /** the details of this step */
  children?: ReactNode;
  /** if this has an icon */
  hasIcon?: boolean;
};

/** Component to show checklist item */
function ChecklistItem({ step, hasIcon = true, title, isComplete = false, children }: ChecklistItemProps) {
  return <Accordion>
    <AccordionSummary hasIcon={hasIcon}>
      <ChecklistIcon text={step} isComplete={isComplete} />
      <Typography variant='h6Semibold' component='h6'>
        {title}
      </Typography>
    </AccordionSummary>
    {isUndefined(children) ? <></> : <AccordionDetails>
      {children}
    </AccordionDetails>}
  </Accordion>;
}

type ChecklistSummaryProps = {
  /** icon for this section */
  icon?: ReactNode;
  /** title of this section */
  title: string;
  /** description of this section */
  description: string;
  /** the progress of this section in % */
  progress?: number;
};

/** Component for the summary of a top level section */
function ChecklistSummary({ icon, title, description, progress = 0 }: ChecklistSummaryProps) {
  return <AccordionSummary hasIcon={false}>
    <Box>
      <Typography variant='h5Semibold' component='h5' className={progress === 100 ? 'strikethrough' : undefined}>
        {icon}
        {title}
      </Typography>
      <p>{description}</p>
      <Box display={'flex'} alignItems={'center'}>
        <LinearProgress
          color="secondary"
          variant="determinate"
          value={progress}
          sx={{ flexGrow: 1, mr: SPACER.XS }}
        />
        {`${progress}%`}
      </Box>
    </Box>
  </AccordionSummary>;
}

/**
 * Checklist component that shows the current user's onboarding status.
 */
export default function Checklist() {
  const [open, setOpen] = useState<boolean>(false);
  const [isSecondExpanded, setSecondExpanded] = useState<boolean | undefined>();
  const button = useRef<HTMLButtonElement>(null);
  const {
    firstProgress,
    hasCTA,
    hasClips,
    hasCopied,
    hasPersonal,
    hasScreenshared,
    secondProgress,
  } = useChecklistData({
    showChecklist: () => setOpen(true),
  });
  const isFirstComplete = firstProgress === 100;
  const isSecondComplete = secondProgress === 100;
  const expandedSecond = isSecondExpanded ?? isFirstComplete;
  const isComplete = isSecondComplete && isFirstComplete;
  const numberOfSteps = '3';

  return <>
    <IconButton
      ref={button}
      onClick={() => {
        setOpen(true);
        trackEvent('checklist');
      }}
      sx={{ p: 0, mr: 1, color: PALETTE_COLOR.neutral[400] }}>
      {isComplete ?
        <SvgIcon
          sx={{ fill: 'none' }}
          component={CompleteChecklistSvg}
          inheritViewBox /> :
        <SvgIcon
          component={ChecklistSvg}
          inheritViewBox />}
    </IconButton>
    <Popper
      sx={(theme) => ({ zIndex: theme.zIndex.modal + 1 })}
      open={open}
      disablePortal={false}
      anchorEl={button.current}
      placement='bottom-end'
      onReset={undefined}
      onResetCapture={undefined}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Paper
          elevation={1}
          sx={{
            p: SPACER.S,
            maxWidth: 369,
            background: PALETTE_COLOR.secondary[50],
          }}>
          <Accordion expanded={!expandedSecond}
            onChange={(_ignore, expand) => {
              setSecondExpanded(!expand); 
              trackEvent('checklist-basics');
            }}
          >
            <ChecklistSummary
              title={t`Getting the basics`}
              description={t`${numberOfSteps} simple steps to creating your video - no video expertise required.`}
              progress={firstProgress}
            />
            <AccordionDetails>
              <Paper elevation={0} sx={{ padding: SPACER.S }}>
                <ChecklistItem
                  step='1'
                  hasIcon={false}
                  title={t`Create your account`}
                  isComplete={true} >
                  {t`You’re sorted!`}
                </ChecklistItem>
                <ChecklistItem
                  step='2'
                  title={t`Record your video`}
                  isComplete={hasClips}  >
                  {t`Meet our recorder. Use your webcam to get your video started.`}
                </ChecklistItem>
                <ChecklistItem
                  step={numberOfSteps}
                  title={t`Share your video`}
                  isComplete={hasCopied}  >
                  {t`You’ve created your first video. Now share it with others.`}
                </ChecklistItem>
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expandedSecond}
            onChange={(_ignore, expand) => {
              setSecondExpanded(expand);
              trackEvent('checklist-expert');
            }}
            sx={{ borderTop: `1px solid ${PALETTE_COLOR.neutral[300]}` }}>
            <ChecklistSummary
              icon={<SvgIcon component={isFirstComplete ? UnlockSvg : LockSvg} inheritViewBox
                sx={{ verticalAlign: 'text-top', mr: SPACER.XS }}
                fontSize='inherit' />}
              title={t`Becoming a video expert`}
              description={t`Discover how to maximize the potential of your video with personalization at scale.`}
              progress={secondProgress}
            />
            <AccordionDetails>
              <Paper elevation={0} sx={{ padding: SPACER.S }}>
                <ChecklistItem
                  step='1'
                  title={t`Add personalized intros`}
                  isComplete={hasPersonal}  >
                  {t`Create a personal intro for a target audience or specific recipient.`}
                </ChecklistItem>
                <ChecklistItem
                  step='2'
                  title={t`Add a screen share`}
                  isComplete={hasScreenshared}  >
                  {t`Share your screen and show the value you can create.`}
                </ChecklistItem>
                <ChecklistItem
                  step='3'
                  title={t`Add a call-to-action`}
                  isComplete={hasCTA}  >
                  {t`Drive your audience to a specific page or website so they can learn more.`}
                </ChecklistItem>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </ClickAwayListener>
    </Popper>
  </>;
}