import { Container, Skeleton } from '@mui/material';
import { SPACER } from 'config/models/themeOptions';
import FetchProject from 'common/components/fetchProject.component';
import TabbedShareView from 'dashboard/components/tabbedShareView.component';
import AlertRefresh from '../../common/components/alert/alertRefresh.component';

/**
 * Share Page for project
 */
export default function SharePage() {
  return (
    <Container component="main" sx={{ py: SPACER.M }} maxWidth={'lg'}>
        <FetchProject
          fetchPolicy="no-cache"
          render={(data, onTitleUpdate) => <TabbedShareView data={data} onTitleUpdate={onTitleUpdate} />}
          renderError={() =>
              <>
                  <AlertRefresh/>
                  <Skeleton height={57} width={'100%'} variant="rounded" sx={{ mt: 5 }} />
                  <Skeleton sx={{ height: { xs: 250, md: 500 }, width: '100%', mt: 5 }} variant="rounded" />
              </>
             }
        />
    </Container>
  );
}
