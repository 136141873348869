import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';
import { themeOptions } from '../models/themeOptions';

function Theme({ children }: { children: ReactNode }) {
  /*
  TODO: Add Support for Dark Mode
  Commented out so that we can solidify the light mode first
  
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createTheme({
        ...themeOptions,
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );
  */

  return (
    <ThemeProvider theme={createTheme(themeOptions)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default Theme;
