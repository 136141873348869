import VideoConfirm from './videoConfirm.component';
import { Button, SvgIcon, useTheme, useMediaQuery, Typography, Box } from '@mui/material';
import VideoSelected from './videoSelected.component';
import { IVideoClip } from 'data/_generated';
import { isEmpty, isError, isUndefined } from 'lodash';
import { UpdateClipInput, UpdateClipTitleInput } from 'edit/const';
import { t } from '@lingui/macro';
import AlertError from 'common/components/alert/alertError.component';
import LogRocket from 'logrocket';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as DeleteSvg } from 'common/images/icons/Utility/trash.svg';
import { ReactComponent as AddSvg } from 'common/images/icons/Utility/add_2.svg';
import { ReactComponent as EmptyHeartSvg } from 'common/images/icons/Utility/empty heart.svg';
import { ReactComponent as FullHeartSvg } from 'common/images/icons/Utility/full heart.svg';
import { isClipSaved } from 'edit/helper';
import { ClipNameModal } from './clipNameModal.component';

type DeleteButtonProps = {
  /** The icon for this delete button */
  icon?: React.ReactNode;
  /** 
   * text for the delete button
   * @default Delete
   */
  text?: string;
  /** if this button should be disabled */
  disabled?: boolean;
  /** callback promise to called on click */
  onDelete: () => void | Promise<void>;
};

/**
 * Component to show the delete button and 
 * contain loading and error handing
 */
function DeleteButton({ disabled, onDelete, text = t`Delete`,
  icon = <SvgIcon inheritViewBox component={DeleteSvg} /> }: DeleteButtonProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const onClick = async () => {
    setIsLoading(true);
    try {
      await onDelete();
    } catch (e) {
      LogRocket.error(e);
      console.error(e);
      if (isError(e)) setError(e);
      setIsLoading(false);
    }
  };
  return (
    <AlertError error={error}>
      <LoadingButton
        loading={isLoading}
        disabled={disabled}
        color="error"
        variant="outlined"
        startIcon={icon}
        onClick={() => void onClick()}
      >
        <span>{text}</span>
      </LoadingButton>
    </AlertError>
  );
}

const largeButtonLabel = {
  isSaved: t`Saved to Clip Library`,
  isNotSaved: t`Save to Clip Library`,
};

const smallButtonLabel = {
  isSaved: t`Saved`,
  isNotSaved: t`Save`,
};
type SaveToLibraryButtonProps = {
  /** callback to update the title */
  onUpdateClipTitle: (clip: UpdateClipTitleInput) => void | Promise<void>;
  /** id of video clip */
  clipId: string;
  /** video title */
  title?: string | null;
  /** whether the video clip has been saved in the library already */
  isSaved?: boolean;
};

/**
 * Button for saving video clip to library
 */
function SaveToLibraryButton({
  clipId,
  title,
  isSaved,
  onUpdateClipTitle,
}: SaveToLibraryButtonProps) {
  const theme = useTheme();
  const buttonLabel = useMediaQuery(theme.breakpoints.only('xs')) ? smallButtonLabel : largeButtonLabel;

  const [isEditingClipName, setIsEditingClipName] = useState<boolean>(false);
  return (
    <>
      <Button
        variant="outlined"
        color={isSaved ? 'success' : 'primary'}
        startIcon={<SvgIcon inheritViewBox component={isSaved ? FullHeartSvg : EmptyHeartSvg} />}
        onClick={() => setIsEditingClipName(true)}
      >
        <Typography textOverflow="ellipsis" noWrap>
          {isSaved ? buttonLabel.isSaved : buttonLabel.isNotSaved}
        </Typography>
      </Button>
      {isEditingClipName &&
        <ClipNameModal
          onUpdateClipTitle={onUpdateClipTitle}
          onClose={() => setIsEditingClipName(false)}
          clipId={clipId}
          title={title}
        />}
    </>
  );
}

type VideoWorkspaceProps = {
  deleteIcon?: React.ReactNode;
  /** text for delete clip */
  deleteText?: string;
  /** callback to delete clip */
  onDelete: () => void | Promise<void>;
  onUpdateClip: (clip: UpdateClipInput) => boolean | Promise<boolean>;
  /** callback to update the title */
  onUpdateClipTitle: (clip: UpdateClipTitleInput) => void | Promise<void>;
  /** callback to add new single slot */
  onNewSingleSlot?: () => void;
  /** whether recording is in progress */
  isRecording: boolean;
  /** sets isRecording state */
  setIsRecording: (r: boolean) => void;
  /** the video clip */
  videoClip?: IVideoClip;
  /** the script */
  script?: string;
  /** show teleprompter */
  showTeleprompter?: boolean;
  /** controls should be disabled */
  disabled?: boolean;
  /** the start of the current clip, in % */
  start?: number;
  /** the end of the current clip, in % */
  end?: number;
  /** clip uploading percent */
  uploadingPercent?: number;
};

export default function VideoWorkspace({
  disabled,
  deleteIcon,
  deleteText,
  onDelete,
  onUpdateClipTitle,
  onUpdateClip,
  onNewSingleSlot,
  videoClip,
  script,
  showTeleprompter,
  start,
  end,
  isRecording,
  setIsRecording,
  uploadingPercent,
}: VideoWorkspaceProps) {
  const onUpdate = async (clip: UpdateClipInput) => {
    await onUpdateClip(clip);
  };
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'width 0.3s ease-in-out',
        overflow: 'hidden',
      }}
    >
      {!isEmpty(videoClip) && videoClip.location ? (
        <VideoSelected key={videoClip.id} start={start} end={end} clip={videoClip} onUpdate={onUpdate}>
          <DeleteButton icon={deleteIcon} text={deleteText} disabled={disabled} onDelete={onDelete} />
          <SaveToLibraryButton
            onUpdateClipTitle={onUpdateClipTitle}
            title={videoClip.title}
            clipId={videoClip.id}
            isSaved={isClipSaved(videoClip)}
          />
          {!isUndefined(onNewSingleSlot) &&
            <LoadingButton
              startIcon={<SvgIcon inheritViewBox component={AddSvg} />}
              variant={'outlined'}
              onClick={() => onNewSingleSlot()}
            >
                <span>{t`Add Clip`}</span>
            </LoadingButton>
          }
        </VideoSelected>
      ) : (
        <VideoConfirm uploadingPercent={uploadingPercent} script={script} onUpload={onUpdateClip} showTeleprompter={showTeleprompter} isRecording={isRecording} setIsRecording={setIsRecording}></VideoConfirm>
      )}
    </Box>
  );
}
