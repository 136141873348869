import { t } from '@lingui/macro';
import Modal from 'common/components/modal/modal.component';
import TextField from 'common/components/textField/textField.component';
import { useState } from 'react';
import { ReactComponent as SaveSvg } from 'common/images/icons/Electrics/floppy disk.svg';
import { Typography } from '@mui/material';
import { isEmpty, isError, isUndefined } from 'lodash';
import LogRocket from 'logrocket';
import { UpdateClipTitleInput } from 'edit/const';


type ClipNameModalProps = {
  /** callback to update the title */
  onUpdateClipTitle: (clip: UpdateClipTitleInput) => void | Promise<void>;
  /** clip id */
  clipId: string;
  /** clip title */
  title?: string | null;
  /** callback on close */
  onClose: () => void;
};

/**
 * Modal for setting clip name
 */
export function ClipNameModal({ clipId, title, onClose, onUpdateClipTitle }: ClipNameModalProps) {
  const modalTitle = title ? t`Rename your clip` : t`Save to Clip Library`;
  const modalDescription = title
    ? t`Name changes will also be reflected in your Clip Library.`
    : t`Access saved clips from your Clip Library in any video project.`;
  const [clipTitle, setClipTitle] = useState<string>(title ?? '');

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  
  const onClickClose = () => {
    setError(undefined);
    setClipTitle(title ?? '');
    onClose();
  };

  const onSaveTitle = async () => {
    if (isEmpty(clipTitle)) {
      setError(Error(t`Required field`));
      return;
    }
    setIsSaving(true);
    try {
      await onUpdateClipTitle({ id: clipId, title: clipTitle });
      onClose();
    } catch (e) {
      if (isError(e)) setError(Error(t`Update Failed`));
      LogRocket.error('Clip Title Update Failed', e);
      console.error('Clip Title Update Failed', e);
      setIsSaving(false);
    }
  };

  return (
    <Modal
      title={modalTitle}
      confirmLabel={t`Save`}
      cancelLabel={t`Cancel`}
      onConfirm={() => void onSaveTitle()}
      onClose={onClickClose}
      isOpen
      icon={SaveSvg}
      confirmLoading={isSaving}
      maxWidth="xs"
    >
      <>
        <Typography variant="bodyMedium">{modalDescription}</Typography>
        <TextField
          fullWidth
          error={!isUndefined(error)}
          helperText={error?.message}
          placeholder={t`Enter clip name here`}
          onChange={(e) => setClipTitle(e.currentTarget.value)}
          value={clipTitle}
        />
      </>
    </Modal>
  );
}
