import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, useEffect, useState } from 'react';
import { StripeAddressElementOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { PALETTE_COLOR, SPACER } from '../../config/models/themeOptions';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, SvgIcon, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { IPaymentMethod, useSubscribeToPlanMutation } from '../../data/_generated';
import { isNil, isUndefined, toLower } from 'lodash';
import { CardBrandDisplayNames, getCardBrandIcon } from '../../common/utils/payment';
import { SetupIntentResult } from '@stripe/stripe-js/types/stripe-js/stripe';
import Alert from '../../common/components/alert/alert.component';
import { ReactComponent as CreditCardSvg } from 'common/images/icons/Business & Finance/Credit card payment.svg';
import AlertError from '../../common/components/alert/alertError.component';

const PaymentElementOptions: StripePaymentElementOptions = {
  layout: 'tabs',
};

type CreditCardUIProps = {
  brandName: string;
  last4: string;
  expiry: string;
};

const CreditCardUI = ({ brandName, last4, expiry }: CreditCardUIProps) => {
  const cardLogo = getCardBrandIcon(toLower(brandName));

  return (
    <Box>
      <Typography>{t`Saved card`}</Typography>
      <Box
        sx={{
          p: 4,
          flexDirection: 'column',
          color: PALETTE_COLOR.neutral[0],
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid grey',
          borderRadius: '10px',
          backgroundColor: PALETTE_COLOR.primary[400],
          // credit card aspect ratio
          aspectRatio: 1.586,
          m: '0 auto',
          maxWidth: 400,
        }}
      >
        {cardLogo ? (
          <SvgIcon inheritViewBox component={cardLogo} sx={{ fontSize: '5rem' }} />
        ) : (
          <Typography variant="h3">{CardBrandDisplayNames[toLower(brandName)]}</Typography>
        )}
        <Box>
          <Typography color={PALETTE_COLOR.secondary[50]}>{t`Card number`}</Typography>
          <Typography fontSize={'20px'}>{`**** **** **** ${last4}`}</Typography>
        </Box>

        <Box display="flex">
          <Box display="block" mr={20}>
            <Typography color={PALETTE_COLOR.secondary[50]}>{t`Expiry`}</Typography>
            <Typography fontSize={'20px'}>{expiry}</Typography>
          </Box>
          <Box display="block">
            <Typography color={PALETTE_COLOR.secondary[50]}>{t`CVV`}</Typography>
            <Typography fontSize={'20px'}>***</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type PaymentFormProps = {
  /** The name of the plan */
  planName: string;
  /** The username of the user */
  userName?: string;
  /** last saved payment method */
  paymentMethod?: IPaymentMethod | null;
};

/**
 * Component for stripe payment form for subscribing to plan
 */
export default function PaymentForm({ planName, userName, paymentMethod }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [intentError, setIntentError] = useState<Error | undefined>();
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(true);
  const [isAddressLoading, setIsAddressLoading] = useState<boolean>(true);
  const [showForm, setShowForm] = useState<boolean>(isNil(paymentMethod?.id));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscribeToPlan, { error: subscribeError }] = useSubscribeToPlanMutation({
    refetchQueries: ['UserSubscriptionDetails'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    //To have custom loading status. Stripe loader is slightly delayed.
    if (elements) {
      const paymentElement = elements.getElement('payment');
      const addressElement = elements.getElement('address');
      paymentElement?.on('ready', () => setIsPaymentLoading(false));
      addressElement?.on('ready', () => setIsAddressLoading(false));
    }
  }, [elements]);

  const addressElementOptions: StripeAddressElementOptions = {
    mode: 'billing',
    defaultValues: {
      name: userName,
    },
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsSubmitting(true);
    let setupIntentResult: SetupIntentResult | undefined = undefined;
    if (showForm || isNil(paymentMethod?.id)) {
      setupIntentResult = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required',
      });
    }

    if (!isUndefined(setupIntentResult) && !isUndefined(setupIntentResult.error)) {
      if (setupIntentResult.error.type === 'validation_error') {
        setIntentError(new Error('Some fields are missing')); // update the message if it's a validation error
      } else {
        setIntentError(new Error(setupIntentResult.error.message));
      }
    } else {
      await subscribeToPlan({ variables: { planName } });
    }
    setIsSubmitting(false);
  };
  const isLoading = isNil(paymentMethod?.id) && (isAddressLoading || isPaymentLoading);
  return (
    <>
      <form onSubmit={(e) => void handleSubmit(e)} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {showForm ? (
          <Box sx={{ minHeight: 300,
            transition: 'all 500ms',
            position: 'relative', opacity: isSubmitting ? 0.5 : 1, pointerEvents: isSubmitting ? 'none' : 'all' }}>
            <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
              <AddressElement options={addressElementOptions} onChange={()=> setIntentError(undefined)} />
              <PaymentElement options={PaymentElementOptions} onChange={()=> setIntentError(undefined)} />
            </Box>
            {isLoading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', translate: '-50% -50%' }} />}
          </Box>
        ) : !isNil(paymentMethod) && !isNil(paymentMethod.card) ? (
          <>
            <CreditCardUI
              brandName={paymentMethod.card.brand}
              last4={paymentMethod.card.last4}
              expiry={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
            />
            <Button
              onClick={() => setShowForm(true)}
              sx={{ width: 'fit-content', alignSelf: 'end' }}
            >{t`Use different card`}</Button>
          </>
        ) : (
          <Alert
            description={t`Your last saved payment method will be used`}
            icon={<SvgIcon inheritViewBox component={CreditCardSvg} />}
          />
        )}
        <Box mt={SPACER.S}>
          <AlertError error={subscribeError ?? intentError} maxWidth={'100%'}>
              <LoadingButton
                fullWidth
                type={'submit'}
                color={isUndefined(subscribeError ?? intentError) ? 'primary' : 'error'}
                loading={isLoading || isSubmitting}
                disabled={!stripe || !elements}
                variant={'contained'}
              >
                <span>{t`Subscribe`}</span>
              </LoadingButton>
          </AlertError>
        </Box>
      </form>
    </>
  );
}
