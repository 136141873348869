import { t } from '@lingui/macro';
import { Button, useMediaQuery } from '@mui/material';
import Alert from './alert.component';
import { Theme } from '@mui/material/styles';

/**
 * Alert error component with refresh button
 * @param title
 */
export default function AlertRefresh({ title = t`Whoops! Something happened on our end. Please refresh.` }: { title?: string }) {
  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Alert
      sx={{
        mt: 3,
        display: 'flex',
        '& .MuiBox-root': isXS
          ? undefined
          : { display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-start' },
        '& .MuiButton-root': {
          color: 'inherit',
          borderColor: 'inherit',
          '&:hover': {
            borderColor: 'inherit',
          },
        },
      }}
      title={title}
      severity={'warning'}
      description={
          <Button variant="outlined" color={'warning'} onClick={() => window.location.reload()}>
            {t`Refresh`}
          </Button>
      }
    />
  );
}
