import VideoTimeline, { isPersonalizedSlotFocus, TimelineSlotFocus } from 'edit/components/videoTimeline.component';
import VideoWorkspace from 'edit/components/videoWorkspace.component';
import { UpdateClipInput, isSingleClipSlot, UpdateClipTitleInput, NAME_TITLE_TOKEN } from 'edit/const';
import { ReactNode, useEffect, useState } from 'react';
import {
  SvgIcon, Toolbar, Typography,
  AppBar, Box, Button, Skeleton,
} from '@mui/material';
import { t } from '@lingui/macro';
import {
  ICallToAction,
  IThumbnail,
  ITimelineObject,
  ITarget,
  ITargetInput, ISingleClipSlot, IVideoClip,
} from 'data/_generated';
import { isEmpty, isUndefined } from 'lodash';
import { ReactComponent as NoCloudSvg } from 'common/images/icons/Extras/no cloud.svg';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate three.svg';
import { ReactComponent as RotateTwoSvg } from 'common/images/icons/Utility/rotate two.svg';
import { ReactComponent as ShareSvg } from 'common/images/icons/Utility/share.svg';
import { ReactComponent as PlaySvg } from 'common/images/icons/Camera/Play.svg';
import { LoadingButton } from '@mui/lab';
import { ScriptGenModal } from './scriptGenModal';
import AlertError from '../../common/components/alert/alertError.component';
import { useDebounce } from 'usehooks-ts';
import Alert from '../../common/components/alert/alert.component';
import { useLocation } from 'react-router-dom';

type EditAppBarProps = {
  isPreviewReady?: boolean;
  shareError?: Error;
  onShare: () => void;
  onPreview?: () => void;
  isSaving?: boolean;
  isPublishing?: boolean;
  disabled?: boolean;
  flexibleRecording: boolean;
};

function EditAppBar({ isPreviewReady, onPreview, isSaving, isPublishing, onShare, shareError, disabled, flexibleRecording }: EditAppBarProps) {
  return <AppBar elevation={1} position="fixed" sx={{ bottom: 0, top: 'auto' }} color="default">
    <Toolbar
      sx={{
        justifyContent: {
          xs: 'center',
          sm: 'space-between',
        },
      }}
    >
      <SavingDisplay isSaving={isSaving} />
      <Box>
        {!flexibleRecording && onPreview && <Button
          disabled={!isPreviewReady || disabled}
          variant='contained'
          color='secondary'
          sx={{ mr: 4 }}
          startIcon={<SvgIcon inheritViewBox component={PlaySvg} />}
          onClick={() => onPreview()}>
          {t`Preview`}
        </Button>}
        <AlertError error={shareError}>
        <LoadingButton onClick={onShare} variant="contained" disabled={!isPreviewReady || isSaving || disabled} loading={isPublishing} startIcon={<SvgIcon inheritViewBox component={ShareSvg} />}>
          <span>{t`Process and share`}</span>
        </LoadingButton>
        </AlertError>
      </Box>
    </Toolbar>
  </AppBar>;
}

type SavingDisplayProps = {
  /** if saving is currently working */
  isSaving?: boolean;
};

/** Component to show all changes are auto saved and when saving is working */
function SavingDisplay({ isSaving }: SavingDisplayProps) {
  if (isUndefined(isSaving)) return <Box>
    <Alert
        sx={{ padding: '0 7px' }}
        severity={'warning'}
        icon={<SvgIcon component={NoCloudSvg} inheritViewBox />}
        description={t`Your media will not be saved`}/>
  </Box>;
  return <Box display='flex' >
    {isSaving ?
      <SvgIcon
        component={RotateSvg}
        inheritViewBox
        sx={(theme) => ({
          fontSize: 'inherit',
          color: theme.palette.neutral[500],
          animation: 'rotating 2s linear infinite',
        })}
        color='error' /> : undefined}
    <Typography paddingLeft={1} sx={(theme) => ({
      color: theme.palette.neutral[500],
      display: {
        xs: 'none',
        md: 'inline',
      },
    })} variant='bodyRegItalic'>
      {isSaving ?
        t`Saving in progress` :
        t`All changes are autosaved`}</Typography>
  </Box>;
}


type EditProps = {
  /** if the preview is ready */
  isPreviewReady?: boolean;
  /**
   * if the changes are saving,
   * if undefined then changes are not autosaved and do not show any messaging about autosaving */
  isSaving?: boolean;
  /** if publishing is in progress */
  isPublishing?: boolean;
  /** the key for the current slot in focus */
  focusKey: string;
  /** The current saved script */
  script?: string;
  /** the current slot that is being focused on */
  focus?: TimelineSlotFocus;
  /** the slot to focus */
  setFocus: (newFocus: TimelineSlotFocus) => void;
  /** list of slots */
  slots: Readonly<ITimelineObject[]>;
  /** callback to add new single slot to the timeline */
  onNewSingleSlot: (addAfterFocus?: boolean) => void | Promise<void>;
  /** delete icon when deleting a clip  */
  deleteClipIcon?: ReactNode;
  /** text to show when deleting a clip */
  deleteClipText?: string;
  /** callback to delete the current focused clip */
  onDeleteClip: () => void | Promise<void>;
  /** callback to update the current */
  onUpdateClip: (clip: UpdateClipInput) => boolean | Promise<boolean>;
  /** callback to update the click title */
  onUpdateClipTitle: (clip: UpdateClipTitleInput) => void | Promise<void>;
  /** callback to update the script */
  onUpdateScript: (script?: string) => void | Promise<void>;
  /** callback to delete the specified slot */
  onDeleteSlot: (slot: ISingleClipSlot) => void | Promise<void>;
  /** callback to duplicate the specified slot */
  onDuplicateSlot: (slot: ISingleClipSlot) => void | Promise<void>;

  /** callback to add a transition at a specific index,
   * no number indicates transition after personalizations
   */
  onNewTransition: (i?: number) => void | Promise<void>;
  /** callback to delete a transition at a specific index,
   * no number indicates transition after personalizations
   */
  onDeleteTransition: (i?: number) => void | Promise<void>;
  /** callback to update the targets */
  onUpdateTargets: (data: ITargetInput[]) => void | Promise<void>;
  /** callback to update order of slots */
  onUpdateOrder: (slotIndex: number, newIndex: number) => void | Promise<void>;
  /** landing page title template for project */
  landingPageTitleTemplate?: string | null;
  /** landing page links */
  links?: ICallToAction[] | null;
  /** thumbnail for processed video */
  thumbnail?: IThumbnail | null;
  /** list of targets */
  targets: Readonly<ITarget[]>;
  /** share action error */
  shareError?: Error;
  /** callback when share is clicked */
  onShare: () => void;
  /** callback on preview videos */
  onPreview: () => void;
  /** callback to open target modal */
  openTargetModal: (addNew?: boolean) => void;
  /** if to start with showing the timeline */
  showTimelineDefault: boolean;
  /** if the user can open and close the timeline */
  flexibleRecording: boolean;
  /** clip uploading percent */
  uploadingPercent?: number;
};

type FocusDetails = {
  /** the video clip currently in focus */
  videoClip?: IVideoClip;
  /** the start of the current slot */
  start?: number;
  /** the end of the current slot */
  end?: number;
  /** the target name of the current target */
  targetName?: string;
};

/**
 * Editing timeline view, needs to be the top level component
 */
export default function Edit({
  focusKey,
  focus,
  script,
  setFocus,
  isPreviewReady,
  isSaving,
  isPublishing,
  slots,
  onDeleteSlot,
  onDuplicateSlot,
  onDeleteClip,
  onUpdateScript,
  onDeleteTransition,
  onNewSingleSlot,
  onNewTransition,
  onUpdateClip,
  onUpdateClipTitle,
  onUpdateTargets,
  onUpdateOrder,
  targets,
  shareError,
  onShare,
  onPreview,
  deleteClipIcon = <SvgIcon inheritViewBox component={RotateTwoSvg}/>,
  deleteClipText = t`Re-record`,
  openTargetModal,
  showTimelineDefault,
  flexibleRecording,
  uploadingPercent,
}: EditProps) {
  const { hash } = useLocation();
  const [isScriptGenOpen, setIsScriptGenOpen] = useState(hash === '#script');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [showTeleprompter, setShowTeleprompter] = useState<boolean>(false);
  //TODO: Remove /quick check when flexible recording is permanently released
  const [showTimeline, setShowTimeline] = useState<boolean>(flexibleRecording ? (isEmpty(hash) || showTimelineDefault) : showTimelineDefault);
  const [internalScript, setInternalScript] = useState<string | undefined>(script);
  const debouncedValue = useDebounce<string | undefined>(internalScript, 1000);

  useEffect(() => {
    if (script === debouncedValue) return;
    void onUpdateScript(debouncedValue);
  }, [debouncedValue, script]);

  function getFocusDetails(f?: TimelineSlotFocus): FocusDetails {
    if (isUndefined(f)) return {};
    else if (isPersonalizedSlotFocus(f)) {
      const slot = f.slot?.personalizedClips?.find((clip) => clip.targetId === f.targetFocus);
      const targetName = targets.find(c=> c.id === f.targetFocus)?.name;
      return {
        videoClip: slot?.videoClip ?? undefined,
        start:  slot?.start ?? undefined,
        end: slot?.end ?? undefined,
        targetName,
      };
    } else if (isSingleClipSlot(f.slot)) {
      return {
        videoClip: f.slot.videoClip ?? undefined,
        start:f.slot.start ?? undefined,
        end: f.slot.end ?? undefined,
      };
    }
    return f.slot satisfies never;
  }

  const handleAddNewSlot = async (addAfterFocus: boolean) => {
    await onNewSingleSlot(addAfterFocus);
    setShowTimeline(true);
  };

  const { videoClip, start, end, targetName } = getFocusDetails(focus);
  const currentScript = internalScript?.replace(NAME_TITLE_TOKEN, targetName ?? NAME_TITLE_TOKEN);

  return (
    <>
        <Box
          display='flex'
          justifyContent='center'
          alignContent='center'
          alignItems='stretch'
          flexDirection='row'
          flexGrow={1}
          flexShrink={1}
          flexBasis={0}
          width='100%'
          position='relative'
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
        {focus ?
            <VideoWorkspace
              videoClip={videoClip}
              uploadingPercent={uploadingPercent}
              start={start}
              end={end}
              script={currentScript}
              key={focusKey}
              deleteIcon={deleteClipIcon}
              deleteText={deleteClipText}
              onDelete={onDeleteClip}
              onUpdateClipTitle={onUpdateClipTitle}
              onUpdateClip={onUpdateClip}
              onNewSingleSlot={isPersonalizedSlotFocus(focus) ? undefined : (flexibleRecording ? () => void handleAddNewSlot(true) : undefined)}
              showTeleprompter={showTeleprompter}
              isRecording={isRecording}
              setIsRecording={(r) => setIsRecording(r)}
            /> : <Skeleton sx={{
              margin: '0 auto',
              transform: 'none',
              aspectRatio: '4/3',
              maxHeight: '50vh',
              maxWidth: '100%',
              alignSelf: 'center',
              overflow: 'hidden',
            }} />}
          <ScriptGenModal
              addBottomBorder={flexibleRecording}
              isOpen={isScriptGenOpen && isUndefined(videoClip)}
              setIsOpen={setIsScriptGenOpen}
              showButton={isUndefined(videoClip)}
              script={internalScript}
              onSaveScript={setInternalScript}
              displayTeleprompter={showTeleprompter}
              onDisplayTeleprompterChange={() => setShowTeleprompter((display) => !display)}
          />
      </Box>
      <VideoTimeline
          canShowHide={flexibleRecording}
          slots={slots}
          disabled={isRecording}
          targets={targets}
          focus={focus}
          setFocus={(slot) => {
            setFocus(slot);
          }}
          appendSingleSlot={async () => {
            await onNewSingleSlot();
          }}
          duplicateSlot={onDuplicateSlot}
          removeSlot={onDeleteSlot}
          addTransition={onNewTransition}
          removeTransition={onDeleteTransition}
          updateTargets={onUpdateTargets}
          updateOrder={onUpdateOrder}
          isOpen={showTimeline}
          setIsOpen={setShowTimeline}
          onPreview={onPreview}
          openTargetModal={openTargetModal}
      />
        <Toolbar />
        <EditAppBar
          flexibleRecording={flexibleRecording}
          isPreviewReady={isPreviewReady}
          onPreview={onPreview}
          isSaving={isSaving}
          isPublishing={isPublishing}
          shareError={shareError}
          onShare={onShare}
          disabled={isRecording}
        />
  </>
  );
}
