import { useNumberFeatureFlag } from 'config/hooks/useFeatureFlags';
import { NumberFeatureFlagNames } from 'config/models/featureFlags';

export default function useTrialLength() {
  const trialDays = useNumberFeatureFlag(NumberFeatureFlagNames.trialDays).number;

  return {
    trialDays,
    trialDaysEnabled: trialDaysEnabled(trialDays),
  };
}

/** Helper to determine if trialLength feature flag is enabled.
 *  if trialDays is -1, feature is considered disabled.
 */
function trialDaysEnabled(trialDays: number): boolean {
  return trialDays !== -1;
}
