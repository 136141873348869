import { assertUnreachable } from './util';

/**
 * Helper to correctly order values by type
 * null in lodash orderBy is sorted before actual values.
 * For string, lowercase the string to compare. If it's null or undefined, compare it as empty string.
 * For number, if it's null or undefined, compare it as 0.
 * @param orderByField name of field to order by
 * @param stringFieldNames list of string fields
 * @param numberFieldNames list of number fields
 * @param value value
 */
export function orderIterateeHelper(
  orderByField: string,
  stringFieldNames: string[],
  numberFieldNames: string[],
  value?: string | number | null,
) {
  if (typeof value === 'string') {
    return value.toLowerCase();
  } else if (stringFieldNames.includes(orderByField)) {
    return value ?? '';
  } else if (numberFieldNames.includes(orderByField)) {
    return value ?? 0;
  }
  // cases are all covered. Shouldn't reach here.
  return assertUnreachable;
}
