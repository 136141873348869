import { useRef, useState } from 'react';
import {
  Button,
  Menu,
  Avatar,
  MenuItem,
  ListItemIcon,
  Typography,
  SvgIcon, CircularProgress,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from '../../../config/hooks/useAuth';
import { stringToColor } from 'common/utils/style';
import { t } from '@lingui/macro';
import { ReactComponent as ExportSvg } from 'common/images/icons/Utility/export.svg';
import { trackEvent } from 'common/utils/errors';
import Checklist from './checklist.component';
import FeedbackOptions from './feedbackOptions.component';
import CustomerPortal from './customerPortal.component';
import { useFeatureFlag } from '../../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../../config/models/featureFlags';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { appInsights } from '../../../config/components/azure.component';
import { useUserInfoQuery } from '../../../data/_generated';
import { isUndefined } from 'lodash';
import { useMsal } from '@azure/msal-react';



export const HELP_CENTER_URL = 'https://help.copilotai.com/video-by-copilot-ai';

/**
 * Helper to build a abbreviated name based off the full name of the user
 *
 * @param name the name of the user
 * @param size the size of avatar
 * @returns a unique color for the user by their name
 */
export function stringAvatar(name: string, size?: number) {
  const splitNameArray = name.split(' ');
  const abbreviated = `${splitNameArray[0][0]}${splitNameArray.length > 1 ? splitNameArray[1][0] : ''}`;
  return {
    sx: {
      width: size ?? 24,
      height: size ?? 24,
      '&.MuiAvatar-root.MuiAvatar-circular': {
        fontSize: 12,
      },
      color: 'background.paper',
      bgcolor: stringToColor(name),
    },
    children: abbreviated,
  };
}


type UserButtonRenderInnerProps = {
  account: AccountInfo;
  onLogout: ()=> void;
};

function UserButtonRenderInner({ account, onLogout } : UserButtonRenderInnerProps) {
  const { isEnabled: checklistEnabled } = useFeatureFlag(BooleanFeatureFlagNames.onboardingChecklist);
  const { isEnabled: isDev } = useFeatureFlag(BooleanFeatureFlagNames.dev);
  // assumes that we are fecthing with the Tracking Provider
  const { loading, data } = useUserInfoQuery({ fetchPolicy: 'cache-only' });
  const [open, setOpen] = useState<boolean>(false);
  const anchorEl = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    setOpen(true);
    trackEvent('profile');
  };
  const handleClose = () => {
    setOpen(false);
  };
  const isOnboarded = !isUndefined(data?.userInfo) ;
  const canOpenMenu = isOnboarded || isDev;
  return (
      <>
        {checklistEnabled && <Checklist/>}
        <Button
            ref={anchorEl}
            onClick={handleClick}
            disabled={!canOpenMenu}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{ mr: -1 }}
            size="small"
            startIcon={<Avatar {...stringAvatar(account.name ?? '')} />}
            endIcon={loading ? <CircularProgress size={10} color='inherit' /> : (canOpenMenu ? <KeyboardArrowDownIcon/> : undefined)}
        >
          <Typography
              variant="button"
              noWrap
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
          >
            {account.name}
          </Typography>
        </Button>
        <Menu
            anchorEl={anchorEl.current}
            id="account-menu"
            open={open}
            onClose={handleClose}
            keepMounted
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
              component='a'
              href={HELP_CENTER_URL}
              target='_blank'
              onClick={() => trackEvent('profile-help-center')}>
            <ListItemIcon><HelpIcon/></ListItemIcon>
            {t`Help Center`}
          </MenuItem>
          <FeedbackOptions/>
          <CustomerPortal/>
          <MenuItem onClick={() => {
            onLogout();
            trackEvent('logout', { user_id: account.localAccountId });
          }}>
            <ListItemIcon>
              <SvgIcon inheritViewBox component={ExportSvg} transform='rotate(180)'/>
            </ListItemIcon>
            {t`Logout`}
          </MenuItem>
        </Menu>
      </>
  );
}

export default function UserButton() {
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const msal = useMsal();
  const pca = msal.instance;
  const onLogout = () => {
    appInsights.ApplicationInsights?.clearAuthenticatedUserContext();
    void instance.logout();
  };
  const isInIframe = window.parent !== window;

  const signUp = async () => {
    if (isInIframe) {
      // iframe busting
      window.parent.location = `${window.location.origin}/signup`;
      return;
    }
    await pca.handleRedirectPromise();

    const authOptions: RedirectRequest = {
      extraQueryParameters: { option: 'signup' },
      scopes: [],
      redirectUri: '/',
    };
    await pca.loginRedirect(authOptions);
  };

  const login = async () => {
    await pca.handleRedirectPromise();
    await pca.loginRedirect({
      scopes: [],
      redirectUri: '/',
    });
  };

  if (!account) return <>
    {!isInIframe &&  <Button color={'inherit'} onClick={()=> void login()} variant={'text'}>{t`Log in`}</Button>}
    <Button
      onClick={()=> void signUp()}
      variant={'contained'}
      color={'secondary'}>
      {t`Sign up`}
    </Button></>;
  return <UserButtonRenderInner
        account={account}
        onLogout={onLogout}/>;
}