import { useMsal } from '@azure/msal-react';
import { useEffectAsync } from 'common/hooks/useEffectAsync';
import { RedirectRequest } from '@azure/msal-browser';
import LoadingIndicator from 'common/components/loadingIndicator.component';
import { useSearchParams } from 'react-router-dom';
import { isUndefined } from 'lodash';

/**
 * Optional query param for redirecting to specific idp login (e.g. '...?domain_hint=google.com 
 * will redirect to google oauth screen)
 */
const DOMAIN_HINT_PARAM = 'domain_hint';

/**
 * Sign up page, redirects to the B2C SignUp journey
 */
function B2CSignUpPage() {
  const msal = useMsal();
  const [params] = useSearchParams();
  useEffectAsync(async () => {
    const pca = msal.instance;
    await pca.handleRedirectPromise();
    const domainHint = params.get(DOMAIN_HINT_PARAM) ?? undefined;
    //If domain hint is defined we do not need to link to signup page
    const extraQueryParameters = isUndefined(domainHint) ? { option: 'signup' } : undefined;
    const authOptions: RedirectRequest = {
      domainHint,
      extraQueryParameters,
      scopes: [],
      redirectUri: '/',
      redirectStartPage: '/',
    };
    await pca.loginRedirect(authOptions);
  }, []);

  return <LoadingIndicator />;
}

export default B2CSignUpPage;
