import { words } from 'lodash';
import { throwError } from './errors';
import { ValueOf } from './types';

/** TODO: Cleanup with API integration */
export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function blobToDataURL(blob: Blob) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64String = reader.result as string;
      resolve(base64String);
    };
  });
}

export function locationToDataURL(url: string) {
  return fetch(url).then(async (r) => blobToDataURL(await r.blob()));
}

export function getVideoDuration(videoPlayer: HTMLVideoElement, resolve: (duration: number) => void) {
  const timeupdate = () => {
    videoPlayer.removeEventListener('timeupdate', timeupdate);
    resolve(videoPlayer.duration);
  };

  // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
  if (videoPlayer.duration === Infinity) {
    videoPlayer.currentTime = Number.MAX_SAFE_INTEGER;
    videoPlayer.addEventListener('timeupdate', timeupdate);
  } else {
    resolve(videoPlayer.duration);
  }
}

/**
 * Get video format from file type assuming it follows in this format -
 * 'video/${format}' for chrome, edge, safari
 * or
 * `video/${format}; codecs='${codecs}'` for firefox
 * @param mimeType
 * @returns video format, throws exception if format is unsupported
 */
export function getVideoContainerFormat(mimeType: string): VideoContainerFormat {
  const [type, subType] = words(mimeType, new RegExp(/(\w-)?[\w]+/g));
  if (type !== 'video') throwError(`Unexpected file mimetype ${mimeType}`);
  switch (subType) {
    case VIDEO_MIME_TYPE_NAMES.webm: return VIDEO_CONTAINER_FORMAT_NAMES.webm;
    case VIDEO_MIME_TYPE_NAMES.quicktime: return VIDEO_CONTAINER_FORMAT_NAMES.mov;
    case VIDEO_MIME_TYPE_NAMES['x-matroska']: return VIDEO_CONTAINER_FORMAT_NAMES.matroska;
    case VIDEO_MIME_TYPE_NAMES.matroska: return VIDEO_CONTAINER_FORMAT_NAMES.matroska;
    case VIDEO_MIME_TYPE_NAMES.mp4: return VIDEO_CONTAINER_FORMAT_NAMES.mp4;
    default: throwError(`Unsupported file mimetype ${mimeType}`);
  }
}

/**
 * Get mimetype for specific video container format
 */
export function toVideoMimeType(format: VideoContainerFormat): VideoMimeType {
  switch (format) {
    case 'webm': return VIDEO_MIME_TYPE_NAMES.webm;
    case 'mov': return VIDEO_MIME_TYPE_NAMES.quicktime;
    case 'mp4': return VIDEO_MIME_TYPE_NAMES.mp4;
    case 'matroska': return VIDEO_MIME_TYPE_NAMES['x-matroska'];
  }
}

/** a list of video container formats */
export const VIDEO_CONTAINER_FORMAT_NAMES = {
  webm: 'webm',
  mov: 'mov',
  matroska: 'matroska',
  mp4: 'mp4',
} as const;

/**
 * Supported video container formats
 */
export type VideoContainerFormat = ValueOf<typeof VIDEO_CONTAINER_FORMAT_NAMES>;

const VIDEO_MIME_TYPE_NAMES = {
  webm: 'webm',
  quicktime: 'quicktime',
  mp4: 'mp4',
  'x-matroska': 'x-matroska',
  'matroska': 'matroska',
} as const;

/**
 * Defines list of video mime types that we support. Used for limiting types in file input
 */
export const SUPPORTED_VIDEO_MIME_TYPES_STRING = [
  VIDEO_MIME_TYPE_NAMES.webm, 
  VIDEO_MIME_TYPE_NAMES.mp4, 
  VIDEO_MIME_TYPE_NAMES.quicktime, 
  VIDEO_MIME_TYPE_NAMES.matroska, 
  VIDEO_MIME_TYPE_NAMES['x-matroska'],
].map( type => `video/${type}`).join(', ');

/**
 * Supported video mime types
 */
export type VideoMimeType = ValueOf<typeof VIDEO_MIME_TYPE_NAMES>;

/** a list of video codecs */
export const VIDEO_CODECS = {
  openh264: 'libopenh264',
} as const;

/** a list of audio codecs */
export const AUDIO_CODECS = {
  aac: 'aac',
  opus: 'libopus',
} as const;

/**
 * Get compatible audio codec for the output container format
 * Note: Does not cover all cases.
 * @param outputFormat
 * @returns audio codec type
 */
export function getCompatibleAudioCodec(outputFormat: VideoContainerFormat): ValueOf<typeof AUDIO_CODECS> {
  if (outputFormat === VIDEO_CONTAINER_FORMAT_NAMES.webm) return AUDIO_CODECS.opus;
  return AUDIO_CODECS.aac;
}
