import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ReactNode, useEffect } from 'react';
import { useConfig } from 'config/hooks/useConfig';
import { isNil } from 'lodash';
import { useAuth } from 'config/hooks/useAuth';

class AppInsights {
  public reactPlugin = new ReactPlugin();

  private appInsights?: ApplicationInsights;

  public isInitialized = false;

  public initialize(appInsightsKey: string) {
    if (this.isInitialized) return;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        //Forces pre-flight requests on the browser
        //enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [this.reactPlugin],
      },
    });
    this.appInsights.loadAppInsights();
    this.isInitialized = true;
  }

  public get ApplicationInsights(): ApplicationInsights | undefined {
    return this.appInsights;
  }
}

export const appInsights = new AppInsights();

/**
 * Props for Azure provider
 */
interface AzureProps {
  children: ReactNode;
}

/**
 * Azure provider wrapper to be included in the app
 */
export default function Azure({ children }: AzureProps) {
  const { appInsightsKey } = useConfig();
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  const accountId = account?.localAccountId; 
  appInsights.initialize(appInsightsKey);
  useEffect(() => {
    if (!isNil(accountId)) {
      appInsights.ApplicationInsights?.setAuthenticatedUserContext(accountId, undefined, true);
    }
  }, [accountId]);

  return <AppInsightsContext.Provider value={appInsights.reactPlugin}>{children}</AppInsightsContext.Provider>;
}
