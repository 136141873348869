import { t } from '@lingui/macro';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { VideoDimensions } from 'common/constants/video';
import { isEmpty, isError, isUndefined } from 'lodash';
import { Box, styled, Typography, Skeleton, Zoom, Fade, Paper } from '@mui/material';
import LogRocket from 'logrocket';
import VideoRecorder from './videoRecorder.component';
import VideoStream from './videoStream.component';
import Alert from 'common/components/alert/alert.component';
import { ReactNode, useRef, useState } from 'react';
import { VideoUploaderBaseProps } from './const';
import errorSystemImage from 'common/images/illustration/error_general.png';
import arrowImage from 'common/images/arrow_left.svg';
import lapImage from 'common/images/illustration/woman on laptop.png';
import errorImage from 'common/images/illustration/man panting clear.png';
import ellipseImage from 'common/images/ellipse_primary.svg';
import { trackError, trackEvent, logError } from 'common/utils/errors';
import {
  VIDEO_TRACK_CONSTRAINTS,
  AUDIO_TRACK_CONSTRAINTS,
  CAM_HELP_ARTICLE_URL,
  LOCAL_STORAGE_KEYS,
  VIDEO_TRACK_HIGH_QUALITY,
} from 'edit/const';
import { useTextFeatureFlag } from 'config/hooks/useFeatureFlags';
import { StringFeatureFlagNames } from 'config/models/featureFlags';
import { VideoSettingsPrompt } from './videoSettings.component';
import { SPACER } from '../../config/models/themeOptions';

/**
 * Global State we're maintaining on the window to be cleaned-up when people leave the page
 * The reason we do this is because the stream is undefined when the page is unloaded
 * hence we can't stop the stream unless we have a reference to it. THIS IS A HACK.
 * Not optimal, but it works.
 */
declare global {
  interface Window {
    lastStream?: MediaStream;
  }
}

type VideoCamErrorProps = {
  error: Error;
  retry: () => void;
  children?: ReactNode;
};

/**
 * Component to show and handle different video error cases
 * @link https://blog.addpipe.com/common-getusermedia-errors/
 */
function VideoWebCamError({ error, retry, children }: VideoCamErrorProps) {
  let errorCode = error.message.toLowerCase().replace(/ /g, '_').replace('.', '');
  //console.log('[render] VideoUploaderError', errorCode, error.name);
  // TODO: Implement this in a more elegant way
  // Safari and Firefox returns different messages
  if (![
    'permission_dismissed',
    'permission_denied',
    'media_in_use'].includes(errorCode) &&
    error.name === 'NotAllowedError') {
    errorCode = 'permission_denied';
  }
  //required track is missing 
  if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
    errorCode = 'track_missing';
  }
  //webcam or mic are already in use 
  if (error.name === 'TrackStartError' || error.name === 'NotReadableError') {
    errorCode = 'media_in_use';
  }

  let title = t`Oops! Something went wrong. Please try again.`;
  let description = t`We couldn't get access to your camera or microphone (${error.message})`;
  let image = errorImage;
  let deliberate = false;
  let actionLabel = undefined;
  let actionHref = undefined;
  let actionClick = undefined;
  switch (errorCode) {
    case 'permission_dismissed':
      title = t`Whoops! You're not doing that right`;
      description = t`You've dismissed camera or microphone access, please allow access.`;
      deliberate = true;
      actionLabel = t`Retry`;
      actionClick = retry;
      break;
    case 'permission_denied':
      title = t`Uh oh! Looks like your microphone and camera were blocked`;
      description = t`Access to your camera or microphone was denied.`;
      deliberate = true;
      actionLabel = t`Unblock`;
      actionHref = CAM_HELP_ARTICLE_URL;
      break;
    case 'media_in_use':
    case 'could_not_start_video_source': //windows specific
      image = errorSystemImage;
      title = t`Oops! It looks like another app may be using the camera already`;
      description = t`Make sure to close any apps or pages that may accessing your camera and refresh the page.`;
      break;
    case 'track_missing':
      title = t`Hmmm ... can you record on this device?`;
      description = t`It seems like you don’t have a camera connected to your device.`;
      break;
    default:
      trackEvent('video-error-unexpected');
      logError(error);
      break;
  }
  const CenteredMessage = styled(Box)(({ theme }) => ({
    padding: theme.spacing(10),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: deliberate ? 1 : 0,
    borderRadius: 10,
    borderColor: theme.palette.error[400],
    height: '100%',
    flexGrow: 1,
  }));
  const imageEl = <Box
      sx={{
        flex: 1,
        backgroundImage: `url('${image}'), url('${ellipseImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        textAlign: 'center',
        width: '100%',
        display: { xs: 'none', sm: 'block' },
      }}
  >
  </Box>;
  return <CenteredMessage>
    <Typography variant='h3'>{title}</Typography>
    {deliberate ? imageEl : children ?? imageEl}
    <Alert
      sx={{ mt: 3 }}
      elevation={6}
      severity="error"
      actionHref={actionHref}
      onAction={actionClick}
      actionLabel={actionLabel}
      description={<div style={{ textAlign: 'left' }}>{description}&nbsp;
        <a target={'_blank'} href={CAM_HELP_ARTICLE_URL} rel="noreferrer">{t`Learn more`}</a>
      </div>}
    />
  </CenteredMessage>;
}

/**
 * Props for the webcam loading view
 */
type VideoWebCamLoaderProps = {
  /** if the screen sharing request was made and is loading */
  screenLoading: boolean;
};

function VideoWebCamLoader({ screenLoading }: VideoWebCamLoaderProps) {
  const CenteredSkeleton = styled(Skeleton)({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 36,
    flexGrow: 1,
  });
  const container = useRef<HTMLSpanElement | null>(null);
  return <CenteredSkeleton
    width='100%'
    height='100%'
    variant='rectangular'
    sx={{ display: 'flex', flexDirection: 'column' }}
    animation='wave'
    ref={container}
  >
    <Box visibility='visible' flex={1} display='flex' flexDirection={'column'} width='100%'>
      {/* transition delays don't work on Slide, hence wrapping fade */}
      <Fade in={true} style={{ transitionDelay: '500ms' }}>
        <Box width='100%' flex={1} display={'flex'} justifyContent={'flex-end'} flexDirection={'column'}>
          <Typography variant='h3' whiteSpace={'break-spaces'}>
            {t`Allow access to your camera and microphone`}
            {screenLoading && <span>&nbsp;{t`for screenshare`}</span>}
          </Typography>
          <Box sx={{
            flex: 1,
            display: { xs: 'none', sm: 'block' },
            width: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center, left top',
            backgroundImage: `url('${lapImage}'), url('${arrowImage}')`,
            animation: 'slide-bg 2s ease forwards',
          }}>
          </Box>
        </Box>
      </Fade>
      <Zoom in={true} style={{ transitionDelay: '5000ms' }} >
        <Box
          sx={{
            maxWidth: '100%',
            width: 'max-content',
            textAlign: 'left',
            mx: 'auto',
            mt: 3,
          }}
        >
          <Alert
            elevation={6}
            actionHref={CAM_HELP_ARTICLE_URL}
            actionLabel={t`Learn How`}
            severity={'warning'}
            description={t`Not sure how to allow access?`}
          />
        </Box>
      </Zoom>
    </Box>
  </CenteredSkeleton>;
}

type VideoWebCamProps = {
  setStreamDimensions: (d: VideoDimensions | undefined) => void;
  setIsRecording: (b: boolean) => void;
  screenLoading: boolean | Error;
  /** call back to set if the  */
  setScreenLoading: (b: boolean | Error) => void;
  children?: ReactNode;
} & VideoUploaderBaseProps;

/**
 * Component to get the web cam
 */
export default function VideoWebCam({
  screenLoading,
  setScreenLoading,
  setIsRecording,
  onDone,
  setStreamDimensions,
  children,
}: VideoWebCamProps) {
  //console.log('[render] VideoWebCam');
  const [stream, setStream] = useState<MediaStream | Error | undefined>(undefined);
  const [videoDeviceId, setVideoDeviceId] = useLocalStorage<string | undefined>(LOCAL_STORAGE_KEYS.VideoDeviceId, undefined);
  const [audioDeviceId, setAudioDeviceId] = useLocalStorage<string | undefined>(LOCAL_STORAGE_KEYS.AudioDeviceId, undefined);
  const [videoMirror, setVideoMirror] = useLocalStorage<boolean | undefined>(LOCAL_STORAGE_KEYS.VideoMirrored, true);
  const { text: videoRecordQuality } = useTextFeatureFlag(StringFeatureFlagNames.videoRecordQuality);

  const flipVideo = () => {
    setVideoMirror((mirror) => !mirror);
  };

  const setupStream = async (
    idealVideo: string | undefined,
    idealAudio: string | undefined) : Promise<{ videoDeviceId?: string, audioDeviceId?: string }> => {
    console.log('[setupStream]', { idealVideo, idealAudio });
    let resultVideoDeviceId = undefined;
    let resultAudioDeviceId = undefined;
    try {
      console.time('[setupStream] enumerateDevices');
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.timeEnd('[setupStream] enumerateDevices');
      console.log('[setupStream] enumerateDevices', devices);
      const hasVideo = !isEmpty(devices.filter(d=>d.kind === 'videoinput'));
      const hasAudio = !isEmpty(devices.filter(d=>d.kind === 'audioinput'));
      if (!hasVideo && !hasAudio) throw new Error('track_missing');

      const isHighQuality = videoRecordQuality === 'high';
      const quality: MediaTrackConstraintSet = isHighQuality ? VIDEO_TRACK_HIGH_QUALITY : {};
      const video: MediaTrackConstraints = {
        ...VIDEO_TRACK_CONSTRAINTS, // frame rate and facing mode
        ...quality,
        deviceId: { ideal: idealVideo },
      };
      const audio: MediaTrackConstraints = { ...AUDIO_TRACK_CONSTRAINTS, deviceId: { ideal: idealAudio } };
      const constraints: MediaStreamConstraints = {
        ...(hasVideo ? { video } : {}),
        ...(hasAudio ? { audio } : {}),
      };
      destroyLastStream();
      console.time('[setupStream] getUserMedia');
      const newStream = await navigator.mediaDevices.getUserMedia(constraints);
      console.timeEnd('[setupStream] getUserMedia');

      const videoTrack = newStream.getVideoTracks()[0];
      const audioTrack = newStream.getAudioTracks()[0];
      if (!isUndefined(audioTrack)) {
        const audioSettings =  audioTrack.getSettings();
        resultAudioDeviceId = audioSettings.deviceId;
        trackEvent('device-request-success-audio', { ...audioSettings });
      }
      if (!isUndefined(videoTrack)) {
        const videoSettings = videoTrack.getSettings();
        resultVideoDeviceId = videoSettings.deviceId;
        trackEvent('device-request-success-video', { ...videoSettings });
      }
      // only if we have both a video track and audio track continue
      if (!isUndefined(videoTrack) && !isUndefined(audioTrack)) {
        window.lastStream = newStream;
        setStream(newStream);
      } else {
        trackError('device-request-failed', {
          hasVideo: !isUndefined(videoTrack),
          hasAudio: !isUndefined(audioTrack),
        });
        setStream(new Error('stream-issue'));
      }
    } catch (error) {
      trackError('device-request-failed', { error: JSON.stringify(error) });
      if (isError(error)) {
        logError(error);
        setStream(error);
      }
    }
    return { videoDeviceId: resultVideoDeviceId, audioDeviceId: resultAudioDeviceId };
  };

  const updateVideoDeviceId = async (deviceId: string) => {
    const result = await setupStream(deviceId, audioDeviceId);
    if (result.videoDeviceId === deviceId) {
      setVideoDeviceId(deviceId);
    } else {
      setVideoDeviceId(undefined); //clear the device selection
      trackError('device-request-failed-video', {
        expected: deviceId,
        actual: result.videoDeviceId,
      });
    }
    return result.videoDeviceId;
  };

  const updateAudioDeviceId = async (deviceId: string) => {
    const result = await setupStream(videoDeviceId, deviceId);
    if (result.audioDeviceId === deviceId) {
      setAudioDeviceId(deviceId);
    } else {
      setAudioDeviceId(undefined);
      trackError('device-request-failed-audio', {
        expected: deviceId,
        actual: result.audioDeviceId,
      });
    }
    return result.audioDeviceId;
  };

  const destroyLastStream = () => {
    console.log('[destroy] VideoWebCam', window.lastStream);
    if (window.lastStream) {
      window.lastStream.getTracks().forEach((track) => track.stop());
      window.lastStream = undefined;
    }
  };

  useEffectOnce(() => {
    void setupStream(videoDeviceId, audioDeviceId);
    return destroyLastStream;
  });

  if (isError(stream)) {
    //console.log('[render][error] VideoWebCam');
    LogRocket.captureException(stream);
    return <VideoWebCamError
      error={stream}
      retry={() => void setupStream(videoDeviceId, audioDeviceId)} >
      <Paper sx={{ p: SPACER.S, mt: SPACER.M }} elevation={4}>
        <VideoSettingsPrompt
          setVideoDeviceId={updateVideoDeviceId}
          setAudioDeviceId={updateAudioDeviceId}
          videoMirror={videoMirror}
          flipVideo={flipVideo}
        />
      </Paper>
    </VideoWebCamError>;
  } else if (isUndefined(stream)) {
    //console.log('[render][loading] VideoWebCam');
    return <VideoWebCamLoader screenLoading={screenLoading === true} />;
  } else {
    return (
      <>
        <VideoStream
          onPreview={(videoWidth, videoHeight) => {
            setStreamDimensions({ videoWidth, videoHeight });
          }}
          stream={stream}
          mirror={videoMirror}
        />
        <VideoRecorder
          screenLoading={screenLoading}
          setScreenLoading={setScreenLoading}
          videoMirror={videoMirror}
          flipVideo={flipVideo}
          onTrackEnd={() => {
            setStreamDimensions(undefined);
            setStream(undefined);
          }}
          stream={stream}
          setVideoDeviceId={updateVideoDeviceId}
          setAudioDeviceId={updateAudioDeviceId}
          onStart={() => setIsRecording(true)}
          onCanceled={() => setIsRecording(false)}
          onRecorded={(b) => {
            setIsRecording(false);
            void onDone(b);
          }}
        />
        {children}
      </>
    );
  }
}
