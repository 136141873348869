import { t } from '@lingui/macro';
import { Fab, SvgIcon, Zoom, alpha, styled } from '@mui/material';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { ceil, isUndefined } from 'lodash';
import { useState } from 'react';
import { ReactComponent as StopSvg } from 'common/images/icons/Utility/stop.svg';
import Tooltip from '../../common/components/tooltip/tooltip.component';
import KeyboardShortcutComponent from './keyboardShortcut.component';
import { useInterval } from 'usehooks-ts';
import { MS_PER_SECOND } from '../../common/constants/date';

export const COUNT_DOWN_SEC = 3;
export const COUNT_DOWN_MS = COUNT_DOWN_SEC * MS_PER_SECOND;

const KeyboardShortcut = styled(KeyboardShortcutComponent)({
  marginLeft: 6,
  height: 'auto',
  '& .MuiChip-label': {
    padding: '0 9px',
  },
});

const RecodingButton = styled(Fab)({
  position: 'absolute',
  top: 0,
  left: 0,
  translate: '21% 20%',
});

type VideoRecordButtonProps = {
  /** callback on start */
  onStart: () => void;
  /** callback on complete */
  onComplete: () => void;
  /** callback on update */
  onUpdate: () => void;
  /** if the button should be mobile */
  isMobile: boolean;
  /** recording start time in ms */
  startTime?: number;
};

/**
 * Component to show a button to start recording and a countdown
 */
export function VideoRecordButton({
  onStart,
  onComplete,
  onUpdate,
  isMobile,
  startTime }: VideoRecordButtonProps) {
  //console.log('[VideoRecordButton] render');
  const [countdown, setCountdown] = useState<number | undefined>(undefined);

  useInterval(() => {
    if (isUndefined(startTime)) return;
    const timeDiff = (Date.now() - startTime) / MS_PER_SECOND;
    if (timeDiff <= COUNT_DOWN_SEC) {
      setCountdown(ceil(COUNT_DOWN_SEC - timeDiff));
      onUpdate();
    } else {
      onComplete();
    }
  }, 1000);

  const size = isMobile ? 'small' : 'medium';
  return <>{isUndefined(countdown) && <Tooltip
      placement={'top'}
      arrow={true}
      title={<span>{t`Record`} <KeyboardShortcut label={'D'}  /></span>}>
    <RecodingButton
    aria-label={t`Record`}
    disabled={!isUndefined(countdown)}
    size={size}
    color="error"
    onClick={onStart}
  >
  </RecodingButton></Tooltip>}
    {[3, 2, 1].map((val) => <Zoom key={val} in={countdown === val}>
      <RecodingButton size={size} color='primary'>{val}</RecodingButton>
    </Zoom>)}
  </>;
}

type VideoStopButtonProps = {
  /** if the button is disabled */
  disabled: boolean;
  /** callback on click */
  onClick: () => void;
  /** if the button should be mobile */
  isMobile: boolean;
};

/**
 * Component to show a button to stop recording
 */
export function VideoStopButton({
  disabled,
  onClick,
  isMobile }: VideoStopButtonProps) {
  //console.log('[VideoStopButton] render');
  return <Tooltip arrow={true} placement={'top'}  title={<span>{t`Stop`} <KeyboardShortcut label={'D'}  /></span>}>
    <RecodingButton
        aria-label={t`Stop`}
    size={isMobile ? 'small' : 'medium'}
    sx={{
      backgroundColor: alpha(PALETTE_COLOR.neutral[0], 0.5),
      '&:hover': {
        backgroundColor: PALETTE_COLOR.neutral[0],
      },
    }}
    color="error"
    disabled={disabled}
    onClick={onClick}
  >
    <SvgIcon color="error" inheritViewBox component={StopSvg} />
    </RecodingButton></Tooltip>;
}
