import { useContext } from 'react';
import { FeatureFlagsContext } from '../context/featureFlags.context';
import {
  FeatureFlagsType,
  BooleanFeatureFlagNamesType,
  StringFeatureFlagNamesType,
  NumberFeatureFlagNamesType,
} from '../models/featureFlags';

/**
 * Gets the feature flags
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 * @returns 
 */
const useFeatureFlags = (): FeatureFlagsType => {
  const context =  useContext<FeatureFlagsType | undefined>(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error('useFlags must be within FlagsProvider');
  }
  return { ...context };
};

/**
 * Gets boolean feature flag
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 * @returns 
 */
const useFeatureFlag = (flagName: BooleanFeatureFlagNamesType): { isEnabled: boolean, isReady: boolean } => {
  const context =  useFeatureFlags();
  return { isEnabled: context[flagName].isEnabled(), isReady: context.isReady };
};


/**
 * Gets boolean feature flag
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 * @returns 
 */
const useTextFeatureFlag = (flagName: StringFeatureFlagNamesType): { text: string, isReady: boolean } => {
  const context =  useFeatureFlags();
  return { text: context[flagName].getValue(context), isReady: context.isReady };
};



/**
 * Gets number feature flag
 * Usage guide: https://cassia.atlassian.net/wiki/spaces/VP/pages/768377248
 * @returns 
 */
const useNumberFeatureFlag = (flagName: NumberFeatureFlagNamesType): { number: number, isReady: boolean } => {
  const context =  useFeatureFlags();
  return { number: context[flagName].getValue(context), isReady: context.isReady };
};


export {
  useFeatureFlags,
  useFeatureFlag,
  useTextFeatureFlag,
  useNumberFeatureFlag,
};