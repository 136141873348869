import { ReactNode } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { en, fr } from 'make-plural/plurals';
import { messages } from '../../locales/en/messages';

function Localization({ children }: { children: ReactNode }) {
  i18n.load('en', messages);
  i18n.loadLocaleData({
    en: { plurals: en },
    fr: { plurals: fr },
  });
  i18n.activate('en');

  return (
    <I18nProvider i18n={i18n}>
      {children}
    </I18nProvider>
  );
}

export default Localization;
