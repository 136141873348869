import { SelectProps as MuiSelectProps, Select as MuiSelect, SvgIcon, useTheme } from '@mui/material';
import { ReactComponent as DownPointerSvg } from 'common/images/icons/Utility/down pointer.svg';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate three.svg';
import { useState } from 'react';

type SelectProps<T> = MuiSelectProps<T> & {
  /** If the current select is loading  */
  loading?: boolean
};

/**
 * Styled Select component from design system
 */
export default function Select<T>({ sx, fullWidth, children, loading, ...rest }: SelectProps<T>) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const open = Boolean(anchorEl);

  return (
    <MuiSelect
      {...rest}
      IconComponent={() => (
        <SvgIcon
          component={loading ? RotateSvg : DownPointerSvg}
          inheritViewBox
          sx={{
            animation: loading ? 'rotating 2s linear infinite' : 'none',
            width: '0.8em', position: 'absolute', right: '7px', pointerEvents: 'none',
          }}
        />
      )}
      onOpen={(e) => setAnchorEl(e.currentTarget)}
      onClose={() => setAnchorEl(undefined)}
      open={open}
      MenuProps={{
        transitionDuration: 0,
        PaperProps: {
          sx: {
            boxShadow: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            border: `1px solid ${theme.palette.neutral[200]}`,
            width: anchorEl?.clientWidth,
            transition: 'none',
            ...(open && { marginTop: '1px' }),
          },
        },
        MenuListProps: {
          sx: {
            '& li:last-child': {
              borderBottom: 'none',
            },
          },
        },
      }}
      sx={{
        padding: 0,
        background: theme.palette.neutral[0],
        ...(fullWidth && { width: '100%' }),
        ...sx,
        ...(open && {
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          '& > svg': { color: theme.palette.primary[500], transform: 'rotate(180deg)' },
        }),
      }}
    >
      {children}
    </MuiSelect>
  );
}
