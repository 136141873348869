import { useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../../common/components/errorBoundary/errorBoundary.component';
import { DashboardDrawer } from './dashboardDrawer.component';
import { DRAWER_WIDTH } from 'common/const';
import VideoTemplatesDashboardView from './videoTemplates.component';
import TableSkeltonUI from '../../common/components/skeleton/dashboardTable.skeleton';
import AlertRefresh from '../../common/components/alert/alertRefresh.component';

export type DashboardLayoutContext = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};

export default function DashboardLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box display="flex">
      <DashboardDrawer drawerWidth={DRAWER_WIDTH} mobileOpen={mobileOpen} onDrawerToggle={handleDrawerToggle} />
      <Box component="main" flexGrow={1} sx={{ p: 3, width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` } }}>
          <ErrorBoundary
          customErrorUI={
            <>
                <AlertRefresh />
                <TableSkeltonUI />
            </>
          }
        >
              <VideoTemplatesDashboardView />
              <Outlet context={{ mobileOpen, handleDrawerToggle }} />
        </ErrorBoundary>
      </Box>
    </Box>
  );
}
