import { t } from '@lingui/macro';
import { ITargetInput, IVideoClipDetailFragment } from '../../data/_generated';
import { useState } from 'react';
import { InputAdornment, SvgIcon, Typography } from '@mui/material';
import TextField from './textField/textField.component';
import Modal from './modal/modal.component';
import { ValueOf } from '../utils/types';
import { extractError, logError } from '../utils/errors';
import useUploadClip from 'common/hooks/useUploadClip';
import VideoConfirm from 'edit/components/videoConfirm.component';
import { ReactComponent as AddAccountSvg } from 'common/images/icons/Utility/add account.svg';
import { ReactComponent as GroupIcon } from 'common/images/icons/Utility/group.svg';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TargetPropertyNames, TargetSchema } from 'edit/components/targetCreation/const';

const ADD_PUBLISH_TARGET_STEPS = {
  Target: 0,
  PersonalizedClip: 1,
} as const;

type AddTargetVideoModalProps = {
  /** The person that we are recording a personalization */
  name: string;
  /** Callback to close */
  onClose: () => void;
  /** Callback for complete */
  onConfirm: (personalizedClip?: IVideoClipDetailFragment) => Promise<void>;
};

/**
 * Add target video modal
 */
function AddTargetVideoModal({ name, onConfirm, onClose }: AddTargetVideoModalProps) {
  const [isUploading, setIsUploading] = useState<boolean>();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const { uploadClip, progressPercent } = useUploadClip();
  return <Modal
    maxWidth='lg'
    sx={{ minWidth: '50vw' }}
    title={t`Say hi to ${name}`}
    onClose={onClose}
    isOpen={true}
    disableClose={isUploading}
    confirmLoading={isUploading}
  >
    <VideoConfirm onUpload={async (clip) => {
      setIsUploading(true);
      if (clip.__typename !== 'NewVideoClip') {
        await onConfirm(clip);
      } else {
        await onConfirm((await uploadClip(clip)));
      }
      setIsUploading(false);
      return true;
    }}
      showTrimming={!isUploading}
      isRecording={isRecording}
      setIsRecording={(r) => setIsRecording(r)}
      uploadingPercent={progressPercent}
    />
  </Modal>;
}


export type AddTargetModalProps = {
  /** Called when modal is closed without confirming */
  onClose: () => void
  /** Callback on when completing */
  onPublishConfirm: (targetInput: ITargetInput, personalizedClip?: IVideoClipDetailFragment) => Promise<void>
};

/**
 * Component to add an additional target to a published project
 */
export default function AddTargetModal({
  onClose,
  onPublishConfirm,
}: AddTargetModalProps) {

  const [activeStep, setActiveStep] = useState<ValueOf<typeof ADD_PUBLISH_TARGET_STEPS>>(ADD_PUBLISH_TARGET_STEPS.Target);
  const [isPublishing, setIsPublishing] = useState<boolean>();
  const [publishingError, setPublishingError] = useState<Error>();
  const form = useForm<ITargetInput>({
    defaultValues: { [TargetPropertyNames.name]: '', [TargetPropertyNames.email]: '' },
    resolver: yupResolver(TargetSchema),
    shouldFocusError: true,
    mode: 'onChange',
  });

  const handleConfirm = async (personalizedClip?: IVideoClipDetailFragment) => {
    setIsPublishing(true);
    try {
      await onPublishConfirm(form.getValues(), personalizedClip);
    } catch (e) {
      const error = extractError(e);
      logError(error);
      setPublishingError(error);
    }
    setIsPublishing(false);
    onClose();
  };
  switch (activeStep) {
    case ADD_PUBLISH_TARGET_STEPS.Target:
      return <Modal
        maxWidth="xs"
        title={t`Add one more recipient`}
        icon={AddAccountSvg}
        isOpen={true}

        disableClose={isPublishing}
        onClose={onClose}

        confirmLabel={t`Add intro`}
        onConfirm={() => setActiveStep(ADD_PUBLISH_TARGET_STEPS.PersonalizedClip)}
        confirmDisabled={!form.formState.isValid}

        secondError={publishingError}
        secondLoading={isPublishing}
        secondLabel={t`Add without intro`}
        secondDisabled={!form.formState.isValid}
        onSecondAction={() => void handleConfirm()}
      >
        <Typography variant="bodyMedium">
          {t`Share this video with a new recipient. 
        You can personalize it with a video introduction.`}</Typography>
        <FormProvider {...form} >
          <Controller
            name={TargetPropertyNames.name}
            control={form.control}
            render={({ field, fieldState }) => (<TextField
              fullWidth
              autoFocus
              placeholder={t`Name`}
              onChange={field.onChange}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              sx={{ my: 1 }}
              value={field.value}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon inheritViewBox component={GroupIcon} />
                  </InputAdornment>
                ),
              }}
            />)} />
        </FormProvider>
      </Modal>;
    case ADD_PUBLISH_TARGET_STEPS.PersonalizedClip:
      return <AddTargetVideoModal
        name={form.getValues().name}
        onConfirm={handleConfirm}
        onClose={() => setActiveStep(ADD_PUBLISH_TARGET_STEPS.Target)}
      />;
  }
  return <></>;
}





