import { useConfig } from './useConfig';
import { loadStripe } from '@stripe/stripe-js';
import { useEffectAsync } from '../../common/hooks/useEffectAsync';
import { useState } from 'react';
import { useCreateSetupIntentMutation } from '../../data/_generated';

/**
 * Returns the config object for the Stripe Elements Provider.
 *
 * @return config object with the stripePromise and clientSecret properties.
 */
export default function useStripeElementConfig() {
  const { stripePublishableKey } = useConfig();
  const [createSetUpIntent] = useCreateSetupIntentMutation();
  const [clientSecret, setClientSecret] = useState<string | undefined>();

  useEffectAsync(async () => {
    const setupIntent = await createSetUpIntent();
    const secret = setupIntent.data?.createSetupIntent.clientSecret;
    setClientSecret(secret);
  }, []);

  return { stripePromise: loadStripe(stripePublishableKey), clientSecret };
}