import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { ReactNode, useEffect, useRef } from 'react';
import { Analytics, AnalyticsBrowser } from '@june-so/analytics-next';
import useUserDetails from '../hooks/useUserDetails.component';
import { isEmpty, isUndefined } from 'lodash';
import { useTextFeatureFlag } from '../hooks/useFeatureFlags';
import { StringFeatureFlagNames } from '../models/featureFlags';

interface TrackingProviderProps {
  children: ReactNode;
}

declare global {
  interface Window {
    mixpanel_loaded?: boolean;
    mixpanel?: Mixpanel;
    june_loaded?: boolean;
    june?: Analytics;
  }
}

type Command = () => void;

export default function TrackingProvider({ children }: TrackingProviderProps) {
  const { text: mixPanelKey, isReady } = useTextFeatureFlag(StringFeatureFlagNames.mixPanelKey);
  const { text: juneSoKey } = useTextFeatureFlag(StringFeatureFlagNames.juneSoKey);
  const isMixPanel = !isEmpty(mixPanelKey);
  const isJuneSo = !isEmpty(juneSoKey);
  const queuedCommands = useRef<Command[]>([]);
  const queueOrExecute = (command: Command) => {
    if (window.mixpanel_loaded && window.june_loaded) command();
    else queuedCommands.current.push(command);
  };

  const flushCommands = () => {
    if (queuedCommands.current.length <= 0) return;
    while (queuedCommands.current.length) {
      const cb = queuedCommands.current.shift();
      if (typeof cb === 'function') cb();
    }
  };
  const loadJune = async () => {
    if (!isJuneSo) {
      window.june_loaded = true;
      return;
    }
    if (window.june) return;
    const [june] = await AnalyticsBrowser.load({
      writeKey: juneSoKey,
      cdnSettings: {
        integrations: {
          'june.so': {
            apiHost: `${window.location.host}/june`,
            protocol: window.location.protocol.includes('https') ? 'https' : 'http',
          },
        },
      },
    });
    window.june = june;
    window.june_loaded = true;
    console.log('loaded june', userId);
  };
  const loadMixPanel = async () => {
    if (!isMixPanel) {
      window.mixpanel_loaded = true;
      return;
    }
    if (window.mixpanel) return;
    return new Promise((resolve, reject) => {
      try {
        mixpanel.init(mixPanelKey, {
          ignore_dnt: true,
          debug: true,
          api_host: `${window.origin}/mixpanel`,
          loaded: resolve,
        });
        window.mixpanel = mixpanel;
        window.mixpanel_loaded = true;
        console.log('loaded mixpanel', userId);
      } catch (e) {
        console.log('failed mixpanel', e);
        reject(e);
      }
    });
  };

  const loadLibraries = async () => {
    await Promise.all([loadMixPanel(), loadJune()]);
    flushCommands();
  };

  useEffect(() => {
    if (!isReady) return;
    void loadLibraries();
    queueOrExecute(() => {
      if (isUndefined(userId)) return;
      window.mixpanel?.identify(userId);
      void window.june?.identify(userId);
    });
  }, [isReady]);

  const { userId } = useUserDetails({
    callback: (uid, userDetails) => {
      queueOrExecute(() => {
        void window.mixpanel?.identify(uid);
        void window.mixpanel?.people.set({
          ...userDetails,
          // email to ensure it's sent for mix panel
          // https://docs.mixpanel.com/docs/data-structure/user-profiles#reserved-user-properties
          $email: userDetails.email,
        });
        void window.june?.identify(uid, userDetails);
      });
    },
  });

  return <>{children}</>;
}
