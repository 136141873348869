import { Box, Card, Divider, Skeleton } from '@mui/material';
import ProjectsList from '../../../dashboard/components/projectsList.component';
import { SortableProjectField } from '../../../dashboard/type';

/**
 * Skeleton UI for projects list table
 * @constructor
 */
export default function TableSkeltonUI() {
  return (
    <Card variant="outlined" sx={{ mt: 3 }}>
      <ProjectsList data={[]} defaultSort={SortableProjectField.lastUpdated} />
      <Box display={'flex'} m={2}>
        <Skeleton height={57} width={90} variant="rounded" sx={{ mr: 5 }} />
        <Box>
          <Skeleton height={20} width={40} variant="rounded" />
          <Skeleton height={20} width={60} />
        </Box>
      </Box>
      <Divider />
      <Box display={'flex'} m={2}>
        <Skeleton height={57} width={90} variant="rounded" sx={{ mr: 5 }} />
        <Box>
          <Skeleton height={20} width={40} variant="rounded" />
          <Skeleton height={20} width={60} />
        </Box>
      </Box>
      <Divider />
      <Box display={'flex'} m={2}>
        <Skeleton height={57} width={90} variant="rounded" sx={{ mr: 5 }} />
        <Box>
          <Skeleton height={20} width={40} variant="rounded" />
          <Skeleton height={20} width={60} />
        </Box>
      </Box>
      <Divider />
      <Box display={'flex'} m={2}>
        <Skeleton height={57} width={90} variant="rounded" sx={{ mr: 5 }} />
        <Box>
          <Skeleton height={20} width={40} variant="rounded" />
          <Skeleton height={20} width={60} />
        </Box>
      </Box>
    </Card>
  );
}
