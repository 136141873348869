import { t } from '@lingui/macro';
import { Box, Chip, Grow, LinearProgress, Slide, styled, Typography } from '@mui/material';
import Sparkles from 'common/components/sparkle.component';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { IVideoClipEffectProcessingStatus, useVideoClipLazyQuery } from 'data/_generated';
import { isNil, isNumber, isUndefined } from 'lodash';
import { useRef, useState } from 'react';
import { useEffectOnce, useInterval } from 'usehooks-ts';
import { MS_PER_SECOND } from '../../common/constants/date';

type VideoEffectThumbnailProps = {
  /** the clip id to lookup */
  clipId: string;
  /** the status of the generation */
  status: IVideoClipEffectProcessingStatus;
  /** date that the video clip was created */
  dateCreated: string;
  /** duration in seconds */
  durationInSeconds?: number
};
const Status = styled(Chip)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  translate: '-50% -50%',
});

/**
 * Component to Show Video Effect Thumbnail with a specific video clip thumbnail
 */
export default function VideoEffectThumbnail({ clipId: videoClipId, status, dateCreated, durationInSeconds }: VideoEffectThumbnailProps) {
  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const [progressPercent, setProgressPercent] = useState<number>(100);
  const created = new Date(dateCreated);
  useInterval(()=> {
    if (isUndefined(durationInSeconds)) return;
    const estimatedTimeTakenInSec = ((2 * durationInSeconds / 3.5) + (durationInSeconds / 8) + 15);
    const now = new Date();
    const currentTimeTakenInSec = (now.getTime() - created.getTime()) / MS_PER_SECOND;
    const newPercent =  isNumber(currentTimeTakenInSec) ? currentTimeTakenInSec / estimatedTimeTakenInSec * 100 : 100;
    setProgressPercent(Math.min(newPercent, 100));
  }, 100);
  const imageRef = useRef<HTMLImageElement>(null);
  const [getVideoClip] = useVideoClipLazyQuery();
  const getThumbnail = async () => {
    const videoClip = await getVideoClip({ variables: { videoClipId } });
    if (isNil(videoClip.data?.videoClip.thumbnail)) return;
    setThumbnail(videoClip.data?.videoClip.thumbnail);
  };
  useEffectOnce(() => {
    void getThumbnail();
  });

  if (status === IVideoClipEffectProcessingStatus.Processing)
    return <Grow in={true}>
      <Box className='animated-thumbnail animated-thumbnail--active' >
        <Sparkles color={PALETTE_COLOR.neutral.light} >
          <img
              ref={imageRef}
              onLoad={()=> {
                if (isNil(imageRef.current)) return;
                imageRef.current.className += ' animated-thumbnail__img--loaded';
              }}
              src={thumbnail}
              alt='AI Effects'
              className='animated-thumbnail__img' />
          <Box className={'animated-thumbnail__message'}>
            <Typography variant='subtitle1'>{t`Casting some magic on your video`}</Typography>
            <LinearProgress value={progressPercent} color='primary' sx={{ width: '36%' }} variant={progressPercent === 100 ? 'indeterminate' : 'determinate'} />
            <Slide direction="down" in={true} style={{ transitionDelay: '6s' }}>
              <Typography variant='subtitle2'>{t`Thanks for your patience, this may take a while...`}</Typography>
            </Slide>
          </Box>
        </Sparkles>
      </Box>
    </Grow>;
  return <Box className='animated-thumbnail' >
    <img
      src={thumbnail}
      alt='AI Effects'
      className='animated-thumbnail__img' />
    <Status
      label={t`Whoops, your video couldn't get the magic`}
      variant='status' color='error' />
  </Box>;
}