import {
  Box,
  Button,
  Container,
  Fab,
  Grid,
  IconButton,
  styled,
  SvgIcon,
  Typography,
  TypographyProps,
} from '@mui/material';
import { isNil, isUndefined } from 'lodash';
import { ChangeEvent, ReactNode, useState } from 'react';
import { t } from '@lingui/macro';
import TextField from 'common/components/textField/textField.component';
import browser_background from 'common/images/illustration/broswer_background.png';
import { CallToActionForm } from './preview/callToActionForm.component';
import { ReactComponent as UploadSvg } from 'common/images/icons/Utility/upload.svg';
import {
  ICallToAction,
  ICallToActionInput,
  IThumbnail,
  IThumbnailInput,
  IThumbnailTypes,
  IVideoProjectInput, ISingleClipSlot,
} from 'data/_generated';
import { useFileInput } from 'common/hooks/useFileInput';
import IconTooltip from 'common/components/tooltip/iconTooltip.component';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import { locationToDataURL } from 'common/utils/video';
import {
  DEFAULT_LANDING_PAGE_TITLE_TEMPLATE, getTargetVideoTitle,
  NAME_TITLE_TOKEN,
} from 'edit/const';
import { ReactComponent as PlaySvg } from 'common/images/icons/Utility/play.svg';
import useThumbnail, { BLANK_THUMBNAIL } from 'common/hooks/useThumbnails';
import ToggleButton from 'common/components/toggleButton.component';
import { getTrimmedDuration, getTrimmedTimeOptional } from './utils';

type MarginBottomTypographyProps = Pick<TypographyProps, 'variant'> & {
  children: ReactNode;
};

/** Styled Typography component with margin bottom */
function MarginBottomTypography({ variant, children }: MarginBottomTypographyProps) {
  return (
    <Typography
      mb={'5px'}
      variant={variant}
      variantMapping={{
        bodyReg: 'p',
        h5Semibold: 'h5',
      }}
    >
      {children}
    </Typography>
  );
}

const Section = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

type VideoRenderProps = {
  /** clip to build thumbnails */
  clipSlot?: Readonly<ISingleClipSlot>;
  /** callback on update preview */
  onUpdate: (data: IVideoProjectInput) => void | Promise<void>;
  /** landing page title template for project */
  landingPageTitleTemplate?: string | null;
  /** landing page links */
  links?: ICallToAction[] | null;
  /** thumbnail for processed video */
  thumbnail?: IThumbnail | null;
  /** the target name we show a preview for */
  targetName?: string;
  /** title for the landing page title */
  landingPageTitleTemplateTitle?: string;
  /** title for the thumbnail */
  thumbnailTitle?: string;
  /** title for the links */
  linksTitle?: string;
  /** if should show the subtitle */
  showSubtitle?: boolean;
};

export default function VideoRender({
  clipSlot,
  onUpdate,
  landingPageTitleTemplate,
  links,
  thumbnail,
  targetName,
  landingPageTitleTemplateTitle = t`Add a page title`,
  thumbnailTitle = t`Select a thumbnail`,
  linksTitle = t`Choose your Call-to-Action`,
  showSubtitle = true,
}: VideoRenderProps) {
  const clipDuration = clipSlot?.videoClip?.durationInSeconds ?? undefined;
  const trimmedClipDuration = getTrimmedDuration(clipSlot?.start, clipSlot?.end, clipDuration);
  const { staticThumbnail, staticLoading, animatedThumbnail, animatedLoading } = useThumbnail({
    options: {  createStatic: true, createAnimated: true },
    videoSrc: clipSlot?.videoClip?.location, 
    startTimeInSec: getTrimmedTimeOptional(clipSlot?.start, clipDuration), 
    durationInSec: trimmedClipDuration,
  });
  const target = isUndefined(targetName) ? undefined : { name: targetName };
  const [selectedThumbnail, setSelectedThumbnail] = useState<IThumbnailInput>(
    !isNil(thumbnail) && !isNil(thumbnail.location)
      ? {
        selection: thumbnail.selection ?? IThumbnailTypes.Static,
        location: thumbnail.location,
        fileName: thumbnail.fileName,
      }
      : { selection: IThumbnailTypes.Static, location: staticThumbnail ?? BLANK_THUMBNAIL },
  );
  const { inputRef: fileUploadRef, onChange: onFileChange, fileUrl: customPreviewUrl, clearFile } = useFileInput();
  const [titleTemplate, setTitleTemplate] = useState<string>(
    landingPageTitleTemplate ?? DEFAULT_LANDING_PAGE_TITLE_TEMPLATE,
  );
  const [ctaLink, setCtaLink] = useState<ICallToActionInput[]>(
    links?.length ? [{ text: links[0].text, link: links[0].link }] : [],
  );
  
  const onFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const blobUrl = onFileChange(event);
    if (!blobUrl) return;
    const blobDataUrl = await locationToDataURL(blobUrl);
    await onSelectThumbnail({ location: blobDataUrl, selection: IThumbnailTypes.Custom });
  };

  const onSelectThumbnail = async (updatedThumbnail: IThumbnailInput) => {
    if (updatedThumbnail.selection !== IThumbnailTypes.Custom) {
      clearFile();
    }
    setSelectedThumbnail(updatedThumbnail);
    await onUpdate({
      thumbnail: updatedThumbnail,
    });
  };

  const updateLandingTitleTemplate = async (newTitle: string) => {
    setTitleTemplate(newTitle);
    await onUpdate({
      landingPageTitleTemplate: newTitle,
    });
  };
  const updateCtaLink = async (ctaLinks: ICallToActionInput[]) => {
    setCtaLink(ctaLinks);
    await onUpdate({
      links: ctaLinks,
    });
  };
  return (
    <Container>
      <Grid container justifyContent={'center'} spacing={4}>
        <Grid item xs={12} md={6}>
          <Section>
            <MarginBottomTypography variant="h5Semibold">
              {landingPageTitleTemplateTitle}
              <IconTooltip
                color="dark"
                title={t`Pro Tip`}
                arrow
                placement="right"
                description={t`Added a Personalized Intro? 
                  Type ${NAME_TITLE_TOKEN} to capture your recipient's name.`}
              >
                <IconButton sx={{ p: 0, ml: 1 }}>
                  <SvgIcon component={InfoSvg} inheritViewBox sx={{ fontSize: '1rem' }} />
                </IconButton>
              </IconTooltip>
            </MarginBottomTypography>
            {showSubtitle &&
              <MarginBottomTypography variant="bodyReg">
                {t`This appears above your video. Say hello, introduce yourself, or get creative!`}
              </MarginBottomTypography>
            }
            <TextField
              value={titleTemplate}
              fullWidth
              onChange={(e) => setTitleTemplate(e.target.value)}
              onBlur={(e) => void updateLandingTitleTemplate(e.target.value)}
            />
          </Section>
          <Section>
            <MarginBottomTypography variant="h5Semibold">{thumbnailTitle}</MarginBottomTypography>
            {showSubtitle &&
              <MarginBottomTypography variant="bodyReg">
                {t`Entice your recipient to click and view your video.`}
              </MarginBottomTypography>
            }
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <ToggleButton
                  onClick={() =>
                    void onSelectThumbnail({ selection: IThumbnailTypes.Static, location: staticThumbnail ?? BLANK_THUMBNAIL })
                  }
                  isActive={selectedThumbnail.selection === IThumbnailTypes.Static}
                  isDisabled={isUndefined(staticThumbnail)}
                  label={t`Static (Default)`}
                  previewUrl={
                    selectedThumbnail.selection === IThumbnailTypes.Static
                      ? selectedThumbnail.location
                      : staticThumbnail
                  }
                  isLoading={staticLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <ToggleButton
                  onClick={() =>
                    void onSelectThumbnail({ selection: IThumbnailTypes.Animated, location: animatedThumbnail ?? BLANK_THUMBNAIL })
                  }
                  isActive={selectedThumbnail.selection === IThumbnailTypes.Animated}
                  isDisabled={isUndefined(animatedThumbnail)}
                  label={t`Animated`}
                  previewUrl={
                    selectedThumbnail.selection === IThumbnailTypes.Animated
                      ? selectedThumbnail.location
                      : animatedThumbnail
                  }
                  isLoading={animatedLoading}
                />
              </Grid>
              <Grid item xs={4}>
                <ToggleButton
                  onClick={() => fileUploadRef.current?.click()}
                  isActive={selectedThumbnail.selection === IThumbnailTypes.Custom}
                  isDisabled={false}
                  label={t`Upload an image`}
                  previewUrl={
                    selectedThumbnail.selection === IThumbnailTypes.Custom
                      ? selectedThumbnail.location
                      : customPreviewUrl
                  }
                >
                  <SvgIcon component={UploadSvg} inheritViewBox sx={{ fontSize: '2rem' }} />
                </ToggleButton>
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => {
                    void onFileUpload(e);
                  }}
                  ref={fileUploadRef}
                  accept={'image/gif, image/jpeg, image/png'}
                ></input>
              </Grid>
            </Grid>
          </Section>
          <Section>
            <MarginBottomTypography variant="h5Semibold">{linksTitle}</MarginBottomTypography>
            {showSubtitle &&
              <MarginBottomTypography variant="bodyReg">
                {t`Drive your viewers to a specific page to learn more about what you're offering.`}
              </MarginBottomTypography>
            }
            <CallToActionForm updateLink={updateCtaLink} links={ctaLink} />
          </Section>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: -1, md: 0 }}>
          {showSubtitle &&
            <Typography
              textAlign="center"
              component="p"
              paddingTop={3}
              variant="caption"
            >{t`Here’s how your video will look to your recipients`}</Typography>
          }
          <Box
            sx={{
              margin: 'auto',
              backgroundImage: `url(${browser_background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              maxHeight: {
                md: '100%',
                xs: '50vh',
              },
              display: 'flex',
              aspectRatio: '1.5',
            }}
          >
            {/* Window Scroll Inner */}
            <Box sx={{
              margin: '2%',
              marginTop: '5%',
              padding: '2%',
              flex: '1 1 auto',
              display: 'flex',
              overflow: 'auto',
            }}>
              <Box
                width='50%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                height='100%' m='auto'>
                <Typography variant="h5" marginBottom={2}>
                  {getTargetVideoTitle(target, titleTemplate)}
                </Typography>
                <Box marginBottom={3}
                  padding='20%'
                  display='flex'
                  position='relative'
                  justifyContent="center"
                  alignItems='center'>
                  <img
                    alt={t`Video thumbnail`}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      maxWidth: '100%',
                      maxHeight: '100%',
                      transform: 'translate(-50%, -50%)',
                      borderRadius: 10,
                    }}
                    src={selectedThumbnail.location} />
                  <Fab
                    size="small"
                    disableRipple
                    sx={(theme) => ({
                      bgcolor: theme.palette.common.white,
                      cursor: 'default',
                    })}
                  ><SvgIcon inheritViewBox component={PlaySvg}></SvgIcon>
                  </Fab>
                </Box>
                {ctaLink[0] ? (
                  <Box><Button
                    href={ctaLink[0].link.includes('http') ? ctaLink[0].link : `http://${ctaLink[0].link}`}
                    target="_blank"
                    key={ctaLink[0].text}
                    variant="contained"
                    component="a"
                  >
                    {ctaLink[0].text}
                  </Button></Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
