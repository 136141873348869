
/** Default aspect ratio to display video recorder at */
export const DEFAULT_ASPECT_RATIO = '4/3';


// we use 0.001 b/c of safari bug and 0.001 is the smallest value that works
// otherwise it shows a black screen
export const MIN_CURRENT_TIME = 0.001;

/**
 * Gets the aspect ratio for a video element
 * if undefined used the default
 * @param dimensions the dimensions of the video or undefined
 */
export const getAspectRatio = (dimensions: VideoDimensions | undefined) => {
  if (!dimensions) return DEFAULT_ASPECT_RATIO;
  return `${dimensions.videoWidth / dimensions.videoHeight}`;
};

export type VideoDimensions = {
  /** Video Height */
  videoWidth: number;
  /** Video Width */
  videoHeight: number;
};