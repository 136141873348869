import { CSSInterpolation } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import LinkBehavior from 'config/components/link.component';
import { LINK, NEUTRAL, SECONDARY_LINK, CONTAINED_DANGER } from 'common/constants/theme';
import React from 'react';

export const TOOLBAR_HEIGHT = 56;
export const APPBAR_HEIGHT = TOOLBAR_HEIGHT + 2;
export const SPACING = 4;
export const SPACER = {
  XXS: 1,
  XS: 2,
  S: 4,
  M: 6,
  L: 8,
  XL: 10,
  XXL: 12,
} as const;

export const AI_GRADIENT = 'linear-gradient(178deg, #2BCFC4 0%, #242ACF 100%), #D9D9D9';

const BORDER_RADIUS = 10;
const ROOT_BUTTON_STYLE: CSSInterpolation = {
  fontFamily: 'Sora',
  fontWeight: 600,
  borderRadius: BORDER_RADIUS,
  fontSize: 14,
  lineHeight: '24px',
  padding: '0.57em 1.57em',
  textTransform: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
};

export const PALETTE_COLOR = {
  background: {
    default: '#FFFFFF00',
  },
  text: {
    primary: '#313131',
    secondary: '#585374',
    disabled: '#BCBACC',
  },
  neutral: {
    main: '#585374',
    light: '#F9F9FB',
    dark: '#151229',
    border: '#BCBACC',
    background: '#FFF',
    0: '#FFFFFF',
    50: '#F9F9FB',
    100: '#F4F3F7',
    200: '#DFDEEB',
    300: '#BCBACC',
    400: '#88859C',
    500: '#696681',
    600: '#585374',
    700: '#3F3B57',
    800: '#2D2944',
    900: '#151229',
  },
  primary: {
    main: '#242ACF',
    light: '#E9EAFB',
    dark: '#171A82',
    border: '#5156E1',
    background: '#E9EAFB',
    50: '#E9EAFB',
    100: '#D4D5F7',
    200: '#A8AAF0',
    300: '#7D80E8',
    400: '#5156E1',
    500: '#242ACF',
    600: '#1E23AE',
    700: '#171A82',
    800: '#0F1157',
    900: '#08092B',
  },
  secondary: {
    main: '#2BCFC4',
    light: '#E9EAFB',
    dark: '#171A82',
    border: '#56DCD3',
    background: '#EAFBF9',
    50: '#EAFBF9',
    100: '#D5F6F4',
    200: '#ABEDE9',
    300: '#80E5DE',
    400: '#56DCD3',
    500: '#2BCFC4',
    600: '#23A9A0',
    700: '#1A7F78',
    800: '#125450',
    900: '#092A28',
  },
  info: {
    main: '#5156E1',
    border: '#0F1157',
    background: '#E9EAFB',
    50: '#E9EAFB',
    100: '#D4D5F7',
    200: '#A8AAF0',
    300: '#7D80E8',
    400: '#5156E1',
    500: '#242ACF',
    600: '#1E23AE',
    700: '#171A82',
    800: '#0F1157',
    900: '#08092B',
  },
  error: {
    main: '#E60A0A',
    border: '#620404',
    background: '#FEE7E7',
    50: '#FEE7E7',
    100: '#FDCECE',
    200: '#FB9D9D',
    300: '#F96C6C',
    400: '#F73B3B',
    500: '#E60A0A',
    600: '#C40808',
    700: '#930606',
    800: '#620404',
    900: '#310202',
  },
  success: {
    main: '#16B32E',
    border: '#0B5B17',
    background: '#E8FCEB',
    50: '#E8FCEB',
    100: '#D2F9D8',
    200: '#A4F4B0',
    300: '#77EE89',
    400: '#49E961',
    500: '#16B32E',
    600: '#16B62E',
    700: '#118823',
    800: '#0B5B17',
    900: '#062D0C',
  },
  warning: {
    main: '#FF7903',
    border: '#663000',
    background: '#FFF1E5',
    50: '#FFF1E5',
    100: '#FFE4CC',
    200: '#FFC999',
    300: '#FFAD66',
    400: '#FF9233',
    500: '#FF7903',
    600: '#CC5F00',
    700: '#994700',
    800: '#663000',
    900: '#331800',
  },
};

//#region Typography
//#region Font Weight
const FONT_REGULAR = 100;
const FONT_MEDIUM = 400;
const FONT_SEMIBOLD = 600;
//#endregion Font Weight

const BODY_REGULAR: React.CSSProperties = {
  fontSize: 14,
  fontWeight: FONT_REGULAR,
  lineHeight: '24px',
  letterSpacing: 0.15,
};

const BUTTON_FONT: React.CSSProperties = {
  fontFamily: 'Sora',
  fontSize: 14,
  lineHeight: '24px',
  letterSpacing: 0.4,
  fontWeight: FONT_SEMIBOLD,
  textTransform: 'none',
};

//#endregion Typography

const LINK_BUTTON_STYLE: CSSInterpolation = {
  fontFamily: 'Basier Square',
  textDecoration: 'underline',
  '&.MuiButton-linkInherit': {
    color: 'inherit',
  },
  '&.MuiButton-linkPrimary:hover': {
    backgroundColor: 'inherit', //PALETTE_COLOR.primary[50],
    textDecoration: 'underline',
  },
  '&.Mui-disabled': {
    color: PALETTE_COLOR.neutral[300],
    backgroundColor: 'transparent',
  },
};

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tag: true;
    status: true;
    badge: true;
  }
}

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    neutral: true;
  }
  interface AlertPropsVariantOverrides {
    outlinedButton: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
  interface PaletteColor {
    border?: string;
    background?: string;
    0?: string;
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    950?: string;
  }

  interface SimplePaletteColorOptions {
    border?: string;
    background?: string;
    0?: string;
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    950?: string;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h5Semibold: React.CSSProperties;
    h6Semibold: React.CSSProperties;
    bodyReg: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySemibold: React.CSSProperties;
    bodyRegItalic: React.CSSProperties;
    bodySemiboldItalic: React.CSSProperties;
    buttonRegUnderline: React.CSSProperties;
    buttonSemiboldUnderline: React.CSSProperties;
    subtitleSemibold: React.CSSProperties;
    tag: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h5Semibold?: React.CSSProperties;
    h6Semibold?: React.CSSProperties;
    bodyReg?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodySemibold?: React.CSSProperties;
    bodyRegItalic?: React.CSSProperties;
    bodySemiboldItalic?: React.CSSProperties;
    buttonRegUnderline?: React.CSSProperties;
    buttonSemiboldUnderline?: React.CSSProperties;
    subtitleSemibold?: React.CSSProperties;
    tag?: React.CSSProperties;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h5Semibold: true;
    h6Semibold: true;
    bodyReg: true;
    bodyMedium: true;
    bodySemibold: true;
    bodyRegItalic: true;
    bodySemiboldItalic: true;
    buttonRegUnderline: true;
    buttonSemiboldUnderline: true;
    subtitleSemibold: true;
    tag: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    [LINK]: true;
    [SECONDARY_LINK]: true;
    [CONTAINED_DANGER]: true;
  }
  
  interface ButtonPropsColorOverrides {
    [NEUTRAL]: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    'large': true;
  }
}

export const themeOptions: ThemeOptions = {
  palette: PALETTE_COLOR,
  spacing: SPACING,
  zIndex: {
    drawer: 1200,
    appBar: 1201,
  },

  mixins: {
    toolbar: {
      minHeight: TOOLBAR_HEIGHT,
    },
  },
  typography: {
    fontFamily: 'Basier Square',
    fontSize: 14,
    bodyReg: BODY_REGULAR,
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    bodySemibold: {
      ...BODY_REGULAR,
      fontWeight: FONT_SEMIBOLD,
    },
    bodyMedium: {
      ...BODY_REGULAR,
      fontWeight: FONT_MEDIUM,
    },
    bodyRegItalic: {
      ...BODY_REGULAR,
      fontWeight: FONT_MEDIUM,
      fontStyle: 'italic',
    },
    h1: {
      fontFamily: 'Sora',
      fontSize: 32,
      fontWeight: 600,
    },
    h2: {
      fontFamily: 'Sora',
      fontSize: 28,
      fontWeight: 600,
      letterSpacing: -0.5,
    },
    h3: {
      fontFamily: 'Sora',
      fontSize: 24,
      fontWeight: 600,
      letterSpacing: 0.5,
    },
    h4: {
      fontSize: 20,
      fontWeight: 100,
    },
    h5: {
      fontSize: 18,
    },
    h5Semibold: {
      fontSize: 18,
      fontWeight: FONT_SEMIBOLD,
    },
    h6: {
      fontSize: 16,
    },
    h6Semibold: {
      fontSize: 16,
      fontWeight: FONT_SEMIBOLD,
    },
    button: { ...BUTTON_FONT },
    buttonRegUnderline: { ...BUTTON_FONT, fontWeight: FONT_REGULAR, textDecoration: 'underline' },
    buttonSemiboldUnderline: { ...BUTTON_FONT, fontWeight: FONT_SEMIBOLD, textDecoration: 'underline' },
    caption: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 0.4,
    },
    overline: {
      fontSize: 12,
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    subtitleSemibold: {
      fontSize: 16,
      fontWeight: FONT_MEDIUM,
    },
    tag: {
      fontSize: 14,
      fontWeight: FONT_MEDIUM,
      fontFamily: 'Sora',
    },
  },
  components: {
    MuiLinearProgress: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          height: 12,
          borderRadius: 33,
        },
        dashed: ({ ownerState }) => ({
          ...(ownerState.color &&
            ownerState.color !== 'inherit' && {
            backgroundImage: `radial-gradient(${PALETTE_COLOR[ownerState.color][500]} 0%, ${
              PALETTE_COLOR[ownerState.color][500]
            } 40%, transparent 42%)`,
          }),
        }),
        colorPrimary: {
          backgroundColor: PALETTE_COLOR.primary[50],
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: PALETTE_COLOR.neutral[100],
            color: PALETTE_COLOR.neutral[300],
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 1203,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'default',
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRight: 0,
          borderLeft: 0,
          borderColor: PALETTE_COLOR.neutral[100],
          '&.MuiAppBar-colorDefault': {
            backgroundColor: PALETTE_COLOR.neutral.background,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },
      styleOverrides: {
        root: {
          position: 'unset',
          transform: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          border: `1px solid ${PALETTE_COLOR.neutral[200]}`,
          backgroundColor: PALETTE_COLOR.neutral[0],
          fontSize: 14,
          padding: 13,
          width: 'auto',
          color: PALETTE_COLOR.neutral[400],
          '& input': {
            padding: 0,
          },
          '&.Mui-focused': {
            borderColor: PALETTE_COLOR.primary[500],
            color: PALETTE_COLOR.neutral[600],
          },
          '&.Mui-disabled': {
            color: PALETTE_COLOR.neutral[200],
          },
          '&.Mui-error': {
            borderColor: PALETTE_COLOR.error[500],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: PALETTE_COLOR.neutral[0],
            color: PALETTE_COLOR.neutral[200],
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: PALETTE_COLOR.primary[50],
          border: `1px solid ${PALETTE_COLOR.primary[500]}`,
          borderRadius: BORDER_RADIUS,
          color: PALETTE_COLOR.neutral[400],
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
          '&.Mui-focused': {
            borderColor: PALETTE_COLOR.primary[500],
            backgroundColor: PALETTE_COLOR.primary[50],
            color: PALETTE_COLOR.neutral[600],
          },
          '&::before': {
            borderBottom: 'none',
          },
          '&::after': {
            borderBottom: 'none',
          },
          '&.Mui-error': {
            background: PALETTE_COLOR.error[50],
          },
        },
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            background: PALETTE_COLOR.success[50],
            borderColor: PALETTE_COLOR.success[500],
            '&:hover': {
              background: PALETTE_COLOR.success[50],
              borderColor: PALETTE_COLOR.success[500],
            },
            '&.Mui-focused': {
              background: PALETTE_COLOR.success[50],
              borderColor: PALETTE_COLOR.success[500],
            },
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 13,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: BORDER_RADIUS,
          color: PALETTE_COLOR.neutral[400],
          '& .MuiOutlinedInput-notchedOutline': {
            display: 'none',
          },
          borderColor: PALETTE_COLOR.neutral[200],
          ...(ownerState.color !== 'success' && {
            '&:hover:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled)': {
              borderColor: PALETTE_COLOR.neutral[300],
              color: PALETTE_COLOR.neutral[600],
            },
          }),
        }),
      },
      variants: [
        {
          props: { color: 'success' },
          style: {
            borderColor: PALETTE_COLOR.success[500],
            '&:hover': {
              borderColor: PALETTE_COLOR.success[500],
            },
            '&.Mui-focused': {
              borderColor: PALETTE_COLOR.success[500],
            },
          },
        },
      ],
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '&.MuiInputAdornment-positionStart': {
            '&.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)': {
              marginTop: 0,
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...ROOT_BUTTON_STYLE,
          '&.Mui-disabled': {
            backgroundColor: PALETTE_COLOR.neutral[100],
            color: PALETTE_COLOR.neutral[300],
          },
          '& .MuiButton-startIcon': {
            marginLeft: 4,
          },
          '& .MuiButton-endIcon': {
            marginRight: 4,
          },
        },
        //#region Contained Style
        contained: {
          border: '1px solid',
          '&.Mui-disabled': {
            borderColor: PALETTE_COLOR.neutral[100],
          },
        },
        containedPrimary: {
          // Colors
          backgroundColor: PALETTE_COLOR.primary[500],
          borderColor: PALETTE_COLOR.primary[500],
          color: PALETTE_COLOR.neutral[0],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.primary[600],
            borderColor: PALETTE_COLOR.primary[600],
          },
        },
        containedSecondary: {
          // Colors
          backgroundColor: PALETTE_COLOR.primary[50],
          borderColor: PALETTE_COLOR.primary[50],
          color: PALETTE_COLOR.primary[500],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.primary[100],
            borderColor: PALETTE_COLOR.primary[100],
          },
        },
        containedSuccess: {
          // Colors
          backgroundColor: PALETTE_COLOR.success[50],
          borderColor: PALETTE_COLOR.success[50],
          color: PALETTE_COLOR.success[700],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.success[100],
            borderColor: PALETTE_COLOR.success[100],
          },
        },
        containedError: {
          // Colors
          backgroundColor: PALETTE_COLOR.error[50],
          borderColor: PALETTE_COLOR.error[50],
          color: PALETTE_COLOR.error[700],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.error[100],
            borderColor: PALETTE_COLOR.error[100],
          },
        },
        containedWarning: {
          // Colors
          backgroundColor: PALETTE_COLOR.warning[50],
          borderColor: PALETTE_COLOR.warning[50],
          color: PALETTE_COLOR.warning[600],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.warning[100],
            borderColor: PALETTE_COLOR.warning[100],
          },
        },
        //#endregion Contained Style
        //#region Outlined Style
        outlinedPrimary: {
          // Colors
          backgroundColor: PALETTE_COLOR.neutral[0],
          color: PALETTE_COLOR.primary[500],
          borderColor: PALETTE_COLOR.primary[500],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
        },
        outlinedSecondary: {
          // Colors
          backgroundColor: PALETTE_COLOR.secondary[200],
          color: PALETTE_COLOR.neutral[900],
          BorderColor: PALETTE_COLOR.secondary[400],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.secondary[100],
          },
        },
        outlinedSuccess: {
          // Colors
          backgroundColor: PALETTE_COLOR.success[50],
          color: PALETTE_COLOR.success[700],
          borderColor: PALETTE_COLOR.success[700],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.success[100],
          },
        },
        outlinedError: {
          // Colors
          backgroundColor: PALETTE_COLOR.error[50],
          color: PALETTE_COLOR.error[700],
          borderColor: PALETTE_COLOR.error[700],
          // Selector
          '&:hover': {
            backgroundColor: PALETTE_COLOR.error[100],
          },
        },
        outlinedInherit: {
          color: PALETTE_COLOR.neutral[500],
          borderColor: PALETTE_COLOR.primary[50],
        },
        //#endregion Outlined Style
        //#region Size
        sizeSmall: {
          padding: 4,
        },
        sizeMedium: {
          padding: 6,
        },
        sizeLarge: {
          padding: '8px 12px',
        },
        //#endregion Size
      },
      variants: [
        {
          props: { variant: LINK },
          style: {
            ...LINK_BUTTON_STYLE,
          },
        },
        {
          props: { variant: SECONDARY_LINK },
          style: {
            ...LINK_BUTTON_STYLE,
            fontWeight: 400,
          },
        },
        {
          props: { color: NEUTRAL },
          style: {
            backgroundColor: PALETTE_COLOR.neutral[100],
            color: PALETTE_COLOR.neutral[600],
            '&:hover': {
              backgroundColor: PALETTE_COLOR.neutral[200],
            },
          },
        },
        {
          props: { variant: CONTAINED_DANGER },
          style: {
            backgroundColor: PALETTE_COLOR.error[400],
            color: PALETTE_COLOR.neutral[0],
            '&:hover': {
              backgroundColor: PALETTE_COLOR.error[500],
            },
          },
        },
      ],
    },
    MuiSwitch: {
      variants: [{
        props: {
          size: 'large',
        },
        style: {
          width: 55,
          height: 25,
          '& .MuiSwitch-thumb': {
            width: 20,
            height: 20,
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
          },
          '& .Mui-checked': {
            transform: 'translateX(31px) !important',
            '&+.MuiSwitch-track': {
              opacity: 1,
            },
          },
        },
      }],
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: 'flex',
        },
        sizeSmall: {
          '& .MuiSwitch-thumb': {
            width: 12,
            height: 12,
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
          },
          '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
          },
          '& .Mui-checked': {
            transform: 'translateX(12px) !important',
            '&+.MuiSwitch-track': {
              opacity: 1,
            },
            '&:active': {
              transform: 'translateX(9px)',
            },
          },
        },
        sizeMedium: {
          width: 40,
          height: 20,
          borderRadius: 10,
          '& .MuiSwitch-switchBase': {
            padding: 1,
          },
          '& .MuiSwitch-thumb': {
            width: 18,
            height: 18,
          },
          '&.Mui-checked': {
            transform: 'translateX(20px)',
            '&+.MuiSwitch-track': {
              opacity: 1,
            },
            '&:active': {
              transform: 'translateX(15px)',
            },
          },
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: PALETTE_COLOR.primary[500],
            },
          },
          '&.Mui-disabled': {
            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: PALETTE_COLOR.primary[100],
            },
          },
        },
        thumb: {
          color: PALETTE_COLOR.neutral[0],
        },
        track: {
          boxSizing: 'border-box',
          backgroundColor: PALETTE_COLOR.neutral[300],
          opacity: 1,
          transition: `background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          backgroundColor: PALETTE_COLOR.neutral[0],
          borderRadius: BORDER_RADIUS,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          marginRight: 16,
          marginTop: 8,
          '&:first-of-type': { borderRadius: BORDER_RADIUS, border: '1px solid' },
          '&:not(:first-of-type)': { borderRadius: BORDER_RADIUS, border: '1px solid' },
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { color: 'neutral' },
          style: {
            backgroundColor: PALETTE_COLOR.neutral[100],
            color: PALETTE_COLOR.neutral[900],
          },
        },
        {
          props: { variant: 'outlinedButton' },
          style: {
            ...ROOT_BUTTON_STYLE,
            border: '1px solid',
            '& .MuiAlert-message': {
              padding: 0,
            },
            '& .MuiAlert-icon': {
              padding: 0,
              marginRight: 8,
              marginLeft: -4,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        standard: {
          borderRadius: BORDER_RADIUS,
          fontWeight: 100,
          '& .MuiAlert-icon, & .MuiAlert-message a': {
            color: 'inherit',
          },
          '& .MuiAlert-message': {
            flexGrow: 1,
          },
        },
        action: {
          padding: 0,
          paddingLeft: 16,
          whiteSpace: 'nowrap',
          alignItems: 'center',
        },
        standardInfo: {
          backgroundColor: PALETTE_COLOR.primary[50],
          color: PALETTE_COLOR.primary[800],
        },
        standardWarning: {
          backgroundColor: PALETTE_COLOR.warning[50],
          color: PALETTE_COLOR.warning[800],
        },
        standardError: {
          backgroundColor: PALETTE_COLOR.error[50],
          color: PALETTE_COLOR.error[800],
        },
        standardSuccess: {
          backgroundColor: PALETTE_COLOR.success[50],
          color: PALETTE_COLOR.success[800],
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 600,
          marginBottom: 0,
        },
      },
    },
    // TODO: remove eslint-disable and ts-ignore when MuiMasonry is moved from @mui/lab to @mui/material
    // https://github.com/mui/material-ui/issues/29817
    /* eslint-disable  @typescript-eslint/ban-ts-comment */
    // @ts-ignore-start
    MuiMasonry: {
      defaultProps: {
        columns: { xs: 1, sm: 2, md: 4 },
        spacing: 3,
      },
    },
    // @ts-ignore-end
    /* eslint-enable  @typescript-eslint/ban-ts-comment */
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: PALETTE_COLOR.neutral[100],
          color: PALETTE_COLOR.neutral[800],
          padding: SPACER.XL,
          '.MuiTooltip--dark &': {
            backgroundColor: PALETTE_COLOR.neutral[600],
            color: PALETTE_COLOR.neutral[0],
          },
        },
        arrow: {
          color: PALETTE_COLOR.neutral[100],
          '.MuiTooltip--dark &': {
            color: PALETTE_COLOR.neutral[600],
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 3,
        PaperProps: {
          sx: {
            borderRadius: '8px',
          },
        },
      },
      styleOverrides: {
        list: {
          padding: 0,
        },
        paper: {
          '& .MuiListItemIcon-root': {
            minWidth: '30px',
            '& svg': {
              fontSize: '21px',
              color: PALETTE_COLOR.primary.main,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&:hover': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
          '&.focus': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
          borderBottom: `1px solid ${PALETTE_COLOR.neutral[200]}`,
          '&.Mui-selected': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
          '&.Mui-focusVisible': {
            backgroundColor: PALETTE_COLOR.primary[50],
          },
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: PALETTE_COLOR.primary[200],
            backgroundColor: PALETTE_COLOR.primary[50],
            borderRadius: BORDER_RADIUS,
          },
        },
        {
          props: { variant: 'outlined', color: 'neutral' },
          style: {
            backgroundColor: PALETTE_COLOR.neutral[100],
            borderColor: PALETTE_COLOR.neutral[500],
            borderRadius: BORDER_RADIUS,
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'tag' },
          style: {
            backgroundColor: PALETTE_COLOR.neutral[500],
            color: PALETTE_COLOR.neutral[0],
            borderRadius: '5px',
            padding: '2px 8px',
            fontWeight: 600,
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
        {
          props: { variant: 'tag', color: 'primary' },
          style: {
            backgroundColor: PALETTE_COLOR.primary[500],
            color: PALETTE_COLOR.primary[50],
          },
        },
        {
          props: { variant: 'tag', color: 'secondary' },
          style: {
            backgroundColor: PALETTE_COLOR.secondary[50],
            color: PALETTE_COLOR.secondary[600],
          },
        },
        {
          props: { variant: 'badge' },
          style: {
            backgroundColor: PALETTE_COLOR.neutral[100],
            color: PALETTE_COLOR.neutral[500],
            borderRadius: BORDER_RADIUS,
            padding: '0 6px',
            fontWeight: 400,
            fontSize: 14,
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
        {
          props: { variant: 'badge', color: 'secondary' },
          style: {
            backgroundColor: PALETTE_COLOR.secondary[50],
            color: PALETTE_COLOR.secondary[700],
          },
        },
        {
          props: { variant: 'status' },
          style: {
            backgroundColor: PALETTE_COLOR.warning[50],
            color: PALETTE_COLOR.warning[400],
            border: '1px solid',
            height: 'auto',
            marginBottom: '7px',
            borderRadius: '5px',
            padding: '2px 8px',
            fontWeight: 400,
            fontSize: 12,
            '& .MuiChip-label': {
              padding: 0,
            },
          },
        },
        {
          props: { variant: 'status', color: 'success' },
          style: {
            backgroundColor: PALETTE_COLOR.secondary[50],
            color: PALETTE_COLOR.secondary[600],
          },
        },
        {
          props: { variant: 'status', color: 'primary' },
          style: {
            backgroundColor: PALETTE_COLOR.primary.main,
            color: PALETTE_COLOR.neutral[0],
          },
        },
        {
          props: { variant: 'status', color: 'error' },
          style: {
            backgroundColor: PALETTE_COLOR.error[50],
            color: PALETTE_COLOR.error[600],
          },
        },
      ],
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: PALETTE_COLOR.neutral[500],
          '.Mui-disabled &, .Mui-active &': {
            fill: PALETTE_COLOR.neutral[0],
          },
        },
        root: {
          border: '1px solid',
          borderRadius: '50%',
          borderColor: PALETTE_COLOR.neutral[500],
          color: PALETTE_COLOR.neutral[200],
          '.Mui-disabled &': {
            border: 0,
          },
          '&.Mui-completed,&.Mui-active': {
            color: PALETTE_COLOR.neutral[500],
            'button:hover &': {
              borderColor: PALETTE_COLOR.primary[500],
              color: PALETTE_COLOR.primary[500],
            },
          },
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          margin: -SPACING * 3,
          padding: SPACING * 3,
        },
        horizontal: {
          '&:hover': {
            color: PALETTE_COLOR.neutral[500],
          },
          '& .MuiStepLabel-label': {
            '&.Mui-completed,&.Mui-active': {
              fontWeight: 600,
            },
          },
          '&.Mui-disabled': {
            background: 'transparent',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: BORDER_RADIUS,
          '&:first-of-type': {
            borderRadius: BORDER_RADIUS,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: BORDER_RADIUS,
        },
      },
    },
  },
};
