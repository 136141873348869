import { Box, Button, ListItemIcon, Menu, MenuItem, SvgIcon, Tab, Tabs } from '@mui/material';
import { SPACER } from 'config/models/themeOptions';
import ShareView from 'dashboard/components/shareView.component';
import { FetchedVideoProjectProps } from 'common/components/fetchProject.component';
import React, { FunctionComponent, ReactNode, SVGProps, SyntheticEvent, useState } from 'react';
import { t } from '@lingui/macro';
import TitleEdit from 'common/components/titleEdit.component';
import EditLandingPage from './editLandingPage.component';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';
import DeleteProjectModal from './deleteProjectModal.component';
import { ReactComponent as OptionsSvg } from 'common/images/icons/Utility/options_vertical.svg';
import { ReactComponent as TrashSvg } from 'common/images/icons/Utility/trash.svg';
import { ReactComponent as PencilSvg } from 'common/images/icons/Utility/pencil.svg';
import { DEFAULT_TITLE } from '../../edit/const';
import EditProjectModal from './editProjectModal.component';

type StyledMenuItemProps = {
  onClick: () => void;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
};
const StyledMenuItem = ({ onClick, icon, label } : StyledMenuItemProps) => {
  return <MenuItem
        component="a"
        onClick={onClick}
        sx={{ alignItems: 'stretch'  }}
    >
        <ListItemIcon>
            <SvgIcon component={icon} inheritViewBox />
        </ListItemIcon>
        {label}
    </MenuItem>;
};
type ProjectMenuProps = {
  /** id of the project */
  projectId: string;
  /** name of the project */
  projectName: string;
};

/**
 * Component for Project Menu
 * lists actionable items on project
 */
function ProjectMenu({ projectName, projectId }: ProjectMenuProps) {
  const { isEnabled: duplicateVideoProject } = useFeatureFlag(BooleanFeatureFlagNames.duplicateVideoProject);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDeleteProject, setIsDeleteProject] = useState<boolean>(false);
  const [isEditProject, setIsEditProject] = useState<boolean>(false);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        size="small"
        startIcon={<SvgIcon component={OptionsSvg} inheritViewBox />}
        onClick={handleClickMenu}
        sx={{ minWidth: 0, p: 2 }}
      />
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleClose}>
        {duplicateVideoProject && 
          <StyledMenuItem
            onClick={() => {
              setIsEditProject(true);
              handleClose();
            }}
            icon={PencilSvg}
            label={t`Edit video`}
          />
        }
        <StyledMenuItem
          onClick={() => {
            setIsDeleteProject(true);
            handleClose();
          }}
          icon={TrashSvg}
          label={t`Delete`}
        />
      </Menu>
      {isDeleteProject && (
        <DeleteProjectModal projectId={projectId} projectName={projectName} onClose={() => setIsDeleteProject(false)} navigateUrl={'/'}/>
      )}
      {isEditProject && <EditProjectModal projectId={projectId} onClose={() => setIsEditProject(false)} />}
    </>
  );
}

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: SPACER.M }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TabbedShareViewProps = FetchedVideoProjectProps & {
  /** callback to update the title */
  onTitleUpdate: (title: string) => Promise<void>;
};

/** The different tabbed views on this interface */
enum TabbedShareViewTab {
  SHARE,
  ANALYTICS,
}

/**
 * Component to show tabbed view of the share actions
 */
export default function TabbedShareView({ data, onTitleUpdate }: TabbedShareViewProps) {
  const [value, setValue] = useState<TabbedShareViewTab>(TabbedShareViewTab.SHARE);
  const { isEnabled: deleteProjectEnabled } = useFeatureFlag(BooleanFeatureFlagNames.deleteVideoProject);
  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box justifyContent={'space-between'} display={'flex'} >
        <TitleEdit maxWidth="100%" variant="h1" title={data.title ?? ''} onUpdate={onTitleUpdate} startIcon={false} />
        {deleteProjectEnabled && (
         <ProjectMenu projectName={data.title ?? DEFAULT_TITLE} projectId={data.id}/>
        )}
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{
            '& .MuiTabs-indicator': {
              height: 4,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label={t`${data.title} details`}
        >
          <Tab label={t`Share`} {...a11yProps(0)} />
          <Tab label={t`Analytics`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={TabbedShareViewTab.SHARE}>
        <Box marginBottom={SPACER.S}>
          <EditLandingPage data={data} />
        </Box>
        <ShareView data={data} showAnalytics={false} enablePreview={true} />
      </TabPanel>
      <TabPanel value={value} index={TabbedShareViewTab.ANALYTICS}>
        <ShareView data={data} showActions={false} enablePreview={true} />
      </TabPanel>
    </>
  );
}
