import { ITarget, ITargetInput } from 'data/_generated';

//#region Target Form Helpers

/**
 * Helper function to convert ITarget to ITargetInput
 * @param targets a list of targets
 * @returns a list of target inputs
 */
export function convertToInputType(targets: Readonly<ITarget[]>): ITargetInput[] {
  return targets.map((target) => {
    return {
      id: target.id,
      name: target.name,
      email: target.email,
    };
  });
}

/**
 * Helper function to check if target form is empty
 * Needed as targets list is not required to create a project.
 * If target form is empty, we should still be able to create a project
 * @param targets a list of targets
 * @returns boolean
 */
export function isTargetFormEmpty(targets: ITargetInput[]) {
  return targets.every((target) => target.name === '' && target.email === '');
}
//#endregion
