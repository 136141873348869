import { Box, IconButton, Link, SvgIcon, Typography, useTheme } from '@mui/material';
import IconTooltip from 'common/components/tooltip/iconTooltip.component';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import { t } from '@lingui/macro';
import { COPILOT_VIDEO_EMAIL } from 'common/const';
import useEmail from 'common/hooks/useEmail';

export function TooltipContent({ max }: { max: number }) {
  const mailTo = useEmail();
  const theme = useTheme();
  return (
    <>
      <Typography variant="bodyReg">{t`You can create up to ${max} personalized intros with your  current plan. Ready to reach a larger audience?`}</Typography>
      <Box display="block" sx={{ mt: 5 }}>
        <Link
          href={mailTo(COPILOT_VIDEO_EMAIL, t`Limit Increase Request`)}
          color={theme.palette.neutral[0]}
          variant="bodySemibold"
        >{t`Contact us `}</Link>{' '}
        <Typography variant="bodySemibold">{t`to explore options for increasing your limit.`}</Typography>
      </Box>
    </>
  );
}

type PersonalizedIntroLimitProps = {
  /** current number of personalized intros */
  current: number;
  /** max number of personalized intros allowed */
  max: number;
};

/**
 * Component to show personalized intro limitation
 */
export default function PersonalizedIntroLimit({ current, max }: PersonalizedIntroLimitProps) {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="baseline">
      <IconTooltip title={<TooltipContent max={max} />} color="dark">
        <IconButton sx={{ p: 0, ml: 1, color: PALETTE_COLOR.neutral[300], mr: 2 }}>
          <SvgIcon component={InfoSvg} inheritViewBox sx={{ fontSize: '1rem' }} />
        </IconButton>
      </IconTooltip>
      <Typography variant="caption" color={theme.palette.neutral[400]}>{t`(${current}/${max} recipients)`}</Typography>
    </Box>
  );
}

