import { t } from '@lingui/macro';
import yup from 'common/utils/yup-extended';
import { ITargetInput } from 'data/_generated';

export const Targets = 'targets' as const;

export const TargetPropertyNames = {
  id: 'id',
  name: 'name',
  email: 'email',
  intro: 'intro',
} as const;

export const TargetSchema = yup.object().shape({
  [TargetPropertyNames.name]: yup
    .string()
    .required(t`Enter a name`)
    // Extract a list of special characters from https://www.webopedia.com/definitions/special-character/
    // Excluded some of the special characters that could be used in name.
    .matches(/^((?![~`!@#$%^&*()_+={}[\]\\:;"'<>,..?]).)*$/, {
      message: t`Special characters are not allowed`,
    }),
  [TargetPropertyNames.email]: yup.string().email(t`Enter a valid email address`),
});

export const TargetsSchema = yup.object().shape({
  [Targets]: yup
    .array()
    .of(TargetSchema)
    .unique(TargetPropertyNames.email, t`Email already exists`),
});


/**
 * New target row type for TargetForm using React Hook Form.
 *
 * target id is set to empty string instead of undefined.
 * We use append from useFieldArray hook from React Hook Form which data is required and not partial.
 * If we remove an existing target and then append a new target, it persists the property from previously removed one unless new value is specified.
 * Empty string will be converted into undefined during handleSubmit.
 */
export const NEW_TARGET_FORM_DATA_INPUT = {
  [TargetPropertyNames.name]: '',
  [TargetPropertyNames.email]: '',
  [TargetPropertyNames.id]: '',
};

/**
 * Target form data type for React Form Hook FieldArray
 */
export type TargetFormDataType = {
  [Targets]: ITargetInput[];
};
/**
 * Max number of personalized intro
 * Legacy limit before monetization
 */
export const MAX_TARGETS_LEGACY = 50;