import { t } from '@lingui/macro';
import Modal from 'common/components/modal/modal.component';
import { useState } from 'react';
import { ReactComponent as GallerySvg } from 'common/images/icons/Camera/Gallery.svg';
import { Button, SvgIcon } from '@mui/material';
import { FetchedVideoProjectProps } from 'common/components/fetchProject.component';
import VideoRender from 'edit/components/videoRender.component';
import { isSingleClipSlot } from 'edit/const';
import { first, isUndefined } from 'lodash';
import { ITargetInput, IVideoProjectInput, usePublishVideoProjectMutation, useUpdateVideoProjectMutation, useUpdateVideoProjectTargetsMutation } from 'data/_generated';
import { extractError } from 'common/utils/errors';

type EditLandingPageModalProps = {
  onClose: () => void;
} & FetchedVideoProjectProps;

function EditLandingPageModal({ onClose, data }: EditLandingPageModalProps) {
  const [updatedData, setUpdatedData] = useState<IVideoProjectInput | undefined>();
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [updateVideoProject] = useUpdateVideoProjectMutation();
  const [updateTargets] = useUpdateVideoProjectTargetsMutation();
  const [publishProject] = usePublishVideoProjectMutation({
    variables: { publishVideoProjectId: data.id },
    refetchQueries: ['UserUsageInfo', 'VideoProject'],
    awaitRefetchQueries: true,
  });

  const handleConfirm = async () => {
    try {
      if (isUndefined(updatedData)) return;
      setIsPublishing(true);

      if (!isUndefined(updatedData.landingPageTitleTemplate)) { // update title hence clear all targets titles
        const targets: ITargetInput[] = data.targets.map(({ email, id, name }) => ({ email, id, name }));
        await updateTargets({
          variables: {
            updateVideoProjectTargetsId: data.id,
            data: { targets },
          },
        });
      }
      await updateVideoProject({
        variables: { updateVideoProjectId: data.id, data: updatedData },
      });
      await publishProject();
      onClose();
    } catch (err) {
      setError(extractError(err));
    } finally {
      setIsPublishing(false);
    }
  };

  return <Modal
    isOpen={true}
    confirmLabel={t`Apply to all`}
    icon={GallerySvg}
    maxWidth='lg'
    confirmLoading={isPublishing}
    confirmDisabled={isUndefined(updatedData)}
    onConfirm={() => void handleConfirm()}
    confirmError={error}
    onClose={onClose} title={t`Customize video appearance`} >
    <VideoRender
      onUpdate={(newData) => setUpdatedData({ ...updatedData, ...newData })}
      landingPageTitleTemplateTitle={t`Change your page title`}
      linksTitle={t`Add your call-to-action`}
      showSubtitle={false}
      landingPageTitleTemplate={data.landingPageTitleTemplate}
      links={data.links}
      thumbnail={data.thumbnail}
      targetName={first(data.targets)?.name}
      clipSlot={first(data.timeline.filter(isSingleClipSlot))}
    />
  </Modal>;
}


export default function EditLandingPage({ data }: FetchedVideoProjectProps) {
  const [open, setOpen] = useState<boolean>(false);
  return <>
    <Button
      variant='outlined'
      startIcon={<SvgIcon component={GallerySvg} inheritViewBox />}
      onClick={() => setOpen(true)}>{t`Customize video appearance`}</Button>
    {open && <EditLandingPageModal data={data} onClose={() => setOpen(false)} />}
  </>;
}