import { ReactNode } from 'react';
import {
  AuthenticationResult,
  Configuration,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useConfig } from '../hooks/useConfig';
import { AuthContext } from '../context/auth.context';
import { MsalProvider } from '@azure/msal-react';

function Auth({ children }: { children: ReactNode }) {
  const { clientId, policy, authority, scope } = useConfig();
  const msalConfig: Configuration = {
    auth: {
      clientId: clientId,
      authority: policy,
      knownAuthorities: [authority],
      navigateToLoginRequestUrl: true,
      redirectUri: '/',
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);
  msalInstance.addEventCallback(
    message => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const { account } = message.payload as AuthenticationResult;
        msalInstance.setActiveAccount(account);
      }
    },
  );
  const accounts = msalInstance.getAllAccounts();
  if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  const value = {
    scopes: [scope],
  };
  return (
    <AuthContext.Provider value={value}>
      <MsalProvider instance={msalInstance}>
        {children}
      </MsalProvider>
    </AuthContext.Provider>);
}

export default Auth;
