import { t } from '@lingui/macro';
import { Box, Skeleton, SvgIcon, Typography } from '@mui/material';
import AppBar from 'common/components/layout/appBarConfig.component';
import { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ReactComponent as LeftSvg } from 'common/images/icons/Utility/left.svg';
import { ErrorBoundary } from 'common/components/errorBoundary/errorBoundary.component';
import { isUndefined } from 'lodash';
import UserButton from 'common/components/userButton/userButton.component';
import TitleEdit from 'common/components/titleEdit.component';
import { SPACER } from '../../config/models/themeOptions';

type OnTitleUpdateCallback = (newTitle: string) => void | Promise<void>;
export type TimelineLayoutContext = {
  updateTitle: (title: string, onTitleUpdate: OnTitleUpdateCallback) => void;
};

type TimelineLayoutProps = {
  /** the url that clicking back should take you to */
  backHref?: string;
  /** the text shown for */
  backText?: string;
  /** if the user can edit the title */
  canEditTitle?: boolean;
  /** if we should show the user button */
  showUserButton?: boolean;
  /** any more elements to show in the app bar */
  children?: ReactNode;
};

export default function TimelineLayout({ 
  backHref, children,
  backText = t`My Videos`,
  canEditTitle = false, 
  showUserButton = true }: TimelineLayoutProps) {
  const [title, setTitle] = useState<string | undefined>();
  const [onTitleUpdate, setOnTitleUpdate] = useState<OnTitleUpdateCallback | undefined>();
  const updateTitle = (newTitle: string, callback?: OnTitleUpdateCallback) => {
    setTitle(newTitle);
    setOnTitleUpdate(() => callback);
  };
  const isInIframe = window.parent !== window;

  const changeTitle = async (newTitle: string) => {
    if (onTitleUpdate) await onTitleUpdate(newTitle);
  };
  return (
    <>
      <AppBar
        backDisabled={isInIframe}
        backHref={backHref}
        backIcon={<SvgIcon inheritViewBox component={LeftSvg} />}
        backText={
          <Typography
            sx={{
              display: {
                xs: 'none',
                sm: 'inline',
              },
            }}
          >{backText}</Typography>
        }
        backStyling={{ justifyContent: 'flex-start' }}
        justifyContent={'center'}
      >
        {canEditTitle && <Typography variant={'body1'}>{t`/`}</Typography>}
        <Box sx={{
          textAlign: 'left',
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-start',
        }} >
          {(canEditTitle && (isUndefined(title) ?
            <Skeleton variant="text" width={100} sx={{ ml: SPACER.XS }} /> :
            <TitleEdit
                startIcon={false}
              title={title}
              maxWidth='calc(100vw - 200px)'
              onUpdate={changeTitle} />))}
        </Box>
        <Box sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
        }}>
          <Box
              component='span'
              id="extra"
              sx={{ ml: 'auto' }}
          >
            {/* Placeholder for extras from AppCues */}
          </Box>
          {children}
          {showUserButton && <UserButton />}
        </Box>
      </AppBar>
      <ErrorBoundary>
        <Outlet context={{ updateTitle }} />
      </ErrorBoundary>
    </>
  );
}
