import { useOutletContext, useParams } from 'react-router-dom';
import { IVideoProcessingStatus, IVideoProjectShareFragment, useVideoProjectQuery } from '../../data/_generated';
import { CircularProgress, Grid } from '@mui/material';
import { usePolling } from 'config/hooks/usePolling';
import { TimelineLayoutContext } from 'edit/layout/timeline.layout';
import { isString, isUndefined } from 'lodash';
import { FetchProps, FetchedViewProps } from 'common/const';
import useUpdateTitle from 'common/hooks/useUpdateTitle';

type FetchVideoProjectProps = FetchProps & {
  /** render with a callback to update the title */
  render: (data: IVideoProjectShareFragment, onTitleUpdate: (title: string) => Promise<void>) => JSX.Element
  /** render error component */
  renderError?: () => JSX.Element
};

export type FetchedVideoProjectProps = FetchedViewProps<IVideoProjectShareFragment>;

/**
 * Using the render props method to fetch data and consistently handle errors in consistent ways
 * @returns {JSX.Element}
 */
export default function FetchVideoProject({
  fetchPolicy,
  render,
  renderError,
  children }: FetchVideoProjectProps) {
  const { id } = useParams();
  if (!id) throw new Error('Could not find the Project ID in the URL, maybe this was a bad link?');
  const { loading, data, error, startPolling, stopPolling } = useVideoProjectQuery({
    variables: { videoProjectId: id },
    fetchPolicy,
    onCompleted: (d) => {
      // this is sync the layout with the current title
      if (isString(d.videoProject.title)) updateTitle(d.videoProject.title, onTitleUpdate);
    },
  });

  const { updateTitle } = useOutletContext<TimelineLayoutContext>();
  const { updateTitle: onTitleUpdate } = useUpdateTitle(id);

  const statuses = data?.videoProject.targets.map((target) => target.publishStatus?.status) ?? [];
  const shouldPoll =
      fetchPolicy === 'no-cache' &&
      (statuses.includes(IVideoProcessingStatus.Init) ||
        statuses.includes(IVideoProcessingStatus.Pending) ||
        statuses.includes(IVideoProcessingStatus.Processing) ||
        statuses.includes(IVideoProcessingStatus.Processed) ||
        statuses.includes(IVideoProcessingStatus.Publishing));

  usePolling({
    startPolling,
    stopPolling,
    shouldPoll,
  });

  if (error) {
    if (!isUndefined(renderError)) {
      renderError();
    } else {throw error;}
  }
  if (loading)
    return children ? (
        <>{children}</>
    ) : (
        <Grid
          container
          sx={{ minHeight: (theme) => `calc(100vh - ${theme.spacing(17)})` }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Grid>
    );
  if (!data?.videoProject) {
    if (!isUndefined(renderError)) {
      return renderError();
    } else {
      throw new Error('Could not find Video Project');
    }
  }
  return render(data.videoProject, onTitleUpdate);
}