import { t } from '@lingui/macro';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  SvgIcon,
  Paper,
  Button,
  useTheme,
  CircularProgress,
} from '@mui/material';
import SpacerBar from 'common/components/layout/spacerBar.component';
import LinkBehavior from 'config/components/link.component';
import { ReactComponent as BarChartRiseSvg } from 'common/images/icons/Business & Finance/Bar chart rise.svg';
import { ReactComponent as VideoFolderSvg } from 'common/images/icons/Folders/video folder.svg';
import { useLocation } from 'react-router-dom';
import {
  ResponsiveDrawerProps,
  ResponsiveDrawer,
} from '../../common/components/responsiveDrawer/responsiveDrawer.component';
import { SPACER } from 'config/models/themeOptions';
import { ReactComponent as DiscoIcon } from 'common/images/icons/Party/disco.svg';
import { useUserUsageInfoQuery } from 'data/_generated';
import useTrialDays from 'common/hooks/useTrialDays';
import CreateVideoButton from './createVideoButton.component';
import { trackEvent } from '../../common/utils/errors';

export const FEATURE_REQUESTS = 'https://video-copilot-ai.canny.io/feature-requests?sort=top';

interface ListItemLinkProps {
  /** url to navigate to on click */
  href: string;
  /** Button text */
  text: string;
  /** the number of this item to show in the listing */
  count?: number;
  /** the icon for this list item */
  icon?: React.ReactElement;
}

/**
 * List Item button that handles active state
 * TODO: using deprecated ListItem with button prop (should be using ListItemButton)
 *          - Update when this PR is fixed https://github.com/mui/material-ui/issues/29030
 */
function ListItemLink({ href, text, count, icon }: ListItemLinkProps) {
  const location = useLocation();
  return (
    <ListItem
      sx={(theme) => ({
        color: theme.palette.neutral.border,
        borderRadius: '5px',
        p: 2,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        '&:focus': {
          color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
        },
      })}
      button
      component={LinkBehavior}
      href={href}
      selected={href === location.pathname}
      secondaryAction={count && <Typography>{count}</Typography>}
    >
      {icon && (
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: 'auto',
            pr: 1,
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={text} />
    </ListItem>
  );
}

function DashboardDrawer(props: Omit<ResponsiveDrawerProps, 'children'>) {
  const { data } = useUserUsageInfoQuery();
  const theme = useTheme();
  const [totalTrial, remainingTrial] = useTrialDays();
  const { drawerWidth, mobileOpen, onDrawerToggle } = props;

  const drawerContent = (
    <Box
      sx={{
        mx: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      }}
    >
      <SpacerBar />
      <List sx={{ flexGrow: 1, p: 6 }}>
      <CreateVideoButton
        buttonSx={{ mb: SPACER.S, display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        buttonColor='primary'
        trackEventName='sidenav-create'
      />
        <ListItemLink href="/" 
          icon={<SvgIcon inheritViewBox component={VideoFolderSvg} />} 
          text={t`My Videos`}
          count={data?.userUsageInfo?.allProjectCount}
          />
        <ListItemLink href="/analytics" 
          icon={<SvgIcon inheritViewBox component={BarChartRiseSvg} />} text={t`Analytics`} 
        />
      </List>
      <Paper sx={{ p: SPACER.S, m: SPACER.M, textAlign: 'center' }} variant="outlined" color="primary">
        <Typography variant="h6">
          <SvgIcon inheritViewBox component={DiscoIcon} sx={{ verticalAlign: 'middle' }} />
          {t`We have some new features brewing...`}
        </Typography>
        <Button
          sx={{ mt: SPACER.S }}
          variant="contained"
          color="primary"
          target="_blank"
          href={FEATURE_REQUESTS}
          onClick={() => trackEvent('drawer-feedback')}
        >{t`See what’s coming`}</Button>
      </Paper>
      {totalTrial && remainingTrial && (
        <Paper
          sx={{
            p: SPACER.S,
            mx: SPACER.M,
            mb: SPACER.M,
            textAlign: 'center',
          }}
          variant="outlined"
          color="neutral"
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant="determinate"
              value={(remainingTrial / totalTrial) * 100}
              sx={{
                '& .MuiCircularProgress-circle': {
                  fill: theme.palette.neutral[200],
                  color: theme.palette.neutral[600],
                },
              }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: theme.palette.neutral[600] }}>{remainingTrial}</Typography>
            </Box>
          </Box>
          <Typography variant="bodyReg" display="block">{t`Days left`}</Typography>
          <Typography variant="bodyReg" display="block">{t`in your free-trial`}</Typography>
        </Paper>
      )}
    </Box>
  );
  return (
    <>
      <ResponsiveDrawer drawerWidth={drawerWidth} mobileOpen={mobileOpen} onDrawerToggle={onDrawerToggle}>
        {drawerContent}
      </ResponsiveDrawer>
      <CreateVideoButton trackEventName='mobile-create' isMobile />
    </>
  );
}

export { DashboardDrawer };
