import { t } from '@lingui/macro';
import { Box, TextField, Typography, MenuItem, TypographyTypeMap } from '@mui/material';
import Select from 'common/components/select/select.component';
import { SPACER } from 'config/models/themeOptions';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type SelectWithCustomOptionProp = {
  /** the field name */
  name: string;
  /** the current value of the field */
  value?: string | null;
  /** the top level label for this field */
  label: string;
  /** the options to show if there are any */
  options: string[];
  /** the placeholder to show to select an option */
  optionsPlaceholder: string;
  /** the text placeholder to show if there is not options or other was selected */
  textPlaceholder: string;
  /** the other value */
  otherValue?: string;
  /** the label for the other field */
  otherLabel: string;
  /** field name for custom option */
  customFieldName: string;
  /** typography variant for labels */
  labelVariant?: TypographyTypeMap['props']['variant'];
};

/** value to trigger custom field when selected */
const Other = 'Other';

/** A wrapper for the functionality to support select with custom option entered by user */
export default function SelectWithCustomOption({
  name,
  value,
  label,
  options,
  optionsPlaceholder,
  textPlaceholder,
  otherValue,
  otherLabel,
  customFieldName,
  labelVariant,
}: SelectWithCustomOptionProp) {
  const { setValue, control } = useFormContext();
  const selectFieldLabel = `${name}_select_label`;

  const [showOtherTextField, setShowOtherTextField] = useState<boolean>(false);

  useEffect(() => {
    if (value !== Other) setShowOtherTextField(false);
  }, [value]);

  return (
    <Box mt={SPACER.M}>
      {isEmpty(options) ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <>
                <Typography variant={labelVariant}>{label}</Typography>
                <TextField
                  name={field.name}
                  fullWidth
                  value={value}
                  size="small"
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    setValue(field.name, e.target.value, { shouldValidate: true });
                  }}
                  placeholder={textPlaceholder}
                />
              </>
            );
          }}
        />
      ) : (
        <>
          <Typography variant={labelVariant}>{label}</Typography>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <Select
                  variant={'standard'}
                  name={field.name}
                  labelId={selectFieldLabel}
                  size="small"
                  fullWidth
                  value={value}
                  displayEmpty
                  renderValue={(option) =>
                    !isEmpty(option) ? (
                      option
                    ) : (
                      <Typography
                        variant="inherit"
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        sx={(theme) => ({ color: theme.palette.grey[400] })}
                      >
                        {optionsPlaceholder}
                      </Typography>
                    )
                  }
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    setValue(field.name, e.target.value, { shouldValidate: true });
                    setValue(customFieldName, '', { shouldValidate: true });
                    if (e.target.value === Other) {
                      setShowOtherTextField(true);
                    }
                  }}
                >
                  {options.map((option) => (
                    <MenuItem value={option} key={option}>
                      <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                        {option}
                      </Typography>
                    </MenuItem>
                  ))}
                  <MenuItem value={Other} key={Other}>
                    <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                      {t`${Other}`}
                    </Typography>
                  </MenuItem>
                </Select>
              );
            }}
          />
        </>
      )}
      {showOtherTextField && (
        <Box mt={SPACER.M}>
          <Controller
            name={customFieldName}
            control={control}
            render={({ field }) => {
              return (
                <>
                  <Typography variant={labelVariant}>{otherLabel}</Typography>
                  <TextField
                    autoFocus
                    name={field.name}
                    onBlur={field.onBlur}
                    fullWidth
                    onChange={(e) => {
                      setValue(field.name, e.target.value, { shouldValidate: true });
                    }}
                    value={otherValue}
                    placeholder={textPlaceholder}
                    size="small"
                  />
                </>
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
}
