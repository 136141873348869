import { t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import Modal from '../../common/components/modal/modal.component';
import { ReactComponent as CancelSvg } from 'common/images/icons/Utility/remove_1.svg';
import { PALETTE_COLOR } from '../../config/models/themeOptions';
import useDeleteProject from '../../common/hooks/useDeleteProject';
import { useDuplicateVideoProjectMutation } from '../../data/_generated';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../common/utils/errors';
import { useState } from 'react';

type EditProjectModalProps = {
  projectId: string;
  /** callbaack on close */
  onClose: () => void;
};

/**
 * Modal for delete project
 */
export default function EditProjectModal({ onClose, projectId }: EditProjectModalProps) {
  const navigate = useNavigate();
  const { deleteVideoProject, error: deleteError } =  useDeleteProject({
    projectId,
    eventName: 'delete-and-duplicate-project',
  });
  const [ duplicateVideoProject, { error: duplicateError } ] = useDuplicateVideoProjectMutation({
    variables: { duplicateVideoProjectId: projectId },
  });
  const [editing, setEditing] = useState<boolean>(false);
  const [duplicating, setDuplicating] = useState<boolean>(false);
  const edit = async () => {
    setEditing(true);
    const duplicated = await duplicateVideoProject();
    await deleteVideoProject();
    navigate(`/edit/${duplicated.data?.duplicateVideoProject.id}`);
    setEditing(false);
  };
  const duplicate = async () => {
    setDuplicating(true);
    const duplicated = await duplicateVideoProject();
    trackEvent('duplicate-project', { projectId });
    navigate(`/edit/${duplicated.data?.duplicateVideoProject.id}`);
    setDuplicating(false);
  };
  return (
        <Modal
            title={t`Editing will reset your analytics`}
            confirmLabel={t`Edit anyways`}
            onConfirm={() => void edit()}
            confirmLoading={editing}
            confirmError={deleteError ?? duplicateError}
            secondLabel={t`Duplicate instead`}
            onSecondAction={() => void duplicate()}
            secondLoading={duplicating}
            secondError={duplicateError}
            onClose={onClose}
            isOpen
            icon={CancelSvg}
            iconStyle={{ color: PALETTE_COLOR.error[300] }}
            maxWidth={'xs'}
        >
            <Box pl={10}>
                <Typography variant='bodyReg' display={'block'}>{t`You will lose your analytics if you make any edit your video. Duplicate your video if you would like to keep analytics data for this project`}</Typography>
            </Box>
        </Modal>
  );
}
