import { ApplicationConfigType } from '../models/applicationConfig';
import { useContext } from 'react';
import { ConfigContext } from '../context/config.context';

const useConfig = (): ApplicationConfigType => {
  const context =  useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be within ConfigProvider');
  }
  return { ...context };
};

export { useConfig };
