import { appInsights } from 'config/components/azure.component';
import { isError, isString } from 'lodash';
import LogRocket from 'logrocket';

/**
 * Helper method to track log
 * @param eventName name of the event to track
 * @param eventProps properties to track in log rocket
 */
export function trackEvent(
  eventName: string,
  eventProps?: Record<string, string | boolean | undefined | number>,
): void {
  console.log(eventName, eventProps);
  LogRocket.track(eventName, eventProps);
  appInsights.ApplicationInsights?.trackEvent({ name: eventName }, eventProps);
  void window.june?.track(eventName, eventProps);
  void window.mixpanel?.track(eventName, eventProps);
}

/**
 * Helper method to track errors
 * @param eventName name of the error to track
 * @param eventProps properties to track in log rocket
 */
export function trackError(eventName: string, eventProps?: Record<string, string | boolean | undefined>): void {
  console.error(eventName, eventProps);
  LogRocket.track(eventName, eventProps);
  appInsights.ApplicationInsights?.trackException({ exception: new Error(eventName) }, eventProps);
  void window.june?.track(eventName, eventProps);
  void window.mixpanel?.track(eventName, eventProps);
}

/**
 * Extract error from unknown type. Useful for narrowing type in try catch
 * @param error
 */
export function extractError(error: unknown): Error {
  if (isError(error)) {
    return error;
  }
  const errorMessage = isString(error) ? error : JSON.stringify(error);
  return new Error(errorMessage);
}

/**
 * Log an error
 * @param error
 */
export function logError(error: Error): void {
  console.error(error);
  LogRocket.error(error);
  LogRocket.captureException(error);
  appInsights.ApplicationInsights?.trackException({ exception: error });
}

/**
 * Throw new exception
 * @param message message to be used
 */
export function throwError(message: string): never {
  throw new Error(message);
}
