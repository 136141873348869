import { CSSProperties, ReactNode, useRef } from 'react';
import AlertPopper, { AlertPopperProps } from './alertPopper.component';

type AlertErrorProps = {
  /** wrapping element related to error state */
  children: ReactNode;
  /** if the element should be full width */
  fullWidth?: boolean;
  /** the max width of the wrapping element */
  maxWidth?: string;
  /** flex of the wrapping element */
  flex?: CSSProperties['flex'];
} & AlertPopperProps;


/**
 * In context wrapper to show error state around an element
 */
export default function AlertError({ maxWidth, fullWidth, flex, children, ...rest }: AlertErrorProps) {
  const ref = useRef<HTMLElement | null>(null);
  return <><span style={{
    flex,
    maxWidth,
    width: fullWidth ? '100%' : undefined,
  }} ref={ref}>{children}</span>
    <AlertPopper anchorEl={ref.current} {...rest} />
  </>;
}