import { Box, CircularProgress } from '@mui/material';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import VideoRecordingTag from './videoRecordingTag.component';
import { ReactNode, useEffect, useState } from 'react';
import { RecorderControlsState } from './const';
import { isUndefined } from 'lodash';
import { MS_PER_SECOND } from '../../common/constants/date';
import { COUNT_DOWN_MS } from './videoRecordingButtons.component';

type VideoRecordingProgressProps = {
  /** if this is mobile view */
  isMobile: boolean;
  /** The current state of recording */
  state: RecorderControlsState;
  /** Max Recording Time in Seconds */
  maxRecordingTime: number;
  /** Recording controls for Record/Stop */
  children: ReactNode;
  /** Callback once it's completed the max time */
  onCompleted: () => void;
  /** recording start time in ms */
  startTime?: number;
};

export default function VideoRecordingProgress({
  isMobile,
  state,
  maxRecordingTime,
  children,
  onCompleted,
  startTime,
}: VideoRecordingProgressProps) {
  //console.log('[render] VideoRecordingProgress');
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    let timeout: NodeJS.Timeout | undefined;
    if (state === 'recording' && !isUndefined(startTime)) {
      interval = setInterval(() => {
        setElapsedTime((Date.now() - startTime - COUNT_DOWN_MS) / MS_PER_SECOND);
      }, 10);
      timeout = setTimeout(() => {
        onCompleted();
      }, maxRecordingTime * MS_PER_SECOND);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [maxRecordingTime, onCompleted, state, startTime]);

  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const progress = ((maxRecordingTime - elapsedTime) / maxRecordingTime) * 100;

  return <>
    <VideoRecordingTag
      state={state}
      currentInSeconds={elapsedTime}
      durationInSeconds={maxRecordingTime} />
    <Box
      display="flex"
      justifyContent="center"
      sx={{
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        pb: isMobile ? 1 : 2,
      }}
    >
      <Box position="relative">
        <CircularProgress
          size={isMobile ? 56 : 68}
          sx={{
            color: PALETTE_COLOR.neutral[0],
            '.MuiCircularProgress-circle': {
              strokeWidth: 2,
            },
          }}
          value={state === 'setup' ? 100 : progress}
          variant={state === 'inactive' ? 'indeterminate' : 'determinate'}
          color="inherit"
        />
        {children}
      </Box>
    </Box>
  </>;
}