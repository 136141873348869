import { t } from '@lingui/macro';
import { MenuItem, ListItemIcon, SvgIcon } from '@mui/material';
import { useCreateCustomerPortalMutation } from 'data/_generated';
import { ReactComponent as IdTagSvg } from 'common/images/icons/Business & Finance/ID tag.svg';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { extractError } from 'common/utils/errors';
import AlertError from 'common/components/alert/alertError.component';
import useUserAppAccess from '../../hooks/useUserAppAccess';

/**
 * Component for creating Stripe Customer Portal session
 */
export default function CustomerPortal() {
  const { hasBillingAccess } = useUserAppAccess();
  const [createCustomerPortal] = useCreateCustomerPortalMutation({
    variables: {
      returnUrl: window.location.origin,
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  const handleClick = async () => {
    setLoading(true);
    try {
      const result = await createCustomerPortal();
      if (result.data?.createCustomerPortal) window.location.assign(result.data.createCustomerPortal);
    } catch (e) {
      setLoading(false);
      setError(extractError(e));
    }
  };

  if (!hasBillingAccess) return <></>;

  return (
    <AlertError error={error}>
      <MenuItem
        sx={{ alignItems: 'flex-start' }}
        onClick={() => void handleClick()}
        component={LoadingButton}
        loading={loading}
        fullWidth
      >
        <ListItemIcon>
          <SvgIcon inheritViewBox component={IdTagSvg} />
        </ListItemIcon>
        {t`Billing`}
      </MenuItem>
    </AlertError>
  );
}
