import { Chip } from '@mui/material';
import { toTime } from 'common/utils/string';
import { RecorderControlsState } from './const';
import { PALETTE_COLOR } from '../../config/models/themeOptions';

export type VideoTagProps = {
  /** the current seconds */
  currentInSeconds?: number;
  /** the max number */
  durationInSeconds?: number;
  /** callback on clip */
  onClick?: () => void;
};


type VideoRecordingTagProps = {
  /** the current state */
  state: RecorderControlsState;
} & VideoTagProps;

/**
 * Based off the current status and the current timestamp show an indicator that recording is happening
 * @returns {JSX.Element}
 */
export default function VideoRecordingTag({
  state,
  currentInSeconds, durationInSeconds }: VideoRecordingTagProps) {
  return (
    <Chip
      sx={{
        fontFamily: 'monospace',
        fontWeight: 'bold',
        position: 'absolute',
        bottom: 10,
        left: 10,
        borderRadius: '10px',
        background: PALETTE_COLOR.neutral[50],
      }}
      color={state === 'recording' ? 'secondary' : 'default'}
      label={`${toTime(currentInSeconds)}/${toTime(durationInSeconds)}`}
    />
  );
}