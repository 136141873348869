import { Typography } from '@mui/material';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { useConfig } from 'config/hooks/useConfig';
import { AppBarProps } from './const';
import AppBarBase from './appBarBase.component';

/** App Bar with the version at the top right */
export default function AppBar({
  children,
  ...rest
}: AppBarProps) {
  const { versionNumber } = useConfig();

  return <AppBarBase {...rest} >
    <Typography variant='overline' sx={{
      pointerEvents: 'none',
      lineHeight: '1em',
      color: PALETTE_COLOR.neutral[200],
      position: 'absolute',
      right: -10,
      top: 0,
    }}>{versionNumber}</Typography>
    {children}
  </AppBarBase>;
}
