import { getCommonMetrics, getRate } from 'dashboard/helper';
import { useLandingPageMetricsByProjectIdQuery } from 'data/_generated';
import Summary from './summary.component';

type ProjectSummaryCardProps = {
  /** the id of the project */
  id: string;
};

export default function ProjectSummaryCard({ id }: ProjectSummaryCardProps) {
  const landingPageMetrics = useLandingPageMetricsByProjectIdQuery({
    variables: { projectIds: [id] },
  });
  const projectAnalyticsData = landingPageMetrics.data?.landingPageMetricsByProjectId[0];
  const metricsData = getCommonMetrics(projectAnalyticsData);
  const conversionRate: string | undefined =
    getRate(projectAnalyticsData?.ctaClickCount, projectAnalyticsData?.videoPlayCount) ?? undefined;

  return <Summary
  data={{
    ctaClick: metricsData.clicks,
    avgWatch: metricsData.watch,
    videoPlays: metricsData.plays,
    conversionRate: conversionRate,
  }}
/>;
    
}