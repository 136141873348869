import { ReactNode } from 'react';
import { ConfigContext } from '../context/config.context';
import { ApplicationConfigType } from '../models/applicationConfig';
import useRest from 'common/hooks/useRest';
import LoadingIndicator from 'common/components/loadingIndicator.component';
import { t } from '@lingui/macro';
import AppBarBase from 'common/components/layout/appBarBase.component';

function AppConfig({ children }: { children: ReactNode }) {
  const { data, loading, error } = useRest<ApplicationConfigType>('/config.json');
  if (loading) {
    return <><AppBarBase /><LoadingIndicator title={t`Launching.`} /></>;
  }
  if (error) throw error;
  return (
    <ConfigContext.Provider value={data}>
      {children}
    </ConfigContext.Provider>
  );
}

export default AppConfig;
