import { VideoDetails } from './const';
import VideoRecorderControls from './videoRecorderControls.component';
import VideoSettings from './videoSettings.component';

export type VideoRecorderProps = {
  onStart: () => void;
  /** Callback if the recording is canceled */
  onCanceled: () => void;
  /** Main Callback to upload a video */
  onRecorded: (video: VideoDetails) => void;
  /** notified when to reset the sizing of the video */
  onTrackEnd: () => void;
  /** the media stream to record */
  stream: MediaStream;
  /** Callback to set the video device id */
  setVideoDeviceId: (deviceId: string) => Promise<string | undefined> | void;
  /** Callback to set the audio device id */
  setAudioDeviceId: (deviceId: string) => Promise<string | undefined> | void;
  screenLoading: boolean | Error;
  setScreenLoading: (b: boolean | Error) => void;
  /** whether the video is mirrored*/
  videoMirror?: boolean;
  /** Callback to flip the video */
  flipVideo: () => void;
};

/**
 * Component that shows recording controls and stream options
 * @returns
 */
export default function VideoRecorder({
  screenLoading,
  setScreenLoading,
  setVideoDeviceId,
  setAudioDeviceId,
  stream,
  onStart,
  onCanceled,
  onRecorded,
  onTrackEnd,
  videoMirror,
  flipVideo,
}: VideoRecorderProps) {
  //console.log('[render] VideoRecorder');
  const videoDeviceId = stream.getVideoTracks()[0]?.getSettings().deviceId;
  const audioDeviceId = stream.getAudioTracks()[0]?.getSettings().deviceId;
  return <>
    <VideoRecorderControls
      screenLoading={screenLoading}
      setScreenLoading={setScreenLoading}
      onTrackEnd={onTrackEnd}
      stream={stream}
      onStart={onStart}
      onCanceled={onCanceled}
      onStop={onRecorded}
      flipVideo={flipVideo}
    >
      <VideoSettings
        videoDeviceId={videoDeviceId}
        audioDeviceId={audioDeviceId}
        setVideoDeviceId={setVideoDeviceId}
        setAudioDeviceId={setAudioDeviceId}
        videoMirror={videoMirror}
        flipVideo={flipVideo}
      />
    </VideoRecorderControls>
  </>;
}
