import { DependencyList, useEffect } from 'react';

/**
 * Accepts an async function that contains imperative, possibly effectful code.
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 */
const useEffectAsync = (effect: () => Promise<void>, deps?: DependencyList | undefined) => {
  useEffect(
    () => {
      void effect().catch(err => { throw err; });
    },
    deps,
  );
};

export { useEffectAsync };