import { Box, SvgIcon, Typography, useTheme, Button, ButtonProps } from '@mui/material';
import { parse } from 'papaparse';
import { ChangeEvent, useRef, useState } from 'react';
import { ReactComponent as AddAccountSvg } from 'common/images/icons/Utility/add account.svg';
import { ReactComponent as RemoveSvg } from 'common/images/icons/Utility/remove_1.svg';
import { t } from '@lingui/macro';
import { UseFormReset } from 'react-hook-form';
import { TargetFormDataType } from './const';
import Modal from 'common/components/modal/modal.component';
import manWithRecyclePng from 'common/images/illustration/delete_general.png';
import BulkUploadConfirm from './bulkUploadConfirm.component';
import { useSnackBarContext } from 'common/components/snackBar/snackBarContext.component';

function BulkWarningContent() {
  return (
    <>
      <Typography sx={{ fontWeight: 300, mb: 6 }}>
        {t`By uploading a new CSV, your current list will be`} <strong>cleared and replaced</strong>{' '}
        {t`with the new list. Any`} <strong>{t`intro videos`}</strong> {t`that have been recorded will be`}{' '}
        <strong>{t`deleted`}</strong> {t`as well.`}
      </Typography>
      <Box
        sx={{ backgroundColor: (theme) => theme.palette.neutral[50] }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img src={manWithRecyclePng} alt={t`Man with recycle bin`} />
      </Box>
    </>
  );
}

type BulkUploadButtonProps = {
  /**
   * callback to update targets after parsing data
   */
  updateTargets: UseFormReset<TargetFormDataType>;
  /** whether to show bulk upload warning that current list will be gone or not */
  showWarning?: boolean;
  /** button design type */
  buttonVariant?: ButtonProps['variant'];
};

/**
 * Component for bulk upload targets
 */
export default function BulkUploadButton(props: BulkUploadButtonProps) {
  const { updateTargets, showWarning = false, buttonVariant = 'contained' } = props;
  const theme = useTheme();
  const { setSnackbarContent } = useSnackBarContext();
  const [fields, setFields] = useState<string[] | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [openWarning, setOpenWarning] = useState<boolean>(false);

  const startCSV = () => {
    inputRef.current?.click();
  };

  const parseCSV = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;
    setFields(undefined);
    parse(file, {
      header: true,
      skipEmptyLines: true,
      preview: 1, // only the first line to get fields
      complete: (r) => {
        if ((r.meta.fields?.length ?? 0) > 0) {
          setFields(r.meta.fields);
        } else {
          setSnackbarContent({
            severity: 'error',
            description: t`Your file has no fields`,
            closeable: true,
          });
        }
      },
      error(error) {
        setSnackbarContent({
          severity: 'error',
          description: error.message,
          closeable: true,
        });
      },
    });
  };
  const reset = () => {
    // clear the current input
    if (inputRef.current) inputRef.current.value = '';
    setFields(undefined);
  };

  return (
    <>
      {
        inputRef.current?.files?.item(0) &&
        fields && fields.length > 0 &&
        <BulkUploadConfirm
          file={inputRef.current.files.item(0)}
          fields={fields}
          onConfirm={(v) => {
            updateTargets(v);
            reset();
          }}
          onCancel={reset}
        />
      }
      <Button
        onClick={() => {
          if (showWarning) {
            setOpenWarning(true);
          } else {
            startCSV();
          }
        }}
        startIcon={<SvgIcon component={AddAccountSvg} inheritViewBox />}
        variant={buttonVariant}
        size="large"
      >
        {t`Upload via CSV`}
        <input onChange={parseCSV} title="Upload via CSV" accept=".csv" className="hidden" type="file" ref={inputRef} />
      </Button>
      <Modal
        isOpen={openWarning}
        title={'Are you sure?'}
        onClose={() => setOpenWarning(false)}
        onConfirm={() => {
          startCSV();
          setOpenWarning(false);
        }}
        icon={RemoveSvg}
        confirmLabel={'Continue'}
        cancelLabel={'Cancel'}
        iconStyle={{ color: theme.palette.warning[500] }}
      >
        <BulkWarningContent />
      </Modal>
    </>
  );
}
