import { t } from '@lingui/macro';

export const EMAIL_SUBJECT = t`👋 A quick video for you, [first_name]`;
export const EMAIL_BODY = t`Hi [first_name],
  I recorded a quick video for you. Check it out and let me know what you think. 👇
  
  <We've copied your video to your clipboard! Replace this text and "Paste" to add it in>
  
  Cheers,
  [my_name]`;

/** gmail link domain */
export const GMAIL_LINK = 'https://mail.google.com/mail';
