import { ApolloProvider, createHttpLink, ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { ReactNode, useState } from 'react';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useConfig } from '../hooks/useConfig';
import { useAuth } from '../hooks/useAuth';
import { useGetAuthToken } from '../../common/hooks/useGetAuthToken';
import { isUndefined, join } from 'lodash';
import { t } from '@lingui/macro';
import LoadingIndicator from 'common/components/loadingIndicator.component';
import { logError, trackEvent } from 'common/utils/errors';

/**
 * GraphQL query when the user is not logged in
 */
const LOGGING_OUT_ERROR = 'no_account_error';
const TIMEOUT_ERROR = 'monitor_window_timeout';

function Apollo({ children }: { children: ReactNode }) {
  const { instance, scopes } = useAuth();
  const getAuthToken = useGetAuthToken();
  const { endpoint } = useConfig();
  const account = instance.getActiveAccount();
  const uri = !isUndefined(endpoint) ? `${endpoint}/graphql` : undefined;
  const defaultLink = createHttpLink({ uri });
  const chatLink = createHttpLink({ uri: !isUndefined(endpoint) ? `${endpoint}/chat/graphql` : '/chat/graphql' });
  const apiLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'chat',
    chatLink, //if above 
    defaultLink,
  );
  const [currentError, setCurrentError] = useState<string | undefined>();

  // Log any GraphQL errors or network error that occurred
  // https://www.apollographql.com/docs/react/api/link/apollo-link-error
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        trackEvent('error-graphQl', { message, locations: join(locations, ','), path: `${path}` });
      });
    if (networkError) {
      if (networkError.message.includes(LOGGING_OUT_ERROR)) {
        setCurrentError(LOGGING_OUT_ERROR);
      } else if (networkError.message.includes(TIMEOUT_ERROR)) {
        void instance.acquireTokenRedirect({ scopes });
        setCurrentError(TIMEOUT_ERROR);
      } else {
        logError(new Error(
          `Failed to fetch ${operation.operationName} - ${networkError.message} (${networkError.name})`, 
          { cause: networkError }));
      }
    }
  });

  apiLink.setOnError((error) => {
    console.error('Apollo Client Error', error);
    return false; // Prevent retry logic.
  });
  const authLink = setContext(async (_, headers: Record<string, string>) => {
    if (account) {
      const authorization = await getAuthToken();
      return {
        headers: {
          headers,
          authorization,
        },
      };
    } else return { headers };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),

    link: errorLink.concat(authLink).concat(apiLink),
    //TODO: update this to look at app config env
    connectToDevTools: true, //process.env.NODE_ENV === 'development',
  });
  return <ApolloProvider client={client}>{
    currentError === LOGGING_OUT_ERROR ?
      <LoadingIndicator title={t`Logging out...`} /> :
      currentError === TIMEOUT_ERROR ?
        <LoadingIndicator title={t`Refreshing your session...`} /> :
        children
  }</ApolloProvider>;
}


export default Apollo;
