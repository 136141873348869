import { Typography } from '@mui/material';
import { PALETTE_COLOR } from 'config/models/themeOptions';

export default function Logo() {
  return <Typography component="h1" sx={{
    color: PALETTE_COLOR.neutral[900],
    fontFamily: 'Montserrat,sans-serif',
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: '.32px',
    textTransform: 'uppercase',
  }}>
    Video By <span style={{ color: '#ffc32e' }}>C</span>oPilot AI
  </Typography>;
}