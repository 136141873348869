import { useEffect, useState } from 'react';
import { isEmpty, isNil, isString, isUndefined, noop, toNumber } from 'lodash';
import Edit from '../components/edit.component';
import {
  ISingleClipSlot,
  IThumbnailTypes,
  IVideoClip,
  IVideoClipEffectProcessingStatus,
  IVideoClipSources,
  useCreateTimelineVideoProjectMutation,
  usePublishVideoProjectMutation,
  useUpdateVideoClipMutation,
  useUpdateVideoProjectMutation,
} from 'data/_generated';
import { DEFAULT_LANDING_PAGE_TITLE_TEMPLATE, DEFAULT_SINGLE_CLIP_SLOT, UpdateClipInput } from 'edit/const';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { TimelineLayoutContext } from 'edit/layout/timeline.layout';
import { SvgIcon, Zoom } from '@mui/material';
import { t } from '@lingui/macro';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate one.svg';
import useUploadClip from 'common/hooks/useUploadClip';
import confettiImage from 'common/images/confetti_left.svg';
import CenteredImage from 'common/components/centeredImage.component';
import { trackEvent } from 'common/utils/errors';
import useThumbnail, { BLANK_THUMBNAIL } from '../../common/hooks/useThumbnails';
import { useEffectOnce } from 'usehooks-ts';
import useNavigateWarning from '../../common/hooks/useNavigateWarning';
import { isClipSaved } from '../helper';
import { getTrimmedTimeOptional } from '../components/utils';

const DEFAULT_TITLE = t`Untitled video`;
const BLOB_URL_SEARCH_PARAM = 'blobUrl';
const DURATION_SEARCH_PARAM = 'duration';

/**
 * Page for creating a quick video. Creates a video project without a timeline.
 * Only one video clip. Changes are not saved to the server till the user clicks process and share
 */
export default function QuickPage() {
  const [title, setTitle] = useState<string>(DEFAULT_TITLE);
  const [slot, setSlot] = useState<Readonly<ISingleClipSlot>>({ ...DEFAULT_SINGLE_CLIP_SLOT });
  const [publishState, setPublishState] = useState<boolean | string>(false);
  const [params, setURLSearchParams] = useSearchParams();
  const blobURL = params.get(BLOB_URL_SEARCH_PARAM);
  const durationInMs = toNumber(params.get(DURATION_SEARCH_PARAM) ?? 0);
  const isReady =
    !isNil(slot.videoClip) &&
    (slot.videoClip.effectSettings?.status ?? IVideoClipEffectProcessingStatus.Completed) ===
      IVideoClipEffectProcessingStatus.Completed;
  useNavigateWarning({
    disable: isString(publishState) || isNil(slot.videoClip) || (!isNil(slot.videoClip) && isClipSaved(slot.videoClip)),
    message: t`If you exit this page, any unprocessed or unsaved clips you have recorded will be lost.`,
  });

  const [createVideoProject, { loading: creating }] = useCreateTimelineVideoProjectMutation();
  const [updateVideoProject, { loading: updating }] = useUpdateVideoProjectMutation();
  const [publishVideoProject, { loading: publishing }] = usePublishVideoProjectMutation();
  const [updateClip, { loading: uploading }] = useUpdateVideoClipMutation();
  const navigate = useNavigate();
  const { uploadClip, progressPercent } = useUploadClip();
  const [script, setScript] = useState<string>();
  const { updateTitle } = useOutletContext<TimelineLayoutContext>();
  useEffectOnce(() => {
    updateTitle(title, setTitle);
  });

  const { staticThumbnail, videoDimension } = useThumbnail({
    options: { createStatic: true },
    videoSrc: blobURL,
  });

  const setSlotFromURL = async () => {
    if (isNil(blobURL) || isUndefined(staticThumbnail) || isUndefined(videoDimension)) return;
    setURLSearchParams((prev) => {
      prev.delete(BLOB_URL_SEARCH_PARAM);
      prev.delete(DURATION_SEARCH_PARAM);
      return prev;
    });
    const videoClip = await uploadClip({
      __typename: 'NewVideoClip',
      location: blobURL,
      durationInSeconds: durationInMs / 1000,
      thumbnail: staticThumbnail,
      source: IVideoClipSources.ScreenShared,
      videoWidth: videoDimension.width,
      videoHeight: videoDimension.height,
    });
    const newSlot: ISingleClipSlot = {
      ...DEFAULT_SINGLE_CLIP_SLOT,
      videoClip,
    };
    setSlot(newSlot);
  };

  useEffect(() => {
    void setSlotFromURL();
  }, [blobURL, staticThumbnail]);

  const { staticThumbnail: defaultThumbnail } = useThumbnail({
    options: {
      createStatic: true,
    },
    videoSrc:
      isNil(slot.videoClip?.effectSettings) ||
      slot.videoClip?.effectSettings.status === IVideoClipEffectProcessingStatus.Completed
        ? slot.videoClip?.location
        : null,
    startTimeInSec: getTrimmedTimeOptional(slot.start, slot.videoClip?.durationInSeconds),
  });

  const publish = async () => {
    if (isNil(slot.videoClip) || isEmpty(slot.videoClip.id)) {
      throw new Error('Failed to find your clip');
    }

    setPublishState(true);
    // Create the project
    const created = await createVideoProject({
      variables: {
        data: {
          landingPageTitleTemplate: DEFAULT_LANDING_PAGE_TITLE_TEMPLATE,
          title: title,
          timeline: [
            {
              singleClip: {
                script: slot.script ?? '',
                start: slot.start,
                end: slot.end,
                title: '',
                videoClipId: slot.videoClip.id,
              },
            },
          ],
        },
      },
    });
    // If created, update the thumbnail and publish
    if (created.data?.createTimelineVideoProject.id) {
      await updateVideoProject({
        variables: {
          updateVideoProjectId: created.data.createTimelineVideoProject.id,
          data: {
            thumbnail: { selection: IThumbnailTypes.Static, location: defaultThumbnail ?? BLANK_THUMBNAIL },
          },
        },
      });
      await publishVideoProject({
        variables: {
          publishVideoProjectId: created.data.createTimelineVideoProject.id,
        },
      });
      trackEvent('publish', { type: 'single' });
      setPublishState(`/share/${created.data.createTimelineVideoProject.id}`);
    } else {
      throw new Error('Failed to create project');
    }
  };

  return (
    <>
      <Edit
        flexibleRecording={false}
        showTimelineDefault={false}
        setFocus={noop}
        // Don't show any slots while the blob is being processed
        slots={blobURL ? [] : [slot]}
        focusKey={slot.videoClip?.id ?? ''}
        focus={{ slot }}
        deleteClipIcon={<SvgIcon component={RotateSvg} inheritViewBox />}
        deleteClipText={t`Re-record`}
        onUpdateClipTitle={async ({ title: videoClipTitle }) => {
          if (
            isNil(slot.videoClip) ||
            isNil(slot.videoClip.durationInSeconds) ||
            isNil(slot.videoClip.location) ||
            isNil(slot.videoClip.videoHeight) ||
            isNil(slot.videoClip.videoWidth)
          )
            return;
          await updateClip({
            variables: {
              updateVideoClipId: slot.videoClip.id,
              data: { title: videoClipTitle },
            },
          });
          const videoClip = {
            ...slot.videoClip,
            title: videoClipTitle,
          };
          setSlot({
            ...slot,
            videoClip,
          });
        }}
        onDeleteClip={() => {
          setSlot({
            ...slot,
            videoClip: undefined,
          });
        }}
        onUpdateScript={(s?: string) => {
          setScript(s);
        }}
        script={script}
        onUpdateClip={async (updatedClip: UpdateClipInput) => {
          const videoClip: IVideoClip =
            updatedClip.__typename === 'NewVideoClip' ? await uploadClip(updatedClip) : updatedClip;
          const updated = {
            ...slot,
            start: videoClip.lastStart,
            end: videoClip.lastEnd,
            videoClip,
          };
          setSlot(updated);
          return false;
        }}
        /** These are empty and noop b/c timeline editing
         * shouldn't be available for this view */
        targets={[]}
        onDeleteTransition={noop}
        onNewTransition={noop}
        onNewSingleSlot={noop}
        onDuplicateSlot={noop}
        onDeleteSlot={noop}
        onUpdateTargets={noop}
        onUpdateOrder={noop}
        isSaving={creating || updating || uploading || publishing}
        onPreview={noop}
        openTargetModal={noop}
        isPublishing={publishState !== false}
        isPreviewReady={isReady}
        onShare={() => {
          void publish();
        }}
        uploadingPercent={progressPercent}
      />
      <Zoom
        mountOnEnter={true}
        in={isString(publishState)}
        onEntered={() => {
          if (!isString(publishState)) return;
          setTimeout(() => {
            navigate(publishState);
          }, 1000);
        }}
      >
        <CenteredImage src={confettiImage} alt={t`Congrats!`} />
      </Zoom>
    </>
  );
}
