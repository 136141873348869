import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import { useLocalStorage, useScript } from 'usehooks-ts';
import AppBar from '../common/components/layout/appBarConfig.component';
import { Button, Container, Grow, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { RedirectRequest } from '@azure/msal-browser';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PALETTE_COLOR, SPACER } from '../config/models/themeOptions';
import PlanCard from '../common/components/planCard.component';
import { BASIC_ANNUAL_PLAN_NAME, BASIC_PLAN_NAME, PlanType, PRICING_PLANS, PRO_ANNUAL_PLAN_NAME, PRO_PLAN_NAME } from '../onboard/plan.const';
import { useState } from 'react';
import { LOCAL_STORAGE_KEYS } from '../edit/const';
import { isUndefined } from 'lodash';

const ENTERPRISE_PLAN : PlanType = {
  name: t`Enterprise`,
  description: t`Unlimited AI Video`,
  price: t`Talk with us`,
  priceCaption: '',
  featureDescription: t`Everything in Pro, plus:`,
  features: [
    t`Teams support`,
    t`Unlimited intros per project`,
    t`10 minutes per clip`,
  ],
};


/**
 * Component to show Plan and select a plan
 * @param planName the plan to show
 * @param onClick the callback when clicking starting a trial
 */
function PlanSelection({ planName, onClick } : { planName: string, onClick: (selectedPlan:string)=> Promise<void> }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const plan: PlanType | undefined = PRICING_PLANS[planName];
  if (isUndefined(plan)) return <></>;
  return <PlanCard plan={plan} sx={{ flex: { xs: '1 1 100%', sm: '1 1 50%', md: '1 1 30%' },  m: SPACER.S }} >
        <Button variant='contained' fullWidth onClick={()=> void onClick(planName)} >{t`Start Free 7-Day Trial`}</Button>
    </PlanCard>;
}

/** 
 * Pricing V2 Listing
 * Page is public facing and show option to select which plan
 */
export default function PricingPage() {
  useScript('/origin.js', { removeOnUnmount: true });
  const msal = useMsal();
  const pca = msal.instance;
  const signUp = async (plan: string) => {
    setSelectedPricing(plan);
    await pca.handleRedirectPromise();

    const authOptions: RedirectRequest = {
      extraQueryParameters: { option: 'signup' },
      scopes: [],
      redirectUri: '/',
    };
    await pca.loginRedirect(authOptions);
  };
  const login = async () => {
    await pca.handleRedirectPromise();
    await pca.loginRedirect({
      scopes: [],
      redirectUri: '/',
    });
  };
  const [selectedPricing, setSelectedPricing] = useLocalStorage(LOCAL_STORAGE_KEYS.SelectedPricingPlan, '');
  const [isMonthly, setIsMonthly] = useState<boolean>(!selectedPricing.includes('Annual'));
  return <HelmetProvider>
      <Helmet><title>{t`Pricing | Video by CoPilot AI`}</title></Helmet>
      <AppBar backHref={'https://videoby.copilotai.com/'}><Box display={'flex'} flex={1} justifyContent={'flex-end'}>
          <Button href={'/pricing'} variant={'text'}>{t`Pricing`}</Button>
          <Button color={'inherit'} onClick={()=> void login()} variant={'text'}>{t`Log in`}</Button>
          <Button variant={'contained'} onClick={()=> void signUp('')} >{t`Try it for free`}</Button>
      </Box>
      </AppBar>
      <Box
      flexGrow={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
        <Container >
            <Box p={SPACER.XXL} textAlign={'center'}>
                <Typography variant='h1' sx={{ fontSize: 55, fontWeight: 800 }}>{t`Multiply your videos in a fraction of the time.`}</Typography>
                <Typography variant='h5' >{t`Discover the power of personalized video messaging and 2x your reply rates with CoPilot Video. 
                Start your 7-day free trial today and experience how we can help you create more content in less time.`}</Typography>
            </Box>
            <Box textAlign='center'>
                {/*Fake toggle */}
                <Box  sx={{ backgroundColor: PALETTE_COLOR.primary[50], m: '0 auto', borderRadius: 3, p: 1 }} display='inline-block'>
                    <Button variant={isMonthly ? 'contained' : undefined} sx={{ mr : 1 }} onClick={()=> setIsMonthly(true)}>{t`Monthly`}</Button>
                    <Button variant={!isMonthly ? 'contained' : undefined} onClick={()=> setIsMonthly(false)}>{t`Annually`}</Button>
                </Box>
            </Box>
            <Grow key={isMonthly ? 'monthly' : 'annual'} in={true}>
                <Box flexDirection='row' display='flex' flexWrap={'wrap'} >
                    <PlanSelection planName={isMonthly ? BASIC_PLAN_NAME : BASIC_ANNUAL_PLAN_NAME} onClick={signUp}></PlanSelection>
                    <PlanSelection planName={isMonthly ? PRO_PLAN_NAME : PRO_ANNUAL_PLAN_NAME} onClick={signUp}></PlanSelection>
                    <PlanCard plan={ENTERPRISE_PLAN} sx={{ flex: { xs: '1 1 100%', sm: '1 1 50%', md: '1 1 30%' }, m: SPACER.S }}>
                        <Button
                            fullWidth
                            href={'mailto:video@copilotai.com?subject=Enterprise%20Inquiry'}
                            component={'a'}
                            variant='contained'
                            color={'secondary'}
                        >{t`Get a Demo`}</Button>
                    </PlanCard>
                </Box>
            </Grow>
        </Container>
    </Box></HelmetProvider>;
}
