/* eslint-disable @typescript-eslint/dot-notation */
import { AccountInfo } from '@azure/msal-browser';
import { IdTokenClaims } from 'config/models/tokenClaim';
import { ceil, isUndefined } from 'lodash';

/**
 * Util function for exhaustive check
 */
export function assertUnreachable(): never {
  throw new TypeError('Case is not handled');
}

/**
 * Util function for getting number of words in a string
 * @param word
 * @returns word count
 */
export function getWordCount(word: string): number {
  let count = 0;
  let insideWord = false;
  let total: number = word.length;
  while (total--) {
    if (word[total] === ' ') insideWord = false;
    else if (!insideWord) {
      count++;
      insideWord = true;
    }
  }
  return count;
}

/**
 * Util function for getting estimated duration to read the script
 * based on the given speed (words per second)
 * @param script
 * @param wordsPerSecond
 * @return estimated duration in s
 */
export function getScriptDuration(wordsPerSecond: number, script?: string) {
  if (isUndefined(script)) return 0;
  const wordCount = getWordCount(script);
  return ceil(wordCount / wordsPerSecond);
}

/**
 * Util function to extract information form the AccountInfo
 * for integration for other tools
 */
export function getAccountData(account: AccountInfo) {
  return {
    ...((account.idTokenClaims as IdTokenClaims | undefined)?.email && {
      email: (account.idTokenClaims as IdTokenClaims | undefined)?.email,
    }),
    ...(account.name && { name: account.name }),
  };
}

/**
 * Helper method to extract search params to an object and the option to
 * prefix each property
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
 * @param searchParams the search params of a url ?foo=1&bar=2
 * @param prefix the prefix to add to each search param (optional)
 * @returns an object
 */
export function getURLParams(searchParams: string, prefix?: string): Record<string, string> {
  const urlParams = {};
  try {
    const urlSearchParams = new URLSearchParams(searchParams);
    urlSearchParams.forEach((v, k) => (urlParams[`${prefix ?? ''}${k}`] = v));
  } catch (e) {
    console.error(e);
  }
  return urlParams;
}

/**
 * Helper method to get UTM params from a url
 * 
 * @param url string url to parse
 * @returns UTM params in object form
 */
export function getUTM(url?: string) {
  if (isUndefined(url))
    return {
      utm_campaign: undefined,
      utm_content: undefined,
      utm_medium: undefined,
      utm_source: undefined,
      utm_term: undefined,
    };
  const urlParams = getURLParams(new URL(url).search);
  return {
    utm_campaign: urlParams['utm_campaign'],
    utm_content: urlParams['utm_content'],
    utm_medium: urlParams['utm_medium'],
    utm_source: urlParams['utm_source'],
    utm_term: urlParams['utm_term'],
  };
}

/**
 * The initial plan param that expected on the origin url
 */
const PLAN_PARAM = 'video';


/**
 * Helper method to get initial plan from the original url
 * 
 * @param url the url to parse
 * @returns the initial plan to assign to the user
 */
export function getInitialPlan(url?: string) {
  if (isUndefined(url))
    return undefined;
  const urlParams = getURLParams(new URL(url).search);
  return urlParams[PLAN_PARAM];
}

/**
 * Rename download file
 * @param url url to download the file
 * @param newFilename new file name to override with
 */
export async function renameDownload(url: string, newFilename: string) {
  // Download the file as a blob
  const blob = await fetch(url).then(r => r.blob());

  // Create a link element
  const link = document.createElement('a');

  // Set the href attribute with the Blob data
  link.href = URL.createObjectURL(blob);

  // Set the download attribute with the new filename
  link.download = newFilename;

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}