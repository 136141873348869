import { SvgIcon, Typography } from '@mui/material';
import { PALETTE_COLOR, SPACER } from '../../config/models/themeOptions';
import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';

type IconItemListProps = {
  /** list of items */
  items: string[];
  /** icon to display next to each item. Default to Checkmark in a circle*/
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
};

/**
 * Shows a list of item with icon next to it
 */
export default function IconItemList({ items, icon }: IconItemListProps) {
  return (
    <>
      {items.map((item) => (
        <Typography display={'flex'} alignItems={'center'} key={item} mb={SPACER.XS}>
          <SvgIcon inheritViewBox component={icon ?? ConfirmSvg} sx={{ mr: 2, color: PALETTE_COLOR.primary[200] }} />
          {item}
        </Typography>
      ))}
    </>
  );
}
