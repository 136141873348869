import { v4 as uuidv4 } from 'uuid';
import { useEffectOnce } from 'usehooks-ts';
import { useRef } from 'react';
import useAuthSilent from 'common/hooks/useAuthSilent';
import { isUndefined } from 'lodash';
import { getURLParams } from 'common/utils/util';

/**
 * Unique identifying token for the user's active session.
 */
const sessionId = uuidv4();

/**
 * Names for events emitted by the landing page.
 */
type LandingPageUsageEventTypes = 'pageOpened' |
'pageClosed' |
'pageBlur' |
'videoPlay' |
'videoPause' |
'videoTick' |
'ctaClick';

/**
 * The regularity with which the event buffer should be cleared in milliseconds.
 */
const emissionInterval = 2000;

/**
 * Basic structure of an event 
 */
type EventStructure = {
  eventType: string,
  eventBody: Record<string, unknown>,
  dateCreated: Date,
};

type UsageTrackingOptions = {
  baseUrl: string;
  projectId?: string;
  ownerId?: string;
};

/**
 * Hook for setting up usage tracking.
 * This component should be created by top level components in order to take maximum advantage of buffering. Avoid 
 * creating multiple instances when possible.
 * @param targetId
 * @param options.baseUrl
 * @param options.ownerId
 * @param options.projectId
 */
function useUsageTracking(
  targetId: string,
  { baseUrl, projectId, ownerId }: UsageTrackingOptions) {
  // state variables
  const events = useRef<EventStructure[]>([]);
  const [authResult] = useAuthSilent();

  // get url params with prefix to avoid collisions with event body params
  const urlParams = getURLParams(window.parent.location.search, '_');

  // function to run on page close
  const handleClose = () => {
    logEvent('pageClosed');
    sendEvents();
  };

  // function to run on page blur (visibility -> hidden)
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') return;
    logEvent('pageBlur');
    sendEvents();
  };

  // setup
  useEffectOnce(() => {
    logEvent('pageOpened');
    setInterval(sendEvents, emissionInterval);
    window.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('unload', handleClose);
  });


  /**
   * Logs an event to be recorded.
   * @param eventType
   * @param eventBody
   */
  function logEvent(eventType: LandingPageUsageEventTypes, eventBody: Record<string, unknown> = {}): void {
    const newEvent = {
      eventType,
      eventBody: {
        ...eventBody,
        ...urlParams,
      },
      dateCreated: new Date(),
    };

    events.current.push(newEvent);
  }

  /**
   * Sends a request to track all events currently in the private events variable, then clears the array.
   * @private
   */
  function sendEvents(): void {
    // if not auth yet don't send any events
    if (isUndefined(authResult.current)) return;
    // if logged in don't send any events
    if (authResult.current.IsLoggedIn) return;
    // if no new events exist, do not evaluate
    if (events.current.length === 0) return;

    // create request w/ all event bodies
    const init: RequestInit = {
      keepalive: true,
      method: 'post',
      body: JSON.stringify({
        sessionId,
        projectId,
        ownerId,
        events: events.current,
      }),
    };

    // send event and clear stored events. if the back-end fails, do not restore the failed events
    events.current = [];
    fetch(`${baseUrl}/usage/target/${targetId}`, init).catch((error) => {
      console.error(`Error experienced tracking events: ${error}`);
    });
  }

  return { logEvent };
}

export default useUsageTracking;