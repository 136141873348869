import { Box, Avatar, Typography } from '@mui/material';
import { useAuth } from 'config/hooks/useAuth';
import { stringAvatar } from './userButton.component';
import { SPACER } from 'config/models/themeOptions';

export default function UserDisabled() {
  const { instance } = useAuth();
  const account = instance.getActiveAccount();
  return <Box display={'flex'}>
    <Avatar {...stringAvatar(account?.name ?? '')} />
    <Typography
      ml={SPACER.XS}
      variant="button"
      noWrap
    >{account?.name}</Typography>
  </Box>;
}