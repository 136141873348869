import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import { ErrorBoundary } from 'common/components/errorBoundary/errorBoundary.component';
import { VideoDimensions } from 'common/constants/video';
import { isError } from 'lodash';
import { Button, Stack, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as UploadSvg } from 'common/images/icons/Utility/upload.svg';
import { ReactComponent as MonitorSvg } from 'common/images/icons/Electrics/monitor.svg';
import { ReactComponent as WebCamSvg } from 'common/images/icons/Electrics/webcam.svg';
import { LoadingButton } from '@mui/lab';
import {
  LOCAL_STORAGE_KEYS,
  VIDEO_WRAPPER,
  MAX_VIDEO_SIZE_BYTES,
  SCREEN_SHARE_ARTICLE_URL,
  SUPPORTED_FILE_TYPE_ARTICLE_URL,
} from 'edit/const';
import AlertError from 'common/components/alert/alertError.component';
import { VideoUploaderBaseProps } from './const';
import VideoWebCam from './videoWebCam.component';
import { SPACER } from 'config/models/themeOptions';
import { SUPPORTED_VIDEO_MIME_TYPES_STRING } from '../../common/utils/video';
import { useLocalStorage } from 'usehooks-ts';
import { extractError, trackEvent } from '../../common/utils/errors';
import { IVideoClipSources } from 'data/_generated';
import BasicTeleprompterComponent from './basicTeleprompter.component';

type VideoUploaderProps = VideoUploaderBaseProps & {
  /** error to be shown */
  error?: Error;
  /** callback on error */
  onError: (error?: Error) => void;
  /** whether recording is in progress */
  isRecording: boolean;
  /** sets isRecording state */
  setIsRecording: (r: boolean) => void;
  /** the script to display in the teleprompter */
  script?: string | null;
  /** show the teleprompter */
  showTeleprompter?: boolean;/** any custom compo */
  children?: ReactNode;
};

/**
 * Component that encapsulates the uploading functionality
 * Whether it's uploaded, recorded or screen shared
 * @returns {JSX.Element}
 */
export default function VideoUploader({
  error,
  onError,
  script,
  showTeleprompter,
  onDone,
  isRecording,
  setIsRecording,
  children,
}: VideoUploaderProps) {
  //console.log('[render] VideoUploader');
  const [streamDimensions, setStreamDimensions] = useState<VideoDimensions | undefined>();
  const aspectRatio = streamDimensions ? `${streamDimensions.videoWidth / streamDimensions.videoHeight}` : undefined;
  const [startCamera, setStartCamera] = useLocalStorage<boolean>(LOCAL_STORAGE_KEYS.StartCamera, false);
  const [screenLoading, setScreenLoading] = useState<boolean | Error>(false);
  const isScreenShareAvail = typeof navigator.mediaDevices.getDisplayMedia === 'function';

  /** File Upload Option */
  const inputRef = useRef<HTMLInputElement>(null);
  const onFileInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;
    // Reset the input to empty state
    if (inputRef.current) inputRef.current.value = '';

    if (file.size > MAX_VIDEO_SIZE_BYTES) {
      onError(new Error('Video size exceeds max limit of 250MB'));
    } else {
      const blobUrl = URL.createObjectURL(file);
      try {
        trackEvent('record', {
          source: IVideoClipSources.Uploaded,
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
        });
        await onDone({
          __typename: 'VideoDetails',
          location: blobUrl,
          source: IVideoClipSources.Uploaded,
        });
      } catch (e) {
        onError(extractError(e));
      }
    }
  };

  return (
    <>
      {!startCamera ? (
        <Typography variant="h2" align="center">
          {t`How would you like to create this clip?`}
        </Typography>
      ) : (
        <VIDEO_WRAPPER sx={{ aspectRatio }}>
          <ErrorBoundary
            noImage={true}
            title={t`Uh oh! Looks like your not using the latest browser`}
            message={t`Please update your browser to access this feature`}
            actionLabel={t`Get Chrome`}
            actionHref={'https://www.google.ca/chrome/'}
          >
            <VideoWebCam
              screenLoading={screenLoading}
              setScreenLoading={setScreenLoading}
              setIsRecording={setIsRecording}
              onDone={onDone}
              setStreamDimensions={setStreamDimensions}
            >
              <BasicTeleprompterComponent value={script} isOpen={showTeleprompter} />
            </VideoWebCam>
          </ErrorBoundary>
        </VIDEO_WRAPPER>
      )}
      <Stack
        sx={{
          opacity: isRecording ? 0.5 : 1,
          pointerEvents: isRecording ? 'none' : 'auto',
          p: SPACER.S,
          width: { xs: '100%', sm: 'auto' },
        }}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        padding={1}
        spacing={3}
      >
        {!startCamera ? (
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            variant="contained"
            startIcon={<SvgIcon inheritViewBox component={WebCamSvg} />}
            onClick={() => setStartCamera(true)}
          >
            {t`Record camera`}
          </Button>
        ) : undefined}
        {isScreenShareAvail && (
          <AlertError
            actionHref={SCREEN_SHARE_ARTICLE_URL}
            actionLabel={t`Fix this`}
            onClose={() => setScreenLoading(false)}
            error={isError(screenLoading) ? screenLoading : undefined}
          >
            <LoadingButton
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              color={isError(screenLoading) ? 'error' : 'primary'}
              variant="outlined"
              loading={screenLoading === true}
              onClick={() => {
                setStartCamera(true);
                setScreenLoading(true);
              }}
              startIcon={<SvgIcon inheritViewBox component={MonitorSvg} />}
            >
              <span>{t`Screenshare`}</span>
            </LoadingButton>
          </AlertError>
        )}
        <AlertError
          onClose={() => onError(undefined)}
          error={error}
          actionHref={SUPPORTED_FILE_TYPE_ARTICLE_URL}
          actionLabel={t`Learn more`}
        >
          <Button
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            color={error ? 'error' : 'primary'}
            variant="outlined"
            startIcon={<SvgIcon inheritViewBox component={UploadSvg} />}
            onClick={() => inputRef.current?.click()}
          >
            {t`Upload Video`}
            <input
              placeholder={t`Upload Video`}
              ref={inputRef}
              onChange={(event) => void onFileInput(event)}
              accept={SUPPORTED_VIDEO_MIME_TYPES_STRING}
              className="hidden"
              type="file"
            />
          </Button>
        </AlertError>
        {children}
      </Stack>
    </>
  );
}
