/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { isEmpty } from 'lodash';
import * as yup from 'yup';

/**
 * Add custom method for validating if the property in the array of objects has unique value
 * Usage:
 *  @param propertyName name of property
 *  @param message error message to display
 *  @returns errors on duplicated values after the first occurrence
 *  yup.array().unique(propertyName, errorMessage)
 * Reference: https://github.com/jquense/yup/issues/345
 */
yup.addMethod(yup.array, 'unique', function (propertyName: string, message: string) {
  return this.test({
    name: 'unique',
    test: function (list = []) {
      const mappedList = list.map((item) => item[propertyName]);

      const errors: yup.ValidationError[] = [];

      mappedList.forEach((item, index, array) => {
        if (!isEmpty(item) && array.indexOf(item) !== index) {
          errors.push(
            this.createError({
              path: `${this.path}.${index}.${propertyName}`,
              message,
            }),
          );
        }
      });

      return isEmpty(errors) ? true : new yup.ValidationError(errors);
    },
  });
});

declare module 'yup' {
  interface ArraySchema<T> {
    unique(propertyName: string, message: string): ArraySchema<T>;
  }
}

export default yup;
