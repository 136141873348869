import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated } from '@azure/msal-react';
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from 'config/hooks/useAuth';
import { useConfig } from 'config/hooks/useConfig';
import { t } from '@lingui/macro';
import LoadingIndicator from 'common/components/loadingIndicator.component';
import { useEffect } from 'react';
import AppBar from 'common/components/layout/appBarConfig.component';
import { Button, Zoom } from '@mui/material';

export default function Authenticated() {
  const isAuthenticated = useIsAuthenticated();
  const { policy } = useConfig();
  const { instance, inProgress, scopes } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) return; //only track location when logged in
    void window.june?.page();
    void window.mixpanel?.track('location', { path: `${location.pathname}${location.hash}` });
  }, [location, window.june, window.mixpanel, isAuthenticated]);

  const login = () => {
    if (window.parent !== window) {
      instance
        .loginPopup({
          scopes,
          authority: policy,
        })
        .catch((err) => {
          throw err;
        });
    } else {
      instance
        .loginRedirect({
          scopes,
          authority: policy,
        })
        .catch((err) => {
          throw err;
        });
    }
  };
  if (!isAuthenticated) {
    if (inProgress === InteractionStatus.None) {
      login();
    }
    return (
      <>
        <AppBar>
          <Zoom style={{ transitionDelay: '3000ms' }} in={true}>
            <Button title={t`Click here if you're not redirected automatically.`} onClick={login}>
              {t`Login`}
            </Button>
          </Zoom>
        </AppBar>
        <LoadingIndicator title={t`Launching..`} />
      </>
    );
  }
  return <Outlet />;
}
