import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../errorBoundary/errorBoundary.component';
import AppBar from './appBarConfig.component';
import UserButton from '../userButton/userButton.component';
import { Box } from '@mui/material';

/**
 * Router Layout with AppBar + Logo + User Button
 * @returns {JSX.Element}
 */
export default function AppBarLayout() {
  return (
    <>
      <AppBar>
          <Box
              component='span'
              id="extra"
              sx={{ ml: 'auto' }}
          >
              {/* Placeholder for extras from AppCues */}
          </Box>
          <UserButton />
      </AppBar>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  );
}
