import { useAuth } from 'config/hooks/useAuth';
import { GMAIL_LINK } from 'share/const';

/** hook for creating mailTo link */
export default function useEmail() {
  const { isGoogleSSO } = useAuth();

  return (email?: string, subject?: string, body?: string) => createMailToLink(isGoogleSSO, email, subject, body);
}

/**
 * Helper function for converting fields of email to href string
 * @param email
 * @param subject
 * @param body
 * @returns href word string
 */
function createMailToLink(isGoogleSSO?: boolean, email?: string, subject?: string, body?: string) {
  if (isGoogleSSO) return createGmailLink(email, subject, body);
  const paramsObj = { subject: subject ?? '', body: body ?? '' };
  const searchParams = new URLSearchParams(paramsObj);
  return `mailto:${email}?${searchParams.toString()}`;
}

/**
 * Helper function for converting fields of email to gmail href string
 * @param email
 * @param subject
 * @param body
 * @returns
 */
function createGmailLink(email?: string, subject?: string, body?: string) {
  //view=cm sets opening the email editor
  const paramsObj = { view: 'cm', source: 'mailto', to: email ?? '', su: subject ?? '', body: body ?? '' };
  const searchParams = new URLSearchParams(paramsObj);
  return `${GMAIL_LINK}/?${searchParams.toString()}`;
}
