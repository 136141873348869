import { t, Trans } from '@lingui/macro';
import { Typography, Box } from '@mui/material';
import LogRocket from 'logrocket';
import { Component, ErrorInfo, ReactNode } from 'react';
import Alert from '../alert/alert.component';
import ellipseImage from 'common/images/ellipse_primary.svg';
import image from 'common/images/illustration/error_general.png';
import { isError, isUndefined } from 'lodash';
import ErrorDetails from '../errorDetails.component';
import { trackError } from 'common/utils/errors';

interface Props {
  children: ReactNode;
  /** custom UI to display on error*/
  customErrorUI?: ReactNode;
  /** Heading message to show above */
  title?: string;
  /** whether to hide a graphic when an error happens */
  noImage?: boolean;
  /** custom message to show user */
  message?: string;
  /** follow up action label */
  actionLabel?: string;
  /** follow up action link */
  actionHref?: string;
}

interface State {
  error?: Error;
}

/**
 * Component that catch JavaScript errors anywhere in their child component tree,
 * log those errors to LogRocket, and display a fallback UI
 */
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    trackError('exception-unexpected', {
      'name': error.name,
      'message': error.message,
      'stack': error.stack,
    });
    LogRocket.captureException(error);
    return { error };
  }

  public render() {
    if (isError(this.state.error)) {
      return (
        <>
          {this.props.customErrorUI ? this.props.customErrorUI :
            <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center">
              {this.props.title ? <Typography variant="h2">{this.props.title}</Typography> : undefined}
              {!this.props.noImage ? (
                <Box
                  sx={{
                    backgroundImage: `url(${ellipseImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    textAlign: 'center',
                  }}
                >
                  <img src={image} alt={t`Error`} style={{ maxHeight: '50vh', maxWidth: '100%' }} />
                </Box>
              ) : undefined}
              <Alert
                sx={{ m: 2, textAlign: 'left' }}
                severity="error"
                title={this.props.message ?? t`Whoops! Something's broken on our end.`}
                actionLabel={this.props.actionLabel ?? t`Check Status`}
                actionHref={this.props.actionHref ?? 'https://app.pulsetic.com/status/gQ2wPZxY'}
                description={
                  <>
                    {isUndefined(this.props.actionLabel) && (
                      <Typography variant="bodyReg" component="div">
                        <Trans>
                          It&apos;s us, not you.{' '}
                          <a rel="noopener noreferrer" target="_blank" href="https://app.pulsetic.com/status/gQ2wPZxY">
                            Click here
                          </a>{' '}
                          for the latest service status.
                        </Trans>
                      </Typography>
                    )}
                    <ErrorDetails error={this.state.error} />
                  </>
                }
              />
            </Box>
          }
        </>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
