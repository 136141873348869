import { useState } from 'react';
import { first, indexOf, isEmpty, isNil, isUndefined, last, noop, sum, without } from 'lodash';
import Edit from '../components/edit.component';
import { v4 as uuid } from 'uuid';
import {
  IPersonalizedClipSlot,
  ISingleClipSlot, ITarget, ITargetInput,
  ITimelineObject,
  IVideoClip,
} from 'data/_generated';
import {
  DEFAULT_PERSONALIZED_CLIP_SLOT,
  DEFAULT_SINGLE_CLIP_SLOT,
  DEFAULT_TITLE,
  DEFAULT_TRANSITION_SLOT,
  isInitializedSlot,
  isPersonalizedClipSlot,
  isSingleClipSlot,
  isTransitionSlot,
  UpdateClipInput,
} from 'edit/const';
import { useOutletContext } from 'react-router-dom';
import { TimelineLayoutContext } from 'edit/layout/timeline.layout';
import { useEffectOnce } from 'usehooks-ts';
import Modal from 'common/components/modal/modal.component';
import { Typography } from '@mui/material';
import { isPersonalizedSlotFocus, PersonalizedSlotFocus, SingleSlotFocus, TimelineSlotFocus } from 'edit/components/videoTimeline.component';
import VideoPreview, { PreviewAlertType } from '../components/videoPreviewModal.component';
import useThumbnail from '../../common/hooks/useThumbnails';
import { getTrimmedTimeOptional } from '../components/utils';
import { countClips, isClipReady } from './live.page';
import { useMsal } from '@azure/msal-react';
import { RedirectRequest } from '@azure/msal-browser';
import { t } from '@lingui/macro';
import TargetsModal from '../components/targetCreation/targetsModal.component';

export default function LocalPage() {
  const [focus, setFocus] = useState<TimelineSlotFocus | undefined>({ slot: DEFAULT_SINGLE_CLIP_SLOT });
  const [title, setTitle] = useState<string>(DEFAULT_TITLE);
  const [slots, setSlots] = useState<Readonly<ITimelineObject[]>>([DEFAULT_PERSONALIZED_CLIP_SLOT, DEFAULT_SINGLE_CLIP_SLOT]);
  const [targets, setTargets] = useState<Readonly<ITarget[]>>([]);
  const [script, setScript] = useState<string | undefined>();
  const [showShare, setShowShare] = useState<boolean>(false);
  const isPreviewReady = !isEmpty(slots.filter(isInitializedSlot));
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showTargetModal, setShowTargetModal] = useState<boolean>(false);
  const [showEmptyTargetRow, setShowEmptyTargetRow] = useState<boolean>(true);
  const msal = useMsal();
  const pca = msal.instance;
  const isInIframe = window.parent !== window;
  const signUp = async () => {
    if (isInIframe) {
      // iframe busting
      window.parent.location = `${window.location.origin}/signup`;
      return;
    }
    await pca.handleRedirectPromise();

    const authOptions: RedirectRequest = {
      extraQueryParameters: { option: 'signup' },
      scopes: [],
      redirectUri: '/',

    };
    await pca.loginRedirect(authOptions);
  };

  const singleSlots = slots.filter(isSingleClipSlot);
  const firstSingleSlot = first(singleSlots);
  const personalizedClip: IPersonalizedClipSlot | undefined = first(slots.filter(isPersonalizedClipSlot));
  const { staticThumbnail: defaultThumbnail } = useThumbnail({
    options: {
      createStatic: true,
    },
    videoSrc: firstSingleSlot?.videoClip?.location,
    startTimeInSec: getTrimmedTimeOptional(firstSingleSlot?.start, firstSingleSlot?.videoClip?.durationInSeconds),
  });

  const { updateTitle } = useOutletContext<TimelineLayoutContext>();
  useEffectOnce(() => {
    updateTitle(title, setTitle);
  });
  
  // Check if the next slot is a transition
  // A transition can only be added after a single clip slot
  // so make sure to clear is up
  const clearTransition = (slot: ITimelineObject) => {
    const i = indexOf(slots, slot);
    const nextSlot = slots[i + 1];

    if (!isUndefined(nextSlot) && nextSlot.__typename === 'TransitionObject') {
      return without(slots, slot, nextSlot);
    } else {
      return without(slots, slot);
    }
  };

  const openTargetModal = (addNew?: boolean) => {
    setShowTargetModal(true);
    if (!isUndefined(addNew)) setShowEmptyTargetRow(addNew);
  };

  const onUpdateTargets = (updatedTargets: ITargetInput[])=> {
    setTargets( updatedTargets.map<ITarget>(target=> ({
      name: target.name,
      email: target.email,
      title: target.title,
      id: target.id ?? uuid(),
      isDisabled: false,
    })));
  };

  const unfilledTargets = without(
    targets.map((c) => c.id),
    ...(personalizedClip?.personalizedClips?.map((c) => c.targetId) ?? []),
  );
  const hasUnfilledTargets = !isEmpty(unfilledTargets);
  const previewAlertType: PreviewAlertType | undefined = sum(slots.map(s=>countClips(s, isClipReady))) === 0 ? 'missingClip' : isEmpty(targets) ? 'missingTarget' : hasUnfilledTargets ? 'missingIntro' : undefined;
  const onPreviewAlertAction = () => {
    if (isUndefined(previewAlertType)) return undefined;
    switch (previewAlertType) {
      case 'missingClip':
        return undefined;
      case 'missingIntro': {
        return () => {
          const targetFocusId = targets.find((target) => target.id === unfilledTargets[0])?.id;
          if (targetFocusId) setFocus({ slot: personalizedClip, targetFocus: targetFocusId });
        };
      }
      case 'missingTarget': {
        return () => {
          openTargetModal(true);
        };
      }
    }
  };

  /**
   * adds new single slot
   * @param addAfterFocus if true, new single slot is added right after current focus slot. if false, new single slot is added to end of the slots
   */
  const onNewSingleSlot = (addAfterFocus?: boolean) => {
    const slot = { ...DEFAULT_SINGLE_CLIP_SLOT };
    const indexOfFocus = indexOf(slots, focus?.slot);
    const newList = addAfterFocus ? [...slots.slice(0, indexOfFocus + 1), slot, ...slots.slice(indexOfFocus + 1)] : [...slots, slot];
    setSlots(newList);
    setFocus({ slot });
  };

  return <>
    <Edit
      flexibleRecording={true}
      showTimelineDefault={false}
      setFocus={setFocus}
      slots={slots}
      targets={targets}
      focusKey={`${indexOf(slots, focus?.slot)}-${slots.length}`}
      focus={focus}
      onUpdateClipTitle={noop}
      onDeleteTransition={(index) => {
        if (isUndefined(index)) {
          if (!isTransitionSlot(slots[1])) return;
          index = 1;
        }
        const newList = [...slots];
        newList.splice(index, 1);
        setSlots(newList);
      }}
      onNewTransition={(index) => {
        const newList = [...slots];
        if (isUndefined(index)) {
          if (!isPersonalizedClipSlot(slots[0])) {
            newList.splice(0, 0, { ...DEFAULT_PERSONALIZED_CLIP_SLOT });
          }
          index = 1;
        }
        newList.splice(index, 0, { ...DEFAULT_TRANSITION_SLOT });
        setSlots(newList);
      }}
      isPreviewReady={isPreviewReady}
      onNewSingleSlot={onNewSingleSlot}
      onDeleteClip={() => {
        if (!focus?.slot) return;
        if (!isSingleClipSlot(focus.slot)) return; //TODO support delete personalized clips
        const index = indexOf(slots, focus.slot);
        const updated = {
          ...focus.slot,
          videoClip: undefined,
        };
        const clearedTransition = clearTransition(focus.slot);
        clearedTransition.splice(index, 0, updated);
        setSlots(clearedTransition);
        setFocus({ slot: updated });
      }}
      onDuplicateSlot={(slot: ITimelineObject) => {
        const indexOfItem = indexOf(slots, slot);
        const nextSlot = slots[indexOfItem + 1];
        const newList = [...slots];
        const dup = { ...slot } as ISingleClipSlot;
        newList.splice(indexOfItem + (isTransitionSlot(nextSlot) ? 2 : 1), 0, dup);
        setSlots(newList);
        setFocus({ slot: dup });
      }}
      onDeleteSlot={(slot: ITimelineObject) => {
        const currentFocusIndex = indexOf(slots, focus?.slot);
        const deletedIndex = indexOf(slots, slot);
        const newSlots = clearTransition(slot);
        setSlots(newSlots);
        if (deletedIndex === currentFocusIndex) {
          const singles = slots.filter(isSingleClipSlot);
          const singleOnlyFocus = indexOf(singles, focus?.slot);
          const newSingleList = without(singles, slot);
          const newSingleFocus = newSingleList[singleOnlyFocus] ?? last(newSingleList);
          if (isSingleClipSlot(newSingleFocus)) {
            setFocus({ slot: newSingleFocus });
          } else {
            setFocus(undefined);
          }
        }
      }}
      script={script}
      onUpdateScript={(value?: string) => {
        setScript(value);
      }}
      onUpdateClip={(updatedClip: UpdateClipInput) => {
        if (isUndefined(focus) || isUndefined(focus.slot)) return false;

        const videoClip: IVideoClip = updatedClip.__typename === 'NewVideoClip' ? {
          id: uuid(),
          ownerId: '',
          lastUpdated: '',
          dateCreated: '',
          videoHeight: updatedClip.videoHeight,
          videoWidth: updatedClip.videoWidth,
          durationInSeconds: updatedClip.durationInSeconds,
          location: updatedClip.location,
          thumbnail: updatedClip.thumbnail,
        } : {
          ...updatedClip,
          ownerId: '',
        };

        if (isPersonalizedSlotFocus(focus)) {
          const personalizedClips = [
            ...focus.slot.personalizedClips?.filter(m=>m.targetId !== focus.targetFocus) ?? [],
            {
              targetId: focus.targetFocus,
              videoClip,
            },
          ];
          const updated : IPersonalizedClipSlot = {
            ...focus.slot,
            personalizedClips,
          };
          setSlots([
            updated,
            ...slots.slice(1, slots.length),
          ]);
          setFocus({ ...focus, slot: updated } as PersonalizedSlotFocus);
        } else {
          const index = indexOf(slots, focus.slot);
          const updated = {
            ...focus.slot,
            start: videoClip.lastStart,
            end: videoClip.lastEnd,
            videoClip,
          };
          setSlots([
            ...slots.slice(0, index),
            updated,
            ...slots.slice(index + 1, slots.length),
          ]);
          setFocus({ ...focus, slot: updated } as SingleSlotFocus);
        }
        return false;
      }}
      onUpdateTargets={onUpdateTargets}
      onUpdateOrder={(slotIndex, newIndex)=> {
        const newList = [...slots];
        const moveMe = slots[slotIndex];
        newList.splice(slotIndex, 1);
        newList.splice(slotIndex < newIndex ? newIndex - 1 : newIndex, 0, moveMe);
        setSlots(newList);
      }}


      onShare={() => setShowShare(true)}
      onPreview={() => setShowPreview(true)}
      openTargetModal={openTargetModal}
    />
    {showPreview && (
        <VideoPreview
            personalized={personalizedClip?.personalizedClips}
            targets={targets}
            slots={singleSlots.filter((slot) => !isNil(slot.videoClip))}
            onClose={() => setShowPreview(false)}
            thumbnail={defaultThumbnail}
            alertType={previewAlertType}
            onAlertAction={onPreviewAlertAction()}
        />
    )}
    {showTargetModal && (
        <TargetsModal
            targets={targets}
            onClose={() => setShowTargetModal(false)}
            showEmptyRow={showEmptyTargetRow}
            isOpen
            onSubmit={onUpdateTargets}
        />
    )}
    <Modal
      isOpen={showShare}
      title={t`Sign up to access all features`}
      onClose={() => setShowShare(false)}
      onConfirm={() => void signUp()}
      confirmLabel={t`Sign up`}
      cancelLabel={t`Keep playing`}
    >
      <Typography variant='bodyReg'>{t`Create a free account to try out all AI features for 7 days.`}</Typography>
    </Modal>
  </>;
}

