import { t } from '@lingui/macro';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'common/components/alert/alert.component';
import ellipseImage from 'common/images/ellipse_primary.svg';
import image from 'common/images/illustration/error_404.png';
import { trackError } from 'common/utils/errors';

function NotFound() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  trackError('404', { pathname });
  return (
    <Box 
      flexGrow={1}
      display='flex'
      flexDirection={'column'}
      alignItems='center'
      justifyContent='center'>
      <Box
        sx={{
          backgroundImage: `url(${ellipseImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          textAlign: 'center',
        }}
      >
        <img src={image} alt={t`Not found`} style={{ maxHeight: '50vh', maxWidth: '100%' }} />
      </Box>
      <Alert
        onAction={() => navigate('/')}
        actionLabel={t`Go to Dashboard`}
        severity="error"
        title={t`Whoops! Missing Link.`}
        description={t`We Couldn't find ${pathname}`}
      />
    </Box>
  );
}

export default NotFound;
