/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { t } from '@lingui/macro';
import { Box, styled } from '@mui/material';
import {
  ICreateTimelineVideoProjectInput,
  IPersonalizedClipSlot,
  ISingleClipSlot,
  ITarget,
  ITimelineObject,
  ITimelineObjectInput,
  ITransitionEffect,
  ITransitionObject,
  IVideoClipDetailFragment,
  IVideoClipSources,
  Maybe,
} from 'data/_generated';
import { isEmpty, isNil, isString, isUndefined } from 'lodash';
import { replaceProps } from '../common/utils/string';

export type IVideoClipSourcesType = (typeof IVideoClipSources)[keyof typeof IVideoClipSources];

export type NewClipInput = {
  __typename: 'NewVideoClip';
  /** the title of the video clip */
  title?: string;
  /** the url for the blob */
  location: string;
  /** the duration of the video clip in seconds */
  durationInSeconds: number;
  /** the thumbnail to use for the video */
  thumbnail: string;
  /** the height of the video */
  videoHeight: number;
  /** the width of the video */
  videoWidth: number;
  /** source of the video clip */
  source?: IVideoClipSourcesType;
  /** the start of the clip, in % */
  start?: number;
  /** the end of the clip, in %  */
  end?: number;
};

/** Updated Clip Input, Either a new video clip input or an existing clip */
export type UpdateClipInput = NewClipInput | IVideoClipDetailFragment;

/** Updated Clip Title Input */
export type UpdateClipTitleInput = Pick<IVideoClipDetailFragment, 'id' | 'title'>;

/**
 * Whether this timeline object is a transition slot
 */
export function isTransitionSlot(slot: object | undefined): slot is ITransitionObject {
  const typename: ITransitionObject['__typename'] = 'TransitionObject';
  return !isUndefined(slot) && '__typename' in slot && slot.__typename === typename;
}

/**
 * Whether this timeline object is a single clip slot
 */
export function isSingleClipSlot(slot: object | undefined): slot is ISingleClipSlot {
  const typename: ISingleClipSlot['__typename'] = 'SingleClipSlot';
  return !isUndefined(slot) && '__typename' in slot && slot.__typename === typename;
}

/**
 * Whether this timeline object is a personalized clip slot
 */
export function isPersonalizedClipSlot(slot: object | undefined): slot is IPersonalizedClipSlot {
  const typename: IPersonalizedClipSlot['__typename'] = 'PersonalizedClipSlot';
  return !isUndefined(slot) && '__typename' in slot && slot.__typename === typename;
}

/**
 * Whether this timeline slot is initialized with a video clip
 * TODO: this only support SingleClipSlots right now
 */
export function isInitializedSlot(slot: ITimelineObject): boolean {
  return slot.__typename === 'SingleClipSlot' && !isEmpty(slot.videoClip) && isString(slot.videoClip.location);
}

/** Maps ITimelineObject to ITimelineObjectInput  */
export function toInput(item: ITimelineObject): ITimelineObjectInput {
  switch (item.__typename) {
    case 'PersonalizedClipSlot': {
      return {
        personalizedClip: {
          script: item.script ?? '',
          title: item.title,
          personalizedClips:
            item.personalizedClips?.map((p) => ({
              targetId: p.targetId,
              end: p.end,
              start: p.start,
              videoClipId: p.videoClip.id,
            })) ?? [],
        },
      };
    }
    case 'TransitionObject': {
      return { transition: { effectType: item.effectType } };
    }
    case 'SingleClipSlot': {
      return {
        singleClip: {
          end: item.end,
          start: item.start,
          script: item.script ?? '',
          title: item.title,
          videoClipId: item.videoClip?.id,
        },
      };
    }
  }
  return {};
}

/** Default timeline title */
export const DEFAULT_TITLE = t`Untitled Project`;
/** name token for title template*/
export const NAME_TITLE_TOKEN = '{name}';
/** Default landing page title */
export const DEFAULT_LANDING_PAGE_TITLE_TEMPLATE = t`👋 Hi there` + ' ' + NAME_TITLE_TOKEN;

/**
 * Helper to get target video title
 * @param target
 * @param landingPageTitleTemplate landing page title template if null, use the default template
 * @returns
 */
export function getTargetVideoTitle(
  target: Pick<ITarget, 'title' | 'name'> | undefined,
  landingPageTitleTemplate: Maybe<string>,
) {
  if (!isUndefined(target) && !isNil(target.title) && !isEmpty(target.title)) return target.title;
  return getTargetTemplatedTitle(target, landingPageTitleTemplate);
}

/**
 * Helper to get target title using the landing page title template
 * @param target
 * @param landingPageTitleTemplate landing page title template if null, use the default template
 * @returns
 */
export function getTargetTemplatedTitle(
  target: Pick<ITarget, 'title' | 'name'> | undefined,
  landingPageTitleTemplate: Maybe<string>,
) {
  return replaceProps(landingPageTitleTemplate ?? DEFAULT_LANDING_PAGE_TITLE_TEMPLATE, {
    [NAME_TITLE_TOKEN]: target?.name ?? '',
  });
}

export const DEFAULT_TIMELINE_PROJECT: ICreateTimelineVideoProjectInput = {
  title: DEFAULT_TITLE,
  landingPageTitleTemplate: DEFAULT_LANDING_PAGE_TITLE_TEMPLATE,
  timeline: [{ singleClip: { script: '', title: '' } }],
};

/** Default transition to be added to a project */
export const DEFAULT_TRANSITION_SLOT: Readonly<ITransitionObject> = {
  __typename: 'TransitionObject',
  effectType: ITransitionEffect.Fade,
};

/** Default slot added to a project */
export const DEFAULT_SINGLE_CLIP_SLOT: Readonly<ISingleClipSlot> = {
  __typename: 'SingleClipSlot',
  title: '',
};

/** Default personalized slot added to a project */
export const DEFAULT_PERSONALIZED_CLIP_SLOT: Readonly<IPersonalizedClipSlot> = {
  __typename: 'PersonalizedClipSlot',
  title: '',
};

/** Max title length */
export const MAX_TITLE_LENGTH = 50;

/** number of images for trim selector preview */
export const MAX_NUMBER_OF_IMAGES = 15;

export const VIDEO_WRAPPER = styled(Box)(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  textAlign: 'center',
  margin: '0 auto',
  maxWidth: '100%',
  background: theme.palette.neutral[200],
  flexGrow: 1,
  minHeight: '0',
  maxHeight: '50vh',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    borderRadius: 0,
    video: {
      objectFit: 'cover',
    },
  },
}));

/** Different Local Storage Keys */
export const LOCAL_STORAGE_KEYS = {
  /** This mobile advisory is shown if the user is on a XS devices */
  MobileAdvisory: 'hideMobileAdvisory',
  VideoDeviceId: 'videoDeviceId',
  AudioDeviceId: 'audioDeviceId',
  Grid: 'showGrid',
  StartCamera: 'startCamera',
  /** What type of projects to show on the videos page */
  ProjectFilter: 'projectFilter',
  /** Specifies if video templates view should be expanded */
  ExpandVideoTemplatesView: 'expandVideoTemplatesView',
  /** Retain the last AI prompt */
  PreviousAIPrompt: 'previousAIPrompt',
  /** The Pricing Plan selected **/
  SelectedPricingPlan: 'pricingPlan',
  /** Whether the video is mirrored */
  VideoMirrored: 'videoMirrored',
} as const;

/** available type of item that can be dragged and dropped
 *  React DnD library requirement - https://react-dnd.github.io/react-dnd/docs/overview#items-and-types
 */
export const DraggableTypes = {
  SLOT: 'slot',
};

export type SlotDragItem = {
  originalIndex: number;
};

/** Default maximum record time is 90 seconds */
export const DEFAULT_MAX_RECORDING_TIME = 90;

/** MAX Video file size is 250Megabytes */
export const MAX_VIDEO_SIZE_BYTES = 250_000_000;

/**
 * Default constraints for the video stream
 */
export const VIDEO_TRACK_CONSTRAINTS: MediaTrackConstraints = {
  ...(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints().frameRate
    ? { frameRate: { max: 30 } }
    : {}),
  ...(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints().facingMode
    ? { facingMode: 'user' }
    : {}),
};

/**
 * High quality constraints for the video steam
 * @link https://stackoverflow.com/questions/27420581/get-maximum-video-resolution-with-getusermedia
 */
export const VIDEO_TRACK_HIGH_QUALITY: MediaTrackConstraintSet = {
  width: { ideal: 1920 }, // size to be HD
  // Adding height caused issue on mac to show the video in portrait mode
  // height: { ideal: 2160 },
};

/**
 * Default constraints for the audio stream
 */
export const AUDIO_TRACK_CONSTRAINTS: MediaTrackConstraints = {
  ...(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints().noiseSuppression
    ? { noiseSuppression: true }
    : {}),
  ...(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints().echoCancellation
    ? { echoCancellation: true }
    : {}),
};

/** Help Article for cam and mic support  */
export const CAM_HELP_ARTICLE_URL = 'https://help.copilotai.com/my-camera-and-microphone-are-not-detected';

/** Help Article for file support */
export const SUPPORTED_FILE_TYPE_ARTICLE_URL = 'https://help.copilotai.com/what-video-files-are-supported';

/** Help Article for screen share support */

export const SCREEN_SHARE_ARTICLE_URL = 'https://help.copilotai.com/troubleshooting-screen-sharing-issues';

export const SAVE_CLIPS_ARTICLE_URL = 'https://help.copilotai.com/how-do-i-save-my-clips';