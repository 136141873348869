import { Avatar, Box, SvgIcon, TableCell, TableRow, Typography, Skeleton, styled, Button, alpha } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { stringAvatar } from 'common/components/userButton/userButton.component';
import { ReactComponent as AccountSvg } from 'common/images/icons/Utility/account.svg';
import { ReactComponent as PlaySvg } from 'common/images/icons/Camera/Play.svg';
import { ReactComponent as CancelSvg } from 'common/images/icons/Utility/cancel.svg';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate three.svg';
import { ReactComponent as ExpandSvg } from 'common/images/icons/Utility/expand.svg';
import { isNull, isUndefined } from 'lodash';
import { toTime, toTimestampString } from 'common/utils/string';
import { ITargetInput } from 'data/_generated';
import ShareAction, { ActionButtonsProps } from 'share/components/shareActions.component';
import { t } from '@lingui/macro';
import Tooltip from 'common/components/tooltip/tooltip.component';
import { useState } from 'react';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import Modal from 'common/components/modal/modal.component';
import VideoPlay from 'edit/components/videoPlay.component';
import { DEFAULT_ASPECT_RATIO, VideoDimensions } from 'common/constants/video';
import { VIDEO_WRAPPER } from 'edit/const';
import { trackEvent } from 'common/utils/errors';
import TitleEdit from 'common/components/titleEdit.component';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root .cell-label': {
    color: PALETTE_COLOR.neutral[500],
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    border: '1px solid',
    borderColor: PALETTE_COLOR.primary[50],
    borderRadius: 10,
    marginBottom: 8,
    padding: theme.spacing(SPACER.S),
    textAlign: 'left',
    '& .MuiTableCell-root': {
      border: 0,
      padding: 0,
      display: 'inline-block',
      width: '50%',
      '&:first-of-type': {
        width: '100%',
        paddingBottom: theme.spacing(SPACER.XS),
      },
      '& .cell-label': {
        display: 'inline',
        marginRight: 5,
      },
      '&.actions': {
        textAlign: 'left',
      },
    },
    '& .hideInMobile': {
      display: 'none',
    },
  },
}));

type PreviewButtonProps = {
  /** id of the target */
  id: string,
  /** name of the target */
  name?: string,
  /** the location of the thumbnail */
  thumbnailLocation: string,
  /** the duration of the video */
  duration?: number,
  /** the status of the current video */
  publishedStatus?: ActionButtonsProps['publishedStatus']
};

/** Button to Preview the Video based off publish status */
function PreviewButton({ id, name, thumbnailLocation, duration, publishedStatus }: PreviewButtonProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const canPlay = publishedStatus && publishedStatus.status === 'complete' && !isUndefined(publishedStatus.result);
  const isError = publishedStatus && publishedStatus.status === 'error';
  return <>
      <Button
    variant='outlined'
    color='primary'
    disabled={!canPlay}
    onClick={() => {
      trackEvent('preview-video', { id, name });
      setIsOpen(true);
    }}
    sx={{
      width: 86, height: 60,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: PALETTE_COLOR.neutral[900],
      backgroundImage: `url(${thumbnailLocation})`,
      color: PALETTE_COLOR.neutral[0],
      flexDirection: 'column',
      padding: 0,
      overflow: 'hidden',
      '&:hover .duration': {
        display: 'none',
      },
      '& .cover': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
      '& .show-on-hover': {
        opacity: 0,
        transition: 'opacity 0.5s',
      },
      '&:hover .show-on-hover': {
        opacity: 1,
      },
      '&:hover .overlay': {
        display: 'none',
      },
      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
      },
      '&.Mui-disabled': {
        color: PALETTE_COLOR.neutral[0],
        backgroundColor: PALETTE_COLOR.neutral[900],
      },
    }}>

    {canPlay ?
      <>
        <Box className='overlay' sx={{ width: '100%', height: '100%', position: 'absolute', backgroundColor: alpha(PALETTE_COLOR.neutral[500], 0.4) }} />
        <Box className='cover show-on-hover' sx={{ backgroundColor: alpha(PALETTE_COLOR.primary[400], 0.6) }}>
          <SvgIcon component={PlaySvg} inheritViewBox />{t`Preview`}
        </Box>
        <Typography
          className='duration'
          sx={{ position: 'absolute', bottom: 0, right: 0 }}>{toTime(duration)}</Typography>
      </> : (isError ? <Box className='cover'
        sx={{ backgroundColor: alpha(PALETTE_COLOR.neutral[900], 0.6) }}
      >
        <SvgIcon component={CancelSvg} inheritViewBox />{t`Failed`}</Box> :
        <Box
          title={publishedStatus?.status}
          className='cover' sx={{ backgroundColor: alpha(PALETTE_COLOR.neutral[900], 0.6) }}>
          <SvgIcon component={RotateSvg} inheritViewBox sx={{ animation: 'rotating 2s linear infinite' }} />
          {t`Processing`}</Box>)}
  </Button>
    {publishedStatus?.result && isOpen && // only allow preview if the video is published
      <PreviewModal src={publishedStatus.result}
        id={id} name={name}
        thumbnailLocation={thumbnailLocation}
        duration={duration}
        onClose={() => setIsOpen(false)} />
    }
  </>;
}

type PreviewModalProps = {
  /** id of the target */
  id: string,
  /** name of the target */
  name?: string,
  /** the location of the thumbnail */
  thumbnailLocation: string,
  /** the duration of the video */
  duration?: number,
  /** src of the video */
  src: string,
  /** callback when the modal is closed */
  onClose: () => void,
};

/** Preview Modal for a Landing Page */
function PreviewModal({ id, name, duration, src, onClose }: PreviewModalProps) {
  const [currentDuration, setCurrentDuration] = useState<number | undefined>(duration);
  const [previewDimensions, setPreviewDimensions] = useState<VideoDimensions | undefined>();
  const aspectRatio = previewDimensions
    ? `${previewDimensions.videoWidth / previewDimensions.videoHeight}`
    : DEFAULT_ASPECT_RATIO;
  
  return <Modal icon={PlaySvg}
    isOpen={true} title={t`Preview ${name && `for ${name}`}`}
    confirmLabel={t`Looks neat!`}
    sx={{ minWidth: { xs: '90vw', sm: 500 } }}
    secondIcon={ExpandSvg}
    secondLabel={t`Preview in new tab`}
    secondHref={`/s/${id}`}
    onSecondAction={() => {
      trackEvent('preview-landing-page', { id, name });
    }}
    onClose={onClose}
    onConfirm={onClose}>
    <VIDEO_WRAPPER sx={{ aspectRatio }}>
      <VideoPlay
        src={src}
        autoPlay={true}
        durationInSeconds={currentDuration}
        onDimensionsChange={setPreviewDimensions}
        onDurationChange={setCurrentDuration} />
    </VIDEO_WRAPPER>
  </Modal>;
}

type RecipientDetailsProps = Omit<ActionButtonsProps, 'onEditTargetTitle' | 'duration'> & {
  /** project id */
  projectId: string;
  /** callback on update target */
  onUpdateTarget: (target: ITargetInput) => Promise<void>;
  /** callback to disable target */
  onDeleteTarget: (targetId: string) => Promise<void>;
  /** title for the page */
  targetTitle?: string;
  /** target video length */
  videoLength?: number;
  /** number of video plays by the target */
  videoPlays?: number | null;
  /** video length played by the target */
  watchLength?: number | null;
  /** watched video length in % by the target */
  watchPercentage?: number | null;
  /** number of call to action clicks by the target */
  ctaClicks?: number | null;
  /** 
   * whether to show avatar icon , name initials
   * used for nameless target, or the thumbnail
   */
  icon: 'avatar' | 'initials' | 'preview';
  /** if share actions should be shown */
  showShareActions: boolean;
  /** if should show analytics */
  showAnalytics: boolean;
};

/**
 * Component for recipient detail including contact info and analytics data
 */
export default function RecipientDetails({
  targetId,
  targetName,
  targetEmail,
  targetTitle,
  publishedStatus,
  icon,
  thumbnailLocation,
  onCopied,
  accountName,
  projectId,
  onUpdateTarget,
  onDeleteTarget,
  targetVideoTitleTemplate,
  showAnalytics,
  showShareActions,
  ...data
}: RecipientDetailsProps) {

  const handleEditTargetTitle = async (title: string) => {
    const updatedTarget: ITargetInput = {
      email: targetEmail,
      id: targetId,
      name: targetName,
      title: title,
    };
    await onUpdateTarget(updatedTarget);
  };

  return (
    <StyledTableRow>
      <TableCell>
        <Box display="flex">
          {icon === 'avatar' ?
            <Avatar>
              {' '}
              <SvgIcon component={AccountSvg}
                inheritViewBox sx={(theme) => ({ color: theme.palette.neutral[900] })} />
            </Avatar>
            : (icon === 'initials' ?
              <Tooltip title={targetName} 
                placement="right" color="dark"><Avatar {...stringAvatar(targetName, 40)} /></Tooltip> :
              <PreviewButton
                id={targetId}
                name={targetName}
                thumbnailLocation={thumbnailLocation}
                duration={data.videoLength}
                publishedStatus={publishedStatus} />)
          }
          <Box ml={3} display='flex' flex={1} overflow='hidden' alignItems='center'>
            <TitleEdit 
              maxWidth='100%'
              variant='body1' 
              showIconOnHover={true}
              placeholder={targetVideoTitleTemplate}
              key={targetTitle ?? targetVideoTitleTemplate}
              title={targetTitle} 
              onUpdate={handleEditTargetTitle} startIcon={false}   />
          </Box>
        </Box>
      </TableCell>
      {showAnalytics && <>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`Video length:`}
          </Typography>
          {processData(data.videoLength, true)}
        </TableCell>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`Video plays:`}
          </Typography>
          {processData(data.videoPlays)}
        </TableCell>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`Watch time:`}
          </Typography>
          {processData(data.watchLength, true)}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" justifyContent={{ xs: 'none', md: 'space-between' }}>
            <LinearProgress
              className="hideInMobile"
              color="primary"
              variant="determinate"
              value={data.watchPercentage ?? 0}
              sx={{ height: '9px', width: '85%', display: { xs: 'none', md: 'block' } }}
            />
            <Typography className="cell-label" variant="caption">
              {t`% watched:`}
            </Typography>
            {`${data.watchPercentage ?? 0}%`}
          </Box>
        </TableCell>
        <TableCell>
          <Typography className="cell-label" variant="caption">
            {t`CTA clicks:`}
          </Typography>
          {processData(data.ctaClicks)}
        </TableCell></>}
      {showShareActions &&
        <TableCell
          sx={{
            textAlign: 'right',
            '&.MuiTableCell-root': {
              display: { xs: 'block', md: 'table-cell' },
              width: { xs: '100%', md: showAnalytics ? 300 : 400 },
            },
          }}
        >
          <Box display={'flex'} justifyContent={'flex-end'}>
            <ShareAction
              fullWidth={showAnalytics}
              targetId={targetId}
              targetName={targetName}
              targetEmail={targetEmail}
              publishedStatus={publishedStatus}
              accountName={accountName}
              projectId={projectId}
              thumbnailLocation={thumbnailLocation}
              onCopied={onCopied}
              duration={data.videoLength ?? 0}
              onEditTargetTitle={handleEditTargetTitle}
              onDeleteTarget={onDeleteTarget}
              targetVideoTitle={targetTitle}
              targetVideoTitleTemplate={targetVideoTitleTemplate}
            />
          </Box>
        </TableCell>}
    </StyledTableRow>
  );
}

/**
 * Helper to process data for display
 * @param data metrics data
 * @param convertToTimeStamp whethe to convert to timestamp or not. Default to false
 * @returns processed data in string
 */
function processData(data?: number | null, convertToTimeStamp = false) {
  let processedData = data;
  if (isNull(processedData)) {
    processedData = 0;
  }
  if (isUndefined(processedData)) return <Skeleton />;
  return convertToTimeStamp ? toTimestampString(processedData) : processedData.toString();
}
