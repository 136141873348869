import { useRef, useEffect } from 'react';

type VideoStreamProps = {
  /** the video stream to show and cleanup */
  stream: MediaStream;
  /** mirror video */
  mirror?: boolean;
  /** callback when video is previewed */
  onPreview: (width: number, height: number) => void;
};

/**
 * Component to show a video stream
 * @returns {JSX.Element}
 */
export default function VideoStream({
  stream,
  mirror = true,
  onPreview,
}: VideoStreamProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream, videoRef.current?.srcObject]);
  return <video
    crossOrigin=""
    playsInline
    muted
    style={{ transform: mirror ? 'scaleX(-1)' : undefined }}
    height='100%'
    ref={videoRef}
    onError={(e)=> console.error('Video Stream Preview Error', e)}
    onLoadedMetadata={(e) => {
      onPreview(e.currentTarget.videoWidth, e.currentTarget.videoHeight);
    }}
    autoPlay />;
}