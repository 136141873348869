import { t } from '@lingui/macro';
import { Box, Button, ButtonProps, CircularProgress, Fab, SvgIcon, SxProps, Typography, useTheme } from '@mui/material';
import { ReactComponent as AddSvg } from 'common/images/icons/Utility/add.svg';
import { ReactComponent as AddCircleSvg } from 'common/images/icons/Utility/add_1.svg';
import { ReactComponent as CameraSvg } from 'common/images/icons/Utility/video camera.svg';
import { ReactComponent as WomanSvg } from 'common/images/icons/Utility/favourite woman.svg';
import { ReactComponent as RotateSvg } from 'common/images/icons/Utility/rotate three.svg';
import { ReactComponent as NotesSvg } from 'common/images/icons/Extras/AI notes.svg';
import Modal from 'common/components/modal/modal.component';
import { FunctionComponent, SVGProps, useState } from 'react';
import Alert from 'common/components/alert/alert.component';
import { isUndefined, noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import useCreateProject from 'dashboard/hook/useCreateProject';
import { trackEvent } from 'common/utils/errors';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';
import TargetsModal from '../../edit/components/targetCreation/targetsModal.component';
import { ITargetInput } from '../../data/_generated';

type MobileButtonProps = {
  onClick: () => void;
  loading: boolean;
  error: ApolloError | undefined;
};

function MobileButton({ onClick, loading, error }: MobileButtonProps) {
  return (
    <Fab
      disabled={loading}
      color={error ? 'error' : 'primary'}
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: { xs: 'block', sm: 'none' },
      }}
      onClick={onClick}
    >
      <SvgIcon
        inheritViewBox
        sx={{
          animation: loading ? 'rotating 2s linear infinite' : 'none',
        }}
        fontSize="inherit"
        component={loading ? RotateSvg : AddSvg}
      />
    </Fab>
  );
}

type ModalButtonProps = Pick<CreateVideoButtonProps, 'buttonColor' | 'buttonSx'> & Pick<ButtonProps, 'onClick'>;

function ModalButton({ buttonColor, buttonSx, onClick }: ModalButtonProps) {
  return (
    <Button
      sx={buttonSx}
      onClick={onClick}
      startIcon={<SvgIcon inheritViewBox component={AddSvg} />}
      variant="contained"
      color={buttonColor}
    >
      {t`Create new`}
    </Button>
  );
}

type ModalActionButtonProps = ButtonProps & {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  loading?: boolean;
};

function ModalActionButton({ icon, title, subtitle, loading, disabled, sx, ...rest }: ModalActionButtonProps) {
  const theme = useTheme();
  return (
    <Button
      fullWidth
      sx={{
        border: `1px solid ${theme.palette.primary[200]}`,
        p: 6,
        justifyContent: 'start',
        '&:hover': {
          borderColor: theme.palette.primary[500],
        },
        ...sx,
      }}
      disabled={disabled || loading}
      {...rest}
    >
      <SvgIcon inheritViewBox component={icon} />
      <Box textAlign={'left'} ml={'18px'}>
        <Typography color={theme.palette.neutral[900]} fontWeight={600}>
          {title}
        </Typography>
        <Typography color={theme.palette.neutral[600]}>{subtitle}</Typography>
      </Box>
      {loading && (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress size="1rem" />
        </Box>
      )}
    </Button>
  );
}

/**
 * Different modal that can be open
 * If set to Closed, all modals are closed.
 */
enum OpenModalStatus {
  Closed,
  CreateModal,
  TargetModal,
}

type CreateVideoButtonProps = {
  /** button styling */
  buttonSx?: SxProps;
  /** button color */
  buttonColor?: ButtonProps['color'];
  /** if it's mobile view */
  isMobile?: boolean;
  /** name of event to track */
  trackEventName: string;
};

/**
 * Component for create video project type modal
 */
export default function CreateVideoButton({ buttonSx, buttonColor, isMobile, trackEventName }: CreateVideoButtonProps) {
  const { isEnabled: flexibleRecording } = useFeatureFlag(BooleanFeatureFlagNames.flexibleRecording);
  const [create, { loading, error, reset }] = useCreateProject();
  const [openModal, setOpenModal] = useState<OpenModalStatus>(OpenModalStatus.Closed);
  const navigate = useNavigate();

  /**
   * handle create video project
   * @param appendTrack indicator to append for tracking
   * @param appendUrl url to append
   * @param targets list of targets to add to video project upon the creation
   */
  const handleCreate = async ({ appendTrack = '', appendUrl, targets }: { appendTrack?: string, appendUrl?: string, targets?: ITargetInput[] } = {}) => {
    await create({
      appendUrl,
      targets,
    });
    const eventDetails = !isUndefined(targets) ? { targets: targets.length } : undefined;
    trackEvent(`${trackEventName}${appendTrack}`, eventDetails);
  };

  return (
    <>
      {isMobile ? (
        <MobileButton onClick={() => setOpenModal(OpenModalStatus.CreateModal)} loading={loading} error={error} />
      ) : (
        <ModalButton buttonColor={buttonColor} buttonSx={buttonSx} onClick={() => setOpenModal(OpenModalStatus.CreateModal)} />
      )}
      {(openModal === OpenModalStatus.CreateModal) && (
        <Modal
          title={t`How would you like to create your video?`}
          /** when something is loading, we want the close button displayed but unactionable.  */
          onClose={
            loading
              ? noop
              : () => {
                setOpenModal(OpenModalStatus.Closed);
                reset();
              }
          }
          isOpen
          icon={AddCircleSvg}
          maxWidth={'xs'}
          disableClose={loading}
          sx={
            loading
              ? {
                '& .MuiIconButton-root': {
                  opacity: '20%',
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
              }
              : {}
          }
        >
          <ModalActionButton
            icon={CameraSvg}
            title={t`Record a single video`}
            subtitle={t`A quick and easy way to record and share a video.`}
            disabled={loading}
            onClick={() => navigate('/quick')}
          />
          <ModalActionButton
            icon={WomanSvg}
            title={t`Record multiple videos`}
            subtitle={t`Stitch multiple video clips or personalized intros together.`}
            onClick={() => {
              if (flexibleRecording) {
                setOpenModal(OpenModalStatus.TargetModal);
              } else void handleCreate();
            }}
            loading={loading}
            sx={{ mt: 4 }}
          />
          <ModalActionButton
            icon={NotesSvg}
            title={t`Start with a script`}
            subtitle={t`Feeling unsure about what to say? Get started using our AI Script Generator.`}
            onClick={() => {
              void handleCreate({
                appendTrack: '-with-script',
                appendUrl: '#script',
              });
            }}
            loading={loading}
            sx={{ mt: 4 }}
          />
          {error && <Alert description={error.message} severity="error" sx={{ mt: 2 }} />}
        </Modal>
      )}
      {(openModal === OpenModalStatus.TargetModal) && (
        <TargetsModal
          isOpen
          minRow={1}
          showEmptyRow
          // Creating new project with new targets so default is always empty list here
          targets={[]}
          onSubmit={(targets) => void handleCreate({ appendTrack: '-with-targets', targets })}
          onClose={() => {
            setOpenModal(OpenModalStatus.Closed);
          }}
          onBack={() => {
            setOpenModal(OpenModalStatus.CreateModal);
          }}
        />
      )}
    </>
  );
}
