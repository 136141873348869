import { Box, Chip, Fab, styled } from '@mui/material';
import { useVideoClipLazyQuery } from 'data/_generated';
import { isNil, isUndefined } from 'lodash';
import { forwardRef, useState, MouseEvent } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import HeightIcon from '@mui/icons-material/Height';

type VideoEffectSliderProps = {
  /** the clip id to lookup */
  clipId: string;
};

const Wrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'all',
});

const Container = styled(Box)({
  top: 0,
  right: 0,
  position: 'absolute',
  height: '100%',
  width: '0%',
  overflow: 'hidden',
  display: 'flex',
  resize: 'horizontal',
  maxWidth: '100%',
  justifyContent: 'flex-end',
  visibility: 'hidden',
  '&:after': {
    position: 'absolute',
    width: 2,
    background: '#f9f9fb',
    height: '100%',
    top: 0,
    left: 0,
    display: 'block',
    content: '""',
  },
  '&.video-effect-slider--loaded': {
    visibility: 'visible',
  },
});

const Slider = styled(Fab)({
  position: 'absolute',
  top: '50%',
  right: '0',
  transform: 'translate(50%,-50%) rotate(90deg)',
  cursor: 'ew-resize',
});

const OriginalLabel = styled(Chip)({
  position: 'absolute',
  bottom: 10,
  right: 10,
});

/**
 * Component to Show Video Effect Slider with a preview of the original side by side
 */
export default forwardRef<HTMLVideoElement, VideoEffectSliderProps>(function VideoEffectSlider(
  { clipId: videoClipId }: VideoEffectSliderProps,
  ref,
) {
  const [location, setLocation] = useState<string | undefined>();
  const [getVideoClip] = useVideoClipLazyQuery();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [width, setWidth] = useState(0);
  const [isDragging, setIsDragging] = useState<number | undefined>();

  const handleMouseDown = (e: MouseEvent<HTMLButtonElement>) => {
    setIsDragging(e.clientX);
  };

  const handleMouseUp = () => {
    setIsDragging(undefined);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (isUndefined(isDragging)) return;
    const newX = e.clientX;
    const diffX = isDragging - newX;
    setWidth((prevSize) => Math.max(prevSize + diffX, 0));
    setIsDragging(newX);
  };
  const getLocation = async () => {
    const videoClip = await getVideoClip({ variables: { videoClipId } });
    if (isNil(videoClip.data?.videoClip.location)) return;
    setLocation(videoClip.data?.videoClip.location);
  };

  useEffectOnce(() => {
    void getLocation();
  });
  return (
    <Wrapper onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp} onMouseUp={handleMouseUp}>
      <Container sx={{ width: `${width}px` }} className={loaded ? 'video-effect-slider--loaded' : ''}>
        <video
          ref={ref}
          className={'video-effect-slider__video'}
          controls={false}
          muted={true}
          disablePictureInPicture
          disableRemotePlayback
          src={location}
          height={'100%'}
          onCanPlayThrough={() => setLoaded(true)}
        />
        <OriginalLabel label={'Original'} variant={'filled'} color={'primary'} />
      </Container>
      <Slider size={'small'} style={{ right: `${width}px` }} onMouseDown={handleMouseDown}>
        <HeightIcon />
      </Slider>
    </Wrapper>
  );
});
