import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import useRandomInterval from 'common/hooks/useRandomInterval';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { random, range } from 'lodash';
import { ReactNode, CSSProperties, useState } from 'react';
import { v4 as uuid } from 'uuid';

/** Helper method that generates a random sparkle of size, and position */
const generateSparkle = (color: string) => {
  return {
    id: uuid(),
    createdAt: Date.now(),
    color,
    size: random(10, 30),
    style: {
      top: `${random(0, 100)}%`,
      left: `${random(0, 100)}%`,
    },
  };
};
type SparklesProps = {
  /** 
   * the color of the sparkles
   * @default PALETTE_COLOR.warning[100]
   */
  color?: string;
  children: ReactNode;
};

/**
 * Component to add sparkles to a component
 * https://www.joshwcomeau.com/react/animated-sparkles-in-react/
 */
export default function Sparkles({
  color = PALETTE_COLOR.warning[100],
  children,
}: SparklesProps) {
  const [sparkles, setSparkles] = useState(() => {
    return range(3).map(() => generateSparkle(color));
  });
  useRandomInterval(
    () => {
      const sparkle = generateSparkle(color);
      const now = Date.now();
      const nextSparkles = sparkles.filter(sp => {
        const delta = now - sp.createdAt;
        return delta < 750;
      });
      nextSparkles.push(sparkle);
      setSparkles(nextSparkles);
    },
    50,
    150,
  );
  return (
    <Wrapper>
      {sparkles.map(sparkle => (
        <Sparkle
          key={sparkle.id}
          color={sparkle.color}
          size={sparkle.size}
          style={sparkle.style}
        />
      ))}
      <ChildWrapper>{children}</ChildWrapper>
    </Wrapper>
  );
}

type SparkleProps = {
  size: number;
  color: string;
  style: CSSProperties;
};

const Sparkle = ({ size, color, style }: SparkleProps) => {
  const path =
    'M26.5 25.5C19.0043 33.3697 0 34 0 34C0 34 ' +
    '19.1013 35.3684 26.5 43.5C33.234 50.901 34 68 ' +
    '34 68C34 68 36.9884 50.7065 44.5 43.5C51.6431 ' +
    '36.647 68 34 68 34C68 34 51.6947 32.0939 44.5 ' +
    '25.5C36.5605 18.2235 34 0 34 0C34 0 33.6591 ' +
    '17.9837 26.5 25.5Z';
  return (
    <SparkleWrapper style={style}>
      <SparkleSvg width={size} height={size} viewBox="0 0 68 68" fill="none">
        <path d={path} fill={color} />
      </SparkleSvg>
    </SparkleWrapper>
  );
};
const comeInOut = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;
const Wrapper = styled.span`
  display: inline-block;
  position: relative;
`;
const SparkleWrapper = styled.span`
  position: absolute;
  display: block;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${comeInOut} 700ms forwards;
  }
`;
const SparkleSvg = styled.svg`
  display: block;
  @media (prefers-reduced-motion: no-preference) {
    animation: ${spin} 1000ms linear;
  }
`;
const ChildWrapper = styled.strong`
  position: relative;
  z-index: -1;
  display: inline-block;
  font-weight: bold;
`;
