import { FunctionComponent, SVGProps } from 'react';
import { CircularProgress, ListItemIcon, MenuItem, SvgIcon } from '@mui/material';

type ProjectMenuItemProps = {
  onClick: () => void;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  label: string;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
};

/**
 * Component for Project Menu Item
 */
export default function ProjectMenuItem({ href, onClick, icon, label, loading, disabled } : ProjectMenuItemProps) {
  return (
    <MenuItem component="a" onClick={onClick} sx={{ alignItems: 'center' }} disabled={disabled || loading} href={href}>
      <ListItemIcon>
        <SvgIcon component={icon} inheritViewBox />
      </ListItemIcon>
      {label}
      {loading && (
        <CircularProgress sx={{ width: '1rem !important', height: '1rem !important', ml: 1 }} />
      )}
    </MenuItem>
  );
}