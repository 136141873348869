import { styled } from '@mui/material';

export default styled('img')(({ theme }) => ({
  position: 'fixed',
  maxWidth: '300px',
  maxHeight: '300px',
  top: 'calc(50% - 150px)',
  left: 'calc(50% - 150px)',
  zIndex: theme.zIndex.modal + 1,
  pointerEvents: 'none',
}));