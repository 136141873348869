import { Button, SvgIcon } from '@mui/material';
import { ReactComponent as LinkSvg } from 'common/images/icons/Utility/link three.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { absoluteLink } from '../util/share';
import ActionButton from './actionButton.component';
import Tooltip from 'common/components/tooltip/tooltip.component';
import { MouseEvent } from 'react';

type ShareActionLinkProps = {
  targetId: string;
  text: string;
  success?: boolean;
  onClick: () => void;
  onClicked: (link: string) => void;
  fullWidth?: boolean;
  iconOnly?: boolean;
};
export default function ShareActionLink({
  targetId,
  success = false, 
  text,
  onClick,
  onClicked, iconOnly = false,
  fullWidth = false } : ShareActionLinkProps) {
  const click = (e: MouseEvent<HTMLButtonElement>) => {
    onClick();
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();
    onClicked(absoluteLink(targetId));
  };
  const icon = <SvgIcon component={success ? ConfirmSvg : LinkSvg} inheritViewBox />;
  return iconOnly ? <Tooltip color='dark' title={text} placement="top" arrow>
      <ActionButton
          variant="outlined"
          LinkComponent="a"
          href={`/s/${targetId}`}
          onClick={click}
          startIcon={icon}
          color={success ? 'success' : 'primary'}
          size="small"
      />
  </Tooltip> : <Button
        LinkComponent="a"
        href={`/s/${targetId}`}
        onClick={click}
        startIcon={icon}
        size="small"
        variant="outlined"
        color={success ? 'success' : 'primary'}
        sx={{ flex: fullWidth ? 1 : undefined }}
    >
        {text}
    </Button>;
}