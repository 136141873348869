import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { SPACER } from 'config/models/themeOptions';
import { ReactNode, useState } from 'react';
import { useInterval } from 'usehooks-ts';

type Props = {
  /** @type {string} title to show above the circular loading */
  title?: string;
  /** @type {ReactNode} the elements to show below the loading */
  children?: ReactNode;
  /** @type {CircularProgressProps['color']} the color of the progress */
  color?: CircularProgressProps['color'];
  /**  */
  variant?: 'indeterminate' | 'determinate';
};

/**
 * Loading indicator with a title, and spinner
 * @returns {JSX.Element}
 */
export default function LoadingIndicator({
  variant = 'indeterminate',
  title,
  color,
  children }: Props) {
  const [percent, setPercent] = useState<number>(50);
  useInterval(()=> {
    setPercent((p)=> Math.min( p + 5, 100));
  }, 100);
  return <Box
    p={SPACER.S}
    flexDirection='column'
    display='flex'
    flexGrow={1}
    alignItems='center'
    justifyContent='center'>
    {title &&
      <Typography style={{
        fontSize: '12px',
        fontFamily: 'monospace',
        fontWeight: '400',
      }} >{title}</Typography>}
    <CircularProgress
        color={color} sx={{ margin: '10px' }}
        value={percent}
        variant={percent === 100 ? 'indeterminate' : variant}
    />
    {children}
  </Box>;
}