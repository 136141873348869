import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  styled,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MAX_TARGETS_LEGACY, TargetFormDataType } from './const';
import { parse, ParseResult } from 'papaparse';
import { useState } from 'react';
import { UseFormReset } from 'react-hook-form';
import { ReactComponent as CloseSvg } from 'common/images/icons/Utility/close.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { isEmpty, isNil, isUndefined } from 'lodash';
import Select from 'common/components/select/select.component';
import { ITargetDetailFragment } from 'data/_generated';
import { SPACER } from 'config/models/themeOptions';
import usePersonalizedIntroMax from 'common/hooks/usePersonalizedIntroMax';

const MAX_PREVIEW_ROWS = 3;

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '10px',
  borderBottom: '1px solid',
  borderColor: theme.palette.neutral[300],
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({ background: theme.palette.primary[100] }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid',
  background: theme.palette.neutral[100],
  borderColor: theme.palette.neutral[300],
  '&:last-child': {
    borderLeft: 'none',
  },
  'tr:last-child &': {
    borderBottom: 'none',
  },
}));

type BulkUploadConfirmProps = {
  /** the specific file to parse */
  file: File | null;
  /** the columns initially parsed from the file */
  fields: string[];
  /** callback to successfully import the data */
  onConfirm: UseFormReset<TargetFormDataType>;
  /** callback to cancel the confirmation */
  onCancel: () => void;
};

/**
 * Confirmation and Preview of CSV upload
 */
export default function BulkUploadConfirm({ file, fields, onConfirm, onCancel }: BulkUploadConfirmProps) {
  const [data, setData] = useState<ITargetDetailFragment[] | undefined>();
  const { maxTargets, introMaxEnabled } = usePersonalizedIntroMax();
  const [nameColumn, setNameColumn] = useState<string>('');
  const [emailColumn, setEmailColumn] = useState<string>('');
  if (!file) return <></>;

  const parseData = (nameCol: string, emailCol: string) => {
    if (isEmpty(nameCol)) return; //can't parse data without the name specified
    parse(file, {
      header: true,
      skipEmptyLines: true,
      preview: introMaxEnabled ? maxTargets : MAX_TARGETS_LEGACY,
      complete: (results: ParseResult<Record<string, string | undefined>>) => {
        const targets: ITargetDetailFragment[] = results.data.map((rowData) => {
          return {
            name: rowData[nameCol]?.trim() ?? '',
            email: rowData[emailCol]?.trim(),
          };
        });
        setData(targets);
      },
    });
  };
  const confirm = () => {
    onConfirm({ targets: data });
  };
  return (
    <Dialog open={!isNil(fields)} fullWidth >
      <DialogTitle
        component={'div'}
        display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}
      >
        <Box sx={{ pt: SPACER.XS, pl: SPACER.XS }}>
          <Typography variant="h3">{t`Match columns to fields`}</Typography>
          <Typography
            variant="bodyReg"
            sx={(theme) => ({ color: theme.palette.neutral[600] })} >
            {t`Select the columns in your CSV file.`}</Typography>
        </Box>
        <IconButton onClick={onCancel}>
          <SvgIcon component={CloseSvg} inheritViewBox />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={(theme) => ({
          background: theme.palette.neutral[100],
          border: '1px solid',
          borderRadius: '4px',
          borderColor: theme.palette.neutral[200],
          padding: '20px 30px',
        })} >
          <Grid container alignItems={'center'} spacing={1} marginBottom={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel id="csv-name-header">
                <Typography variant='bodySemibold'>
                  {t`Name`}</Typography></InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                variant={'standard'}
                value={nameColumn}
                size="small"
                labelId="csv-name-header"
                onChange={(e) => {
                  setNameColumn(e.target.value);
                  parseData(e.target.value, emailColumn);
                }}
                fullWidth
                displayEmpty
                renderValue={(value) =>
                  !isEmpty(value) ? value :
                    <Typography
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      sx={(theme) => ({ color: theme.palette.grey[500] })}>
                      {t`Select one column from file`}</Typography>

                }
              >
                {fields.map((text, textIndex) => (
                  <MenuItem value={text} key={`text-${textIndex}`}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} sm={6}>
              <InputLabel id="csv-email-header" >
                <Typography variant='bodySemibold'>
                  {t`Email Address`}
                </Typography> <Typography variant='bodyReg'>
                  {t`(optional)`}
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                variant={'standard'}
                value={emailColumn}
                size="small"
                labelId='csv-email-header'
                onChange={(e) => {
                  setEmailColumn(e.target.value);
                  parseData(nameColumn, e.target.value);
                }}
                fullWidth
                sx={{
                  mr: 2,
                  width: '100%',
                  p: 0,
                }}
                displayEmpty
                renderValue={(value) =>
                  !isEmpty(value) ? value :
                    <Typography
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      sx={(theme) => ({ color: theme.palette.grey[500] })}>
                      {t`Select one column from file`}</Typography>
                }
              >
                {fields.map((text, textIndex) => (
                  <MenuItem value={text} key={`text-${textIndex}`}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
        {data && <>
          <Typography variant="h4" sx={{ my: SPACER.M }}>{t`Data Preview`}</Typography>
          <StyledTableContainer sx={{
            borderBottomLeftRadius: data.length > MAX_PREVIEW_ROWS ? 0 : undefined,
            borderBottomRightRadius: data.length > MAX_PREVIEW_ROWS ? 0 : undefined,
          }}>
            <Table >
              <StyledTableHead>
                <TableRow>
                  <TableCell>{t`Name`}</TableCell>
                  <TableCell>{t`Email Address`}</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {//We only show 3 of the first 
                  data.slice(0, MAX_PREVIEW_ROWS).map((c, i) =>
                    <TableRow key={i}>
                      <StyledTableCell>{c.name}</StyledTableCell>
                      <StyledTableCell>{c.email}</StyledTableCell>
                    </TableRow>)}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </>}
      </DialogContent>
      <DialogActions sx={{ pb: SPACER.M, pr: SPACER.M }}>
        <Button
          onClick={onCancel}
          variant='outlined'
          color="primary"
        >
          {t`Cancel`}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => confirm()}
          disabled={isUndefined(data)}
          endIcon={<SvgIcon component={ConfirmSvg} inheritViewBox />}
        >
          <span>{t`Import ${data?.length} Contacts`}</span>
        </LoadingButton>
      </DialogActions>
    </Dialog >
  );
}