import { t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import errorImage from 'common/images/illustration/man panting small.png';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import { ReactComponent as ContactSvg } from 'common/images/icons/Freebies/contact.svg';
import Modal from './modal/modal.component';
import { SPACER } from 'config/models/themeOptions';
import useCreateCheckout from 'common/hooks/useCreateCheckout';
import { IUserInfoFragment } from 'data/_generated';

type PlanCancelledModalProps = {
  userInfo?: IUserInfoFragment;
};

/**
 * Modal to show when a user's plan has been cancelled or their free trial has ended
 */
export default function PlanCancelledModal({ userInfo }: PlanCancelledModalProps) {
  const planName = userInfo?.initialPlan ?? undefined;
  const { handleCreateCheckout, isCreatingCheckout, error } = useCreateCheckout({
    planName,
    successUrl: `${window.location.origin}/welcome-back`,
    cancelUrl: window.location.origin,
  });

  return <Modal
    icon={InfoSvg}
    confirmIcon={ContactSvg}
    confirmLoading={isCreatingCheckout}
    confirmLabel={t`Reactivate plan`}
    onConfirm={() => void handleCreateCheckout()}
    confirmError={error}
    isOpen={true} maxWidth={'xs'} title={t`Your plan has been cancelled`}>
    <Typography variant='body2' component={'p'} pl={SPACER.XL} mb={SPACER.S}>{
      t`Your plan has been cancelled, but it's not the end of the world! 
    Simply reactivate your plan to keep creating and sharing your content.`}</Typography>
    <Box sx={{ textAlign: 'center' }}>
      <img src={errorImage} alt="error" />
    </Box>
  </Modal>;
}