import { logError, extractError } from 'common/utils/errors';
import { blobToDataURL } from 'common/utils/video';
import { useApplyVideoEffectMutation, useCreateVideoClipMutation, useUpdateVideoClipMutation } from 'data/_generated';
import { NewClipInput, MAX_VIDEO_SIZE_BYTES } from 'edit/const';
import { useUploadBlob } from './useUploadBlob';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { floor, isUndefined } from 'lodash';
import { MS_PER_SECOND } from '../constants/date';
import { useFeatureFlag } from '../../config/hooks/useFeatureFlags';
import { BooleanFeatureFlagNames } from '../../config/models/featureFlags';

/**
 * Bytes per Megabit
 */
const BYTES_PER_MB = 125_000;
/**
 * Upload speed in bytes per second
 * - 15 is an arbitrary number for slow upload speed in megabits per son Mbps
 */
const UPLOAD_SPEED = 15 * BYTES_PER_MB;
/** Use Hook to Upload a Clip */
export default function useUploadClip() {
  const uploadBlob = useUploadBlob();
  const [createClip] = useCreateVideoClipMutation();
  const [updateClip] = useUpdateVideoClipMutation();
  const { isEnabled } = useFeatureFlag(BooleanFeatureFlagNames.ngcConference);
  const [applyEffectMutation] = useApplyVideoEffectMutation();


  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [estimatedCompletion, setEstimatedCompletion] = useState<{ startTime: number, estimatedDuration: number }>();

  useInterval(()=> {
    if (isUndefined(estimatedCompletion)) return;
    const currentTimeTakenInSec = (Date.now() - estimatedCompletion.startTime);
    const newPercent =  floor(currentTimeTakenInSec / estimatedCompletion.estimatedDuration * 100) ;
    setProgressPercent(Math.min(newPercent, 100));
  }, 100);

  /** Helper method to persist a thumbnail for a video clip */
  const persistThumbnail = async (updateVideoClipId: string, thumbnailObjectUrl: string) => {
    const thumbnail = await blobToDataURL(await (await fetch(thumbnailObjectUrl)).blob());
    // console.log(`Generated Blob Thumbnail for '${thumbnailObjectUrl}'`, thumbnail);
    try {
      // async update of the video clip, no need to wait
      await updateClip({
        variables: { updateVideoClipId, data: { thumbnail } },
        refetchQueries: ['UserUsageInfo'],
      });
    } catch (e) {
      logError(extractError(e));
    }
  };

  /** Uploads a new Clip */
  const uploadClip = async (clip: NewClipInput) => {
    // New clip to be uploaded
    const fetched = await fetch(clip.location);
    const blob = await fetched.blob();

    // Check if the size is within the limits
    if (blob.size > MAX_VIDEO_SIZE_BYTES) throw Error('Video size exceeds max limit of 250MB');
    // Create a clip and persist the duration
    const { data: newClip } = await createClip({
      variables: {
        data: {
          title: clip.title,
          durationInSeconds: clip.durationInSeconds,
          videoHeight: clip.videoHeight,
          videoWidth: clip.videoWidth,
          source: clip.source,
          lastStart: clip.start,
          lastEnd: clip.end,
        },
      },
    });
    if (!newClip?.createVideoClip.id) throw Error('Failed to create video clip');
    if (!newClip.createVideoClip.location) throw Error('Failed to create video clip with an upload location');
    setEstimatedCompletion({
      startTime: Date.now(),
      estimatedDuration: blob.size / UPLOAD_SPEED * MS_PER_SECOND,
    });

    // Update the clip with the thumbnail (no need to wait)
    void persistThumbnail(newClip.createVideoClip.id, clip.thumbnail);
    // Upload the actual video
    await uploadBlob(blob, newClip.createVideoClip.location);

    if (isEnabled) {
      //Initiate effects right away
      const { data: withEffects } = await applyEffectMutation({
        variables: {
          clipId: newClip.createVideoClip.id,
          gazeRedirection: true,
          backgroundBlur: true,
        },
      });
      setProgressPercent(0);
      setEstimatedCompletion(undefined);
      if (!withEffects?.applyVideoEffect.id || !withEffects.applyVideoEffect.location) {
        return {
          ...newClip.createVideoClip,
          thumbnail: clip.thumbnail,
          videoHeight: clip.videoHeight,
          videoWidth: clip.videoWidth,
        };
      }
      return {
        ...withEffects.applyVideoEffect,
        thumbnail: clip.thumbnail,
        videoHeight: clip.videoHeight,
        videoWidth: clip.videoWidth,
      };
    }
    
    setProgressPercent(0);
    setEstimatedCompletion(undefined);
    return {
      ...newClip.createVideoClip,
      thumbnail: clip.thumbnail,
      videoHeight: clip.videoHeight,
      videoWidth: clip.videoWidth,
    };
  };
  return {
    uploadClip,
    progressPercent,
  };
}
