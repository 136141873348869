import { t } from '@lingui/macro';
import Modal, { ModalProps } from 'common/components/modal/modal.component';
import { ITarget, ITargetInput } from 'data/_generated';
import { Box, Typography, useTheme } from '@mui/material';
import BulkUploadButton from 'edit/components/targetCreation/bulkUploadButton.component';
import { FormProvider, useForm } from 'react-hook-form';
import { MAX_TARGETS_LEGACY, TargetFormDataType, Targets, TargetsSchema } from './const';
import { convertToInputType, isTargetFormEmpty } from './util';
import { yupResolver } from '@hookform/resolvers/yup';
import TargetForm from 'edit/components/targetCreation/targetForm.component';
import { useState } from 'react';
import { convertToUpdateTarget } from 'edit/helper';
import usePersonalizedIntroMax from 'common/hooks/usePersonalizedIntroMax';
import { SPACER } from 'config/models/themeOptions';
import PreviewImg from '../../../common/images/illustration/recipientsPreview.png';

type TargetsModalProps = Omit<ModalProps, 'title' | 'onClose'> & {
  /** list of targets */
  targets: Readonly<ITarget[]>;
  /** callback on submit form */
  onSubmit: (data: ITargetInput[]) => void | Promise<void>;
  /** callback on close */
  onClose: () => void;
  /** callback on click back button */
  onBack?: () => void;
  /** if to show an empty row item */
  showEmptyRow?: boolean;
  /** minimum number of rows */
  minRow?: number;
};

/**
 * Modal component for updating targets
 */
export default function TargetsModal({
  targets,
  onSubmit,
  onClose,
  onBack,
  showEmptyRow,
  minRow,
  ...props
}: TargetsModalProps) {
  const theme = useTheme();
  const { maxTargets, introMaxEnabled } = usePersonalizedIntroMax();

  const defaultTargets = convertToInputType(targets);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formMethods = useForm<TargetFormDataType>({
    defaultValues: { [Targets]: defaultTargets },
    resolver: yupResolver(TargetsSchema),
    mode: 'all',
    criteriaMode: 'all',
  });

  const handleSubmitTargets = async (values: TargetFormDataType) => {
    setIsSubmitting(true);
    const updatedTargets = values[Targets].map(convertToUpdateTarget);
    await onSubmit(updatedTargets);
    setIsSubmitting(false);
    onClose();
  };

  //Checking first target's name to verify if initial target is not a placeholder target
  const totalTargets = formMethods.getValues().targets[0]?.name ? formMethods.getValues().targets.length : 0;

  const maxRows = introMaxEnabled ? maxTargets : MAX_TARGETS_LEGACY;

  return (
    <Modal
      title={t`Add your recipients`}
      iconStyle={{ color: theme.palette.secondary[500] }}
      confirmLabel={totalTargets > 0 ? `${t`Save`} ${totalTargets} recipients` : t`Save recipients`}
      confirmLoading={isSubmitting}
      onConfirm={() => void formMethods.handleSubmit(handleSubmitTargets)()}
      disableBackdropClick
      onClose={onClose}
      backAction={onBack}
      {...props}
    >
      <img src={PreviewImg} alt={t`Recipients recording`} style={{ maxHeight: '50vh', maxWidth: '100%' }} />
      <Typography
        my={SPACER.S}
        variant="bodyMedium"
        display={'block'}
      >{t`Record unique clips for different recipients to stitch to the beginning of your video. Enter your recipient names in manually or upload via a CSV file (max ${maxRows} recipients).`}
      </Typography>
      {<Box paddingBottom={SPACER.S}>
        <BulkUploadButton
          updateTargets={formMethods.reset}
          buttonVariant="outlined"
          showWarning={!isTargetFormEmpty(formMethods.getValues().targets)}
        />
      </Box>}
      <FormProvider {...formMethods}>
        <TargetForm
          hideEmail={true}
          maxRows={maxRows}
          showEmptyRow={showEmptyRow}
          minRows={minRow ?? 0} />
      </FormProvider>
    </Modal>
  );
}
