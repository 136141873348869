import { t } from '@lingui/macro';
import { Box, Button, Grow, Typography } from '@mui/material';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import Modal from './modal/modal.component';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import { BASIC_ANNUAL_PLAN_NAME, BASIC_PLAN_NAME, PlanType, PRICING_PLANS, PRO_ANNUAL_PLAN_NAME, PRO_PLAN_NAME } from '../../onboard/plan.const';
import PlanCard from './planCard.component';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import SubscribeView from '../../onboard/components/subscribeView.component';
import { IPaymentMethod } from '../../data/_generated';


const ENTERPRISE_PLAN : PlanType = {
  name: t`Enterprise`,
  description: t`Unlimited AI Video`,
  price: t`Talk with us`,
  priceCaption: '',
  featureDescription: t`Everything in Pro, plus:`,
  features: [
    t`Teams support`,
    t`Unlimited intros per project`,
    t`10 minutes per clip`,
  ],
};

type PlanSelectionViewProps = {
  /** description for plan selection if any */
  description?: string;
  /** callback when a plan is selected */
  selectPlan: (selectedPlan:string)=> void
};

/**
 * Plan selection view, enables a user to select between plans
 */
function PlanSelectionView({ description, selectPlan } : PlanSelectionViewProps) {
  const [isMonthly, setIsMonthly] = useState<boolean>(true);
  return <Box>
    {!isUndefined(description) && <Typography variant='body2' component={'p'} pl={SPACER.XL} mb={SPACER.S}>{description}</Typography>}
    <Box textAlign='center'>
      {/*Fake toggle */}
      <Box  sx={{ backgroundColor: PALETTE_COLOR.primary[50], m: '0 auto', borderRadius: 3, p: 1 }} display='inline-block'>
        <Button variant={isMonthly ? 'contained' : undefined} sx={{ mr : 1 }} onClick={()=> setIsMonthly(true)}>{t`Monthly`}</Button>
        <Button variant={!isMonthly ? 'contained' : undefined} onClick={()=> setIsMonthly(false)}>{t`Annually`}</Button>
      </Box>
    </Box>
    <Grow key={isMonthly ? 'monthly' : 'annual'} in={true}>
      <Box flexDirection='row' display='flex' flexWrap={'wrap'} >
        <PlanSelection planName={isMonthly ? BASIC_PLAN_NAME : BASIC_ANNUAL_PLAN_NAME} onClick={selectPlan}></PlanSelection>
        <PlanSelection planName={isMonthly ? PRO_PLAN_NAME : PRO_ANNUAL_PLAN_NAME} onClick={selectPlan}></PlanSelection>
        <PlanCard plan={ENTERPRISE_PLAN} sx={{ flex: { xs: '1 1 100%', sm: '1 1 50%', md: '1 1 30%' }, m: SPACER.S }}>
          <Button
              fullWidth
              href={'mailto:video@copilotai.com?subject=Enterprise%20Inquiry'}
              component={'a'}
              variant='contained'
              color={'secondary'}
          >{t`Get a Demo`}</Button>
        </PlanCard>
      </Box>
    </Grow>
  </Box>;
}

/**
 * Component to show Plan and select a plan
 * @param planName the plan to show
 * @param onClick the callback when clicking starting a trial
 */
function PlanSelection({ planName, onClick } : { planName: string, onClick: (selectedPlan:string)=> void }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const plan: PlanType | undefined = PRICING_PLANS[planName];
  if (isUndefined(plan)) return <></>;
  return <PlanCard plan={plan} sx={{ flex: { xs: '1 1 100%', sm: '1 1 30%' },  m: SPACER.S }} >
    <Button variant='contained' fullWidth onClick={()=> onClick(planName)} >{t`Select Plan`}</Button>
  </PlanCard>;
}

type PlanSelectionModalProps = {
  /** the title of the modal */
  title: string;
  /** the sub description of the modal */
  description?: string;
  /** account name */
  accountName?: string;
  /** last saved payment method */
  paymentMethod?: IPaymentMethod | null;
};


/**
 * Modal to show when a user's plan has been cancelled or their free trial has ended
 */
export default function PlanSelectionModal({
  title,
  description,
  accountName,
  paymentMethod } : PlanSelectionModalProps) {
  const [selectedPricing, setSelectedPricing] = useState<string | undefined>();
  return <Modal
    icon={InfoSvg}
    maxWidth={'xl'}
    sx={{ width: '100%' }}
    isOpen={true}
    title={title}
  >
    {isUndefined(selectedPricing) ?
        <PlanSelectionView description={description} selectPlan={setSelectedPricing} />
      : <SubscribeView
            planName={selectedPricing}
            changePlan={()=> setSelectedPricing(undefined)}
            accountName={accountName}
            paymentMethod={paymentMethod}
        />}
  </Modal>;
}