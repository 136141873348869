import { t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { SvgIcon, useTheme } from '@mui/material';
import { ReactComponent as RotateOneIcon } from 'common/images/icons/Utility/rotate one.svg';
import { usePublishTargetVideoByTargetIdMutation } from 'data/_generated';

type RetryPublishButtonProps = {
  /** id of target to publish video */
  targetId: string;
  /** id of video project target belongs to */
  projectId: string;
};

/**
 * Button Component for re-publish target video
 * @returns
 */
export default function RetryPublishButton({ targetId, projectId }: RetryPublishButtonProps) {
  const theme = useTheme();

  const [retryPublishVideo, { loading: retryPublishLoading }] = usePublishTargetVideoByTargetIdMutation();

  const handleRetry = async () => {
    await retryPublishVideo({
      variables: {
        input: {
          targetId: targetId,
          projectId: projectId,
        },
      },
      refetchQueries: ['VideoProject'],
      awaitRefetchQueries: true,
    });
  };

  return (
    <LoadingButton
      startIcon={<SvgIcon component={RotateOneIcon} inheritViewBox />}
      variant="contained"
      color="secondary"
      sx={{
        '.MuiButton-startIcon': {
          color: theme.palette.primary[200],
        },
      }}
      size="small"
      onClick={() => void handleRetry()}
      loading={retryPublishLoading}
      loadingPosition="start"
    >
      <span>{t`Retry`}</span>
    </LoadingButton>
  );
}
