import { FunctionComponent, SVGProps, useState } from 'react';
import {
  MenuItem,
  ListItemIcon, SvgIcon, TextField,
} from '@mui/material';
import { t } from '@lingui/macro';
import { IUserFeedbackType, useCreateUserFeedbackMutation } from 'data/_generated';
import { ReactComponent as CalendarSvg } from 'common/images/icons/Utility/calendar.svg';
import { ReactComponent as BugSvg } from 'common/images/icons/Security/Warning.svg';
import { ReactComponent as AnnouncementSvg } from 'common/images/icons/Freebies/announcement.svg';
import Modal from '../modal/modal.component';
import LogRocket from 'logrocket';
import { isEmpty, trim } from 'lodash';
import { useTextFeatureFlag } from 'config/hooks/useFeatureFlags';
import { StringFeatureFlagNames } from 'config/models/featureFlags';
import { trackEvent } from 'common/utils/errors';
import { FEATURE_REQUESTS } from '../../../dashboard/components/dashboardDrawer.component';


type FeedbackFormProps = {
  /** icon for this form */
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  /** title of this form */
  title: string;
  /** placeholder text of this form */
  text: string;
  /** on save callback of this form, is a promise when the action is done */
  onSave: (message: string) => Promise<void>;
  onClose: () => void;
};

/** Feedback form to show */
function FeedbackForm({ icon, title, text, onSave, onClose }: FeedbackFormProps) {
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onConfirm = async () => {
    setIsLoading(true);
    await onSave(message ?? '');
  };
  return <Modal
    icon={icon}
    title={title}
    confirmLabel={t`Submit`}
    confirmLoading={isLoading}
    onConfirm={() => void onConfirm()}
    onClose={() => onClose()}
    isOpen={true}
    maxWidth="sm"
    sx={{ width: '100%' }}
  >
    <>
      <TextField
        rows={5}
        multiline
        autoFocus
        fullWidth
        placeholder={text}
        onChange={(e) => setMessage(e.currentTarget.value)}
        value={message}
      />
    </>
  </Modal>;
}

/**
 * Feedback Options that are shown in the User Menu
 */
export default function FeedbackOptions() {
  const { text, isReady } = useTextFeatureFlag(StringFeatureFlagNames.onboardingCalendar);
  const [feedbackType, setFeedbackType] = useState<IUserFeedbackType | undefined>();
  const [createUserFeedback] = useCreateUserFeedbackMutation();
  const [sessionIdentifier, setSessionIdentifier] = useState<string | undefined>();
  const onClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    feedback: IUserFeedbackType) => {
    e.preventDefault();
    setFeedbackType(feedback);
    LogRocket.getSessionURL(setSessionIdentifier);
  };
  const onSave = async (message: string) => {
    if (!feedbackType) return;
    await createUserFeedback({
      variables: {
        data: {
          feedbackType,
          message,
          sessionIdentifier,
        },
      },
    });
    setFeedbackType(undefined);
  };
  const onClose = () => setFeedbackType(undefined);
  return <>
    {feedbackType === IUserFeedbackType.Bug &&
      <FeedbackForm icon={BugSvg} onClose={onClose} onSave={onSave}
        title={t`We're so sorry.`}
        text={t`Please share any details to help resolve this issue.
          We'll get back to you as soon as possible.`} />}
    {feedbackType === IUserFeedbackType.Idea &&
      <FeedbackForm
        icon={AnnouncementSvg} onClose={onClose} onSave={onSave}
        title={t`Share your thoughts.`}
        text={t`Have feedback, suggestions or any questions?
              Our team would love to hear from you.`}
      />}

    <MenuItem component='a' target='_blank' href={FEATURE_REQUESTS} onClick={() => trackEvent('profile-feedback')}>
      <ListItemIcon>
        <SvgIcon inheritViewBox component={AnnouncementSvg} />
      </ListItemIcon>
      {t`Give feedback`}
    </MenuItem>


    <MenuItem component='a' onClick={(e) => {
      onClick(e, IUserFeedbackType.Bug);
      trackEvent('profile-report-issue');
    }}>
      <ListItemIcon>
        <SvgIcon inheritViewBox component={BugSvg} />
      </ListItemIcon>
      {t`Report issue`}
    </MenuItem>

    {(isReady && !isEmpty(trim(text))) ?
      <MenuItem component='a' title={t`Book a meeting with our onboarding specialist`} target='_blank' href={text}>
        <ListItemIcon>
          <SvgIcon component={CalendarSvg} inheritViewBox fontSize='inherit' />
        </ListItemIcon>
        {t`Book a call`}
      </MenuItem> : undefined}
  </>;
}
