import { VideoExisting } from './const';
import VideoLibrary from './videoLibrary.component';

type VideoDemoLibraryProps = {
  /** callback when a clip is selected */
  onSelected: (c: VideoExisting) => void | Promise<void>;
};


export default function VideoDemoLibrary({ onSelected } : VideoDemoLibraryProps) {
  const options : VideoExisting[] = [{
    id: 'outreach',
    title: 'Outreach',
    location: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/24ee4b2009e985c86b84e47874237a9b',
    dateCreated: '',
    ownerId: '',
    lastUpdated: '',
    thumbnail: '/template-outreach.jpeg',
    durationInSeconds: 34,
    videoHeight: 720,
    videoWidth: 1280,
  }, {
    id: 'meeting-reminder',
    title: 'Meeting Reminder',
    location: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/1736d005d001a34cb102cd877b9eef3f',
    dateCreated: '',
    ownerId: '',
    lastUpdated: '',
    thumbnail: '/template-meeting.jpeg',
    durationInSeconds: 21,
    videoHeight: 720,
    videoWidth: 1280,
  }, {
    id: 'follow-up',
    title: 'Follow-Up',
    location: 'https://afde-video-prod-cdn-hmh6bkgtdmc5e5h0.z01.azurefd.net/landingPageStorage/processed-videos/0c159b4177b60694edd20f51f6f46b14',
    dateCreated: '',
    ownerId: '',
    lastUpdated: '',
    thumbnail: '/template-followup.jpeg',
    durationInSeconds: 49,
    videoHeight: 718,
    videoWidth: 1440,
  },
  ];
  return <VideoLibrary onSelected={onSelected} loading={false} options={options} />;
}