import { t } from '@lingui/macro';
import { ReactComponent as CloseSvg } from 'common/images/icons/Utility/close.svg';
import { ReactComponent as SparkleSvg } from 'common/images/icons/Extras/sparkle_gradient.svg';
import { ReactComponent as NotesSvg } from 'common/images/icons/Extras/AI notes.svg';
import {
  Box,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
  CircularProgress,
  Button,
  Switch,
  FormControlLabel, Slide,
} from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import useChat from '../../common/hooks/useChat';
import { IKeyValueInput } from '../../data/_chat';
import { PALETTE_COLOR, SPACER } from '../../config/models/themeOptions';
import { isEmpty, isUndefined, trim } from 'lodash';
import AIScriptBuilder from './aiScriptBuilder';
import { getScriptDuration } from '../../common/utils/util';
import { toTimestampString } from '../../common/utils/string';
import { notEmpty } from '../../common/utils/array';
import { trackEvent } from '../../common/utils/errors';
import Alert from '../../common/components/alert/alert.component';
import Tooltip from 'common/components/tooltip/tooltip.component';
import useAiUpsell from '../../common/hooks/useAiUpsell';
import AIFeatureModal from '../../common/components/AIFeatureModal.component';
import ScriptImg from '../../common/images/illustration/script.png';
import useActiveAccount from '../../config/hooks/useActiveAccount';

type ScriptGenButtonProps = {
  onClick: () => void;
  isOpen: boolean;
};

function ScriptGenButton({ onClick, isOpen }: ScriptGenButtonProps) {
  const theme = useTheme();
  return <Tooltip color='dark' title={isOpen ? t`Close Notes` : undefined}>
    <Button
      onClick={onClick}
      sx={{
        // Use Icon Only when open
        minWidth: 'unset',
        '& .MuiButton-startIcon' : {
          margin: isOpen ? 0 : undefined,
        },
        // Positioning
        position: 'absolute',
        top: 10,
        right: theme.spacing(SPACER.XS),
        zIndex: theme.zIndex.appBar - 1,
      }}
      startIcon={<SvgIcon component={isOpen ? CloseSvg : NotesSvg} inheritViewBox />}
      variant={'outlined'}
    >
      {!isOpen && t`AI Speaker Notes`}
    </Button>
  </Tooltip>;
}

const DEFAULT_WIDTH = 440;
const DEFAULT_WIDTH_XL = 600;
const DEFAULT_SCRIPT = t`Type your speaker notes or script here. Unsure what to say? Generate your own script using the button below.`;
const SCRIPT_READ_SPEED = 3;
type ScriptGenModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  showButton?: boolean;
  script?: string;
  onSaveScript:  (script?: string) => void | Promise<void>;
  displayTeleprompter: boolean;
  onDisplayTeleprompterChange: () => void;
  addBottomBorder: boolean;
};

/**
 * Modal for script generation.
 */
export function ScriptGenModal({
  isOpen,
  setIsOpen,
  showButton = true,
  script,
  onSaveScript,
  onDisplayTeleprompterChange,
  displayTeleprompter,
  addBottomBorder,
}: ScriptGenModalProps) {
  const theme = useTheme();
  const { accountId, name } = useActiveAccount();
  const { isAiUpsellOpen, canApplyAI, handleAIUpsellClose, getUpgradeUrl } = useAiUpsell({ source: isUndefined(accountId) ? 'ai-script-prompt-local' : 'ai-script-prompt' });
  const [getChat, { loading: scriptGenLoading }] = useChat();
  const [isBuilderOpen, setIsBuilderOpen] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [isAdjustingScript, setIsAdjustingScript] = useState<boolean>(false);

  useEffect(() => {
    setIsBuilderOpen(false);
  }, [isOpen]);

  const generateScript = async (values: IKeyValueInput[]) => {
    const vals = JSON.stringify(values);
    trackEvent('speaker-notes-ai-generate', {
      values: vals,
    });
    const result = await getChat({
      variables: {
        input: {
          promptLabel: 'video-generate',
          promptValues: values,
        },
      },
    });

    const resultData = result.data?.secureMessage;
    if (resultData?.__typename !== 'MessageResult') {
      const e = new Error('Failed to generate script');
      setError(e);
      trackEvent('speaker-notes-ai-generate-error', {
        values: vals,
        error: result.error?.message,
      });
      throw e;
    }
    const messageContent = resultData.message;
    trackEvent('speaker-notes-ai-generate-results', {
      values: vals,
      results: messageContent,
    });
    if (!isUndefined(script) && notEmpty(script)) {
      void onSaveScript(`${script}\n${messageContent}`);
    } else {
      void onSaveScript(messageContent);
    }
  };
  const adjustScript = async (label: string, promptLabel: string, promptScript: string) => {
    trackEvent('speaker-notes-ai-adjustment', {
      label,
      promptLabel,
      promptScript,
    });
    setIsAdjustingScript(true);
    const result = await getChat({
      variables: {
        input: {
          promptLabel,
          promptValues: [{
            key: 'script',
            value: promptScript,
          }],
        },
      },
    });
    setIsAdjustingScript(false);
    const resultData = result.data?.secureMessage;
    if (resultData?.__typename !== 'MessageResult') {
      trackEvent('speaker-notes-ai-adjustment-error', {
        label,
        promptLabel,
        promptScript,
        error: result.error?.message,
      });
      throw new Error('Failed to generate script');
    }
    const messageContent = resultData.message;
    trackEvent('speaker-notes-ai-adjustment-results', {
      label,
      promptLabel,
      promptScript,
      results: messageContent,
    });
    void onSaveScript(messageContent);
  };

  const openDrawer = (value: boolean) => {
    trackEvent('speaker-notes', { isOpen: value }); //open and close of speaker notes
    setIsOpen(value);
  };

  const openBuilder = (value: boolean) => {
    trackEvent('speaker-notes-ai', { isOpen: value }); //open and close of speaker notes
    setIsBuilderOpen(value);
  };

  return (
    <>
      {(showButton || isOpen) && <ScriptGenButton isOpen={isOpen} onClick={() => openDrawer(!isOpen)} />}
      <Slide in={isOpen} direction={'left'} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            width: { xs: DEFAULT_WIDTH, xl: DEFAULT_WIDTH_XL },
            maxWidth: '100vw',
            flexShrink: 0, // necessary for the slide animation
            borderLeft: `1px solid ${PALETTE_COLOR.neutral[200]}`,
            /** TODO: [VID-2377] Remove borders*/
            borderBottom: addBottomBorder ? `1px solid ${PALETTE_COLOR.neutral[200]}` : undefined,
            borderRadius: addBottomBorder ? '0 0 10px 10px' :  undefined,
            backgroundColor: PALETTE_COLOR.neutral[0],
            right: 0,
            top: 0,
            zIndex: 1,
            [theme.breakpoints.down('md')]: {
              position: 'absolute',
              height: '100%',
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            maxHeight={'100%'}
            width={'100%'}
            boxShadow={'unset'}
            sx={{
              px: 5,
              py: 4,
              justifyContent: 'space-between',
            }}
          >
            <Box display={'flex'} flexDirection={'column'} flexGrow={1}>
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': {
                    height: 4,
                  },
                  borderBottom: `1px solid ${theme.palette.neutral.border}`,
                }}
                value={0}
              >
                <Tab label={t`AI Speaker Notes`} />
              </Tabs>
              <Box
                sx={{
                  mt: theme.spacing(SPACER.S),
                  borderRadius: '10px',
                  border: `1px solid ${theme.palette.neutral[200]}`,
                  backgroundColor: theme.palette.neutral[0],
                  fontSize: 14,
                  padding: isBuilderOpen ? 0 : '16px',
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: isAdjustingScript ? 'center' : 'space-between',
                  textAlign: isAdjustingScript ? 'center' : 'unset',
                }}
              >
                {isAdjustingScript ? (
                  <Box>
                    <CircularProgress sx={{ m: 4, color: theme.palette.primary[300] }} />
                    <Typography
                      m={4}
                      color={theme.palette.primary[300]}
                    >{t`Casting some magic on your script...`}</Typography>
                  </Box>
                ) : (
                  <>
                    {!isBuilderOpen && (
                      <TextField
                        value={script}
                        multiline
                        onChange={(e) => void onSaveScript(e.currentTarget.value)}
                        sx={{
                          '& .MuiInputBase-root': { border: '0px', width: '100%', p: 0, height: '100%' },
                          '& .MuiInputBase-inputMultiline': { height: '100% !important', overflowY: 'auto !important' },
                          '& ::placeholder': { color: theme.palette.neutral[900] },
                          flexGrow: 1,
                        }}
                        placeholder={DEFAULT_SCRIPT}
                        onFocus={() => !isUndefined(error) && setError(undefined)}
                      />
                    )}
                    <Box sx={{ textAlign: 'end', flexGrow: isBuilderOpen ? 1 : 'unset' }}>
                      {!isEmpty(trim(script)) &&
                        !isBuilderOpen &&
                        adjustmentOptions.map((option, index) => (
                          <Button
                            key={`template${index}`}
                            color={'neutral'}
                            size={'small'}
                            sx={{ m: 1, fontSize: '0.7rem' }}
                            onClick={() => {
                              canApplyAI(() => void adjustScript(option.label, option.promptLabel, script ?? ''));
                            }}
                            startIcon={SparkleIcon('inherit')}
                          >
                            {option.label}
                          </Button>
                        ))}
                      <AIScriptBuilder
                        generateScript={(value) => generateScript(value)}
                        loading={scriptGenLoading}
                        isOpen={isBuilderOpen}
                        setIsOpen={openBuilder}
                        onFocusTextField={() => !isUndefined(error) && setError(undefined)}
                        userName={name}
                      />
                      {isAiUpsellOpen &&
                        <AIFeatureModal
                          onClose={handleAIUpsellClose}
                          imgSrc={ScriptImg}
                          trackSource={isUndefined(accountId) ? 'ai-script-prompt-local' : 'ai-script-prompt'}
                          getUpgradeUrl={getUpgradeUrl}
                        />
                      }
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            {!isUndefined(error) && (
              <Alert
                severity={'error'}
                sx={{ mt: 2 }}
                description={t`Oops! An error occurred while generating your result. Please try again.`}
              />
            )}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
              <FormControlLabel
                control={<Switch checked={displayTeleprompter} size={'medium'} sx={{ mr: 1 }} />}
                label={t`Display teleprompter`}
                onChange={() => onDisplayTeleprompterChange()}
                sx={{ '& .MuiFormControlLabel-label': theme.typography.body2, ml: 0 }}
              />
              <Typography variant="body2" color={theme.palette.neutral[500]}>
                {t`Estimated duration: ${toTimestampString(getScriptDuration(SCRIPT_READ_SPEED, script))}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Slide>
    </>
  );
}

const SparkleIcon = (color: CSSProperties['fill']) => (
  <SvgIcon inheritViewBox component={SparkleSvg} sx={{ fontSize: '1rem', mr: 1, '& > path': { fill: color } }} />
);

type ScriptGenAdjustmentOption = Readonly<{
  label: string;
  promptLabel: string;
}>;

const adjustmentOptions: ScriptGenAdjustmentOption[] = [
  { label: 'Make it more professional', promptLabel: 'video-more-professional' },
  { label: 'Make it more casual', promptLabel: 'video-more-casual' },
  { label: 'Make it shorter', promptLabel: 'video-more-short' },
  { label: 'Make it longer', promptLabel: 'video-more-long' },
];
