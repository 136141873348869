import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

/**
 * Page layout for landing page
 * @returns 
 */
export default function LandingPageLayout() {
  return (
    <Box display='flex' minHeight='100vh' justifyContent='center' alignItems='center'>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}