import { t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import wavingImage from 'common/images/illustration/orange man waving.png';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import Modal from './modal/modal.component';
import { PALETTE_COLOR, SPACER } from 'config/models/themeOptions';
import { useNavigate } from 'react-router-dom';

/**
 * Modal to show when a user's plan has been reactivated
 */
export default function PlanReactivatedModal() {
  const navigate = useNavigate();
  return <Modal
    iconStyle={{ color: PALETTE_COLOR.secondary[500] }}
    icon={ConfirmSvg}
    confirmIcon={ConfirmSvg}
    confirmLabel={t`Back to my videos`}
    onConfirm={() => void navigate('/')}
    isOpen={true} maxWidth={'xs'} title={t`Your plan has been reactivated!`}>
    <Typography variant='body2' component={'p'} pl={SPACER.XL} mb={SPACER.S}>{
      t`Welcome back! Let’s make some videos together!`}</Typography>
    <Box sx={{ textAlign: 'center' }}>
      <img src={wavingImage} alt="man waving" />
    </Box>
  </Modal>;
}