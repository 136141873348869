import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Fade,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as SparkleSvg } from 'common/images/icons/Extras/sparkle_gradient.svg';
import { ReactComponent as TimerSvg } from 'common/images/icons/Utility/timer.svg';
import { ReactComponent as RemoveSvg } from 'common/images/icons/Utility/remove.svg';
import { t } from '@lingui/macro';
import { ReactNode, useState } from 'react';
import { AI_GRADIENT } from '../../config/models/themeOptions';
import TextField from '../../common/components/textField/textField.component';
import { isEmpty, isUndefined } from 'lodash';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS } from '../const';
import { trackEvent } from '../../common/utils/errors';
import { IKeyValueInput } from '../../data/_chat';
import { useUserInfoQuery } from '../../data/_generated';
import AIFeatureModal from '../../common/components/AIFeatureModal.component';
import ScriptImg from '../../common/images/illustration/script.png';
import useAiUpsell from '../../common/hooks/useAiUpsell';
import useActiveAccount from '../../config/hooks/useActiveAccount';
const SparkleIcon = (
  <SvgIcon inheritViewBox component={SparkleSvg} sx={{ fontSize: '1rem', mr: 1, '& > path': { fill: 'white' } }} />
);

type TemplateType = Readonly<{ label: string; template: string }[]>;

const DEFAULT_TEMPLATES: TemplateType = [
  // Temporarily removing from the list for styling
  // {
  //   label: t`Pitch my business to a potential lead`,
  //   template: t`Pitch my business that [describe your business] to help [target audience]. Tell them to [call-to-action] after watching my video.`,
  // },
  {
    label: t`Demo a new feature to my customers`,
    template: t`Demo a new feature to my customers that [describe functionality of feature] for my product that [describe product or service]. Tell them to [call-to-action] after watching my video.`,
  },
  {
    label: t`Reminder before a meeting`,
    template: t`Remind someone about our meeting that is coming up. I plan to discuss [key details and goal of meeting].`,
  },
  {
    label: t`Follow up after a meeting`,
    template: t`Follow up after a meeting where we talked about [key points]. Tell them to [call-to-action] after watching my video.`,
  },
  {
    label: t`Explain why my business is a good fit`,
    template: t`Explain why my business that [describe your business] is a good fit for [target audience]. Tell them to [call-to-action] after watching my video.`,
  },
];

type AIButtonProps = {
  onClick: () => void;
  value: string;
  startIcon?: ReactNode;
};

const AIButton = ({ onClick, value, startIcon }: AIButtonProps) => {
  return (
    <Button color={'neutral'} size={'small'} sx={{ m: 1, maxWidth: '100%' }} onClick={onClick} startIcon={startIcon}>
      <Typography fontSize={'0.8rem'} textOverflow={'ellipsis'} noWrap overflow={'hidden'}>
        {value}
      </Typography>
    </Button>
  );
};

type ScriptHelperProps = {
  /** list of prompt templates*/
  templates: TemplateType;
  /** callback on selecting prompt template */
  onSelect: (script: string) => void;
  /** previous template used */
  prevTemplate?: string;
};

/**
 * Component to display script prompt templates
 */
function ScriptTemplateHelper({ templates, onSelect, prevTemplate }: ScriptHelperProps) {
  const selectScriptTemplate = (label: string, prompt: string) => {
    trackEvent('speaker-notes-ai-template', {
      label,
      prompt,
    });
    onSelect(prompt);
  };
  return (
    <Box textAlign={'start'}>
      <Typography color={(theme) => theme.palette.neutral[500]} sx={{ mb: 1, fontSize: '0.8rem' }}>
        💡 {t`Start with one of our templates:`}
      </Typography>
      {prevTemplate && (
        <AIButton
          startIcon={<SvgIcon inheritViewBox component={TimerSvg} />}
          value={t`Recent: ${prevTemplate}`}
          onClick={() => selectScriptTemplate('recent', prevTemplate)}
        />
      )}
      {templates.map((template, index) => (
        <AIButton
          key={`template${index}`}
          onClick={() => selectScriptTemplate(template.label, template.template)}
          value={template.label}
        />
      ))}
    </Box>
  );
}

type TextEditorProps = {
  /** script prompt value */
  value?: string;
  /** callback on change */
  onChange: (value: string) => void;
  /** callback on generate script */
  onGenerateScript: () => Promise<void>;
  /** callback on focus textfield */
  onFocus: () => void;
  minWidth?: number;
};

/**
 * Script Prompt TextEditor
 */
function TextEditor({ value, onChange, onGenerateScript, onFocus, minWidth }: TextEditorProps) {
  const [focused, setFocused] = useState<boolean>(false);

  const handleFocus = () => {
    onFocus();
    setFocused(true);
  };

  return (
    <Box
      sx={(theme) => ({
        minWidth: minWidth,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: '10px',
        borderColor: focused ? theme.palette.primary[600] : theme.palette.neutral[200],
        '&:hover': { borderColor: theme.palette.primary[600] },
      })}
    >
      <TextField
        placeholder={t`Tell us what you need the script for...`}
        multiline
        sx={{
          display: 'flex',
          width: '100%',
          mt: 1,
          flexGrow: 1,
          '& .MuiInputBase-inputMultiline': { height: '100% !important', overflowY: 'auto !important' },
          '& .MuiInputBase-root': {
            width: '100%',
            flexGrow: 1,
            border: 'unset',
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'baseline',
          },
        }}
        onChange={(event) => onChange(event.currentTarget.value)}
        onFocus={handleFocus}
        onBlur={() => setFocused(false)}
        value={value}
      />
      <Button
        variant={'contained'}
        onClick={() => void onGenerateScript()}
        disabled={isEmpty(value)}
        size={'small'}
        sx={{ m: 2, width: 'fit-content', alignSelf: 'flex-end' }}
      >{t`Generate`}</Button>
    </Box>
  );
}

type AIScriptBuilderProps = {
  /** list of prompt examples */
  templates?: TemplateType;
  /** callback on generate AI script*/
  generateScript: (value: IKeyValueInput[]) => Promise<void>;
  /** loading state */
  loading: boolean;
  /** whether component should be displayed */
  isOpen: boolean;
  /** function to update isOpen state */
  setIsOpen: (isOpen: boolean) => void;
  /** callback on focusing text field */
  onFocusTextField: () => void;
  /** name of the user */
  userName?: string;
  /** url to upgrade plan*/
  upgradeUrl?: string;
};

/**
 * Component for generating AI Script
 */
export default function AIScriptBuilder({
  templates = DEFAULT_TEMPLATES,
  generateScript,
  loading,
  isOpen,
  setIsOpen,
  onFocusTextField,
  userName,
}: AIScriptBuilderProps) {
  const theme = useTheme();
  const { accountId } = useActiveAccount();
  const { data: userInfoData } = useUserInfoQuery();
  const { isAiUpsellOpen, canApplyAI, handleAIUpsellClose, getUpgradeUrl } = useAiUpsell({ source: isUndefined(accountId) ? 'ai-script-builder-local' : 'ai-script-builder' });
  const [prevPrompt, setPrevPrompt] = useLocalStorage<string | undefined>(
    LOCAL_STORAGE_KEYS.PreviousAIPrompt,
    undefined,
  );
  const [prompt, setPrompt] = useState<string>();
  const handleGenerateScript = async () => {
    if (isUndefined(prompt) || isEmpty(prompt)) return;
    setPrevPrompt(prompt);
    await generateScript([
      { key: 'script-goal', value: prompt },
      { key: 'name', value: userName ?? '' },
      { key: 'jobTitle', value: userInfoData?.userInfo?.jobTitle ?? '' },
    ]);
    setPrompt(undefined);
    setIsOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <Fade in>
          <Card
            sx={{
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <CardActionArea onClick={() => setIsOpen(false)} disabled={loading}>
              <Box
                sx={{
                  background: AI_GRADIENT,
                  display: 'flex',
                  borderRadius: '8px 8px 0 0',
                  color: theme.palette.neutral[0],
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }}
              >
                <Typography m={'auto'}>
                  {SparkleIcon}
                  {t`Help me write a new script`}
                </Typography>
                <SvgIcon
                  inheritViewBox
                  component={RemoveSvg}
                  sx={{
                    color: theme.palette.neutral[0],
                    p: 0,
                    mr: 1,
                    fontSize: '1rem',
                    opacity: loading ? 0.3 : 1,
                  }}
                />
              </Box>
            </CardActionArea>
            <Box
              p={3}
              alignItems={loading ? 'center' : 'unset'}
              justifyContent={loading ? 'center' : 'unset'}
              sx={{
                height: '100%',
                borderRadius: '0 0 10px 10px',
                border: '1px solid transparent',
                background: `linear-gradient(white, white) padding-box, ${AI_GRADIENT}`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {loading ? (
                <>
                  <CircularProgress sx={{ m: 4, color: theme.palette.primary[300] }} />
                  <Typography m={4} color={theme.palette.primary[300]}>{t`Generating your script...`}</Typography>
                </>
              ) : (
                <>
                  <ScriptTemplateHelper
                    prevTemplate={prevPrompt}
                    templates={templates}
                    onSelect={(value: string) => setPrompt(value)}
                  />
                  <TextEditor
                    value={prompt}
                    onChange={(value) => setPrompt(value)}
                    onFocus={onFocusTextField}
                    onGenerateScript={handleGenerateScript}
                  />
                </>
              )}
            </Box>
          </Card>
        </Fade>
      ) : (
        <Button
          variant={'contained'}
          startIcon={SparkleIcon}
          onClick={() => canApplyAI(() => setIsOpen(true))}
          size={'small'}
          sx={{
            background: AI_GRADIENT,
            border: 0,
            width: 'fit-content',
            fontSize: '0.7rem',
          }}
        >{t`Help me write a new script`}</Button>
      )}
      {isAiUpsellOpen && <AIFeatureModal imgSrc={ScriptImg} onClose={handleAIUpsellClose} trackSource={isUndefined(accountId) ? 'ai-script-builder-local' : 'ai-script-builder'} getUpgradeUrl={getUpgradeUrl}/>}
    </>
  );
}
