import { AuthConfigType } from '../models/authConfig';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { AuthContext } from '../context/auth.context';

/** google identity provider issue ID */
const GOOGLE_IDP = 'google.com';

function useAuth(): AuthConfigType & IMsalContext & { isGoogleSSO?: boolean } {
  const context = useContext(AuthContext);
  const msalContext = useMsal();
  if (context === undefined) {
    throw new Error('useAuth must be within AuthProvider');
  }

  //if authorized user has signed up through google sso
  const isGoogleSSO = msalContext.accounts[0] ? msalContext.accounts[0].idTokenClaims?.idp === GOOGLE_IDP : undefined;
  return { ...msalContext, ...context, isGoogleSSO };
}

export { useAuth };
