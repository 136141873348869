import { t } from '@lingui/macro';
import FetchProjects from 'dashboard/components/fetchProjects.component';
import DashboardBar from 'dashboard/components/dashboardBar.component';
import { Button, Card, CardContent, CircularProgress } from '@mui/material';
import { useLocalStorage } from 'usehooks-ts';
import { LOCAL_STORAGE_KEYS } from 'edit/const';
import ProjectsFilterList, { ProjectFilterOptions } from 'dashboard/components/projectsFilteredList.component';
import { trackEvent } from 'common/utils/errors';
import { SortableProjectField } from 'dashboard/type';

/**
 * Page displaying all projects
 */
export default function ProjectsPage() {
  const [projectFilter, setProjectFilter] = useLocalStorage<ProjectFilterOptions>(
    LOCAL_STORAGE_KEYS.ProjectFilter,
    'All',
  );

  return (
    <>
      <DashboardBar title={t`My videos`}>
        <>
          <Button
            size="large"
            onClick={() => {
              setProjectFilter('All');
              trackEvent('videos-all');
            }}
            color={projectFilter === 'All' ? 'primary' : 'inherit'}
            variant={projectFilter === 'All' ? 'outlined' : 'text'}
          >
            {t`All`}
          </Button>
          <Button
            size="large"
            onClick={() => {
              setProjectFilter('Published');
              trackEvent('videos-published');
            }}
            color={projectFilter === 'Published' ? 'primary' : 'inherit'}
            variant={projectFilter === 'Published' ? 'outlined' : 'text'}
          >
            {t`Published`}
          </Button>
          <Button
            size="large"
            onClick={() => {
              setProjectFilter('Draft');
              trackEvent('videos-drafts');
            }}
            color={projectFilter === 'Draft' ? 'primary' : 'inherit'}
            variant={projectFilter === 'Draft' ? 'outlined' : 'text'}
          >
            {t`Drafts`}
          </Button>
        </>
      </DashboardBar>
      <FetchProjects
        render={(data) => (
          <Card variant="outlined">
            <ProjectsFilterList 
              data={data} 
              filter={projectFilter} 
              emptyMessage={t`You have no videos yet. Create a new video to get started!`}
              defaultSort={SortableProjectField.lastUpdated}
              showMenu={true}
            />

          </Card>
        )}
      >
        <Card variant="outlined">
          <CardContent sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </CardContent>
        </Card>
      </FetchProjects>
    </>
  );
}
