import { ReactComponent as CancelSvg } from 'common/images/icons/Utility/cancel.svg';
import { ReactComponent as ConfirmSvg } from 'common/images/icons/Utility/confirm.svg';
import { ReactComponent as InfoSvg } from 'common/images/icons/Utility/information.svg';
import { ReactComponent as RemoveSvg } from 'common/images/icons/Utility/remove_1.svg';
import { assertUnreachable } from './util';

/**
 * Helper to build a unique color for a string
 * @link https://mui.com/components/avatars/
 * @param string a string
 * @returns
 */
export function stringToColor(string: string): string {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
type StatusType = 'success' | 'info' | 'warning' | 'error' | 'neutral';

/**
 * Get icon based on the given type
 * @param type status type - 'success' | 'info' | 'warning' | 'error' | 'neutral'
 * @returns Svg Element
 */
export function getStatusIcon(type: StatusType) {
  switch (type) {
    case 'error':
      return CancelSvg;
    case 'info':
      return InfoSvg;
    case 'neutral':
      return InfoSvg;
    case 'success':
      return ConfirmSvg;
    case 'warning':
      return RemoveSvg;
    default:
      return assertUnreachable();
  }
}
