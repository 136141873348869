import TableSortLabel from '@mui/material/TableSortLabel';
import { TableCell } from '@mui/material';
import { DataOrder } from 'common/hooks/useCreateSortHandler';
import { MouseEvent } from 'react';

type RecipientsTableHeadProps = {
  /** head id - should be the same as data property name to sort by */
  id: string;
  /** head label for display */
  label: string;
  /** whether table head is selected to order by */
  isOrdered: boolean;
  /** order direction */
  orderDirection: DataOrder;
  /** callback on clicking sort */
  onSort: (event: MouseEvent<unknown>) => void;
  /** width of the table head */
  width?: number;
};

/**
 * Table head with sorting
 * To be used as a child of TableRow in the following format
 * <TableHead>
 *  <TableRow>
 *    <TableHeadSort/>
 *  </TableRow>
 * </TableHead>
 */
export default function TableHeadSort({ id, label, isOrdered, orderDirection, onSort, width }: RecipientsTableHeadProps) {
  return (
    <TableCell id={id} width={width}>
      <TableSortLabel active={isOrdered} direction={isOrdered ? orderDirection : 'desc'} onClick={onSort}>
        {label}
      </TableSortLabel>
    </TableCell>
  );
}
