import { t } from '@lingui/macro';
import FetchProjects from 'dashboard/components/fetchProjects.component';
import { Card, CardContent, CardHeader, CircularProgress } from '@mui/material';
import Summary from 'dashboard/components/summary.component';
import { isNil } from 'lodash';
import { ILandingPageMetricsDetailsFragment, useLandingPageMetricsQuery } from 'data/_generated';
import DashboardBar from 'dashboard/components/dashboardBar.component';
import { getCommonMetrics } from 'dashboard/helper';
import ProjectsFilterList from 'dashboard/components/projectsFilteredList.component';
import { SortableProjectField } from 'dashboard/type';

/**
 * Analytics page displaying all projects and analytics
 */
export default function AnalyticsPage() {
  const { data: landingPageData } = useLandingPageMetricsQuery({ fetchPolicy: 'no-cache' });
  const result: ILandingPageMetricsDetailsFragment | undefined =
    isNil(landingPageData?.landingPageMetrics) ? undefined :
      landingPageData?.landingPageMetrics;

  const metricsData = getCommonMetrics(result);

  return (<>
    <DashboardBar title={t`Analytics`} />
    <Summary data={{
      videoPlays: metricsData.plays,
      avgWatch: metricsData.watch,
      ctaClick: metricsData.clicks,
      conversionRate: null,
    }} />
    <FetchProjects
      render={(data) => {
        return (
          <Card variant="outlined">
            <CardHeader title={t`Your published videos`} sx={{ paddingBottom: 0 }}></CardHeader>
            <ProjectsFilterList
              data={data}
              filter={'Published'}
              emptyMessage={t`You have no videos yet. Create a new video to get started!`}
              defaultSort={SortableProjectField.lastUpdated}
            />
          </Card>
        );
      }
      }
    >
      <Card variant='outlined'>
        <CardHeader title={t`Your published videos`}></CardHeader>
        <CardContent sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </CardContent>
      </Card>
    </FetchProjects>
  </>);
}
