import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import { t } from '@lingui/macro';
import { ReactComponent as ToastSvg } from 'common/images/icons/Party/toast.svg';
import boxImage from 'common/images/illustration/woman in box.png';
import { SPACER } from '../../config/models/themeOptions';
import { LoadingButton } from '@mui/lab';
import IconItemList from '../../common/components/iconItemList.component';

const TRIAL_FEATURES = [
  t`AI Eye Contact`,
  t`AI Background Blur`,
  t`AI Script Generator`,
  t`Personalized Intros`,
  t`Video engagement analytics`,
];

type TrialViewProps = {
  /** callback on button click */
  onConfirm: () => Promise<void>;
  /** loading status of button */
  loading: boolean;
};
/**
 * View for plan subscription
 * @constructor
 */
export default function TrialView({ onConfirm, loading }: TrialViewProps) {
  return (
    <Grid container flexGrow={1} alignContent={'center'}>
      <Grid item xs={12} md={7} order={{ xs: 2, md: 0 }}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'} p={SPACER.M}>
          <Box>
            <Typography variant="h1" sx={{ mb: 2 }}>
              <SvgIcon component={ToastSvg} inheritViewBox fontSize="inherit" sx={{ mr: 2 }} />
              {t`Welcome to Your 7-Day Free Trial!`}
            </Typography>
            <Typography
              component={'div'}
            >{t`During your trial, you'll have access to all our features, so you can see the difference for yourself. No credit card required.`}</Typography>
          </Box>
          <Box my={5}>
            <Typography
              variant={'bodySemibold'}
              mb={3}
              component={'p'}
              fontSize={'1rem'}
            >{t`Try out our premium features like:`}</Typography>
            <IconItemList items={TRIAL_FEATURES}/>
          </Box>
          <LoadingButton loading={loading} variant={'contained'} fullWidth onClick={() => void onConfirm()}>
            <span>{t`Get started`}</span>
          </LoadingButton>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} textAlign={'center'} alignSelf={'center'}>
        <img
          style={{
            maxHeight: '40vh',
            maxWidth: '100%',
            margin: '0 auto',
          }}
          src={boxImage}
          alt={t`Person in a box`}
        />
      </Grid>
    </Grid>
  );
}
