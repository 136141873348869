import useRest from '../../common/hooks/useRest';
import { useConfig } from '../../config/hooks/useConfig';
import { usePollEndpoint } from '../../common/hooks/usePollEndpoint';

/**
 * Details pertaining to target landing page. These are published as soon as publishing is initialized.
 */
export type PublishedLandingPageDetails = {
  /** name of target */
  targetName: string;
  /** video url. Should only be included in legacy published videos*/
  videoUrl?: string;
  /** call to action links */
  links: { text: string; link: string }[];
  /** location of thumbnail */
  thumbnailLocation?: string;
  /** title for video */
  title?: string;
  /** the owner of the project */
  ownerId?: string;
  /** the project id */
  projectId?: string;
};
/**
 * Published once video processing is completed
 */
export type PublishedVideoDetails = {
  /** video url */
  videoUrl: string;
};

const POLLING_INTERVAL_MS = 10_000;

/**
 * Hook for fetching landing page data
 * @param targetId target id to fetch data for
 */
export function useFetchTargetLandingPageDetails(targetId: string) {
  const { landingPageDocumentsEndpoint } = useConfig();
  const landingPageDetails = useRest<PublishedLandingPageDetails>(
    `/public/${targetId}.json`,
    landingPageDocumentsEndpoint,
  );
  const videoDetails = usePollEndpoint<PublishedVideoDetails>({
    url: `${landingPageDocumentsEndpoint}/public/video.${targetId}.json`,
    intervalInMs: POLLING_INTERVAL_MS,
    startPolling: true,
  });
  return { landingPageDetails, videoDetails };
}
