import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Button,
  IconButton,
  Box,
  DialogProps,
  Typography,
  SxProps,
  Theme,
} from '@mui/material';
import { FunctionComponent, ReactNode, SVGProps } from 'react';
import { ReactComponent as CloseSvg } from 'common/images/icons/Utility/close.svg';
import { ReactComponent as LeftSvg } from 'common/images/icons/Utility/left.svg';
import { LoadingButton } from '@mui/lab';
import AlertError from '../alert/alertError.component';
import { isUndefined } from 'lodash';
import { t } from '@lingui/macro';
import { SPACER } from '../../../config/models/themeOptions';

type ModalHeaderProps = Pick<ModalProps, 'title' | 'icon' | 'iconStyle' | 'onClose' | 'confirmLoading' | 'backAction'>;

function HeaderTitle({ title, icon, iconStyle, onClose, confirmLoading }: ModalHeaderProps) {
  return (
      <DialogTitle
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            '&.MuiDialogTitle-root': (theme) => ({
              ...theme.typography.h3,
            }),
          }}
      >
        <Box display={'flex'} alignItems={'center'}>
          {icon && <SvgIcon component={icon} inheritViewBox sx={{ ...iconStyle, mr: 4 }} />}
          {title}
        </Box>
        {onClose && (
            <IconButton onClick={onClose} disabled={confirmLoading}>
              <SvgIcon component={CloseSvg} inheritViewBox />
            </IconButton>
        )}
      </DialogTitle>
  );
}

function HeaderButton({ onClose, confirmLoading, title, backAction }: ModalHeaderProps & { backAction: () => void }) {
  return (
    <DialogTitle
        sx={{
          '&.MuiDialogTitle-root': (theme) => ({
            ...theme.typography.h3,
          }),
        }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={SPACER.S}>
        <Button onClick={() => backAction()}><SvgIcon component={LeftSvg} inheritViewBox sx={{ mr: 2 }}/>{t`Back`}</Button>
        {onClose && (
            <IconButton onClick={onClose} disabled={confirmLoading}>
              <SvgIcon component={CloseSvg} inheritViewBox />
            </IconButton>
        )}
      </Box>
      {title}
    </DialogTitle>
  );
}

export type ModalProps = {
  /** title in the modal */
  title: string;
  /** callback on confirm */
  onConfirm?: () => void | Promise<void>;
  /** callback on close */
  onClose?: () => void;
  /** callback for a secondary action */
  onSecondAction?: () => void;
  /** Disable closing modal */
  disableClose?: boolean;
  /** icon next to the title */
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /** custom styling for icon */
  iconStyle?: SxProps;
  /** icon for the confirm button */
  confirmIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /** label for confirm button */
  confirmLabel?: string;
  confirmDisabled?: boolean;
  /** if the confirm is loading */
  confirmLoading?: boolean;
  /** if there is an error for the confirm */
  confirmError?: Error;
  /** label for cancel button */
  cancelLabel?: string;
  /** if the secondary action is disabled */
  secondDisabled?: boolean;
  /** icon for the confirm button */
  secondIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /** label for the secondary action button */
  secondLabel?: string;
  /** if the secondary action is loading */
  secondLoading?: boolean;
  /** if there is an error for the secondary */
  secondError?: Error;
  /** modal content */
  children?: ReactNode;
  /** whether the modal should be open */
  isOpen?: boolean;
  /** custom styling */
  sx?: SxProps<Theme>;
  /** href link to open upon clicking confirm button */
  confirmHref?: string;
  /** href link to open upon clicking secondary button */
  secondHref?: string;
  /** callback on clicking back button */
  backAction?: () => void;
  /** whether the modal theme should be danger themed */
  isDanger?: boolean;
  /** if backdrop click should be disabled */
  disableBackdropClick?: boolean;
} & Pick<DialogProps, 'maxWidth' | 'fullScreen'>;

/**
 * Generic component for Modal from design system
 */
export default function Modal(props: ModalProps) {
  const {
    title,
    onConfirm,
    icon,
    confirmIcon,
    confirmError,
    confirmLoading,
    confirmDisabled,
    confirmLabel,
    confirmHref,
    cancelLabel,
    onSecondAction,
    secondDisabled,
    secondIcon,
    secondLoading,
    secondLabel,
    secondError,
    secondHref,
    backAction,
    isOpen = false,
    onClose,
    iconStyle,
    maxWidth,
    sx,
    fullScreen,
    isDanger = false,
    
    children,
    disableClose,
    disableBackdropClick = false,
  } = props;

  const hasCloseAction = onClose && cancelLabel;
  const hasConfirmAction = onConfirm && confirmLabel;
  const hasSecondAction = onSecondAction && secondLabel;
  const hasActions = hasCloseAction || hasConfirmAction || hasSecondAction;
  
  const handleClose: DialogProps['onClose'] = (_event, reason) => {
    if (!onClose || disableClose) return;
    if (disableBackdropClick && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      PaperProps={{
        sx,
      }}
    >
      {
        isUndefined(backAction) ?
            <HeaderTitle title={title} onClose={onClose} icon={icon} iconStyle={iconStyle} confirmLoading={confirmLoading}/> :
            <HeaderButton title={title} onClose={onClose} icon={icon} iconStyle={iconStyle} confirmLoading={confirmLoading} backAction={backAction}/>
      }
      <DialogContent>{children}</DialogContent>
      {hasActions && (
        <DialogActions sx={{ p: 6, pt: 0 }}>
          {hasCloseAction && (
            <Button onClick={onClose} variant="outlined" disabled={confirmLoading}>
              {cancelLabel}
            </Button>
          )}
          {hasSecondAction && (
            <AlertError error={secondError} maxWidth="100%">
              <LoadingButton
                startIcon={secondIcon && <SvgIcon component={secondIcon} inheritViewBox />}
                loading={secondLoading}
                onClick={onSecondAction}
                disabled={secondDisabled}
                variant="contained"
                color="secondary"
                LinkComponent="a"
                href={secondHref ?? ''}
                target="_blank"
              >
                <span>{secondLabel}</span>
              </LoadingButton>
            </AlertError>
          )}
          {hasConfirmAction && (
            <AlertError error={confirmError} maxWidth="100%">
              <LoadingButton
                startIcon={confirmIcon && <SvgIcon component={confirmIcon} inheritViewBox />}
                disabled={confirmDisabled}
                loading={confirmLoading}
                color={confirmError ? 'error' : 'primary'}
                onClick={() => void onConfirm()}
                variant={isDanger ? 'containedDanger' : 'contained'}
                sx={{ ml: 2, maxWidth: '100%' }}
                LinkComponent="a"
                href={confirmHref ?? ''}
                target="_blank"
              >
                <Typography textOverflow={'ellipsis'} overflow={'hidden'} noWrap>
                  {confirmLabel}
                </Typography>
              </LoadingButton>
            </AlertError>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
