import { t } from '@lingui/macro';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useAuth } from 'config/hooks/useAuth';
import { ILandingPageTargetsMetricsFragment, ITarget, ITargetInput } from 'data/_generated';
import { getTargetTemplatedTitle } from 'edit/const';
import { Dictionary, isEmpty, omit, orderBy as _orderBy, values, isUndefined } from 'lodash';
import { useParams } from 'react-router-dom';
import RecipientDetails from './recipientDetails.component';
import PlayThumbnailSvg from 'common/images/play.svg';
import { useSnackBarContext } from 'common/components/snackBar/snackBarContext.component';
import TableHeadSort from 'common/components/table/tableHeadSort.component';
import { ValueOf } from 'common/utils/types';
import useCreateSortHandler from 'common/hooks/useCreateSortHandler';
import { orderIterateeHelper } from 'common/utils/table';
import { SPACER } from 'config/models/themeOptions';

type RecipientListProps = {
  /** list of targets */
  targets: ITarget[];
  /** 
   * landing page metrics of targets
   * if undefined don't show metrics
   */
  targetMetrics?: Dictionary<ILandingPageTargetsMetricsFragment | undefined>;
  /** video duration by targetId */
  targetDurations: Map<string, number>;
  /**
   * landing page title template
   * used for nameless target
   */
  landingPageTitleTemplate?: string | null;
  /** thumbnail location */
  thumbnailLocation?: string | null;
  /** callback on update target */
  onUpdateTarget: (target: ITargetInput) => Promise<void>;
  /** callback on delete target */
  onDeleteTarget: (targetId: string) => Promise<void>;
  /** if share actions should be shown */
  showShareActions: boolean;
  /** whether to enable the preview */
  enablePreview: boolean;
};

/**
 * Component for recipient table
 */
export default function RecipientList({
  targets,
  targetMetrics,
  targetDurations,
  landingPageTitleTemplate,
  thumbnailLocation,
  onUpdateTarget,
  onDeleteTarget,
  showShareActions,
  enablePreview,
}: RecipientListProps) {
  const { instance } = useAuth();
  const account = instance.getActiveAccount();

  const { id } = useParams();
  if (!id) throw new Error('Could not find the Project ID in the URL, maybe this was a bad link?');
  const { setSnackbarContent } = useSnackBarContext();
  const notifyCopyResult = (copied: boolean) => {
    setSnackbarContent({
      severity: copied ? 'success' : 'error',
      description: copied ? t`Copied to clipboard!` : t`Copy failed`,
      closeable: true,
    });
  };

  const { order, orderByField, createSortHandler } = useCreateSortHandler<SortableFieldValues>(
    SortableField.watchPercent,
  );

  const displayRecipients = getDisplayRecipients(targets, targetDurations, targetMetrics);
  return (
    <TableContainer sx={{ p: { xs: SPACER.S, md: 0 } }}>
      <Table sx={{ tableLayout: 'fixed' }}>
        {!isUndefined(targetMetrics) && <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
          <TableRow>
            <TableHeadSort
              id={SortableField.targetName}
              label={t`Recipient info`}
              isOrdered={orderByField === SortableField.targetName}
              onSort={createSortHandler(SortableField.targetName)}
              orderDirection={order}
              width={300}
            />
            <TableHeadSort
              id={SortableField.videoLength}
              label={t`Video length`}
              isOrdered={orderByField === SortableField.videoLength}
              onSort={createSortHandler(SortableField.videoLength)}
              orderDirection={order}
            />
            <TableHeadSort
              id={SortableField.videoPlayCount}
              label={t`Video plays`}
              isOrdered={orderByField === SortableField.videoPlayCount}
              onSort={createSortHandler(SortableField.videoPlayCount)}
              orderDirection={order}
            />
            <TableHeadSort
              id={SortableField.dropOffPoint}
              label={t`Watch time`}
              isOrdered={orderByField === SortableField.dropOffPoint}
              onSort={createSortHandler(SortableField.dropOffPoint)}
              orderDirection={order}
            />
            <TableHeadSort
              id={SortableField.watchPercent}
              label={t`Percentage watched`}
              isOrdered={orderByField === SortableField.watchPercent}
              onSort={createSortHandler(SortableField.watchPercent)}
              orderDirection={order}
            />
            <TableHeadSort
              id={SortableField.ctaClickCount}
              label={t`CTA clicks`}
              isOrdered={orderByField === SortableField.ctaClickCount}
              onSort={createSortHandler(SortableField.ctaClickCount)}
              orderDirection={order}
            />
            {showShareActions && <TableCell width={300}>{t`Share video`}</TableCell>}
          </TableRow>
        </TableHead>}
        <TableBody>
          {_orderBy(
            displayRecipients,
            [
              (recipient) =>
                orderIterateeHelper(
                  orderByField,
                  [SortableField.targetName],
                  NumberSortableFields,
                  recipient[orderByField],
                ),
            ],
            [order],
          ).map((target) => {
            return (
              <RecipientDetails
                key={target.id}
                targetId={target.id}
                targetName={target.name}
                targetEmail={target.email}
                publishedStatus={target.publishStatus}
                icon={enablePreview ? 'preview' : (isEmpty(target.name) ? 'avatar' : 'initials')}
                videoLength={target.videoLength}
                videoPlays={target.videoPlayCount}
                ctaClicks={target.ctaClickCount}
                watchLength={target.dropOffPoint}
                watchPercentage={target.watchPercent}
                showAnalytics={!isUndefined(targetMetrics)}
                showShareActions={showShareActions}
                projectId={id}
                thumbnailLocation={thumbnailLocation ?? PlayThumbnailSvg}
                accountName={account?.name ?? ''}
                onCopied={notifyCopyResult}
                onUpdateTarget={onUpdateTarget}
                onDeleteTarget={onDeleteTarget}
                targetVideoTitleTemplate={getTargetTemplatedTitle(target, landingPageTitleTemplate)}
                targetTitle={target.title ?? undefined} 
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type DisplayRecipient = Readonly<
Omit<ITarget, '__typename'> &
Omit<ILandingPageTargetsMetricsFragment, '__typename' | 'targetId' | 'pageViewCount'> & { videoLength?: number }
>;

/** Name of sortable field */
const SortableField = {
  targetName: 'name',
  videoLength: 'videoLength',
  videoPlayCount: 'videoPlayCount',
  ctaClickCount: 'ctaClickCount',
  watchPercent: 'watchPercent',
  dropOffPoint: 'dropOffPoint',
} as const;

type SortableFieldValues = ValueOf<typeof SortableField>;

const NumberSortableFields = values(omit(SortableField, SortableField.targetName));

/**
 * Helper to process targets for recipients table
 * @param targets list of targets
 * @param targetMetrics target metrics by targetId
 * @param targetDuration target video duration by targetId
 */
function getDisplayRecipients(
  targets: ITarget[],
  targetDuration: Map<string, number>,
  targetMetrics?: Dictionary<ILandingPageTargetsMetricsFragment | undefined>,
): DisplayRecipient[] {
  return targets.map((target) => {
    const metrics = isUndefined(targetMetrics) ? {} : targetMetrics[target.id];
    return {
      name: target.name,
      id: target.id,
      email: target.email,
      publishStatus: target.publishStatus,
      title: target.title,
      videoLength: targetDuration.get(target.id),
      isDisabled: target.isDisabled,
      ...metrics,
    };
  });
}
