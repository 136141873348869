import { t } from '@lingui/macro';
import { Typography, Button, Grid, InputAdornment, useTheme, MenuItem, FormHelperText, SvgIcon } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ICallToAction, ICallToActionInput } from 'data/_generated';
import { isEmpty, isNil } from 'lodash';
import { UrlRegex } from 'common/constants/regex';
import TextField from 'common/components/textField/textField.component';
import Select from 'common/components/select/select.component';
import { ReactComponent as LinkSvg } from 'common/images/icons/Utility/link one.svg';

const PreloadedOptions = [
  t`Schedule a meeting`,
  t`Get in touch`,
  t`Learn more`,
  t`Try for free`,
  t`Get a quote`,
  t`Click here`,
];

const textFieldName = 'text';
const linkFieldName = 'link';

const LinkSchema = Yup.object().shape({
  [textFieldName]: Yup.string().required(),
  [linkFieldName]: Yup.string()
    .matches(UrlRegex, t`Invalid URL`)
    .when([textFieldName], {
      is: (text: string) => {
        return isNil(text);
      },
      otherwise: Yup.string().required(t`Enter a valid URL`),
    }),
});

/**
 * Placeholder element to align the call to action elements
 * Without this, when there's an error, form layout will be misaligned
 */
const Placeholder = () => {
  return <FormHelperText> </FormHelperText>;
};

type CallToActionFormProps = {
  /** links */
  links?: ICallToAction[] | null;
  /** callback on submit */
  updateLink: (links: ICallToActionInput[]) => Promise<void>;
  /** loading status of the form */
  isLoading?: boolean;
};

/**
 * Form Component for Call To Action
 */
export function CallToActionForm({ links, updateLink, isLoading }: CallToActionFormProps) {
  const theme = useTheme();
  const handleSubmitLinks = async (values: ICallToAction) => {
    await updateLink([{ text: values.text, link: values.link }]);
  };

  const handleReset = async () => {
    await updateLink([]);
  };

  return (
    <Formik
      initialValues={!isEmpty(links) && !isNil(links) ? links[0] : { text: '', link: '' }}
      onSubmit={handleSubmitLinks}
      style={{ marginLeft: '16px', '& .MuiTextField-root': { minwidth: '100%' } }}
      validationSchema={LinkSchema}
      enableReinitialize
      validateOnChange={false}
    >
      {({ values, errors, touched, handleChange, resetForm, initialValues, handleSubmit }) => (
        <Form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Select
                variant={'standard'}
                value={values.text}
                size="small"
                name={textFieldName}
                disabled={isLoading}
                onChange={handleChange}
                onBlur={() => handleSubmit()}
                fullWidth
                displayEmpty
                renderValue={(value) =>
                  value.length > 0 ? (
                    value
                  ) : (
                    <Typography
                      sx={{ color: theme.palette.grey[500], lineHeight: 'inherit' }}
                    >{t`Button Name`}</Typography>
                  )
                }
              >
                {PreloadedOptions.map((text, textIndex) => (
                  <MenuItem value={text} key={`text-${textIndex}`}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
              <Placeholder />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={linkFieldName}
                value={values.link}
                onChange={handleChange}
                onBlur={() => handleSubmit()}
                placeholder={t`Enter URL`}
                disabled={isLoading}
                error={!isNil(errors.link) && touched.link}
                helperText={errors.link}
                fullWidth
                sx={{ width: '100%', '& .MuiSvgIcon-root': { fontSize: '1rem' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon component={LinkSvg} inheritViewBox />
                    </InputAdornment>
                  ),
                }}
              />
              {!errors.link && <Placeholder />}
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => {
                  //To reset and sync the form to empty state when form was only partially filled.
                  //Because the initial values was already empty, UI doesn't get updated
                  if (isEmpty(initialValues.text) && isEmpty(initialValues.link)) {
                    resetForm({ values: { text: '', link: '' } });
                  } else {
                    void handleReset();
                  }
                }}
                disabled={(isEmpty(values.text) && isEmpty(values.link)) || isLoading}
                sx={{
                  color: theme.palette.primary[500],
                  '& .Mui-disabled': {
                    color: theme.palette.neutral[500],
                  },
                }}
                variant="link"
              >{t`Clear`}</Button>
              <Placeholder />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
