import { loadScript } from 'common/utils/scripts';
import useUserDetails from 'config/hooks/useUserDetails.component';
import { ReactNode, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useTextFeatureFlag } from '../hooks/useFeatureFlags';
import { StringFeatureFlagNames } from '../models/featureFlags';
import { isEmpty } from 'lodash';
import { Userpilot } from 'userpilot';

/**
 * Appcues API
 * https://docs.appcues.com/article/161-javascript-api
 */
interface AppcuesAPI {
  /**
   * Used to identify the user to app cues
   */
  identify: (userId: string, metaData?: unknown) => void;
  /**
   * Signifies a page change
   */
  page: () => void;
  /**
   * Track custom events
   */
  track: (eventName: string, eventProperties?: unknown) => void;
  /**
   * Show a specific flow
   * @param id the flow to show
   */
  show: (id: string) => void;
}

declare global {
  interface Window {
    Appcues?: AppcuesAPI;
  }
}

type Command = () => void;

interface GuidesProps {
  children: ReactNode;
}

/**
 * Provider to add guides script and functions as context for hooks
 * @param props App cues props
 */
export default function Guides({ children }: GuidesProps) {
  const { text: appCuesKey, isReady } = useTextFeatureFlag(StringFeatureFlagNames.appCuesKey);
  const { text: userPilotKey } = useTextFeatureFlag(StringFeatureFlagNames.userPilotKey);
  const isAppCues = !isEmpty(appCuesKey);
  const isUserPilot = !isEmpty(userPilotKey);
  const location = useLocation();
  const queuedCommands = useRef<Command[]>([]);
  const queueOrExecute = (command: Command) => {
    if (isReady && ((isAppCues && window.Appcues) || !isAppCues)) {
      command(); //execute the command right away
    }
    queuedCommands.current.push(command);
  };

  const flushCommands = () => {
    if (queuedCommands.current.length <= 0) return;
    while (queuedCommands.current.length) {
      const cb = queuedCommands.current.shift();
      if (typeof cb === 'function') cb();
    }
  };

  useUserDetails({
    callback: (userId, userDetails) => {
      queueOrExecute(() => {
        if (isAppCues) window.Appcues?.identify(userId, userDetails);
        if (isUserPilot) Userpilot.identify(userId, userDetails);
      });
    },
  });

  useEffect(() => {
    queueOrExecute(() => window.Appcues?.page());
  }, [location]);

  // Load Guides library
  useEffect(() => {
    if (isReady) {
      if (isUserPilot) {
        Userpilot.initialize(userPilotKey);
      }
      if (isAppCues) {
        loadScript(`//fast.appcues.com/${appCuesKey}.js`, {
          async: true,
          defer: true,
          onload: flushCommands,
        });
      } else {
        flushCommands();
      }
    }
  }, [isReady]);

  return <>{children}</>;
}
