import { useNavigate } from 'react-router-dom';
import { useDeleteVideoProjectMutation } from '../../data/_generated';
import { trackEvent } from '../utils/errors';
import { isUndefined } from 'lodash';

type DeleteProjectProps = {
  /** the project id */
  projectId: string;
  /** the name of the event to be triggered
   * @default delete-project
   */
  eventName?: string;
  /** the queries to refetch */
  refetchQueries?: string[];
  /** where to navigate after completion */
  navigateUrl?: string;
};

export default function useDeleteProject({
  projectId,
  eventName = 'delete-project',
  refetchQueries,
  navigateUrl,
}: DeleteProjectProps) {
  const navigate = useNavigate();

  const [deleteVideo, { loading, error }] = useDeleteVideoProjectMutation({
    variables: { deleteVideoProjectId: projectId },
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleDelete = async () => {
    await deleteVideo();
    trackEvent(eventName, { projectId });
    if (!isUndefined(navigateUrl)) navigate(navigateUrl);
  };

  return {
    deleteVideoProject: handleDelete,
    loading,
    error,
  };
}
