import { ReactComponent as PauseCircleSvg } from 'common/images/icons/Camera/Pause.svg';
import { ReactComponent as PlayCircleSvg } from 'common/images/icons/Camera/Play.svg';
import { ReactComponent as CancelCircleSvg } from 'common/images/icons/Freebies/cancel.svg';
import { ReactComponent as DialCircleSvg } from 'common/images/icons/Camera/Dial.svg';
import { VideoTagProps } from './videoRecordingTag.component';
import { Button, SvgIcon, Typography, styled } from '@mui/material';
import { toTime } from 'common/utils/string';
import { PALETTE_COLOR } from 'config/models/themeOptions';
import { isUndefined } from 'lodash';


type VideoPlayTagProps = {
  /** the current state */
  state: 'playing' | 'paused' | 'error';
} & VideoTagProps;

const TagButton = styled(Button)({
  color: PALETTE_COLOR.neutral[0],
  minHeight: 32,
  '&.MuiButton-textError': {
    color: PALETTE_COLOR.error[50],
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
});

/**
 * Based off the current status and the current timestamp show an indicator that recording is happening
 * @returns {JSX.Element}
 */
export default function VideoPlayTag({
  onClick,
  state,
  currentInSeconds,
  durationInSeconds }: VideoPlayTagProps) {
  return (
    <TagButton
      size='small'
      disabled={isUndefined(onClick)}
      onClick={onClick}
      color={state === 'error' ? 'error' : undefined}
      startIcon={
        isUndefined(onClick) ?
            <SvgIcon
                component={DialCircleSvg}
                inheritViewBox
                sx={{
                  animation:  'rotating 2s linear infinite',
                }}
            /> :
          state === 'error' ?
          <SvgIcon component={CancelCircleSvg} inheritViewBox /> :
            state === 'paused' ?
            <SvgIcon component={PauseCircleSvg} inheritViewBox /> :
            <SvgIcon component={PlayCircleSvg} inheritViewBox />
      }
    >
      <Typography sx={{ fontFamily: 'monospace', fontSize: 14, fontWeight: 'bold', paddingRight: 2 }} >
        {`${toTime(currentInSeconds)}/${toTime(durationInSeconds)}`}
      </Typography>
    </TagButton>
  );
}