import { Box, Button, Fade, Stack, SvgIcon } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { DEFAULT_ASPECT_RATIO, VideoDimensions } from 'common/constants/video';
import { UpdateClipInput, VIDEO_WRAPPER } from 'edit/const';
import VideoProcess from './videoProcess.component';
import { t } from '@lingui/macro';
import { ReactComponent as TrimSvg } from 'common/images/icons/Utility/cut.svg';
import { SPACER } from 'config/models/themeOptions';
import { isUndefined } from 'lodash';
import { getTrimmedTimeOptional } from './utils';
import { IVideoClip, IVideoClipEffectProcessingStatus } from 'data/_generated';
import { trackError } from 'common/utils/errors';
import VideoPlay from './videoPlay.component';
import VideoEffect from './videoEffect.component';
import VideoEffectThumbnail from './videoEffectThumbnail.component';
import useVideoEffectOnClip from 'common/hooks/useVideoEffectOnClip';
import Tooltip from '../../common/components/tooltip/tooltip.component';
import VideoEffectSlider from './videoEffectSlider.component';

type VideoSelectedProps = {
  /** the clip */
  clip: IVideoClip;
  /** actions to display */
  children: ReactNode;
  /** callback on confirm trim */
  onUpdate: (videoClip: UpdateClipInput) => void | Promise<void>;
  /** the start of the current clip, in % */
  start?: number;
  /** the end of the current clip, in % */
  end?: number;
};

/**
 * Component to show selected  with an overlay over the clip
 * The overlay does not prevent interacting with the clip
 */
export default function VideoSelected({
  clip,
  onUpdate,
  children,
  start,
  end,
}: VideoSelectedProps) {

  /** total duration of original video, considering the start and end */
  const totalDuration: number | undefined = clip.durationInSeconds ?? undefined;
  const endTime = getTrimmedTimeOptional(end, totalDuration);
  const startTime = getTrimmedTimeOptional(start, totalDuration);
  const calculatedDuration: number | undefined = (!isUndefined(start) && !isUndefined(end) && totalDuration) ?
    (end - start) / 100 * totalDuration :
    totalDuration;
  /** the dimensions of the video clip */
  const dimensions: VideoDimensions | undefined = (clip.videoHeight && clip.videoWidth) ? {
    videoHeight: clip.videoHeight,
    videoWidth: clip.videoWidth,
  } : undefined;

  const [isTrimming, setIsTrimming] = useState<boolean>(false);
  const [videoDimensions, setVideoDimensions] = useState<VideoDimensions | undefined>(dimensions);
  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    hasVideoEffect,
    effectSettings,
  } = useVideoEffectOnClip({
    clipId: clip.id,
    clipEffectSettings: clip.effectSettings ?? undefined,
    onUpdate: (newSettings)=> {
      void onUpdate({ ...clip, effectSettings: newSettings });
    },
  });

  const aspectRatio = videoDimensions
    ? `${videoDimensions.videoWidth / videoDimensions.videoHeight}`
    : DEFAULT_ASPECT_RATIO;
  if (!clip.location) {
    trackError('clip-issue', { id: clip.id });
    return <></>;
  }
  /** existing recording if available */
  const location: string = clip.location;

  return (
    <>
      {isTrimming ? (
        <VideoProcess
          video={clip}
          start={start}
          end={end}
          showTrimming
          onUpload={async (upload) => {
            await onUpdate(upload);
            setIsTrimming(false);
          }}
          onCancel={() => setIsTrimming(false)}
          confirmLabel={t`Confirm`}
          cancelLabel={t`Cancel`}
        />
      ) : (
          <>
          <VIDEO_WRAPPER sx={{ aspectRatio }}>
            {effectSettings &&
              effectSettings.status !== IVideoClipEffectProcessingStatus.Completed &&
              <VideoEffectThumbnail
                  dateCreated={clip.dateCreated}
                durationInSeconds={calculatedDuration}
                status={effectSettings.status}
                clipId={effectSettings.baseClipId} />
            }
            <Fade
              mountOnEnter
              appear={hasVideoEffect}
              in={effectSettings?.status !== IVideoClipEffectProcessingStatus.Processing &&
                effectSettings?.status !== IVideoClipEffectProcessingStatus.Failed}
              key={clip.id}
            >
              <Box>
                {effectSettings && effectSettings.status === IVideoClipEffectProcessingStatus.Completed &&
                    <VideoEffectSlider clipId={effectSettings.baseClipId} ref={videoRef}   />
                }
                <VideoPlay
                  src={location}
                  matchVideoRef={videoRef}
                  startTime={startTime}
                  endTime={endTime}
                  onDimensionsChange={({ videoHeight, videoWidth }) => {
                    if (videoDimensions?.videoHeight !== videoHeight || videoDimensions.videoWidth !== videoWidth) {
                      setVideoDimensions({ videoWidth, videoHeight });
                    }
                  }}
                  durationInSeconds={calculatedDuration}
                />
              </Box>
            </Fade>
            <Box sx={{
              position: 'absolute',
              top: 16,
              right: 16,
            }}>
              <Tooltip title={t`Trim`} color='dark' placement='top'>
                <Button
                    variant='outlined'
                    disabled={!(isUndefined(effectSettings) || effectSettings.status === IVideoClipEffectProcessingStatus.Completed)}
                    startIcon={<SvgIcon inheritViewBox component={TrimSvg} />}
                    onClick={() => setIsTrimming(true)}
                    sx={{ minWidth: 'auto', '& .MuiButton-startIcon' : { margin: 0 } }}
                >
                </Button>
              </Tooltip>
              <VideoEffect
                  clipId={clip.id}
                  effectSettings={effectSettings}
                  onUpdate={onUpdate}
              />
            </Box>
          </VIDEO_WRAPPER>
          <Stack
            p={SPACER.S}
            spacing={3}
            direction={'row'}
            justifyContent={'center'}
            sx={{
              maxWidth: '100vw',
              margin: '0 auto',
            }}
          >
            {children}
          </Stack>
        </>
      )}
    </>
  );
}
