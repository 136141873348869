import { t } from '@lingui/macro';
import { ILandingPageMetricsByProjectIdDetailsFragment, ILandingPageMetricsDetailsFragment, ITarget, IVideoProjectShareFragment } from 'data/_generated';
import { getTrimmedDuration } from 'edit/components/utils';
import { isSingleClipSlot, isPersonalizedClipSlot } from 'edit/const';
import { compact, floor, isNull, isNumber, isUndefined, keyBy, mapValues, reduce, sum } from 'lodash';

/**
 * Get common landing page metrics between user and project dashboard
 * @param data
 * @returns
 */
export function getCommonMetrics(
  data?: ILandingPageMetricsDetailsFragment | ILandingPageMetricsByProjectIdDetailsFragment,
) {
  const plays: string | undefined = isNumber(data?.videoPlayCount) ? t`${data?.videoPlayCount}` : undefined;
  const watch: string | undefined = isNumber(data?.watchPercent) ? t`${data?.watchPercent}%` : undefined;
  const clicks: string | undefined = isNumber(data?.ctaClickCount) ? t`${data?.ctaClickCount}` : undefined;

  return {
    plays,
    watch,
    clicks,
  };
}

/**
 * Get equal column width for MUI grid
 * Given list of item data, if data is available calculate how much column should be taken by each item
 * ex. 3 out of 4 item data is not null -> 12 / 3 = 4 columns for each item 
 * @param itemData 
 */
export function getEqualColumnWidth(itemData: { [key: string]: string | null } | undefined) {
  if (isUndefined(itemData)) return;
  const numberOfItems = reduce(
    itemData,
    (numItems, item) => {
      if (!isNull(item)) {
        return numItems + 1;
      }
      return numItems;
    },
    0,
  );

  return 12 / numberOfItems;
}


/**
 * Helper to get rate in %
 * @param value1 numerator value
 * @param value2 denominator value
 * @returns rate % in string
 */
export  function getRate(value1?: number | null, value2?: number | null) {
  if (!isNumber(value1) || !isNumber(value2)) return undefined;
  if (value1 === 0 || value2 === 0) return '0%';

  return `${Math.ceil((value1 / value2) * 100).toString()}%`;
}

/**
 * Helper to get total video duration by targetId
 * total video duration = personalized intro clip duration (if any) + non-personalized clips duration
 * @param targets list of targets
 * @param timeline project time the targets belong to
 * @returns
 */
export function getTargetTotalDuration(targets: ITarget[], timeline: IVideoProjectShareFragment['timeline']) {
  const targetIds = targets.map((target) => target.id);

  const videoDuration = sum(compact(timeline.filter(isSingleClipSlot).map((c) => getTrimmedDuration(c.start, c.end, c.videoClip?.durationInSeconds) ?? 0)));
  const personalizedClips = timeline.find(isPersonalizedClipSlot)?.personalizedClips ?? [];
  const personalizedClipDurations: { [targetid: string]: number | undefined } = mapValues(
    keyBy(personalizedClips, 'targetId'),
    (targetVideo) => {
      return getTrimmedDuration(targetVideo.start, targetVideo.end, targetVideo.videoClip.durationInSeconds ) ?? 0;
    },
  );

  const targetClipDurations: Map<string, number> = new Map();
  targetIds.forEach((targetId) => {
    targetClipDurations.set(targetId, floor((personalizedClipDurations[targetId] ?? 0) + videoDuration));
  });
  
  return targetClipDurations;
}
