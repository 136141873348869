import { ITargetInput, IVideoClip } from 'data/_generated';
import { isEmpty, isNil } from 'lodash';

export const toTimeString = (seconds: number, showMilliSeconds = true) => {
  const hoursNum = Math.floor(seconds / 3600);
  let hoursStr = hoursNum.toString();

  const minutesNum = Math.floor((seconds - hoursNum * 3600) / 60);
  let minutesStr = minutesNum.toString();

  const calcSecondsNum = seconds - hoursNum * 3600 - minutesNum * 60;
  let calcSecondsStr = calcSecondsNum.toString();
  // add 0 if value < 10; Example: 2 => 02
  if (hoursNum < 10) {
    hoursStr = '0' + hoursNum.toString();
  }
  if (minutesNum < 10) {
    minutesStr = '0' + minutesNum.toString();
  }
  if (calcSecondsNum < 10) {
    calcSecondsStr = '0' + calcSecondsNum.toString();
  }

  // matches the decimal point and the digits after it e.g if the number is 4.567 it matches .567
  const maltissaRegex = /\..*$/;
  const millisec = calcSecondsStr.match(maltissaRegex);
  return (
    hoursStr +
    ':' +
    minutesStr +
    ':' +
    calcSecondsStr.replace(maltissaRegex, '') +
    (showMilliSeconds ? (millisec ? millisec[0] : '.000') : '')
  );
};

export const readFileAsBase64 = async (file: Blob): Promise<string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result == null) {
        reject();
      } else {
        resolve(reader.result);
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const download = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '');
  link.click();
};

/**
 * function to replace token from a string
 * @param template string to replace token from
 * @param from token
 * @param to string to replace to
 * @returns 
 */
export const replaceToken = (template: string, from: string, to?: string) => {
  return template.split(from).join(to ?? '');
};

/**
 * function to determine if the clip has been saved to the library
 * Currently determining this based on the presence of clip title
 * @param clip 
 * @returns boolean
 */
export const isClipSaved = (clip: IVideoClip) => {
  return !isNil(clip.title);
};

/**
 * Helper function to convert ITargetInput from TargetForm to BE compatible ITargetInput
 *
 * target id is set to empty string instead of undefined due to limitations from React Hook Form (refer to NewTargetFormDataInput) 
 * id should be undefined for new target as BE depends on the absence of id to determine whether it needs to create or update a target
 *
 * @param target ITargetInput
 * @returns ITargetInput
 */
export function convertToUpdateTarget(target: ITargetInput): ITargetInput  {
  return { email: target.email, name: target.name, id: isEmpty(target.id) ? undefined : target.id };
}