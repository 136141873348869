
import { Box } from '@mui/material';

export default function VideoWebCamGrid() {
  return <>
    <Box
      sx={{
        position: 'absolute',
        top: '33.3%',
        left: '0',
        width: '100%',
        height: '33.3%',
        pointerEvents: 'none',
        border: '2px dashed #fff',
        borderLeft: 'none',
        borderRight: 'none',
      }}
    ></Box>
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '33.3%',
        width: '33.3%',
        height: '100%',
        pointerEvents: 'none',
        border: '2px dashed #fff',
        borderTop: 'none',
        borderBottom: 'none',
      }}
    >
    </Box>
  </>;
}