
import { toDurationString } from '../../common/utils/string';
import { t } from '@lingui/macro';
import { isUndefined } from 'lodash';

// TODO: use config to get base url instead of window.location.origin
export const absoluteLink = (id: string) => `${window.location.origin}/s/${id}`;

type EmbedOptions = {
  /** the duration of the clip */
  duration?: number;
  /** whether to add a link */
  addLink?: boolean;

};
export const embedString = (
  id: string,
  thumbnailLocation: string,
  { addLink = false, duration }: EmbedOptions) => {
  const durationText = isUndefined(duration) ? '' : ` (${toDurationString(duration)})`;
  const alt = t`▶️ Watch video ${durationText}`;
  let embed = `<a href="${absoluteLink(id)}">
      <img
        style="width:320px"
        width="320" 
        alt="${alt}" 
        src="${thumbnailLocation}"/>
    </a>`;

  if (addLink) {
    embed += '<br>';
    embed += `<a href="${absoluteLink(id)}">${alt}</a>`;
  }
  return embed;
};